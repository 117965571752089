.hd {
  width: 100%;
  max-width: 86.5vw;
  text-transform: uppercase;
  margin: 0 auto;
  text-align: inherit;
  display: block;
}
.hd.xxsmall,
.label.xxsmall,
.span.xxsmall,
.p.xxsmall {
  font-size: 5.4vw;
  line-height: 5.9vw;
}
.hd.xsmall,
.label.xsmall,
.span.xsmall,
.p.xsmall {
  font-size: 2.5rem;
  line-height: 3rem;
}
.hd.small,
.label.small,
.span.small,
.p.small {
  font-size: 3rem;
  line-height: 3.6rem;
}
.hd.large,
.label.large,
.span.large,
.p.large {
  font-size: 5.4vw;
  line-height: 5.6vw;
}
.hd.xlarge,
.label.xlarge,
.span.xlarge,
.p.xlarge {
  font-size: 10.7vw;
  line-height: 11.2vw;
}
.hd.xxlarge,
.label.xxlarge,
.span.xxlarge,
.p.xxlarge {
  font-size: 16vw;
  line-height: 14.7vw;
  margin: 0.4em 0;
}
@media (min-width: 950px) {
  .hd.xxsmall,
  .label.xxsmall,
  .span.xxsmall,
  .p.xxsmall {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  .hd.xsmall,
  .label.xsmall,
  .span.xsmall,
  .p.xsmall {
    font-size: 2.5rem;
    line-height: 3rem;
  }
  .hd.small,
  .label.small,
  .span.small,
  .p.small {
    font-size: 3rem;
    line-height: 3.6rem;
  }
  .hd.large,
  .label.large,
  .span.large,
  .p.large {
    font-size: 3.5rem;
    line-height: 4.2rem;
  }
  .hd.xlarge,
  .label.xlarge,
  .span.xlarge,
  .p.xlarge {
    font-size: 4rem;
    line-height: 4rem;
  }
  .hd.xxlarge,
  .label.xxlarge,
  .span.xxlarge,
  .p.xxlarge {
    font-size: 6rem;
    line-height: 7.2rem;
    margin: 0.4em 0;
  }
  .hd.xxxlarge,
  .label.xxxlarge,
  .span.xxxlarge,
  .p.xxxlarge {
    font-size: 8rem;
    line-height: normal;
  }
}
.hd.italic,
.label.italic,
.span.italic,
.p.italic {
  font-style: italic;
}
.hd.semibold,
.label.semibold,
.span.semibold,
.p.semibold {
  font-weight: 600;
}
.hd.bold,
.label.bold,
.span.bold,
.p.bold {
  font-weight: bold;
}
.hd.mediumblue,
.label.mediumblue,
.span.mediumblue,
.p.mediumblue {
  color: #99dbf7;
}
.hd.powderblue,
.label.powderblue,
.span.powderblue,
.p.powderblue {
  color: #00a5eb;
}
.hd.dodgerblue,
.label.dodgerblue,
.span.dodgerblue,
.p.dodgerblue {
  color: #0891d8;
}
.hd.royalblue,
.label.royalblue,
.span.royalblue,
.p.royalblue {
  color: #1e55a0;
}
.hd.cta-yellow,
.label.cta-yellow,
.span.cta-yellow,
.p.cta-yellow {
  color: #feb700;
}
.hd.white,
.label.white,
.span.white,
.p.white {
  color: #fff;
}
