.keno-top-spot .keno-section {
  padding: 12vw 0;
}
@media screen and (min-width: 950px) {
  .keno-top-spot .keno-section {
    padding: 5rem 0;
  }
}
.keno-top-spot .keno-section .keno-video-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  display: none;
}
.keno-top-spot .keno-section .keno-video-wrapper video::-webkit-media-controls {
  display: none !important;
}
.keno-top-spot .keno-section .keno-video-wrapper video::-webkit-media-controls-start-playback-button {
  display: none !important;
}
.keno-top-spot .keno-section .keno-video-wrapper video {
  object-fit: inherit;
}
@media (min-width: 950px) {
  .keno-top-spot .keno-section .keno-video-wrapper {
    display: block;
  }
}
.keno-top-spot .keno-section .keno-video {
  min-width: 100%;
  min-height: 100%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  transition: opacity 0.5s ease-in-out;
}
.keno-top-spot .keno-section .keno-video.is-loading {
  opacity: 0;
}
.keno-top-spot .keno-section .keno-video.can-playthrough {
  opacity: 1;
}
.keno-top-spot .keno-section .keno-content-wrapper > * {
  position: relative;
  z-index: 2;
}
.keno-top-spot .keno-section h1.hd {
  margin: 4vw auto;
  font-size: 6.7vw;
  line-height: normal;
}
@media (min-width: 950px) {
  .keno-top-spot .keno-section h1.hd {
    margin: 2rem auto 1rem;
    word-spacing: normal;
    font-size: 4rem;
    line-height: 4rem;
  }
}
.keno-top-spot .keno-section h1.hd.big-header {
  word-spacing: 25vw;
  font-size: 16vw;
  line-height: 14.7vw;
}
@media (min-width: 950px) {
  .keno-top-spot .keno-section h1.hd.big-header {
    word-spacing: normal;
    font-size: 6rem;
    line-height: normal;
  }
}
.keno-top-spot .keno-section .btn {
  margin-bottom: 2vw;
  clear: both;
}
@media (min-width: 950px) {
  .keno-top-spot .keno-section .btn {
    margin-bottom: 2rem;
  }
}
.keno-top-spot .keno-section .help-link {
  text-decoration: none;
  border-bottom: .1rem solid #80a8b7;
  display: inline-block;
  line-height: 4.2vw;
  margin-top: 2rem;
  clear: both;
}
@media (min-width: 950px) {
  .keno-top-spot .keno-section .help-link {
    line-height: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }
}
.keno-top-spot .keno-section .help-link:hover {
  border-color: transparent;
}
