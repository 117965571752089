@import url("../../Mixins/_mixins.less");

.region-keno {
  .how-to-play-section {
    .headline {
      font-style: italic;
      color: @keno-powderblue;
      text-transform: uppercase;
    }

    .text {
      color: @keno-light-grey;
    }

    .video-headline {
      color: @keno-powderblue;
      text-transform: uppercase;
    }

    .video-element {
      .video-frame {
        background: @keno-powderblue;
      }

      .video-play-button {
        .play-button {
          border-color: transparent transparent transparent @keno-powderblue;
        }
      }
    }
  }
}