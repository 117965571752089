@import url("../Mixins/_mixins.less");

.keno-game-promotion-spots-container {
  @media (min-width: @keno-desktop-width) {
    display: flex;
  }

  .keno-fast-play {
    @media (min-width: @keno-desktop-width) {
      flex: 1;
    }

    .keno-section {
      height: 100%;

      .fast-play-game-spot {
        background: transparent;
        padding: 12vw;

        @media (min-width: @keno-desktop-width) {
          float: left;
          padding: 5rem 11rem 5rem;
        }

        .fastplay-headline {
          max-width: 86.5vw;
          font-size: 10.7vw;
          font-style: italic;
          font-weight: bold;
          line-height: 11.2vw;
          text-transform: uppercase;
          color: @keno-royalblue;
          margin: 0 auto 1vw;

          @media (min-width: @keno-desktop-width) {
            font-size: 4rem;
            line-height: 4rem;
            margin-bottom: 1rem;
          }
        }

        .fastplay-subheadline {
          max-width: 86.5vw;
          font-size: 5.4vw;
          font-style: italic;
          font-weight: bold;
          line-height: 5.9vw;
          text-transform: uppercase;
          color: @keno-powderblue;
          margin: 0 auto;

          @media (min-width: @keno-desktop-width) {
            font-size: 2rem;
            line-height: 2.4rem;
          }
        }

        .btn.cta {
          margin: 4vw 0 0;

          @media (min-width: @keno-desktop-width) {
            margin: 2rem 0 0;
          }
        }
      }
    }

    &:first-of-type {
      .keno-section {
        .fast-play-game-spot {
          @media (min-width: @keno-desktop-width) {
            float: right;
          }
        }
      }
    }
  }
}
