.keno-winning-numbers .keno-section {
  padding: 12vw 0;
}
@media (min-width: 950px) {
  .keno-winning-numbers .keno-section {
    padding: 5rem 0;
  }
}
@media (min-width: 950px) {
  .keno-winning-numbers .keno-section .news-heading {
    padding: 14rem 0 6rem;
  }
}
.keno-winning-numbers .keno-section .keno-content-wrapper.bring-to-front {
  z-index: 3;
}
.keno-winning-numbers .keno-section .keno-content-wrapper .select-date-section {
  width: 86.667vw;
  margin: auto;
  position: relative;
}
@media (min-width: 950px) {
  .keno-winning-numbers .keno-section .keno-content-wrapper .select-date-section {
    width: 58rem;
  }
}
.keno-winning-numbers .keno-section .keno-content-wrapper .select-date-section h2.hd {
  font-size: 5.333vw;
  margin-bottom: 2rem;
}
@media (min-width: 950px) {
  .keno-winning-numbers .keno-section .keno-content-wrapper .select-date-section h2.hd {
    font-size: 2rem;
  }
}
.keno-winning-numbers .keno-section .keno-content-wrapper .select-date-section .select-dropdown {
  max-width: 100%;
}
.keno-winning-numbers .keno-section .keno-content-wrapper .ticket-config:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
@media (min-width: 950px) {
  .keno-winning-numbers .keno-section .keno-content-wrapper .ticket-config {
    margin-bottom: 3rem;
  }
}
@media (min-width: 950px) {
  .keno-winning-numbers .keno-section .keno-content-wrapper .ticket-config .box-half {
    width: 46.875%;
  }
  .keno-winning-numbers .keno-section .keno-content-wrapper .ticket-config .box-half:first-of-type {
    float: left;
  }
  .keno-winning-numbers .keno-section .keno-content-wrapper .ticket-config .box-half:last-of-type {
    float: right;
  }
}
.keno-winning-numbers .keno-section .keno-content-wrapper h3.hd {
  font-size: 5.333vw;
  padding: 4vw 0 2.4vw;
}
@media (min-width: 950px) {
  .keno-winning-numbers .keno-section .keno-content-wrapper h3.hd {
    font-size: 2rem;
    padding: 3rem 0;
  }
}
.keno-winning-numbers .keno-section .keno-content-wrapper .number-picker-container {
  padding: 0 15.6vw;
}
@media (min-width: 950px) {
  .keno-winning-numbers .keno-section .keno-content-wrapper .number-picker-container {
    padding: 0;
  }
}
.keno-winning-numbers .keno-section .keno-content-wrapper .number-picker-container .cell {
  margin: 2.6vw;
}
@media (min-width: 950px) {
  .keno-winning-numbers .keno-section .keno-content-wrapper .number-picker-container .cell {
    margin: 0.43rem;
  }
}
