.keno-receipt .keno-section {
  padding: 5.4vw 0 5.4vw 0;
}
@media (min-width: 950px) {
  .keno-receipt .keno-section {
    padding: 4rem 0;
  }
}
.keno-receipt .keno-section .keno-content-wrapper {
  width: 100%;
  max-width: 86.7vw;
}
@media (min-width: 950px) {
  .keno-receipt .keno-section .keno-content-wrapper {
    max-width: 96rem;
  }
}
.keno-receipt .keno-section .keno-content-wrapper .hr {
  margin: 6vw auto 4vw;
}
@media (min-width: 950px) {
  .keno-receipt .keno-section .keno-content-wrapper .hr {
    margin: 3.5rem auto 2.5rem;
  }
}
.keno-receipt .keno-section .keno-content-wrapper .p {
  font-size: 3.75vw;
  margin: 0 auto 2vw;
  color: #000;
}
.keno-receipt .keno-section .keno-content-wrapper .p.word-width-limit {
  max-width: 60vw;
}
@media (min-width: 950px) {
  .keno-receipt .keno-section .keno-content-wrapper .p {
    font-size: 2rem;
    line-height: 2.4rem;
    margin: 0 auto 2rem;
  }
  .keno-receipt .keno-section .keno-content-wrapper .p.word-width-limit {
    max-width: 100%;
  }
}
.keno-receipt .keno-section .keno-content-wrapper .p .a {
  color: #000;
  border-bottom: .1rem solid #000;
}
.keno-receipt .keno-section .keno-content-wrapper .p.countdown-fallback {
  margin-top: 2vw;
}
@media (min-width: 950px) {
  .keno-receipt .keno-section .keno-content-wrapper .p.countdown-fallback {
    margin-top: 2rem;
  }
}
.keno-receipt .keno-section .keno-content-wrapper .p.countdown-fallback.hide {
  display: none;
}
.keno-receipt .receipt-confirmation {
  margin: auto;
}
@media (min-width: 950px) {
  .keno-receipt .receipt-confirmation {
    width: 100%;
    max-width: 96rem;
  }
}
.keno-receipt .receipt-confirmation svg {
  margin-bottom: 4vw;
}
@media (min-width: 950px) {
  .keno-receipt .receipt-confirmation svg {
    margin-bottom: 2.5rem;
  }
}
.keno-receipt .xlarge {
  font-size: 6.6vw;
  line-height: 6.6vw;
  margin-bottom: 2vw;
}
@media (min-width: 950px) {
  .keno-receipt .xlarge {
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: 1rem;
  }
}
.keno-receipt .xsmall {
  font-size: 5.25vw;
  line-height: 6vw;
}
@media (min-width: 950px) {
  .keno-receipt .xsmall {
    font-size: 2.5rem;
    line-height: 2rem;
  }
}
.keno-receipt span {
  display: block;
  margin: 0 .2rem;
}
.keno-receipt span.xxxlarge {
  font-size: 7.5vw;
  line-height: 8vw;
}
@media (min-width: 950px) {
  .keno-receipt span.xxxlarge {
    font-size: 8rem;
    line-height: 8rem;
  }
}
.keno-receipt span.xxsmall {
  font-size: 3.75vw;
}
@media (min-width: 950px) {
  .keno-receipt span.xxsmall {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}
.keno-receipt .svg-checkmark {
  margin: 0 auto 3.5vw;
}
@media (min-width: 950px) {
  .keno-receipt .svg-checkmark {
    margin: 0 auto 2rem;
  }
}
