.keno-classic-game .number-picker-footer,
.keno-system-game .number-picker-footer,
.keno-winning-numbers .number-picker-footer {
  padding: 3.334vw 0 2.134vw;
  position: relative;
}
.keno-classic-game .number-picker-footer:after,
.keno-system-game .number-picker-footer:after,
.keno-winning-numbers .number-picker-footer:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
@media (min-width: 950px) {
  .keno-classic-game .number-picker-footer,
  .keno-system-game .number-picker-footer,
  .keno-winning-numbers .number-picker-footer {
    padding: 2rem 0 1.1rem;
  }
}
.keno-classic-game .number-picker-footer .counter-box,
.keno-system-game .number-picker-footer .counter-box,
.keno-winning-numbers .number-picker-footer .counter-box {
  font-size: 3.734vw;
  font-weight: bold;
  color: #1e55a0;
  text-transform: uppercase;
  padding: 3.334vw 0;
  display: inline-block;
  float: left;
}
@media (min-width: 950px) {
  .keno-classic-game .number-picker-footer .counter-box,
  .keno-system-game .number-picker-footer .counter-box,
  .keno-winning-numbers .number-picker-footer .counter-box {
    font-size: 2rem;
    padding: 0.7rem 0 0.6rem;
  }
}
.keno-classic-game .number-picker-footer .counter-box .total,
.keno-system-game .number-picker-footer .counter-box .total,
.keno-winning-numbers .number-picker-footer .counter-box .total {
  color: #fff;
}
.keno-classic-game .number-picker-footer .buttons-container,
.keno-system-game .number-picker-footer .buttons-container,
.keno-winning-numbers .number-picker-footer .buttons-container {
  float: right;
  white-space: nowrap;
}
.keno-classic-game .number-picker-footer .buttons-container .button,
.keno-system-game .number-picker-footer .buttons-container .button,
.keno-winning-numbers .number-picker-footer .buttons-container .button {
  min-width: 23.2vw;
  font-size: 3.734vw;
  font-weight: bold;
  color: #4f5254;
  text-align: left;
  background-color: #ebf8fd;
  margin-left: 3.334vw;
  padding: 2.767vw 3.334vw;
  border-radius: 6px;
  display: inline-block;
  text-transform: uppercase;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  -webkit-transition: opacity 0.25s, background-color 0.25s, color 0.25s;
  -moz-transition: opacity 0.25s, background-color 0.25s, color 0.25s;
  -ms-transition: opacity 0.25s, background-color 0.25s, color 0.25s;
  -o-transition: opacity 0.25s, background-color 0.25s, color 0.25s;
  transition: opacity 0.25s, background-color 0.25s, color 0.25s;
  user-select: none;
}
.keno-classic-game .number-picker-footer .buttons-container .button:first-child,
.keno-system-game .number-picker-footer .buttons-container .button:first-child,
.keno-winning-numbers .number-picker-footer .buttons-container .button:first-child {
  margin-left: 0;
}
@media (min-width: 950px) {
  .keno-classic-game .number-picker-footer .buttons-container .button,
  .keno-system-game .number-picker-footer .buttons-container .button,
  .keno-winning-numbers .number-picker-footer .buttons-container .button {
    min-width: inherit;
    font-size: 1.4rem;
    margin-left: 2rem;
    padding: 1.2rem 4rem 1.1rem 1.4rem;
    cursor: pointer;
  }
}
.keno-classic-game .number-picker-footer .buttons-container .button.inactive,
.keno-system-game .number-picker-footer .buttons-container .button.inactive,
.keno-winning-numbers .number-picker-footer .buttons-container .button.inactive {
  -webkit-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
  cursor: default;
}
.keno-classic-game .number-picker-footer .buttons-container .autogenerate-button .icon,
.keno-system-game .number-picker-footer .buttons-container .autogenerate-button .icon,
.keno-winning-numbers .number-picker-footer .buttons-container .autogenerate-button .icon,
.keno-classic-game .number-picker-footer .buttons-container .reset-row-button .icon,
.keno-system-game .number-picker-footer .buttons-container .reset-row-button .icon,
.keno-winning-numbers .number-picker-footer .buttons-container .reset-row-button .icon {
  width: 2.934vw;
  height: 3.7vw;
  fill: #4f5254;
  position: absolute;
  top: 3.4vw;
  right: 3.7vw;
  -webkit-transition: fill 0.25s;
  -moz-transition: fill 0.25s;
  -ms-transition: fill 0.25s;
  -o-transition: fill 0.25s;
  transition: fill 0.25s;
  overflow: visible;
}
@media (min-width: 950px) {
  .keno-classic-game .number-picker-footer .buttons-container .autogenerate-button .icon,
  .keno-system-game .number-picker-footer .buttons-container .autogenerate-button .icon,
  .keno-winning-numbers .number-picker-footer .buttons-container .autogenerate-button .icon,
  .keno-classic-game .number-picker-footer .buttons-container .reset-row-button .icon,
  .keno-system-game .number-picker-footer .buttons-container .reset-row-button .icon,
  .keno-winning-numbers .number-picker-footer .buttons-container .reset-row-button .icon {
    width: 1.6rem;
    height: 1.6rem;
    top: 1.1rem;
    right: 1.4rem;
  }
}
.keno-classic-game .number-picker-footer .autogenerate-box,
.keno-system-game .number-picker-footer .autogenerate-box,
.keno-winning-numbers .number-picker-footer .autogenerate-box {
  display: inline-block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.keno-classic-game .number-picker-footer .autogenerate-box .autogenerate-button,
.keno-system-game .number-picker-footer .autogenerate-box .autogenerate-button,
.keno-winning-numbers .number-picker-footer .autogenerate-box .autogenerate-button {
  position: relative;
}
.keno-classic-game .number-picker-footer .autogenerate-box .autogenerate-button:after,
.keno-system-game .number-picker-footer .autogenerate-box .autogenerate-button:after,
.keno-winning-numbers .number-picker-footer .autogenerate-box .autogenerate-button:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 1.9vw solid transparent;
  border-right: 1.9vw solid transparent;
  border-bottom: 1.8vw solid #fff;
  margin-top: 1.666vw;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-1.5vw);
  display: none;
}
@media (min-width: 950px) {
  .keno-classic-game .number-picker-footer .autogenerate-box .autogenerate-button:after,
  .keno-system-game .number-picker-footer .autogenerate-box .autogenerate-button:after,
  .keno-winning-numbers .number-picker-footer .autogenerate-box .autogenerate-button:after {
    border-left: .7rem solid transparent;
    border-right: .7rem solid transparent;
    border-bottom: .7rem solid #fff;
    margin-top: 1.3rem;
    transform: translateX(-50%);
  }
}
.keno-classic-game .number-picker-footer .autogenerate-box .options,
.keno-system-game .number-picker-footer .autogenerate-box .options,
.keno-winning-numbers .number-picker-footer .autogenerate-box .options {
  width: 75.235vw;
  background-color: #fff;
  position: absolute;
  top: 16.4vw;
  left: 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  display: none;
  z-index: 1;
}
@media (min-width: 950px) {
  .keno-classic-game .number-picker-footer .autogenerate-box .options,
  .keno-system-game .number-picker-footer .autogenerate-box .options,
  .keno-winning-numbers .number-picker-footer .autogenerate-box .options {
    width: 58rem;
    top: 8rem;
  }
}
.keno-classic-game .number-picker-footer .autogenerate-box .options .item,
.keno-system-game .number-picker-footer .autogenerate-box .options .item,
.keno-winning-numbers .number-picker-footer .autogenerate-box .options .item {
  width: 50%;
  font-size: 3.2vw;
  font-weight: bold;
  color: #0891d8;
  text-align: center;
  float: left;
  padding: 4vw 0;
  border-width: 0 .2rem .2rem 0;
  border-style: solid;
  border-color: #cdd3d7;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
@media (min-width: 950px) {
  .keno-classic-game .number-picker-footer .autogenerate-box .options .item,
  .keno-system-game .number-picker-footer .autogenerate-box .options .item,
  .keno-winning-numbers .number-picker-footer .autogenerate-box .options .item {
    width: 20%;
    font-size: 1.4rem;
    padding: 1.1rem 0 1rem;
    border-width: 0 .2rem .2rem 0;
    cursor: pointer;
  }
}
.keno-classic-game .number-picker-footer .autogenerate-box .options .item:first-child:before,
.keno-system-game .number-picker-footer .autogenerate-box .options .item:first-child:before,
.keno-winning-numbers .number-picker-footer .autogenerate-box .options .item:first-child:before {
  content: '';
  height: 3.4vw;
  background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 30%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 30%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 30%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0);
  position: absolute;
  top: 0;
  left: 4vw;
  right: -1vw;
}
@media (min-width: 950px) {
  .keno-classic-game .number-picker-footer .autogenerate-box .options .item:first-child:before,
  .keno-system-game .number-picker-footer .autogenerate-box .options .item:first-child:before,
  .keno-winning-numbers .number-picker-footer .autogenerate-box .options .item:first-child:before {
    height: 3.4rem;
    left: 1rem;
    right: 80%;
  }
}
.keno-classic-game .number-picker-footer .autogenerate-box .options .item.hidden,
.keno-system-game .number-picker-footer .autogenerate-box .options .item.hidden,
.keno-winning-numbers .number-picker-footer .autogenerate-box .options .item.hidden,
.keno-classic-game .number-picker-footer .autogenerate-box .options .item.hidden.inactive,
.keno-system-game .number-picker-footer .autogenerate-box .options .item.hidden.inactive,
.keno-winning-numbers .number-picker-footer .autogenerate-box .options .item.hidden.inactive {
  color: #fff;
  cursor: default;
}
.keno-classic-game .number-picker-footer .autogenerate-box .options .item:nth-child(2n),
.keno-system-game .number-picker-footer .autogenerate-box .options .item:nth-child(2n),
.keno-winning-numbers .number-picker-footer .autogenerate-box .options .item:nth-child(2n) {
  border-width: 0 0 .2rem 0;
}
.keno-classic-game .number-picker-footer .autogenerate-box .options .item:last-child,
.keno-system-game .number-picker-footer .autogenerate-box .options .item:last-child,
.keno-winning-numbers .number-picker-footer .autogenerate-box .options .item:last-child {
  border-width: 0 0 0 0;
}
.keno-classic-game .number-picker-footer .autogenerate-box .options .item:nth-last-child(2),
.keno-system-game .number-picker-footer .autogenerate-box .options .item:nth-last-child(2),
.keno-winning-numbers .number-picker-footer .autogenerate-box .options .item:nth-last-child(2) {
  border-width: 0 .2rem 0 0;
}
.keno-classic-game .number-picker-footer .autogenerate-box .options .item.inactive,
.keno-system-game .number-picker-footer .autogenerate-box .options .item.inactive,
.keno-winning-numbers .number-picker-footer .autogenerate-box .options .item.inactive {
  color: #cdd3d7;
}
@media (min-width: 950px) {
  .keno-classic-game .number-picker-footer .autogenerate-box .options .item.inactive,
  .keno-system-game .number-picker-footer .autogenerate-box .options .item.inactive,
  .keno-winning-numbers .number-picker-footer .autogenerate-box .options .item.inactive {
    cursor: default;
  }
}
@media (min-width: 950px) {
  .keno-classic-game .number-picker-footer .autogenerate-box .options.ten-cells .item:nth-child(2n),
  .keno-system-game .number-picker-footer .autogenerate-box .options.ten-cells .item:nth-child(2n),
  .keno-winning-numbers .number-picker-footer .autogenerate-box .options.ten-cells .item:nth-child(2n) {
    border-width: 0 .2rem .2rem 0;
  }
  .keno-classic-game .number-picker-footer .autogenerate-box .options.ten-cells .item:nth-child(5n),
  .keno-system-game .number-picker-footer .autogenerate-box .options.ten-cells .item:nth-child(5n),
  .keno-winning-numbers .number-picker-footer .autogenerate-box .options.ten-cells .item:nth-child(5n) {
    border-width: 0 0 .2rem 0;
  }
  .keno-classic-game .number-picker-footer .autogenerate-box .options.ten-cells .item:nth-last-child(-n+5),
  .keno-system-game .number-picker-footer .autogenerate-box .options.ten-cells .item:nth-last-child(-n+5),
  .keno-winning-numbers .number-picker-footer .autogenerate-box .options.ten-cells .item:nth-last-child(-n+5) {
    border-width: 0 .2rem 0 0;
  }
  .keno-classic-game .number-picker-footer .autogenerate-box .options.ten-cells .item:last-child,
  .keno-system-game .number-picker-footer .autogenerate-box .options.ten-cells .item:last-child,
  .keno-winning-numbers .number-picker-footer .autogenerate-box .options.ten-cells .item:last-child {
    border-width: 0 0 0 0;
  }
}
@media (min-width: 950px) {
  .keno-classic-game .number-picker-footer .autogenerate-box .options.eight-cells .item,
  .keno-system-game .number-picker-footer .autogenerate-box .options.eight-cells .item,
  .keno-winning-numbers .number-picker-footer .autogenerate-box .options.eight-cells .item {
    width: 25%;
  }
  .keno-classic-game .number-picker-footer .autogenerate-box .options.eight-cells .item:nth-child(2n),
  .keno-system-game .number-picker-footer .autogenerate-box .options.eight-cells .item:nth-child(2n),
  .keno-winning-numbers .number-picker-footer .autogenerate-box .options.eight-cells .item:nth-child(2n) {
    border-width: 0 .2rem .2rem 0;
  }
  .keno-classic-game .number-picker-footer .autogenerate-box .options.eight-cells .item:nth-child(4n),
  .keno-system-game .number-picker-footer .autogenerate-box .options.eight-cells .item:nth-child(4n),
  .keno-winning-numbers .number-picker-footer .autogenerate-box .options.eight-cells .item:nth-child(4n) {
    border-width: 0 0 .2rem 0;
  }
  .keno-classic-game .number-picker-footer .autogenerate-box .options.eight-cells .item:nth-last-child(-n+4),
  .keno-system-game .number-picker-footer .autogenerate-box .options.eight-cells .item:nth-last-child(-n+4),
  .keno-winning-numbers .number-picker-footer .autogenerate-box .options.eight-cells .item:nth-last-child(-n+4) {
    border-width: 0 .2rem 0 0;
  }
  .keno-classic-game .number-picker-footer .autogenerate-box .options.eight-cells .item:last-child,
  .keno-system-game .number-picker-footer .autogenerate-box .options.eight-cells .item:last-child,
  .keno-winning-numbers .number-picker-footer .autogenerate-box .options.eight-cells .item:last-child {
    border-width: 0 0 0 0;
  }
}
.keno-classic-game .number-picker-footer .autogenerate-box.active .button,
.keno-system-game .number-picker-footer .autogenerate-box.active .button,
.keno-winning-numbers .number-picker-footer .autogenerate-box.active .button {
  color: #fff;
  background-color: #0891d8;
}
.keno-classic-game .number-picker-footer .autogenerate-box.active .button:after,
.keno-system-game .number-picker-footer .autogenerate-box.active .button:after,
.keno-winning-numbers .number-picker-footer .autogenerate-box.active .button:after {
  display: block;
}
.keno-classic-game .number-picker-footer .autogenerate-box.active .button .icon,
.keno-system-game .number-picker-footer .autogenerate-box.active .button .icon,
.keno-winning-numbers .number-picker-footer .autogenerate-box.active .button .icon {
  fill: #fff;
}
.keno-classic-game .number-picker-footer .autogenerate-box.active .options,
.keno-system-game .number-picker-footer .autogenerate-box.active .options,
.keno-winning-numbers .number-picker-footer .autogenerate-box.active .options {
  display: block;
}
.keno-classic-game .number-picker-container,
.keno-system-game .number-picker-container,
.keno-winning-numbers .number-picker-container {
  margin: 0 -1.5%;
  position: relative;
  white-space: normal;
  -webkit-transform: translateZ(0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (min-width: 950px) {
  .keno-classic-game .number-picker-container,
  .keno-system-game .number-picker-container,
  .keno-winning-numbers .number-picker-container {
    margin: 0 -1rem;
  }
}
.keno-classic-game .number-picker-container .cell,
.keno-system-game .number-picker-container .cell,
.keno-winning-numbers .number-picker-container .cell {
  width: 9.055vw;
  height: 9.055vw;
  color: #fff;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 1vw;
}
@media (min-width: 950px) {
  .keno-classic-game .number-picker-container .cell,
  .keno-system-game .number-picker-container .cell,
  .keno-winning-numbers .number-picker-container .cell {
    width: 4rem;
    height: 0;
    padding-top: 4rem;
    margin: 1rem;
    cursor: pointer;
  }
}
.keno-classic-game .number-picker-container .cell:after,
.keno-system-game .number-picker-container .cell:after,
.keno-winning-numbers .number-picker-container .cell:after {
  content: '';
  position: absolute;
  top: -1vw;
  left: -1vw;
  right: -1vw;
  bottom: -1vw;
}
@media (min-width: 950px) {
  .keno-classic-game .number-picker-container .cell:after,
  .keno-system-game .number-picker-container .cell:after,
  .keno-winning-numbers .number-picker-container .cell:after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.keno-classic-game .number-picker-container .cell .box,
.keno-system-game .number-picker-container .cell .box,
.keno-winning-numbers .number-picker-container .cell .box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.keno-classic-game .number-picker-container .cell .box .front-face,
.keno-system-game .number-picker-container .cell .box .front-face,
.keno-winning-numbers .number-picker-container .cell .box .front-face,
.keno-classic-game .number-picker-container .cell .box .back-face,
.keno-system-game .number-picker-container .cell .box .back-face,
.keno-winning-numbers .number-picker-container .cell .box .back-face {
  height: 100%;
  width: 100%;
  display: block;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  position: absolute;
  top: 0;
  left: 0;
}
.keno-classic-game .number-picker-container .cell .box .front-face,
.keno-system-game .number-picker-container .cell .box .front-face,
.keno-winning-numbers .number-picker-container .cell .box .front-face {
  background-color: #0591da;
  visibility: visible;
}
.keno-classic-game .number-picker-container .cell .box .back-face,
.keno-system-game .number-picker-container .cell .box .back-face,
.keno-winning-numbers .number-picker-container .cell .box .back-face {
  background-color: #21375f;
  visibility: hidden;
}
.keno-classic-game .number-picker-container .cell.selected .box .front-face,
.keno-system-game .number-picker-container .cell.selected .box .front-face,
.keno-winning-numbers .number-picker-container .cell.selected .box .front-face {
  visibility: hidden;
}
.keno-classic-game .number-picker-container .cell.selected .box .back-face,
.keno-system-game .number-picker-container .cell.selected .box .back-face,
.keno-winning-numbers .number-picker-container .cell.selected .box .back-face {
  visibility: visible;
}
.keno-classic-game .number-picker-container .cell.flip-animation-in-progress,
.keno-system-game .number-picker-container .cell.flip-animation-in-progress,
.keno-winning-numbers .number-picker-container .cell.flip-animation-in-progress {
  transform-style: preserve-3d;
  transform: rotateX(0) translateZ(0);
  perspective: 21.14vw;
  animation: keno-flip-animation-timer 0.3s;
}
@keyframes keno-flip-face-animation {
  from {
    transform: rotateX(0deg) translate3D(0, 0, 4.5275vw);
  }
  to {
    transform: rotateX(-90deg) translate3D(0, 0, 4.5275vw);
  }
}
@keyframes keno-flip-back-animation {
  from {
    transform: rotateX(90deg) translate3D(0, 0, 4.5275vw);
  }
  to {
    transform: rotateX(0deg) translate3D(0, 0, 4.5275vw);
  }
}
@keyframes keno-reverse-flip-face-animation {
  from {
    transform: rotateX(-90deg) translate3D(0, 0, 4.5275vw);
  }
  to {
    transform: rotateX(0deg) translate3D(0, 0, 4.5275vw);
  }
}
@keyframes keno-reverse-flip-back-animation {
  from {
    transform: rotateX(0deg) translate3D(0, 0, 4.5275vw);
  }
  to {
    transform: rotateX(90deg) translate3D(0, 0, 4.5275vw);
  }
}
@media (min-width: 950px) {
  @keyframes keno-flip-face-animation {
    from {
      transform: rotateX(0deg) translate3D(0, 0, 2rem);
    }
    to {
      transform: rotateX(-90deg) translate3D(0, 0, 2rem);
    }
  }
  @keyframes keno-flip-back-animation {
    from {
      transform: rotateX(90deg) translate3D(0, 0, 2rem);
    }
    to {
      transform: rotateX(0deg) translate3D(0, 0, 2rem);
    }
  }
  @keyframes keno-reverse-flip-face-animation {
    from {
      transform: rotateX(-90deg) translate3D(0, 0, 2rem);
    }
    to {
      transform: rotateX(0deg) translate3D(0, 0, 2rem);
    }
  }
  @keyframes keno-reverse-flip-back-animation {
    from {
      transform: rotateX(0deg) translate3D(0, 0, 2rem);
    }
    to {
      transform: rotateX(90deg) translate3D(0, 0, 2rem);
    }
  }
}
@keyframes keno-flip-face-animation-IE {
  from {
    transform: perspective(21.14rem) rotateX(0deg) translate3D(0, 0, 2rem) scale(0.99);
  }
  to {
    transform: perspective(21.14rem) rotateX(-90deg) translate3D(0, 0, 2rem) scale(0.99);
  }
}
@keyframes keno-flip-back-animation-IE {
  from {
    transform: perspective(21.14rem) rotateX(90deg) translate3D(0, 0, 2rem) scale(0.99);
  }
  to {
    transform: perspective(21.14rem) rotateX(0deg) translate3D(0, 0, 2rem) scale(0.99);
  }
}
@keyframes keno-reverse-flip-face-animation-IE {
  from {
    transform: perspective(21.14rem) rotateX(-90deg) translate3D(0, 0, 2rem) scale(0.99);
  }
  to {
    transform: perspective(21.14rem) rotateX(0deg) translate3D(0, 0, 2rem) scale(0.99);
  }
}
@keyframes keno-reverse-flip-back-animation-IE {
  from {
    transform: perspective(21.14rem) rotateX(0deg) translate3D(0, 0, 2rem) scale(0.99);
  }
  to {
    transform: perspective(21.14rem) rotateX(90deg) translate3D(0, 0, 2rem) scale(0.99);
  }
}
.keno-classic-game .number-picker-container .cell.flip-animation-in-progress .box,
.keno-system-game .number-picker-container .cell.flip-animation-in-progress .box,
.keno-winning-numbers .number-picker-container .cell.flip-animation-in-progress .box {
  transform-style: preserve-3d;
  transform: translate3D(0, 0, -4.5275vw);
}
@media (min-width: 950px) {
  .keno-classic-game .number-picker-container .cell.flip-animation-in-progress .box,
  .keno-system-game .number-picker-container .cell.flip-animation-in-progress .box,
  .keno-winning-numbers .number-picker-container .cell.flip-animation-in-progress .box {
    transform: translate3D(0, 0, -2rem);
  }
}
.keno-classic-game .number-picker-container .cell.flip-animation-in-progress .box .front-face,
.keno-system-game .number-picker-container .cell.flip-animation-in-progress .box .front-face,
.keno-winning-numbers .number-picker-container .cell.flip-animation-in-progress .box .front-face,
.keno-classic-game .number-picker-container .cell.flip-animation-in-progress .box .back-face,
.keno-system-game .number-picker-container .cell.flip-animation-in-progress .box .back-face,
.keno-winning-numbers .number-picker-container .cell.flip-animation-in-progress .box .back-face {
  visibility: visible;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  will-change: transform;
}
.keno-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .front-face,
.keno-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .front-face,
.keno-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation .front-face {
  animation: keno-flip-face-animation 0.3s forwards linear;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .keno-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .front-face,
  .keno-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .front-face,
  .keno-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation .front-face {
    animation: keno-flip-face-animation-IE 0.3s forwards linear;
  }
}
.keno-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .back-face,
.keno-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .back-face,
.keno-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation .back-face {
  animation: keno-flip-back-animation 0.3s forwards linear;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .keno-classic-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .back-face,
  .keno-system-game .number-picker-container .cell.flip-animation-in-progress.select-number-animation .back-face,
  .keno-winning-numbers .number-picker-container .cell.flip-animation-in-progress.select-number-animation .back-face {
    animation: keno-flip-back-animation-IE 0.3s forwards linear;
  }
}
.keno-classic-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .front-face,
.keno-system-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .front-face,
.keno-winning-numbers .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .front-face {
  animation: keno-reverse-flip-face-animation 0.3s forwards linear;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .keno-classic-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .front-face,
  .keno-system-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .front-face,
  .keno-winning-numbers .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .front-face {
    animation: reverse-flip-face-animation-IE 0.3s forwards linear;
  }
}
.keno-classic-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .back-face,
.keno-system-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .back-face,
.keno-winning-numbers .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .back-face {
  animation: keno-reverse-flip-back-animation 0.3s forwards linear;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .keno-classic-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .back-face,
  .keno-system-game .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .back-face,
  .keno-winning-numbers .number-picker-container .cell.flip-animation-in-progress.deselect-number-animation .back-face {
    animation: keno-reverse-flip-back-animation-IE 0.3s forwards linear;
  }
}
.keno-classic-game .number-picker-container .cell .number,
.keno-system-game .number-picker-container .cell .number,
.keno-winning-numbers .number-picker-container .cell .number {
  width: 100%;
  height: 100%;
  font-size: 4vw;
  font-weight: bold;
  font-style: italic;
  line-height: 9.5vw;
  color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-font-smoothing: antialiased;
}
@media (min-width: 950px) {
  .keno-classic-game .number-picker-container .cell .number,
  .keno-system-game .number-picker-container .cell .number,
  .keno-winning-numbers .number-picker-container .cell .number {
    font-size: 2rem;
    line-height: 2.1;
  }
}
.keno-classic-game .number-picker-container .cell .front-face .number,
.keno-system-game .number-picker-container .cell .front-face .number,
.keno-winning-numbers .number-picker-container .cell .front-face .number {
  background-color: #0591da;
}
.keno-classic-game .number-picker-container .cell .back-face .number,
.keno-system-game .number-picker-container .cell .back-face .number,
.keno-winning-numbers .number-picker-container .cell .back-face .number {
  background-color: #21375f;
}
.keno-classic-game .number-picker-container .cell.active .number,
.keno-system-game .number-picker-container .cell.active .number,
.keno-winning-numbers .number-picker-container .cell.active .number {
  line-height: 7.428vw;
  height: 130%;
  -webkit-transform: translateY(-100%) scale(1.3);
  -moz-transform: translateY(-100%) scale(1.3);
  -ms-transform: translateY(-100%) scale(1.3);
  -o-transform: translateY(-100%) scale(1.3);
  transform: translateY(-100%) scale(1.3);
}
@media (min-width: 950px) {
  .keno-classic-game .number-picker-container .cell.active .number,
  .keno-system-game .number-picker-container .cell.active .number,
  .keno-winning-numbers .number-picker-container .cell.active .number {
    height: 100%;
    line-height: 2.1;
    -webkit-transform: translateY(0) scale(1);
    -moz-transform: translateY(0) scale(1);
    -ms-transform: translateY(0) scale(1);
    -o-transform: translateY(0) scale(1);
    transform: translateY(0) scale(1);
  }
}
