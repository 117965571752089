﻿@import url("../Mixins/_mixins.less");

.keno-million {
  background-color: @keno-powderblue;
  border-color: @keno-powderblue;
  position: relative;

  .game-not-available-wrapper {
    padding: 15vw 0;
    text-align: center;

    @media (min-width: @keno-desktop-width) {
      padding: 5rem 0;
    }

    svg {
      width: 17.33vw;
      height: 17.33vw;
      margin-bottom: 2vw;
      margin-top: -8vw;

      @media (min-width: @keno-desktop-width) {
        width: 6.4rem;
        height: 6.4rem;
        margin-bottom: 1rem;
        margin-top: 0;
      }
    }

    .subheadline {
      font-size: 3.733vw;
      text-transform: uppercase;
      color: #fff;
      line-height: 1.3;
      padding-top: 1.6vw;
      margin: 0;

      @media (min-width: @keno-desktop-width) {
        font-size: 2rem;
        padding-top: 0.5rem;
      }
    }
  }

  .keno-content-wrapper {
    padding: 12vw 0 0 0;

    @media @md {
      padding: 5rem 0 0 0;
    }
  }

  .keno-million-headline {
    max-width: 86.5vw;
    font-size: 10.7vw;
    font-style: italic;
    color: @keno-white;
    line-height: 11.2vw;
    text-transform: uppercase;
    margin: 0 auto 1.4vw;

    @media (min-width: @keno-desktop-width) {
      font-size: 4rem;
      line-height: 4rem;
      margin: 0 auto 1rem;
    }
  }

  .subheading {
    max-width: 96rem;
    margin: auto;

    @media (min-width: @keno-desktop-width) {
      width: 100%;
      max-width: 86.5vw;
    }

    .subheading-text {
      max-width: 86.5vw;
      font-size: 5.4vw;
      font-weight: bold;
      line-height: 5.9vw;
      color: @keno-white;
      text-transform: uppercase;
      display: inline-block;
      padding: 0 .3rem;

      @media (min-width: @keno-desktop-width) {
        font-size: 2rem;
        line-height: 2.4rem;
        margin: 0 auto .5rem;
      }

      &.yellow {
        color: @keno-cta-yellow;
      }
    }
  }

  .draw-info {
    margin: 6vw 0;

    @media (min-width: @keno-desktop-width) {
      margin: 2rem 0;
    }

    .draw-info-text {
      max-width: 86.5vw;
      font-size: 4vw;
      line-height: 6vw;
      color: @keno-white;
      display: inline-block;

      @media (min-width: @keno-desktop-width) {
        font-size: 1.5rem;
        padding: 0 1rem;
        line-height: 1;
      }
    }
  }

  hr.divider {
    width: 86.5vw;
    border: .1rem solid @keno-mediumblue;
    border-top: 0;
    margin: 6vw auto;


    @media (min-width: @keno-desktop-width) {
      margin: 2.5rem auto;
      max-width: 96rem;
    }
  }

  .rows-headline {
    max-width: 86.5vw;
    font-size: 5.4vw;
    line-height: 5.9vw;
    color: @keno-white;
    text-transform: uppercase;
    margin: 0 auto .5rem;

    @media (min-width: @keno-desktop-width) {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }


  @keno-row-transition-time: 0.5s;
  @number-draw-row-height: 10.7vw;

  .row-block {
    width: 100%;
    max-width: @keno-device-width;
    max-height: 26vw;
    margin: 1.5rem auto 0;
    position: relative;
    overflow: hidden;
    -webkit-transition: max-height @keno-row-transition-time;
    -moz-transition: max-height @keno-row-transition-time;
    -ms-transition: max-height @keno-row-transition-time;
    -o-transition: max-height @keno-row-transition-time;
    transition: max-height @keno-row-transition-time;
    -webkit-transition-delay: 0s; /* Safari */
    transition-delay: 0s;

    @media (min-width: @keno-desktop-width) {
      max-width: 46rem;
      max-height: 27.5rem;
      width: 100%;
      margin: 1.5rem auto 0;
    }

    .number-draw-row {
      background-color: @keno-dodgerblue;
      color: #fff;
      display: block;
      font-size: 4vw;
      line-height: @number-draw-row-height;
      font-weight: bold;
      font-style: italic;
      border-radius: 5px;
      margin: 0 0 4vw;

      @media (min-width: @keno-desktop-width) {
        font-size: 2rem;
        line-height: 4rem;
        margin: 0 0 1.5rem;
      }
    }

    .bottom-gradient {
      width: 100%;
      height: @number-draw-row-height;
      position: absolute;
      bottom: 0;
      display: block;
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 165, 235, 0.9) 50%, rgba(0, 165, 235, 1) 100%);
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 165, 235, 0.9) 50%, rgba(0, 165, 235, 1) 100%);
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 165, 235, 0.9) 50%, rgba(0, 165, 235, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#007db9e8', endColorstr='#00a5eb',GradientType=0);
      -webkit-transition: opacity @keno-row-transition-time;
      -moz-transition: opacity @keno-row-transition-time;
      -ms-transition: opacity @keno-row-transition-time;
      -o-transition: opacity @keno-row-transition-time;
      transition: opacity @keno-row-transition-time;

      @media (min-width: @keno-desktop-width) {
        display: none;
      }
    }
  }

  .compact {
    display: none;
  }

  .expand {
    display: inline-block;

    @media (min-width: @keno-desktop-width) {
      display: none;
    }
  }

  .btn {
    margin: .5rem .5rem 3.5rem;

    &.expand,
    &.compact {
      padding-right: 10vw;

      @media @md {
        padding-right: 4vw;
      }
    }

    // hide arrow icons on desktop
    .arrow-icon {
      @media @md {
        display: none;
      }
    }

    &.new-row-generator {
      .icon {
        display: none;

        @media @md {
          display: inline-block;
        }
      }
    }
  }

  // If the keno million rows have been expanded
  &.is-expanded {
    .compact {
      display: inline-block;

      @media (min-width: @keno-desktop-width) {
        display: none;
      }
    }

    .expand {
      display: none;
    }

    .row-block {
      max-height: 74vw;

      .bottom-gradient {
        opacity: 0;
      }
    }
  }

  .help-link {
    @media (min-width: @keno-desktop-width) {
      top: -10.5rem;
      font-size: 1.5rem;
      // changes to the underline of the hyperlink
      line-height: 1.5rem;
    }
  }
}

// hide bottom manchete on mobile
.manchete.bot {
  .keno-section {
    @media (max-width: 959px) {
      &:after {
        display: none;
      }
    }
  }
}
