// bring in mixins
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

.win-message-container {
  background-color: @keno-powderblue;
  padding: 4vw 14.13vw 4vw;

  @media (min-width: @keno-desktop-width) {
    padding: 3rem;
  }

  .receive-winner-message-icon {
    width: 10vw;
    height: 10vw;
    margin: 0 auto 2.5vw;

    @media (min-width: @keno-desktop-width) {
      width: 6.4rem;
      height: 6.4rem;
      margin: 0 auto 2rem;
    }
  }

  .notice-step {
    color: #FFF;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;

    .headline {
      width: 100%;
      max-width: 86.5vw;
      font-size: 5.5vw;
      text-transform: uppercase;
      line-height: normal;
      margin: 0 auto;

      @media (min-width: @keno-desktop-width) {
        font-size: 3rem;
      }
    }

    .subheadline {
      width: 100%;
      max-width: 60vw;
      font-size: 4.27vw;
      text-transform: uppercase;
      padding: 1vw 0;
      margin: 0 auto;

      @media (min-width: @keno-desktop-width) {
        max-width: 86.5vw;
        font-size: 2.2rem;
        line-height: normal;
        padding: 1rem 0 2.5rem;
      }
    }

    .checkbox-container {

      @media (min-width: @keno-desktop-width) {
        display: flex;
      }

      .keno-checkbox {
        display: flex;
        margin: 3.34vw 0;

        @media (min-width: @keno-desktop-width) {
          margin: 0rem 2rem;
        }

        label {
          display: flex;
          align-items: center;
          position: relative;

          &:not(.ios-skin) {
            position: relative;
            padding: 0 0 0 3.33vw;

            @media (min-width: @keno-desktop-width) {
              padding: 0 0 0 2rem;
            }
          }

          & .media-text {
            width: auto;
            font-size: 4.27vw;
            font-weight: bold;
            line-height: 1.5;
            color: @keno-white;
            text-transform: uppercase;
            margin: 0;

            @media (min-width: @keno-desktop-width) {
              font-size: 1.8rem;
            }
          }
        }
      }
    }
  }
}

