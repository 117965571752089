// bring in mixins  
@import (reference) "Mixins/_mixins.less"; // ┬──┬﻿ ¯\_(ツ)

.keno-classic-game {
  .keno-content-wrapper {
    width: 100vw;
    text-align:left;
    overflow:hidden;

    @media (min-width: @keno-desktop-width) {
      width:100%;
      overflow:visible;
    }
  }
  @rows-sliding-animation-duration:.6s;
  .rows-container {
    height:142.3vw;
    font-size: 0;
    white-space: nowrap;
    padding-top:5.333vw;
    padding-bottom:4.8vw;
    position:relative;
    will-change: transform;
    overflow:hidden;
    @media (min-width: @keno-desktop-width) {
      height:56.5rem;
      padding-top:4rem;
      padding-bottom:2rem;
    }

    .keyframes(keno-slide-to-right-animation; {
      /*0% { .transform(translate3D(0, 0, 0)); }
			100% { .transform(translate3D(-37%, 0, 0)); }*/
    });

    .keyframes(keno-slide-to-right-infocus-row-animation; {
      0% { .transform(scale(0.9) translate3D(115%, 1%, 0)); .opacity(.5); }
      100% { .transform(scale(1) translate3D(0, 0, 0)); .opacity(1); }
    });

    .keyframes(keno-slide-to-right-next-row-animation; {
      0% { .transform(scale(0.9) translate3D(185%, 1%, 0)); .opacity(.5); }
      100% { .transform(scale(0.9) translate3D(115%, 1%, 0)); .opacity(.5); }
    });

    .keyframes(keno-slide-to-right-prev-row-animation; {
      0% { .transform(scale(1) translate3D(0, 0, 0)); .opacity(1); }
      100% { .transform(scale(0.9) translate3D(-115%, 1%, 0)); .opacity(.5); }
    });

    .keyframes(keno-slide-to-right-prev-prev-row-animation; {
      0% { .transform(scale(0.9) translate3D(-115%, 1%, 0)); .opacity(.5); }
      100% { .transform(scale(0.9) translate3D(-185%, 1%, 0)); .opacity(.5); }
    });

    .keyframes(keno-slide-to-left-animation; {
      /*0% { .transform(translate3D(-74%, 0, 0)); }
			100% { .transform(translate3D(-37%, 0, 0)); }*/
    });

    .keyframes(keno-slide-to-left-half-animation; {
      /*0% { .transform(translate3D(-37%, 0, 0)); }
			100% { .transform(translate3D(0, 0, 0)); }*/
    });

    .keyframes(keno-slide-to-left-infocus-row-animation; {
      0% { .transform(scale(0.9) translate3D(-115%, 1%, 0)); .opacity(.5); }
      100% { .transform(scale(1) translate3D(0, 0, 0)); .opacity(1); }
    });

    .keyframes(keno-slide-to-left-next-row-animation; {
      0% { .transform(scale(1) translate3D(0, 0, 0)); .opacity(1); }
      100% { .transform(scale(0.9) translate3D(115%, 1%, 0)); .opacity(.5); }
    });

    .keyframes(keno-slide-to-right-next-next-row-animation; {
      0% { .transform(scale(0.9) translate3D(115%, 1%, 0)); .opacity(.5); }
      100% { .transform(scale(0.9) translate3D(185%, 1%, 0)); .opacity(.5); }
    });

    .keyframes(keno-slide-to-left-prev-row-animation; {
      0% { .transform(scale(0.9) translate3D(-185%, 1%, 0)); .opacity(.5); }
      100% { .transform(scale(0.9) translate3D(-115%, 1%, 0)); .opacity(.5); }
    });


    @media (min-width: @keno-desktop-width) {
      .keyframes(keno-slide-to-right-animation; {
        /*0% { .transform(translate3D(0, 0, 0)); }
				100% { .transform(translate3D(-29rem, 0, 0)); }*/
      });

      .keyframes(keno-slide-to-right-infocus-row-animation; {
        0% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,650,9,0,1)); .opacity(.5); }
        100% { .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1)); .opacity(1); }
      });

      .keyframes(keno-slide-to-right-next-row-animation; {
        0% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,1200,9,0,1)); .opacity(.5); }
        100% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,650,9,0,1)); .opacity(.5); }
      });

      .keyframes(keno-slide-to-right-prev-row-animation; {
        0% { .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1)); .opacity(1); }
        100% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,-650,9,0,1)); .opacity(.5); }
      });

      .keyframes(keno-slide-to-right-prev-prev-row-animation; {
        0% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,-650,9,0,1)); .opacity(.5); }
        100% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,-1200,9,0,1)); .opacity(.5); }
      });

      .keyframes(keno-slide-to-left-half-animation; {
        /*0% { .transform(translate3D(-29rem, 0, 0)); }
				100% { .transform(translateX(0%)); }*/
      });

      .keyframes(keno-slide-to-left-animation; {
        /*0% { .transform(translate3D(-58rem, 0, 0)); }
				100% { .transform(translate3D(-29rem, 0, 0)); }*/
      });

      .keyframes(keno-slide-to-left-infocus-row-animation; {
        0% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,-650,9,0,1)); .opacity(.5); }
        100% { .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1)); .opacity(1); }
      });

      .keyframes(keno-slide-to-left-next-row-animation; {
        0% { .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1)); .opacity(1); }
        100% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,650,9,0,1)); .opacity(.5); }
      });

      .keyframes(keno-slide-to-left-next-next-row-animation; {
        0% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,650,9,0,1)); .opacity(.5); }
        100% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,1200,9,0,1)); .opacity(.5); }
      });

      .keyframes(keno-slide-to-left-prev-row-animation; {
        0% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,-1200,9,0,1)); .opacity(.5); }
        100% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,-650,9,0,1)); .opacity(.5); }
      });
    }

    @media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: @keno-desktop-width) {
      .keyframes(keno-slide-to-right-infocus-row-animation; {
        0% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,487.5,9,0,1)); .opacity(.5); }
        100% { .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1)); .opacity(1); }
      });

      .keyframes(keno-slide-to-right-next-row-animation; {
        0% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,900,9,0,1)); .opacity(.5); }
        100% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,487.5,9,0,1)); .opacity(.5); }
      });

      .keyframes(keno-slide-to-right-prev-row-animation; {
        0% { .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1)); .opacity(1); }
        100% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,-487.5,9,0,1)); .opacity(.5); }
      });

      .keyframes(keno-slide-to-right-prev-prev-row-animation; {
        0% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,-487.5,9,0,1)); .opacity(.5); }
        100% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,-900,9,0,1)); .opacity(.5); }
      });

      .keyframes(keno-slide-to-left-infocus-row-animation; {
        0% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,-487.5,9,0,1)); .opacity(.5); }
        100% { .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1)); .opacity(1); }
      });

      .keyframes(keno-slide-to-left-next-row-animation; {
        0% { .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1)); .opacity(1); }
        100% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,487.5,9,0,1)); .opacity(.5); }
      });

      .keyframes(keno-slide-to-left-next-next-row-animation; {
        0% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,487.5,9,0,1)); .opacity(.5); }
        100% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,900,9,0,1)); .opacity(.5); }
      });

      .keyframes(keno-slide-to-left-prev-row-animation; {
        0% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,-900,9,0,1)); .opacity(.5); }
        100% { .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,-487.5,9,0,1)); .opacity(.5); }
      });
    }

    &.slide-to-right {
      .animation(keno-slide-to-right-animation @rows-sliding-animation-duration forwards ease-in-out);

      .in-focus {
        .animation(keno-slide-to-right-infocus-row-animation @rows-sliding-animation-duration forwards ease-in-out);
      }
      .next-row {
        .animation(keno-slide-to-right-next-row-animation @rows-sliding-animation-duration forwards ease-in-out);
      }
      .prev-row {
        .animation(keno-slide-to-right-prev-row-animation @rows-sliding-animation-duration forwards ease-in-out);
      }
      .prev-prev-row {
        .animation(keno-slide-to-right-prev-prev-row-animation @rows-sliding-animation-duration forwards ease-in-out);
      }
    }


    /*&.slide-to-left {
			.animation(slide-to-left-animation @rows-sliding-animation-duration forwards linear);
			.next-next-row {
				.animation(slide-to-left-next-next-row-animation @rows-sliding-animation-duration forwards linear);
			}
		}
		&.slide-to-left-half {
			.animation(slide-to-left-half-animation @rows-sliding-animation-duration forwards linear);
			.next-next-row {
				.animation(slide-to-left-half-next-next-row-animation @rows-sliding-animation-duration forwards linear);
			}
		}*/

    &.slide-to-left, &.slide-to-left-half {
      .animation(keno-slide-to-left-animation @rows-sliding-animation-duration forwards linear);
      .in-focus {
        .animation(keno-slide-to-left-infocus-row-animation @rows-sliding-animation-duration forwards linear);
      }
      .next-row {
        .animation(keno-slide-to-left-next-row-animation @rows-sliding-animation-duration forwards linear);
      }
      .next-next-row {
        .animation(keno-slide-to-left-next-next-row-animation @rows-sliding-animation-duration forwards linear);
      }
      .prev-row {
        .animation(keno-slide-to-left-prev-row-animation @rows-sliding-animation-duration forwards linear);
      }
    }

    &.rows-static-position {
      //.transform(translate3D(-37%, 0, 0));
      /*@media (min-width: @keno-desktop-width) {

			}
			.in-focus{
				@media (min-width: @keno-desktop-width) {
					.transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,-290,0,0,1));
				}
			}*/
    }

    &.slide-to-left .in-focus + .next-row:not(.interacted-row):last-of-type,
    &.slide-to-left-half .in-focus + .next-row:not(.interacted-row):last-of-type {
      .animation(keno-slide-to-left-next-row-animation @rows-sliding-animation-duration forwards linear);
    }

    &.delete-row {
      .keyframes(keno-delete-row-animation-in-focus-row; {
        0%   { .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1)); .opacity(1); }
        100%  { .transform(matrix3d(0.5,0,0.00,0,0.00,0.5,0.00,-.0002,0,0,1,0,0,1200,0,1)); .opacity(0); }
      });

      @media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: @keno-desktop-width) {
        .keyframes(keno-delete-row-animation-in-focus-row; {
          0%   { .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1)); .opacity(1); }
          100%  { .transform(matrix3d(0.5,0,0.00,0,0.00,0.5,0.00,-.0002,0,0,1,0,0,900,0,1)); .opacity(0); }
        });
      }

      & > .in-focus {
        .animation(keno-delete-row-animation-in-focus-row .5s .0s forwards linear);
      }
      & > .in-focus + .next-row {
        .animation(keno-slide-to-right-infocus-row-animation .5s .15s forwards linear);
      }
      & > .in-focus + .next-row + div {
        .animation(keno-delete-row-animation-next-next-row .5s .15s forwards linear);
      }
    }

    /*.new-row:not(.next-next-row) {
			.animation(slide-to-right-next-row-animation @rows-sliding-animation-duration forwards linear);
		}*/

		
    &:not(.delete-row) .in-focus + .next-row:not(.interacted-row):last-of-type {
      //.animation(slide-to-right-next-row-animation @rows-sliding-animation-duration forwards linear);
    }

    @keyframes keno-row-blur-aniamtion {
      0% { filter:blur(0); }
      5% { filter:blur(1rem); }
      95% { filter:blur(1rem); }
      100% { filter:blur(0); }
    }
    @keyframes keno-instructions-box-one {
      0% { transform:translate(-50%,-150%) scale(.6); opacity:0; }
      5% { transform:translate(-50%,-50%) scale(1); opacity:1; }
      95% { transform:translate(-50%,-50%) scale(1); opacity:1; }
      100% { transform:translate(-50%,50%) scale(.6); opacity:0; }
    }
    @keyframes keno-instructions-box-two {
      0% { transform:translate(90%,-50%) scale(.6); opacity:0; }
      5% { transform:translate(90%,-50%) scale(1); opacity:1; }
      95% { transform:translate(90%,-50%) scale(1); opacity:1; }
      100% { transform:translate(90%,-50%) scale(.6); opacity:0; }
    }
    @keyframes keno-instructions-box-three {
      0% { transform:translate(180%,-150%) scale(.6); opacity:0; }
      5% { transform:translate(180%,-50%) scale(1); opacity:1; }
      95% { transform:translate(180%,-50%) scale(1); opacity:1; }
      100% { transform:translate(180%,50%) scale(.6); opacity:0; }
    }

    &.first-new-row-valid, &.new-row-valid {
			
      .new-row {
        .cell {
          @media (min-width: @keno-desktop-width) {
            transform-style:preserve-3d;
            transform:rotateX(0) translateZ(0);
            perspective: 21.14vw;
          }

          .box {
            @media (min-width: @keno-desktop-width) {
              transform-style: preserve-3d;
              transform: translate3D(0, 0, -4.5275vw);
              transform:translate3D(0,0,-2rem);
            }
			
            @keyframes keno-flip-face-demo-animation {
              from { transform:rotateX(0deg) translate3D(0,0,4.5275vw); }
              to { transform:rotateX(-90deg) translate3D(0,0,4.5275vw); }
            }
            @keyframes keno-flip-back-demo-animation {
              from { transform:rotateX(90deg) translate3D(0,0,4.5275vw); }
              to { transform:rotateX(0deg) translate3D(0,0,4.5275vw); }
            }
            @media (min-width: @keno-desktop-width) {
              @keyframes keno-flip-face-demo-animation {
                0% { transform:rotateX(0deg) translate3D(0,0,2rem); }
                30% { transform:rotateX(-90deg) translate3D(0,0,2rem); }
                70% { transform:rotateX(-270deg) translate3D(0,0,2rem); }
                100% { transform:rotateX(-360deg) translate3D(0,0,2rem); }
              }
              @keyframes keno-flip-back-demo-animation {
                0% { transform:rotateX(90deg) translate3D(0,0,2rem); }
                30% { transform:rotateX(10deg) translate3D(0,0,2rem); }
                70% { transform:rotateX(-10deg) translate3D(0,0,2rem); }
                100% { transform:rotateX(-90deg) translate3D(0,0,2rem); }
              }
            }

            .front-face,
            .back-face {
              @media (min-width: @keno-desktop-width) {
                visibility:visible;
                backface-visibility: hidden;
                -webkit-backface-visibility:hidden;
                will-change: transform;
              }
            }
            .front-face {
              @media (min-width: @keno-desktop-width) {
                transform:rotateX(0deg) translate3D(0,0,2rem);
                animation:keno-flip-face-demo-animation .8s forwards linear;
              }
            }
            .back-face {
              @media (min-width: @keno-desktop-width) {
                transform:rotateX(-90deg) translate3D(0,0,2rem);
                animation:keno-flip-back-demo-animation .8s forwards linear;
              }
            }
          }

          &:nth-child(10n+1) {
            .box {
              .front-face, .back-face {
                animation-delay:1.5s;
              }
            }
          }
          &:nth-child(10n+2) {
            .box {
              .front-face, .back-face {
                animation-delay:1.58s;
              }
            }
          }
          &:nth-child(10n+3) {
            .box {
              .front-face, .back-face {
                animation-delay:1.66s;
              }
            }
          }
          &:nth-child(10n+4) {
            .box {
              .front-face, .back-face {
                animation-delay:1.74s;
              }
            }
          }
          &:nth-child(10n+5) {
            .box {
              .front-face, .back-face {
                animation-delay:1.82s;
              }
            }
          }
          &:nth-child(10n+6) {
            .box {
              .front-face, .back-face {
                animation-delay:1.9s;
              }
            }
          }
          &:nth-child(10n+7) {
            .box {
              .front-face, .back-face {
                animation-delay:1.98s;
              }
            }
          }
          &:nth-child(10n+8) {
            .box {
              .front-face, .back-face {
                animation-delay:2.06s;
              }
            }
          }
          &:nth-child(10n+9) {
            .box {
              .front-face, .back-face {
                animation-delay:2.14s;
              }
            }
          }
          &:nth-child(10n+10) {
            .box {
              .front-face, .back-face {
                animation-delay:2.22s;
              }
            }
          }

        }
      }
    }

    &.first-new-row-valid {
      .in-focus {
        @media (min-width: @keno-desktop-width) {
          animation:keno-row-blur-aniamtion 4.5s linear forwards;
        }
      }
      .new-row {
        @media (min-width: @keno-desktop-width) {
          animation:keno-row-blur-aniamtion 3.5s 2s linear forwards;
        }
				
        .cell {
          &:nth-child(10n+1) {
            .box {
              .front-face, .back-face {
                animation-delay:5.5s;
              }
            }
          }
          &:nth-child(10n+2) {
            .box {
              .front-face, .back-face {
                animation-delay:5.58s;
              }
            }
          }
          &:nth-child(10n+3) {
            .box {
              .front-face, .back-face {
                animation-delay:5.66s;
              }
            }
          }
          &:nth-child(10n+4) {
            .box {
              .front-face, .back-face {
                animation-delay:5.74s;
              }
            }
          }
          &:nth-child(10n+5) {
            .box {
              .front-face, .back-face {
                animation-delay:5.82s;
              }
            }
          }
          &:nth-child(10n+6) {
            .box {
              .front-face, .back-face {
                animation-delay:5.9s;
              }
            }
          }
          &:nth-child(10n+7) {
            .box {
              .front-face, .back-face {
                animation-delay:5.98s;
              }
            }
          }
          &:nth-child(10n+8) {
            .box {
              .front-face, .back-face {
                animation-delay:6.06s;
              }
            }
          }
          &:nth-child(10n+9) {
            .box {
              .front-face, .back-face {
                animation-delay:6.14s;
              }
            }
          }
          &:nth-child(10n+10) {
            .box {
              .front-face, .back-face {
                animation-delay:6.22s;
              }
            }
          }

        }
      }
      .instructions-wrapper {
        @media (min-width: @keno-desktop-width) {
          width:58rem;
          height:100%;
          margin:auto;
          position:absolute;
          right:0;
          left:0;
          top:0;
          z-index:1;
          pointer-events:none;
        }

        .instructions-text-box {
          @media (min-width: @keno-desktop-width) {
            top:50%;
            color:@keno-white;
            position:absolute;
            white-space:normal;
            padding:2rem;
            text-align:center;
            line-height:1.1;
            font-weight:bold;
          }
					
          &:after {
            content:'';
            position:absolute;
            left:0;
            right:0;
            top:0;
            bottom:0;
            //background-color:rgba(0,145,207,.9);
            z-index:-1;
            filter:blur(1rem);
          }

          &.box-one {
            @media (min-width: @keno-desktop-width) {
              width:40rem;
              font-size:5rem;
              left:50%;
              transform:translate(-50%,-50%);
              animation:keno-instructions-box-one 4.5s linear forwards;
            }
          }
          &.box-two {
            @media (min-width: @keno-desktop-width) {
              width:11rem;
              height:11rem;
              font-size:5rem;
              line-height:2;
              right:0;
              transform:translate(90%,-50%);
              opacity:0;
              animation:keno-instructions-box-two 3.5s .9s linear forwards;
              border-radius:50%;
              &:after {
                background-color:rgba(5, 145, 218,.9);
                border-radius:50%;
              }
            }
          }
          &.box-three {
            @media (min-width: @keno-desktop-width) {
              width:20rem;
              font-size:5rem;
              right:7rem;
              transform:translate(180%,-50%);
              opacity:0;
              animation:keno-instructions-box-three 3.5s 2s linear forwards;
            }
          }
        }
      }
    }

  }

  .left-gradient, .right-gradient {
    width:0;
    height:100%;
    position:absolute;
    top:0;
    @media (min-width: @keno-desktop-width) {
      width:19vw;
    }
  }
  .left-gradient{
    left:0;
    background: -moz-linear-gradient(left,  rgba(0,165,235,1) 0%, rgba(0,165,235,0) 100%);
    background: -webkit-linear-gradient(left,  rgba(0,165,235,1) 0%,rgba(0,165,235,0) 100%);
    background: linear-gradient(to right,  rgba(0,165,235,1) 0%,rgba(0,165,235,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a5eb', endColorstr='#0000a5eb',GradientType=1 );
  }
  .right-gradient {
    right:-19vw;
    background: -moz-linear-gradient(left,  rgba(0,165,235,0) 0%, rgba(0,165,235,1) 100%);
    background: -webkit-linear-gradient(left,  rgba(0,165,235,0) 0%,rgba(0,165,235,1) 100%);
    background: linear-gradient(to right,  rgba(0,165,235,0) 0%,rgba(0,165,235,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0000a5eb', endColorstr='#00a5eb',GradientType=1 );
    transition:right .25S linear;

    &.active {
      right:0;
    }
  }

  .rows-wrapper {
    filter: url("#rows-side-sliding-blur");
  }

  .row-container {
    width: 75.235%;
    padding-bottom:4.8vw;
    border-bottom:.2rem solid #4dc0f1;
    display: inline-block;
    position: absolute;
    visibility: hidden;
    left:0;
    right:0;
    margin:auto;
    .box-sizing();
    .opacity(0);
    @media (min-width: @keno-desktop-width) {
      width:58rem;
      padding-bottom:1rem;
    }

    &.in-focus, &.next-row , &.prev-row, &.next-next-row, &.prev-prev-row  {
      .opacity(.5);
      visibility: visible;
      will-change: transform, opacity;
    }

    &.in-focus {
      .transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,0,0,0,1));
      .opacity(1);
      z-index:1;
    }

    &.next-row, &.prev-row, &.prev-prev-row, &.next-next-row {
      &:after {
        content:'';
        width:100%;
        height:100%;
        position:absolute;
        top:0;
        left:0;
      }
    }

    &.next-row { 
      .transform(scale(0.9) translate3D(115%, 1%, 0));
      @media (min-width: @keno-desktop-width) {
        .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,650,9,0,1));
      }
      @media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: @keno-desktop-width) {
        .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,487.5,9,0,1));
      }
    }

    &.next-next-row {
      .transform(scale(0.9) translate3D(185%, 1%, 0));
      @media (min-width: @keno-desktop-width) {
        .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,1200,9,0,1));
      }
      @media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: @keno-desktop-width) {
        .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,900,9,0,1));
      }
    }


    &.prev-row {
      .transform(scale(0.9) translate3D(-115%, 1%, 0));
      @media (min-width: @keno-desktop-width) {
        .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,-650,9,0,1));
      }
      @media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: @keno-desktop-width) {
        .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,-487.5,9,0,1));
      }
    }

    &.prev-prev-row {
      .transform(scale(0.9) translate3D(-185%, 1%, 0));
      @media (min-width: @keno-desktop-width) {
        .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,-1200,9,0,1));
      }
      @media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: @keno-desktop-width) {
        .transform(matrix3d(0.89,0,0.00,0,0.00,0.89,0.00,0,0,0,1,0,-900,9,0,1));
      }
    }

    .keyframes(keno-number-cell-shake-animation; {
      0%   { .transform(scale(1) rotate(0)); }
      10%  { .transform(scale(1.13) rotate(5deg)); }
      20%  { .transform(scale(1.13) rotate(-5deg)); }
      30%  { .transform(scale(1.13) rotate(5deg)); }
      40%  { .transform(scale(1.13) rotate(-5deg)); }
      50%  { .transform(scale(1.13) rotate(5deg)); }
      60%  { .transform(scale(1.13) rotate(-5deg)); }
      70%  { .transform(scale(1.13) rotate(5deg)); }
      80%  { .transform(scale(1.13) rotate(-5deg)); }
      90%  { .transform(scale(1.13) rotate(5deg)); }
      100% { .transform(scale(1) rotate(0)); }
    });

    .keyframes(keno-rules-pulse-animation; {
      0%   { .transform(scale(1)); }
      25%  { .transform(scale(1.13)); }
      50%  { .transform(scale(1)); }
      75%  { .transform(scale(1.13)); }
      100% { .transform(scale(1)); }
    });

    &.shake-state {
      .selected {
        .animation(keno-number-cell-shake-animation .8s forwards);
      }

      .header-rules-text {
        .animation(keno-rules-pulse-animation .8s forwards);
      }
    }
  }

  .row-header {
    font-size: 3.734vw;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    padding-bottom: 3.334vw;
    border-bottom: .2rem solid #4dc0f1;
    .clearfix();

    @media (min-width: @keno-desktop-width) {
      font-size:2rem;
      padding-bottom:1.3rem;
    }

    .counter {
      padding-right:2.666vw;
      float:left;
			
      @media (min-width: @keno-desktop-width) {
        padding-right:2rem;
      }
    }

    .right-side {
      padding-right:2.667vw;
      position:relative;
      @media (min-width: @keno-desktop-width) {
        padding-right:1.5rem;
      }

      .row-delete-button {
        display:block;
        position:absolute;
        top:.75rem;
        right:0;
        cursor:pointer;
        .transition(opacity .25s);
				
        .icon-x-big.icon {
          width:2.667vw;
          height:2.667vw;
          fill:#fff;

          @media (min-width: @keno-desktop-width) {
            width:1.2rem;
            height:1.2rem;
          }
        }

        &.inactive {
          .opacity(.3);
          cursor:default;
        }
      }
    }

    .header-rules-text {
      color:#1e55a0;
      display:inline-block;
    }
  }

  .prev-row-button {
    width: 10.5vw;
    height: 32vw;
    background-color: rgba(235,248,253,.8);
    position: absolute;
    left: 0;
    top: 50%;
    border-radius: 0 6px 6px 0;
    .transform(translateY(-50%) translateX(-10.5vw));
    .transitionWithTransform(transform .15s);
    z-index:1;
    @media (min-width: @keno-desktop-width) {
      width:4.2rem;
      height:14rem;
      .transform(translateY(-50%) translateX(-4.2rem));
      cursor:pointer;
    }

    .icon {
      width:2.4vw;
      height:3.468vw;
      font-size: 4.5vw;
      line-height: .55;
      fill: #3f6372;
      background-color: transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      .transform(translateY(-50%) translateX(-1.5vw));
      @media (min-width: @keno-desktop-width) {
        width:1.2rem;
        height:1.2rem;
        .transform(translateY(-50%) translateX(-.6rem));
      }
    }

    &.active {
      .transform(translateY(-50%) translateX(-1.5vw));
      @media (min-width: @keno-desktop-width) {
        .transform(translateY(-50%) translateX(0));
      }
    }
  }

  .create-new-row-button, .next-row-button {
    width: 10.5vw;
    height: 32vw;
    background-color: rgba(235,248,253,.8);
    position: absolute;
    right: 0;
    top: 50%;
    border-radius: 6px 0 0 6px;
    .transform(translateY(-50%) translateX(10.5vw));
    .transitionWithTransform(transform .15s);
    z-index:1;
    @media (min-width: @keno-desktop-width) {
      width:4.2rem;
      height:14rem;
      .transform(translateY(-50%) translateX(4.2rem));
      cursor:pointer;
    }
		

    .icon {
      width:2.4vw;
      height:3.468vw;
      font-size: 4.5vw;
      line-height: .55;
      fill: #3f6372;
      background-color: transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      .transform(translateY(-50%) translateX(-1.5vw));
      @media (min-width: @keno-desktop-width) {
        width:1.2rem;
        height:1.2rem;
        .transform(translateY(-50%) translateX(-.4rem));
      }
    }

    &.active {
      .transform(translateY(-50%) translateX(1.5vw));
      @media (min-width: @keno-desktop-width) {
        .transform(translateY(-50%) translateX(0rem));
      }
    }
  }

  .rows-list-box {

    .container {
      width: 87.8vw;
      position: absolute;
      overflow:hidden;
      z-index:2;
      transform:translate3d(-100%, 0, 0);
      .transitionWithTransform(transform .15s);
      border-radius:0 6px 6px 0;
      @media (min-width: @keno-desktop-width) {
        width:50rem;
      }

      &.single-row {
        transform:translate3d(-100%, 30vw, 0);
        @media (min-width: @keno-desktop-width) {
          transform:translate3d(-100%, 8rem, 0);
        }
      }

      .informations-box, > .headline, .items-box {
        .box-sizing();
        background: rgba(235,248,253,.8);
        .transition(background-color .1s;);
      }

      .informations-box {
        font-size:3.733vw;
        color:@keno-darkgrey;
        padding:3.34vw 6.667vw 0;
        .border-radius(0 6px 0 0);
        position:relative;
        z-index:2;
				
        @media (min-width: @keno-desktop-width) {
          font-size:1.5rem;
          padding:2.5rem 3rem 0;
        }

        p {
          margin:0;
        }
      }

      > .headline {
        color:#00a5eb;
        font-size:3.734vw;
        font-weight:bold;
        padding:3.34vw 6.667vw;
        position:relative;
        z-index:2;
        @media (min-width: @keno-desktop-width) {
          font-size:2rem;
          padding:1.1rem 3rem 1rem;
        }
      }
      .items-box {
        position:relative;
        overflow:hidden;
        overflow-y:auto;
        .border-radius(0 0 6px 0);
        .items-inner-box {
          .item {
            width:calc(~'100% - 6.667vw');
            font-size:3.2vw;
            font-weight:bold;
            line-height:1.25;
            padding:3.4vw 0;
            border-top:.2rem solid #cdd3d7;
            position:relative;
            left:3.333vw;
            @media (min-width: @keno-desktop-width) {
              width:calc(~'100% - 6rem');
              font-size:2rem;
              padding:1.2rem 0 1.3rem;
              left:3rem;
            }

            .headline {
              color:#00a5eb;
              cursor:default;
            }
				
            .numbers{
              color:#1e55a0;
              font-style:italic;
            }

            .delete-button {
              width:10vw;
              height:15vw;
              position:absolute;
              top:0;
              right:0;
              @media (min-width: @keno-desktop-width) {
                top:1.2rem;
                width:3rem;
                height:5rem;
              }

              .icon-x-big {
                width:2.667vw;
                height:2.667vw;
                position:absolute;
                fill:#777a7f;
                top:50%;
                right:0;
                transform:translateY(-50%);
                @media (min-width: @keno-desktop-width) {
                  width:1.2rem;
                  height:1.2rem;
                  cursor:pointer;
                  fill: @keno-powderblue;
                }

                .icon {
                  width:2.667vw;
                  height:2.667vw;
                  position:absolute;
                  fill:#777a7f;
                  top:50%;
                  right:0;
                  .transform(translateY(-50%));
                  @media (min-width: @keno-desktop-width) {
                    width:1.2rem;
                    height:1.2rem;
                  }
                }
              }
            }

            &.invalid-row {
              .headline {
                &:before {
                  content:'!';
                  width:3.734vw;
                  height:3.734vw;
                  display:inline-block;
                  .border-radius(50%);
                  background-color:#d01212;
                  margin-right:0.934vw;
                  position:relative;
                  top:0;
                  line-height:1.3;
                  text-indent:1.3vw;
                  color:#fff;
                  cursor:pointer;

                  @media (min-width: @keno-desktop-width) {
                    width:1.8rem;
                    height:1.8rem;
                    font-size:1.5rem;
                    margin-right:.7rem;
                    line-height:1.34;
                    text-indent:.65rem;
                    top:-.1rem;
                  }
                }
              }
            }
          }
        }

        .scroll-button {
          height:12vw;
          left:0;
          right:0;
          position:fixed;
          .opacity(0);
          .transitionWithTransform(transform .25s, opacity .25s;);
          z-index:1;
          cursor:pointer;
          will-change: transform;
          @media (min-width: @keno-desktop-width) {
            height:7.7rem;
            right:1.8rem;
          }

          &.scroll-up {
            background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0.7) 75%, rgba(255,255,255,0) 100%);
            background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0.7) 75%,rgba(255,255,255,0) 100%);
            background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(255,255,255,0.7) 75%,rgba(255,255,255,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
            .transform(translateY(-100%));
          }
          &.scroll-down {
            bottom:0;
            background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.7) 25%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%);
            background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.7) 25%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%);
            background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.7) 25%,rgba(255,255,255,1) 50%,rgba(255,255,255,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
            .transform(translateY(100%));
          }

          .icon {
            width:4.266vw;
            height:2.4vw;
            fill:@keno-powderblue;
            position:absolute;
            top:50%;
            left:50%;
            .transform(translateX(-50%) translateY(-50%));
            @media (min-width: @keno-desktop-width) {
              height:1rem;
            }
          }

          &.active {
            .opacity(1);
            .transform(translateY(0));
          }
        }
      }
			
      .error-message-box {
        background-color:#e9e9e9;
        color:@keno-darkgrey;
        margin-top:-7vw;
        margin-left:-1.333vw;
        padding:4.666vw 4vw;
        .box-sizing();
        .border-radius(6px);
        position:absolute;
        z-index:3;
        left:3.2vw;
        transform:translate3d(0,-100%,0);
        .opacity(0);
        visibility:hidden;
        transition:top .25s, transform .25s, opacity .25s, visibility 0s .3s;
						
        @media (min-width: @keno-desktop-width) {
          left:3rem;
          margin-top:-3rem;
          margin-left:-1rem;
          padding:1.5rem 2rem;
        }

        &:after {
          content:'';
          border-left: 1.333vw solid transparent;
          border-right: 1.333vw solid transparent;
          border-top: 1.333vw solid #e9e9e9;
          position:absolute;
          bottom:-1.333vw;
          left:2vw;

          @media (min-width: @keno-desktop-width) {
            border-left: .6rem solid transparent;
            border-right: .6rem solid transparent;
            border-top: .6rem solid #e9e9e9;
            bottom:-.5rem;
            left:1.3rem;
          }
        }

        .error-headline {
          font-size:3.2vw;
          font-weight:bold;
          text-transform:uppercase;
          @media (min-width: @keno-desktop-width) {
            font-size:1.6rem;
          }
        }

        .error-subheadline {
          font-size:3.2vw;
          font-weight:100;
          @media (min-width: @keno-desktop-width) {
            font-size:1.5rem;
          }
        }

        &.active {
          opacity:1;
          visibility:visible;
          transform:translate3d(0,-110%,0);
          transition:top .25s, transform .25s, opacity .25s, visibility 0s;
        }
      }
    }

    .button {
      width: 10.5vw;
      height: 10.5vw;
      background-color: rgba(235,248,253,.8);
      position: absolute;
      left: 0;
      top: 50%;
      border-radius: 0 6px 6px 0;
      overflow:hidden;
      .transform(translateY(-30vw) translateX(-10.5vw));
      .transitionWithTransform(transform .15s, background-color .1s;);
      z-index:1;
      @media (min-width: @keno-desktop-width) {
        width:4.2rem;
        height:7rem;
        .transform(translateY(-16rem) translateX(-4.2vw));
        cursor:pointer;
      }

      .icon-list {
        width:3.8vw;
        height:4vw;
        fill:#3f6372;
        position:absolute;
        top:50%;
        left:50%;
        .transform(translateY(-50%) translateX(-1.6vw));
        .opacity(1);
        .transitionWithTransform(transform .25s, opacity .25s;);
        @media (min-width: @keno-desktop-width) {
          width:1.5rem;
          height:1.2rem;
          .transform(translateY(-50%) translateX(-.7rem));
        }
      }
      .icon-left-arrow {
        width:3.8vw;
        height:3.47vw;
        fill:@keno-darkgrey;
        position:absolute;
        top:50%;
        left:50%;
        .transform(translateY(-50%) translateX(7vw));
        .opacity(0);
        .transitionWithTransform(transform .25s, opacity .25s;);
        @media (min-width: @keno-desktop-width) {
          width:1.5rem;
          height:1.2rem;
          .transform(translateY(-50%) translateX(-.7rem));
        }
      }

      &.active {
        .transform(translateY(-30vw) translateX(-1.5vw));
        @media (min-width: @keno-desktop-width) {
          .transform(translateY(-16rem) translateX(0));
        }
      }
    }

    &.active {
      .container {
        transform:translate3d(0, 0, 0);
        .informations-box, > .headline, .items-box {
          background-color:rgba(255,255,255,1);
        }
        &.single-row {
          transform:translate3d(0, 30vw, 0);
          @media (min-width: @keno-desktop-width) {
            transform:translate3d(0, 8rem, 0);
          }
        }
      }
      .button {
        background-color:rgba(255,255,255,1);
        .transform(translateY(-30vw) translateX(85.6vw));
        @media (min-width: @keno-desktop-width) {
          .transform(translateY(-16rem) translateX(48.8rem));
        }

        .icon-list {
          .transform(translateY(-50%) translateX(-11vw));
          .opacity(0);
        }
        .icon-left-arrow {
          .transform(translateY(-50%) translateX(-1.6vw));
          .opacity(1);
          @media (min-width: @keno-desktop-width) {
            .transform(translateY(-50%) translateX(-.3rem));
          }
        }
      }
    }
  }
}
