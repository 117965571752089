.keno-promotion .keno-section {
  background-color: #fff;
  border-color: #fff;
  padding: 12vw 0;
}
@media screen and (min-width: 950px) {
  .keno-promotion .keno-section {
    padding: 5rem 0;
  }
}
.keno-promotion .keno-section .money-bags {
  width: 40vw;
  height: 29vw;
  margin: 0 auto 8vw;
  position: relative;
}
@media (min-width: 950px) {
  .keno-promotion .keno-section .money-bags {
    width: 15.6rem;
    height: 12rem;
    margin: 0 auto 1rem;
  }
}
.keno-promotion .keno-section .money-bags svg {
  position: absolute;
  width: 13vw;
  height: 20vw;
}
@media (min-width: 950px) {
  .keno-promotion .keno-section .money-bags svg {
    width: 5.1rem;
    height: 8.3rem;
  }
}
.keno-promotion .keno-section .money-bags svg.moneybag {
  fill: #0891d8;
}
.keno-promotion .keno-section .money-bags svg.moneybag:nth-child(1) {
  left: 0;
  bottom: 0;
}
.keno-promotion .keno-section .money-bags svg.moneybag:nth-child(2) {
  left: 13.5vw;
  top: 0;
}
@media (min-width: 950px) {
  .keno-promotion .keno-section .money-bags svg.moneybag:nth-child(2) {
    left: 5.3rem;
  }
}
.keno-promotion .keno-section .money-bags svg.moneybag:nth-child(3) {
  right: 0;
  bottom: 0;
}
.keno-promotion .keno-section .money-bags svg.shadow {
  fill: #dce0e3;
}
.keno-promotion .keno-section .money-bags svg.shadow:nth-child(4) {
  left: 0;
  bottom: 0;
}
.keno-promotion .keno-section .money-bags svg.shadow:nth-child(5) {
  left: 13.5vw;
  bottom: 3vw;
}
@media (min-width: 950px) {
  .keno-promotion .keno-section .money-bags svg.shadow:nth-child(5) {
    left: 5.3rem;
    bottom: 1rem;
  }
}
.keno-promotion .keno-section .money-bags svg.shadow:nth-child(6) {
  right: 0;
  bottom: 0;
}
.keno-promotion .keno-section .promotion-headline {
  max-width: 86.5vw;
  font-size: 10.7vw;
  font-style: italic;
  font-weight: bold;
  line-height: 11.2vw;
  text-transform: uppercase;
  color: #1e55a0;
  margin: 0 auto 1vw;
}
@media (min-width: 950px) {
  .keno-promotion .keno-section .promotion-headline {
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: 1rem;
  }
}
.keno-promotion .keno-section .promotion-subheadline {
  max-width: 86.5vw;
  font-size: 5.4vw;
  font-style: italic;
  font-weight: bold;
  line-height: 5.9vw;
  text-transform: uppercase;
  color: #00a5eb;
  margin: 0 auto;
}
@media (min-width: 950px) {
  .keno-promotion .keno-section .promotion-subheadline {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}
.keno-promotion .keno-section .btn.cta {
  margin: 4vw 0 0;
}
@media (min-width: 950px) {
  .keno-promotion .keno-section .btn.cta {
    margin: 2rem 0 0;
  }
}
