// bring in mixins
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

.keno-classic-game, .keno-system-game, .keno-winning-numbers {
  .number-picker-footer {
    padding:3.334vw 0 2.134vw;
    position:relative;
    .clearfix();
    @media (min-width: @keno-desktop-width) {
      padding:2rem 0 1.1rem;
    }

    .counter-box {
      font-size: 3.734vw;
      font-weight: bold;
      color: #1e55a0;
      text-transform: uppercase;
      padding: 3.334vw 0;
      display: inline-block;
      float: left;
      @media (min-width: @keno-desktop-width) {
        font-size:2rem;
        padding:0.7rem 0 0.6rem;
      }

      .total {
        color: #fff;
      }
    }

    .buttons-container {
      float: right;
      white-space: nowrap;

      .button {
        min-width: 23.2vw;
        font-size: 3.734vw;
        font-weight: bold;
        color:#4f5254;
        text-align:left;
        background-color: #ebf8fd;
        margin-left: 3.334vw;
        padding:2.767vw 3.334vw;
        border-radius: 6px;
        display: inline-block;
        text-transform:uppercase;
        .box-sizing();
        position:relative;
        .transition(opacity .25s, background-color .25s, color .25s;);
        user-select:none;

        &:first-child {
          margin-left: 0;
        }
        @media (min-width: @keno-desktop-width) {
          min-width:inherit;
          font-size:1.4rem;
          margin-left:2rem;
          padding:1.2rem 4rem 1.1rem 1.4rem;
          cursor:pointer;
        }

        &.inactive {
          .opacity(.3);
          cursor:default;
        }
      }

      .autogenerate-button .icon, .reset-row-button .icon {
        width:2.934vw;
        height:3.7vw;
        fill:#4f5254;
        position:absolute;
        top:3.4vw;
        right:3.7vw;
        .transition(fill .25s);
        overflow:visible;

        @media (min-width: @keno-desktop-width) {
          width:1.6rem;
          height:1.6rem;
          top:1.1rem;
          right:1.4rem;
        }
      }
    }

    .autogenerate-box {
      display: inline-block;
      -webkit-touch-callout:none;
      .user-select(none);

      .autogenerate-button {
        position:relative;

        &:after {
          content:'';
          width:0;
          height:0;
          border-left:1.9vw solid transparent;
          border-right:1.9vw solid transparent;
          border-bottom:1.8vw solid #fff;
          margin-top:1.666vw;
          position:absolute;
          top:100%;
          left:50%;
          transform:translateX(-1.5vw);
          display:none;

          @media (min-width: @keno-desktop-width) {
            border-left:.7rem solid transparent;
            border-right:.7rem solid transparent;
            border-bottom:.7rem solid #fff;
            margin-top:1.3rem;
            transform:translateX(-50%);
          }
        }
      }

      .options {
        width:75.235vw;
        background-color:#fff;
        position:absolute;
        top:16.4vw;
        left:0;
        .border-radius(6px);
        display:none;
        z-index:1;
        @media (min-width: @keno-desktop-width) {
          width:58rem;
          top:8rem;
        }

        .item {
          width:50%;
          font-size:3.2vw;
          font-weight:bold;
          color:#0891d8;
          text-align:center;
          float:left;
          padding:4vw 0;
          border-width:0 .2rem .2rem 0;
          border-style:solid;
          border-color:#cdd3d7;
          .box-sizing();
          position:relative;
          @media (min-width: @keno-desktop-width) {
            width:20%;
            font-size:1.4rem;
            padding:1.1rem 0 1rem;
            border-width:0 .2rem .2rem 0;
            cursor:pointer;
          }

          &:first-child {
            &:before {
              content:'';
              height:3.4vw;
              background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
              background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 30%,rgba(255,255,255,0) 100%);
              background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 30%,rgba(255,255,255,0) 100%);
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
              position:absolute;
              top:0;
              left:4vw;
              right:-1vw;
              @media (min-width: @keno-desktop-width) {
                height:3.4rem;
                left:1rem;
                right:80%;
              }
            }

          }
          &.hidden, &.hidden.inactive {
            color:#fff;
            cursor:default;
          }
          &:nth-child(2n){
            border-width:0 0 .2rem 0;
          }

          &:last-child {
            border-width:0 0 0 0;
          }
          &:nth-last-child(2) {
            border-width:0 .2rem 0 0;
          }
          &.inactive {
            color:#cdd3d7;
            @media (min-width: @keno-desktop-width) {
              cursor:default;
            }
          }
        }

        &.ten-cells {
          .item {
            @media (min-width: @keno-desktop-width) {
              &:nth-child(2n){
                border-width:0 .2rem .2rem 0;
              }
              &:nth-child(5n){
                border-width:0 0 .2rem 0;
              }
              &:nth-last-child(-n+5) {
                border-width:0 .2rem 0 0;
              }
              &:last-child {
                border-width:0 0 0 0;
              }
            }
          }
        }

        &.eight-cells {
          .item {
            @media (min-width: @keno-desktop-width) {
              width:25%;

              &:nth-child(2n){
                border-width:0 .2rem .2rem 0;
              }
              &:nth-child(4n){
                border-width:0 0 .2rem 0;
              }
              &:nth-last-child(-n+4) {
                border-width:0 .2rem 0 0;
              }
              &:last-child {
                border-width:0 0 0 0;
              }
            }
          }
        }
      }

      &.active {
        .button {
          color:#fff;
          background-color:@keno-dodgerblue;

          &:after {
            display:block;
          }

          .icon {
            fill:#fff;
          }
        }

        .options {
          display:block;
        }
      }
    }
  }

  .number-picker-container {
    margin:0 -1.5%;
    position: relative;
    white-space:normal;
    -webkit-transform: translateZ(0);
    -webkit-touch-callout:none;
    .user-select(none);

    @media (min-width: @keno-desktop-width) {
      margin:0 -1rem;
    }

    .cell {
      width: 9.055vw;
      height: 9.055vw;
      color: #fff;
      text-align: center;
      position: relative;
      display:inline-block;
      margin:1vw;
      //will-change: transform;
      //.transition(width .25s, height .25s;);
      //.perspective(21.14vw);

      @media (min-width: @keno-desktop-width) {
        width:4rem;
        height:0;
        padding-top:4rem;
        margin:1rem;
        //.perspective(21.14rem);
        cursor:pointer;
      }

      &:after {
        content: '';
        position: absolute;
        top: -1vw;
        left: -1vw;
        right: -1vw;
        bottom: -1vw;

        @media (min-width: @keno-desktop-width) {
          top:0;
          left:0;
          right:0;
          bottom:0;
        }
      }

      .box {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        .front-face,
        .back-face {
          height: 100%;
          width:100%;
          display: block;
          .border-radius(6px);
          position:absolute;
          top:0;
          left:0;
        }

        .front-face {
          background-color: rgba(5, 145, 218, 1);
          visibility:visible;
        }

        .back-face {
          background-color: rgba(33, 55, 95, 1);
          visibility:hidden;
        }
      }

      &.selected .box {
        .front-face {
          visibility:hidden;
        }
        .back-face {
          visibility:visible;
        }
      }

      @keno-flip-animation-duration:.3s;
      @keno-flip-animation-function:linear;
      &.flip-animation-in-progress {
        transform-style:preserve-3d;
        transform:rotateX(0) translateZ(0);
        perspective: 21.14vw;
        animation: keno-flip-animation-timer @keno-flip-animation-duration;

        @keyframes keno-flip-face-animation {
          from { transform:rotateX(0deg) translate3D(0,0,4.5275vw); }
          to { transform:rotateX(-90deg) translate3D(0,0,4.5275vw); }
        }
        @keyframes keno-flip-back-animation {
          from { transform:rotateX(90deg) translate3D(0,0,4.5275vw); }
          to { transform:rotateX(0deg) translate3D(0,0,4.5275vw); }
        }
        @keyframes keno-reverse-flip-face-animation {
          from { transform:rotateX(-90deg) translate3D(0,0,4.5275vw); }
          to { transform:rotateX(0deg) translate3D(0,0,4.5275vw); }
        }
        @keyframes keno-reverse-flip-back-animation {
          from { transform:rotateX(0deg) translate3D(0,0,4.5275vw); }
          to { transform:rotateX(90deg) translate3D(0,0,4.5275vw); }
        }

        @media (min-width: @keno-desktop-width) {
          @keyframes keno-flip-face-animation {
            from { transform:rotateX(0deg) translate3D(0,0,2rem); }
            to { transform:rotateX(-90deg) translate3D(0,0,2rem); }
          }
          @keyframes keno-flip-back-animation {
            from { transform:rotateX(90deg) translate3D(0,0,2rem); }
            to { transform:rotateX(0deg) translate3D(0,0,2rem); }
          }
          @keyframes keno-reverse-flip-face-animation {
            from { transform:rotateX(-90deg) translate3D(0,0,2rem); }
            to { transform:rotateX(0deg) translate3D(0,0,2rem); }
          }
          @keyframes keno-reverse-flip-back-animation {
            from { transform:rotateX(0deg) translate3D(0,0,2rem); }
            to { transform:rotateX(90deg) translate3D(0,0,2rem); }
          }
        }

        @keyframes keno-flip-face-animation-IE {
          from { transform:perspective(21.14rem) rotateX(0deg) translate3D(0,0,2rem) scale(.99); }
          to { transform:perspective(21.14rem) rotateX(-90deg) translate3D(0,0,2rem) scale(.99); }
        }
        @keyframes keno-flip-back-animation-IE {
          from { transform:perspective(21.14rem) rotateX(90deg) translate3D(0,0,2rem) scale(.99); }
          to { transform:perspective(21.14rem) rotateX(0deg) translate3D(0,0,2rem) scale(.99); }
        }
        @keyframes keno-reverse-flip-face-animation-IE {
          from { transform:perspective(21.14rem) rotateX(-90deg) translate3D(0,0,2rem) scale(.99); }
          to { transform:perspective(21.14rem) rotateX(0deg) translate3D(0,0,2rem) scale(.99); }
        }
        @keyframes keno-reverse-flip-back-animation-IE {
          from { transform:perspective(21.14rem) rotateX(0deg) translate3D(0,0,2rem) scale(.99); }
          to { transform:perspective(21.14rem) rotateX(90deg) translate3D(0,0,2rem) scale(.99); }
        }

        .box {
          transform-style: preserve-3d;
          transform: translate3D(0, 0, -4.5275vw);
          @media (min-width: @keno-desktop-width) {
            transform:translate3D(0,0,-2rem);
          }
			
          .front-face,
          .back-face {
            visibility:visible;
            backface-visibility: hidden;
            -webkit-backface-visibility:hidden;
            will-change: transform;
          }
        }

        &.select-number-animation {
          .front-face {
            animation:keno-flip-face-animation @keno-flip-animation-duration forwards @keno-flip-animation-function;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              animation:keno-flip-face-animation-IE @keno-flip-animation-duration forwards @keno-flip-animation-function;
            }
          }
          .back-face {
            animation:keno-flip-back-animation @keno-flip-animation-duration forwards @keno-flip-animation-function;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              animation:keno-flip-back-animation-IE @keno-flip-animation-duration forwards @keno-flip-animation-function;
            }
          }
        }
        &.deselect-number-animation {
          .front-face {
            animation:keno-reverse-flip-face-animation @keno-flip-animation-duration forwards @keno-flip-animation-function;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              animation:reverse-flip-face-animation-IE @keno-flip-animation-duration forwards @keno-flip-animation-function;
            }
          }
          .back-face {
            animation:keno-reverse-flip-back-animation @keno-flip-animation-duration forwards @keno-flip-animation-function;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
              animation:keno-reverse-flip-back-animation-IE @keno-flip-animation-duration forwards @keno-flip-animation-function;
            }
          }
        }
      }

      .number {
        width: 100%;
        height: 100%;
        font-size: 4vw;
        font-weight: bold;
        font-style: italic;
        line-height: 9.5vw;
        color: #fff;
        .border-radius(6px);
        position:absolute;
        top:0;
        left:0;
        -webkit-font-smoothing: antialiased;

        @media (min-width: @keno-desktop-width) {
          font-size:2rem;
          line-height:2.1;
        }

      }

      .front-face .number {
        background-color:rgba(5, 145, 218, 1);
      }
      .back-face .number {
        background-color:rgba(33, 55, 95, 1);
      }

      &.active .number {
        line-height: 7.428vw;
        height: 130%;
        .transform(translateY(-100%) scale(1.3));

        @media (min-width: @keno-desktop-width) {
          height:100%;
          line-height:2.1;
          .transform(translateY(0) scale(1));
        }
      }

    }
  }
}
