.keno-winnings-section {
  width: 100%;
  padding: 7.5vw 0;
  text-align: center;
  overflow: hidden;
  position: relative;
}
@media (min-width: 950px) {
  .keno-winnings-section {
    padding: 4.25rem 0;
  }
}
.keno-winnings-section .winnings-headline {
  font-size: 5.333vw;
  font-weight: bold;
  font-style: italic;
  color: #00a5eb;
  text-transform: uppercase;
  margin: 0;
}
@media (min-width: 950px) {
  .keno-winnings-section .winnings-headline {
    font-size: 3rem;
  }
}
.keno-winnings-section .winnings-subheadline {
  font-size: 4vw;
  font-weight: bold;
  color: #1e55a0;
  text-transform: uppercase;
  margin: 3.2vw 0;
}
@media (min-width: 950px) {
  .keno-winnings-section .winnings-subheadline {
    font-size: 2rem;
    margin: 2rem 0;
  }
}
.keno-winnings-section .winnings-subheadline .notice {
  color: #feb700;
}
.keno-winnings-section .winnings-subheadline .a,
.keno-winnings-section .winnings-subheadline a:visited {
  color: inherit;
}
.keno-winnings-section .winnings-notice {
  font-size: 2.8vw;
}
@media (min-width: 950px) {
  .keno-winnings-section .winnings-notice {
    font-size: 1.4rem;
    margin: 2rem 0;
  }
}
.keno-winnings-section .rows-numbers-wrapper {
  direction: rtl;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (min-width: 950px) {
  .keno-winnings-section .rows-numbers-wrapper {
    width: 74rem;
    margin: auto;
    overflow: hidden;
  }
}
.keno-winnings-section .rows-numbers-wrapper .rows-numbers-inner-container {
  -webkit-transition: -webkit-transform 0.25s linear;
  -moz-transition: -moz-transform 0.25s linear;
  -ms-transition: -ms-transform 0.25s linear;
  -o-transition: -o-transform 0.25s linear;
  transition: transform 0.25s linear;
  position: relative;
}
.keno-winnings-section .rows-numbers-wrapper .row-number-box {
  width: 12vw;
  height: 12vw;
  display: inline-block;
  position: relative;
}
@media (min-width: 950px) {
  .keno-winnings-section .rows-numbers-wrapper .row-number-box {
    width: 6rem;
    height: 6rem;
    cursor: pointer;
  }
}
.keno-winnings-section .rows-numbers-wrapper .row-number-box:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.keno-winnings-section .rows-numbers-wrapper .row-number-box .row-number-text {
  font-size: 5vw;
  font-weight: bold;
  font-style: italic;
  line-height: 2.5;
  color: inherit;
}
@media (min-width: 950px) {
  .keno-winnings-section .rows-numbers-wrapper .row-number-box .row-number-text {
    font-size: 2.5rem;
  }
}
.keno-winnings-section .rows-numbers-wrapper .rows-numbers-blue,
.keno-winnings-section .rows-numbers-wrapper .rows-numbers-white {
  width: 12vw;
  white-space: nowrap;
  position: absolute;
  top: 0;
  right: 50%;
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -ms-transform: translateX(50%);
  -o-transform: translateX(50%);
  transform: translateX(50%);
}
@media (min-width: 950px) {
  .keno-winnings-section .rows-numbers-wrapper .rows-numbers-blue,
  .keno-winnings-section .rows-numbers-wrapper .rows-numbers-white {
    width: 6rem;
  }
}
.keno-winnings-section .rows-numbers-wrapper .rows-numbers-blue {
  color: #00a5eb;
}
.keno-winnings-section .rows-numbers-wrapper .rows-numbers-white {
  color: #fff;
  overflow: hidden;
}
.keno-winnings-section .rows-numbers-wrapper .rows-numbers-background {
  width: 12vw;
  height: 12vw;
  background-color: #00a5eb;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  position: relative;
  right: 50%;
  -webkit-transform: translateX(50%);
  -moz-transform: translateX(50%);
  -ms-transform: translateX(50%);
  -o-transform: translateX(50%);
  transform: translateX(50%);
}
@media (min-width: 950px) {
  .keno-winnings-section .rows-numbers-wrapper .rows-numbers-background {
    width: 6rem;
    height: 6rem;
  }
}
.keno-winnings-section .rows-numbers-wrapper .next-number-button,
.keno-winnings-section .rows-numbers-wrapper .prev-number-button {
  width: 14.5vw;
  position: absolute;
  top: 0;
  bottom: 0;
  -webkit-transition: -webkit-transform 0.25s;
  -moz-transition: -moz-transform 0.25s;
  -ms-transition: -ms-transform 0.25s;
  -o-transition: -o-transform 0.25s;
  transition: transform 0.25s;
}
@media (min-width: 950px) {
  .keno-winnings-section .rows-numbers-wrapper .next-number-button,
  .keno-winnings-section .rows-numbers-wrapper .prev-number-button {
    width: 8rem;
    overflow: hidden;
    cursor: pointer;
  }
}
.keno-winnings-section .rows-numbers-wrapper .next-number-button:after,
.keno-winnings-section .rows-numbers-wrapper .prev-number-button:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.keno-winnings-section .rows-numbers-wrapper .next-number-button__icon,
.keno-winnings-section .rows-numbers-wrapper .prev-number-button__icon {
  width: 4.267vw;
  height: 4.267vw;
  fill: #00a5eb;
  position: absolute;
  top: 50%;
  transition: transform .25s, opacity .25s;
}
@media (min-width: 950px) {
  .keno-winnings-section .rows-numbers-wrapper .next-number-button__icon,
  .keno-winnings-section .rows-numbers-wrapper .prev-number-button__icon {
    width: 1.8rem;
    height: 1.8rem;
  }
}
.keno-winnings-section .rows-numbers-wrapper .next-number-button.active,
.keno-winnings-section .rows-numbers-wrapper .prev-number-button.active {
  transform: translate3d(0, 0, 0);
}
.keno-winnings-section .rows-numbers-wrapper .next-number-button.active .next-number-button__icon,
.keno-winnings-section .rows-numbers-wrapper .prev-number-button.active .next-number-button__icon,
.keno-winnings-section .rows-numbers-wrapper .next-number-button.active .prev-number-button__icon,
.keno-winnings-section .rows-numbers-wrapper .prev-number-button.active .prev-number-button__icon {
  transform: translate3d(0, -50%, 0);
  opacity: 1;
}
.keno-winnings-section .rows-numbers-wrapper .next-number-button {
  right: 0;
  transform: translate3d(20%, 0, 0);
  background: -moz-linear-gradient(left, rgba(235, 248, 253, 0) 0%, rgba(235, 248, 253, 0.4) 10%, rgba(235, 248, 253, 0.65) 20%, rgba(235, 248, 253, 0.8) 50%, #ebf8fd 99%, #ebf8fd 100%);
  background: -webkit-linear-gradient(left, rgba(235, 248, 253, 0) 0%, rgba(235, 248, 253, 0.4) 10%, rgba(235, 248, 253, 0.65) 20%, rgba(235, 248, 253, 0.8) 50%, #ebf8fd 99%, #ebf8fd 100%);
  background: linear-gradient(to right, rgba(235, 248, 253, 0) 0%, rgba(235, 248, 253, 0.4) 10%, rgba(235, 248, 253, 0.65) 20%, rgba(235, 248, 253, 0.8) 50%, #ebf8fd 99%, #ebf8fd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ebf8fd', endColorstr='#ebf8fd', GradientType=1);
}
.keno-winnings-section .rows-numbers-wrapper .next-number-button__icon {
  right: 5.8vw;
  transform: translate3d(-20%, -50%, 0);
  opacity: 0;
}
@media (min-width: 950px) {
  .keno-winnings-section .rows-numbers-wrapper .next-number-button__icon {
    right: 3rem;
  }
}
.keno-winnings-section .rows-numbers-wrapper .prev-number-button {
  left: 0;
  transform: translate3d(-20%, 0, 0);
  background: -moz-linear-gradient(left, #ebf8fd 0%, #ebf8fd 1%, rgba(235, 248, 253, 0.8) 50%, rgba(235, 248, 253, 0.65) 80%, rgba(235, 248, 253, 0.4) 90%, rgba(235, 248, 253, 0) 100%);
  background: -webkit-linear-gradient(left, #ebf8fd 0%, #ebf8fd 1%, rgba(235, 248, 253, 0.8) 50%, rgba(235, 248, 253, 0.65) 80%, rgba(235, 248, 253, 0.4) 90%, rgba(235, 248, 253, 0) 100%);
  background: linear-gradient(to right, #ebf8fd 0%, #ebf8fd 1%, rgba(235, 248, 253, 0.8) 50%, rgba(235, 248, 253, 0.65) 80%, rgba(235, 248, 253, 0.4) 90%, rgba(235, 248, 253, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ebf8fd', endColorstr='#00ebf8fd', GradientType=1);
}
.keno-winnings-section .rows-numbers-wrapper .prev-number-button__icon {
  left: 5.8vw;
  transform: translate3d(20%, -50%, 0);
  opacity: 0;
}
@media (min-width: 950px) {
  .keno-winnings-section .rows-numbers-wrapper .prev-number-button__icon {
    left: 3rem;
  }
}
.keno-winnings-section .winnings-wrapper {
  width: 100%;
  height: 16vw;
  margin-top: -3vw;
  position: relative;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (min-width: 950px) {
  .keno-winnings-section .winnings-wrapper {
    height: 9.9rem;
    margin-top: -2.5rem;
  }
}
.keno-winnings-section .winnings-wrapper .winnings-inner-wrapper {
  width: 100%;
  margin-bottom: -2.716vw;
  position: absolute;
  bottom: 50%;
  -webkit-transition: -webkit-transform 0.25s linear;
  -moz-transition: -moz-transform 0.25s linear;
  -ms-transition: -ms-transform 0.25s linear;
  -o-transition: -o-transform 0.25s linear;
  transition: transform 0.25s linear;
}
@media (min-width: 950px) {
  .keno-winnings-section .winnings-wrapper .winnings-inner-wrapper {
    margin-bottom: -1.65rem;
  }
}
.keno-winnings-section .winnings-wrapper .winnings-inner-wrapper .winning-amount {
  font-size: 5.333vw;
  line-height: 5.433vw;
  font-weight: bold;
  font-style: italic;
  color: #00a5eb;
  -webkit-transition: -webkit-transform 0.25s linear;
  -moz-transition: -moz-transform 0.25s linear;
  -ms-transition: -ms-transform 0.25s linear;
  -o-transition: -o-transform 0.25s linear;
  transition: transform 0.25s linear;
}
@media (min-width: 950px) {
  .keno-winnings-section .winnings-wrapper .winnings-inner-wrapper .winning-amount {
    font-size: 2.5rem;
    line-height: 3.3rem;
    cursor: default;
  }
}
.keno-winnings-section .winnings-wrapper .winnings-inner-wrapper .winning-amount:last-child {
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -ms-transform: scale(1.25);
  -o-transform: scale(1.25);
  transform: scale(1.25);
}
.keno-winnings-section .winnings-wrapper .winnings-top-gradient,
.keno-winnings-section .winnings-wrapper .winnings-bottom-gradient {
  width: 100%;
  height: 5.733vw;
  position: absolute;
  left: 0;
}
@media (min-width: 950px) {
  .keno-winnings-section .winnings-wrapper .winnings-top-gradient,
  .keno-winnings-section .winnings-wrapper .winnings-bottom-gradient {
    height: 3.3rem;
  }
}
.keno-winnings-section .winnings-wrapper .winnings-top-gradient {
  top: -0.3vw;
  background: -moz-linear-gradient(top, #ebf8fd 0%, rgba(235, 248, 253, 0.99) 1%, rgba(235, 248, 253, 0.6) 60%, rgba(235, 248, 253, 0) 100%);
  background: -webkit-linear-gradient(top, #ebf8fd 0%, rgba(235, 248, 253, 0.99) 1%, rgba(235, 248, 253, 0.6) 60%, rgba(235, 248, 253, 0) 100%);
  background: linear-gradient(to bottom, #ebf8fd 0%, rgba(235, 248, 253, 0.99) 1%, rgba(235, 248, 253, 0.6) 60%, rgba(235, 248, 253, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ebf8fd', endColorstr='#00ebf8fd', GradientType=0);
}
@media (min-width: 950px) {
  .keno-winnings-section .winnings-wrapper .winnings-top-gradient {
    top: -0.3rem;
  }
}
.keno-winnings-section .winnings-wrapper .winnings-bottom-gradient {
  bottom: -0.3vw;
  background: -moz-linear-gradient(top, rgba(235, 248, 253, 0) 0%, rgba(235, 248, 253, 0.6) 40%, rgba(235, 248, 253, 0.99) 99%, #ebf8fd 100%);
  background: -webkit-linear-gradient(top, rgba(235, 248, 253, 0) 0%, rgba(235, 248, 253, 0.6) 40%, rgba(235, 248, 253, 0.99) 99%, #ebf8fd 100%);
  background: linear-gradient(to bottom, rgba(235, 248, 253, 0) 0%, rgba(235, 248, 253, 0.6) 40%, rgba(235, 248, 253, 0.99) 99%, #ebf8fd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ebf8fd', endColorstr='#ebf8fd', GradientType=0);
}
@media (min-width: 950px) {
  .keno-winnings-section .winnings-wrapper .winnings-bottom-gradient {
    bottom: -0.3rem;
  }
}
.keno-winnings-section .winnings-level-overview {
  width: 75.235%;
  font-size: 0;
  margin: auto;
  padding-bottom: 9.2vw;
  position: relative;
}
.keno-winnings-section .winnings-level-overview:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
@media (min-width: 950px) {
  .keno-winnings-section .winnings-level-overview {
    width: 58rem;
    padding-bottom: 4.5rem;
  }
}
.keno-winnings-section .winnings-level-overview .number {
  width: 7.52vw;
  height: 10.666vw;
  font-size: 3.2vw;
  font-weight: bold;
  line-height: 10.866vw;
  text-align: center;
  color: #99dbf7;
  display: inline-block;
  position: relative;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}
@media (min-width: 950px) {
  .keno-winnings-section .winnings-level-overview .number {
    width: 5.8rem;
    height: 5.6rem;
    font-size: 1.8rem;
    line-height: 5.6rem;
  }
}
.keno-winnings-section .winnings-level-overview .number.selected {
  color: #fff;
}
.keno-winnings-section .winnings-level-overview .number.selected:before {
  content: '';
  border-left: 1.2vw solid transparent;
  border-right: 1.2vw solid transparent;
  border-bottom: 1.333vw solid #ebf8fd;
  position: absolute;
  bottom: -0.2vw;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
}
@media (min-width: 950px) {
  .keno-winnings-section .winnings-level-overview .number.selected:before {
    border-left: .6rem solid transparent;
    border-right: .6rem solid transparent;
    border-bottom: .7rem solid #ebf8fd;
    bottom: 0;
  }
}
.keno-winnings-section .winnings-level-overview .out-of-range-numbers {
  text-align: left;
  background-color: #00a5eb;
  position: relative;
  float: left;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 6px;
  -webkit-border-top-left-radius: 6px;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 6px;
  -moz-border-radius-topleft: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.keno-winnings-section .winnings-level-overview .out-of-range-numbers.full-out-of-range {
  border-radius: 6px;
}
.keno-winnings-section .winnings-level-overview .in-range-numbers {
  text-align: right;
  background-color: #0891d8;
  position: relative;
  margin-left: -0.2vw;
  float: left;
  -webkit-border-top-right-radius: 6px;
  -webkit-border-bottom-right-radius: 6px;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius-topright: 6px;
  -moz-border-radius-bottomright: 6px;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
@media (min-width: 950px) {
  .keno-winnings-section .winnings-level-overview .in-range-numbers {
    margin-left: 0;
  }
}
.keno-winnings-section .winnings-level-overview .in-range-numbers .info-text {
  font-size: 3.2vw;
  font-weight: bold;
  line-height: .7;
  color: #0891d8;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  bottom: -5.7vw;
  transform: translateX(-50%);
}
@media (min-width: 950px) {
  .keno-winnings-section .winnings-level-overview .in-range-numbers .info-text {
    font-size: 1.8rem;
    bottom: -3.2rem;
  }
}
