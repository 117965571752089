.confirm-bar-wrapper {
  width: 100%;
  height: 16.3vw;
  position: relative;
  z-index: 4;
}
.confirm-bar-wrapper .confirm-bar {
  width: 100%;
  height: 16.3vw;
  position: relative;
  font-size: 3.2vw;
  font-weight: bold;
}
.confirm-bar-wrapper .confirm-bar:before,
.confirm-bar-wrapper .confirm-bar:after {
  content: "";
  width: calc((100% - 96rem) / 2);
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.95);
}
.confirm-bar-wrapper .confirm-bar:before {
  left: 0;
}
.confirm-bar-wrapper .confirm-bar:after {
  right: 0;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content {
  position: relative;
  text-align: left;
  background-color: inherit;
  width: 100%;
  max-width: 96rem;
  height: inherit;
  margin: auto;
  display: table;
  table-layout: fixed;
  line-height: 7rem;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content a {
  line-height: normal;
  display: block;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div {
  width: 50%;
  height: inherit;
  display: table-cell;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  border-left: 0.1rem solid #cdd3d7;
  box-sizing: border-box;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div span {
  display: none;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-back {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.95);
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-back:hover {
  background-color: #ffffff;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta {
  width: 50%;
  text-align: center;
  border-left: none;
  background-color: #feb700;
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta:hover {
  background-color: #f5d900;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta .total-price {
  transition: opacity .25s, transform .25s;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta.is-inactive {
  cursor: default;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta.is-inactive:hover {
  background-color: #feb700;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta.is-inactive .total-price {
  -webkit-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta .confirm .purchase-button-loader-box {
  width: 100%;
  position: absolute;
  transform: translateY(-200%) scale(0.8);
  opacity: 0;
  transition: transform .25s, opacity .15s;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta .confirm .purchase-button-loader-box .loader-text {
  display: inline-block;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta .confirm .purchase-button-loader-box .loader-animation-box {
  margin-left: .5rem;
  display: inline-block;
}
@keyframes loader-dot-animation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100%);
  }
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta .confirm .purchase-button-loader-box .loader-animation-box .loader-dot {
  width: 1.2vw;
  height: 1.2vw;
  margin-left: .3rem;
  border-radius: 50%;
  background-color: #000;
  display: inline-block;
}
@media (min-width: 950px) {
  .confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta .confirm .purchase-button-loader-box .loader-animation-box .loader-dot {
    width: .8rem;
    height: .8rem;
  }
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta.processing,
.status-pending-confirmation .confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta {
  cursor: none;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta.processing .confirm .purchase-button-loader-box,
.status-pending-confirmation .confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta .confirm .purchase-button-loader-box {
  transform: translateY(0) scale(1);
  opacity: 1;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta.processing .confirm .purchase-button-loader-box .loader-animation-box .loader-dot:nth-child(1n),
.status-pending-confirmation .confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta .confirm .purchase-button-loader-box .loader-animation-box .loader-dot:nth-child(1n) {
  animation: loader-dot-animation 0.5s ease-in-out -0.66s infinite;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta.processing .confirm .purchase-button-loader-box .loader-animation-box .loader-dot:nth-child(2n),
.status-pending-confirmation .confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta .confirm .purchase-button-loader-box .loader-animation-box .loader-dot:nth-child(2n) {
  animation: loader-dot-animation 0.5s ease-in-out -0.33s infinite;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta.processing .confirm .purchase-button-loader-box .loader-animation-box .loader-dot:nth-child(3n),
.status-pending-confirmation .confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta .confirm .purchase-button-loader-box .loader-animation-box .loader-dot:nth-child(3n) {
  animation: loader-dot-animation 0.5s ease-in-out -1s infinite;
}
.confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta.processing .confirm .total-price,
.status-pending-confirmation .confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta .confirm .total-price {
  transform: translateY(100%) scale(0.8);
  opacity: .6;
}
.confirm-bar-wrapper.fixed .confirm-bar {
  position: fixed;
  bottom: 0;
}
@media (min-width: 950px) {
  .confirm-bar-wrapper {
    height: 7rem;
  }
  .confirm-bar-wrapper.fixed {
    bottom: 3rem;
  }
  .confirm-bar-wrapper .confirm-bar {
    font-size: 1.4rem;
    height: 7rem;
  }
  .confirm-bar-wrapper .confirm-bar .confirm-bar-content {
    height: 100%;
  }
  .confirm-bar-wrapper .confirm-bar .confirm-bar-content > div {
    width: auto;
  }
  .confirm-bar-wrapper .confirm-bar .confirm-bar-content > div span {
    display: inline;
  }
  .confirm-bar-wrapper .confirm-bar .confirm-bar-content > div.confirm-bar-cta {
    width: auto;
  }
}
