.keno-confirm .keno-section {
  background-color: #fff;
  border-color: #fff;
}
.keno-confirm .keno-section .btn {
  margin: 6rem 1rem 2rem;
}
.keno-confirm .keno-section .cta-deposit {
  margin: 0 0 2rem;
}
.keno-confirm .keno-section .keno-row-display {
  padding: 2vw 0 0;
}
.keno-confirm .keno-section .keno-row-display .alt-text {
  font-size: 3.35vw;
  color: #4f5254;
  padding-bottom: 3vw;
  margin-top: 0;
}
@media (min-width: 950px) {
  .keno-confirm .keno-section .keno-row-display .alt-text {
    font-size: 2rem;
    padding-bottom: 3rem;
  }
}
.keno-confirm .keno-section .keno-row-display .row-title {
  max-width: unset;
  font-size: 4.5vw;
  font-weight: bold;
  text-transform: uppercase;
  color: #0891d8;
  line-height: 8vw;
  padding: 3vw 0;
  margin: 0 auto;
}
@media (min-width: 768px) and (max-width: 950px) {
  .keno-confirm .keno-section .keno-row-display .row-title {
    font-size: 4vw;
    line-height: 5vw;
    padding: 1.6vw 0;
  }
}
@media (min-width: 950px) {
  .keno-confirm .keno-section .keno-row-display .row-title {
    max-width: 58rem;
    font-size: 2rem;
    line-height: 2.4rem;
    border-bottom: 1px solid #99dbf7;
    padding: 1.2rem 0;
  }
}
.keno-confirm .keno-section .keno-row-display .row-title .multiple-draws {
  display: block;
}
.keno-confirm .keno-section .keno-row-display .row-title:nth-child(even) {
  background-color: #e0f4fd;
}
@media (min-width: 950px) {
  .keno-confirm .keno-section .keno-row-display .row-title:nth-child(even) {
    background-color: #fff;
  }
}
.keno-confirm .keno-section .keno-row-display .row-title:last-of-type {
  border-bottom: none;
}
@media (min-width: 950px) {
  .keno-confirm .keno-section .keno-row-display .row-title:last-of-type {
    padding-bottom: 1.2rem;
  }
}
.keno-confirm .keno-section .keno-row-display .row-title .row-content {
  font-weight: bold;
  font-style: italic;
  color: #1e55a0;
  text-transform: uppercase;
}
.keno-confirm .keno-section .keno-row-display .row-title .row-content.numbers {
  display: block;
}
.keno-confirm .keno-section .keno-row-display .rows-wrapper {
  max-height: 44vw;
  overflow-y: hidden;
  transition: max-height 0.25s linear;
  position: relative;
  margin-bottom: 4vw;
}
@media (min-width: 768px) and (max-width: 950px) {
  .keno-confirm .keno-section .keno-row-display .rows-wrapper {
    max-height: 26vw;
  }
}
@media (min-width: 950px) {
  .keno-confirm .keno-section .keno-row-display .rows-wrapper {
    max-height: 14rem;
    margin-bottom: 4rem;
  }
}
@media (min-width: 950px) {
  .keno-confirm .keno-section .keno-row-display .rows-wrapper .row-title:first-child {
    border-top: 1px solid #99dbf7;
  }
}
.keno-confirm .keno-section .keno-row-display .rows-wrapper.expanded .gradient-wrapper {
  display: none;
}
.keno-confirm .keno-section .keno-row-display .rows-wrapper .gradient-wrapper {
  width: 100%;
  height: 22vw;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.65) 35%, rgba(255, 255, 255, 0.8) 70%, rgba(255, 255, 255, 0.95) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
}
@media (min-width: 768px) and (max-width: 950px) {
  .keno-confirm .keno-section .keno-row-display .rows-wrapper .gradient-wrapper {
    height: 13vw;
  }
}
@media (min-width: 950px) {
  .keno-confirm .keno-section .keno-row-display .rows-wrapper .gradient-wrapper {
    height: 7rem;
  }
}
.keno-confirm .keno-section .keno-row-display .rows-wrapper .gradient-wrapper .show-more-button {
  min-width: 32vw;
  font-size: 3.8vw;
  font-weight: bold;
  line-height: 10.7vw;
  text-transform: uppercase;
  color: #4f5254;
  background-color: #feb700;
  border-radius: 5px;
  display: inline-block;
  padding: 0 4vw;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  user-select: none;
  margin: 0;
  transition: background-color 0.15s linear;
}
@media (min-width: 768px) and (max-width: 950px) {
  .keno-confirm .keno-section .keno-row-display .rows-wrapper .gradient-wrapper .show-more-button {
    font-size: 3vw;
    line-height: 8vw;
  }
}
@media (min-width: 950px) {
  .keno-confirm .keno-section .keno-row-display .rows-wrapper .gradient-wrapper .show-more-button {
    min-width: 12rem;
    font-size: 1.4rem;
    line-height: 4rem;
    padding: 0 1.2rem;
  }
}
@media (min-width: 950px) {
  .keno-confirm .keno-section .keno-row-display .rows-wrapper .gradient-wrapper .show-more-button:hover {
    background-color: #f5d900;
  }
}
.keno-confirm .keno-section .keno-row-display .terms-container {
  max-width: 86.5vw;
  font-size: 3vw;
  color: #4f5254;
  margin: 0 auto;
  margin-bottom: 7vw;
}
@media (min-width: 950px) {
  .keno-confirm .keno-section .keno-row-display .terms-container {
    font-size: 1.5rem;
    margin-bottom: 4rem;
  }
}
.keno-confirm .keno-section .keno-row-display .terms-container .terms-link {
  color: #4f5254;
  display: block;
}
@media (min-width: 950px) {
  .keno-confirm .keno-section .keno-row-display .terms-container .terms-link {
    display: inline-block;
  }
}
