﻿@import url("../Mixins/_mixins.less");

.countdown {
  width: 100%;
  max-width: 88vw;
  text-align: center;
  margin: auto;

  @media (min-width: @keno-desktop-width) {
    max-width: 96rem;
  }

  &.hide {
    display: none;
  }

  &.no-opendraw {
    .countdown-headline {
      color: @keno-white;
    }

    .number-container {
      color: @keno-white;
    }
  }

  .number-container {
    color: @keno-lightskyblue;
  }

  .countdown-headline {
    font-size: 3.75vw;
    font-weight: bold;
    text-transform: uppercase;
    color: @keno-powderblue;

    @media (min-width: @keno-desktop-width) {
      font-size: 2rem;
    }
  }

  .number-container {
    display: inline-block;
    margin: 0 3vw;
    color: @keno-royalblue;

    @media (min-width: @keno-desktop-width) {
      margin: 0 1.5rem;
    }

    > span {
      margin: 0 .2rem;
      display: initial;
    }

    .number {
      font-size: 7.5vw;

      @media (min-width: @keno-desktop-width) {
        font-size: 3.8rem;
        line-height: normal;
      }
    }
  }
}

div[data-component="keno-game-closed"] {
  .keno-no-open-draw {
    .keno-section {

      .header-icon {
        margin-bottom: 1rem;

        svg {
          width: 10vw;
          height: 10vw;

          @media (min-width: @keno-desktop-width) {
            width: 6.4rem;
            height: 6.4rem;
          }
        }
      }

      &.manchete.top {
        padding: 5rem 0 7rem;
      }

      .countdown {
        .countdown-headline {
          color: @keno-powderblue;
        }

        .number-container {
          color: @keno-royalblue;
        }
      }
    }
  }
}
