@import url("../Mixins/_mixins.less");

.keno-receipt {

  .keno-section {
    padding: 5.4vw 0 5.4vw 0;
    @media (min-width: @keno-desktop-width) {
      padding: 4rem 0;
    }
    .keno-content-wrapper {
      width: 100%;
      max-width: 86.7vw;
      @media (min-width: @keno-desktop-width) {
        max-width: 96rem;
      }

      .hr {
        margin: 6vw auto 4vw;
        @media (min-width: @keno-desktop-width) {
          margin: 3.5rem auto 2.5rem; // hacking default margin for streamline design
        }
      }
      .p {
        font-size: 3.75vw;
        margin: 0 auto 2vw;
        color: #000;
        &.word-width-limit {
          max-width: 60vw;
        }
        @media (min-width: @keno-desktop-width) {
          font-size: 2rem;
          line-height: 2.4rem;
          margin: 0 auto 2rem;
          &.word-width-limit {
            max-width: 100%;
          }
        }
        .a {
          color: #000;
          border-bottom: .1rem solid #000;
        }
        &.countdown-fallback {
          margin-top: 2vw;
          @media (min-width: @keno-desktop-width) {
            margin-top: 2rem;
          }
          &.hide {
            display: none;
          }
        }
      }
    }
  }

  .receipt-confirmation {
    margin: auto;
    @media (min-width: @keno-desktop-width) {
      width: 100%;
      max-width: 96rem;
    }
    svg {
      margin-bottom: 4vw;
      @media (min-width: @keno-desktop-width) {
        margin-bottom: 2.5rem;
      }
    }
  }

  .xlarge {
    font-size: 6.6vw;
    line-height: 6.6vw;
    margin-bottom: 2vw;
    @media (min-width: @keno-desktop-width) {
      font-size: 4rem;
      line-height: 4rem;
      margin-bottom: 1rem;
    }
  }

  .xsmall {
    font-size: 5.25vw;
    line-height: 6vw;
    @media (min-width: @keno-desktop-width) {
      font-size: 2.5rem;
      line-height: 2rem;
    }
  }

  span {
    display: block;
    margin: 0 .2rem;
    &.xxxlarge {
      font-size: 7.5vw;
      line-height: 8vw;
      @media (min-width: @keno-desktop-width) {
        font-size: 8rem;
        line-height: 8rem;
      }
    }
    &.xxsmall {
      font-size: 3.75vw;
      @media (min-width: @keno-desktop-width) {
        font-size: 2rem;
        line-height: 2.4rem;
      }
    }
  }
    

  .svg-checkmark {
    margin: 0 auto 3.5vw;
    @media (min-width: @keno-desktop-width) {
      margin: 0 auto 2rem;
    }
  }
}