﻿@import (reference) "Mixins/_mixins.less"; // ┬──┬﻿ ¯\_(ツ)

.region-keno {
  font-size: 1.4rem;
  background-color: @keno-powderblue;
  width: 100%; //overflow-x: hidden;
  .container {
    width: 100vw;

    @media (min-width: @keno-desktop-width) {
      width: 100%;
    }
  }
  .page-box .container {
    width: 100%;
  }
}

// `keno-section` encapsulates each section entirely
// it also controls background visuals like back-
// ground images, form, and background color.
.keno-section {
  position: relative; // fonts
  font-style: normal; // background colors
  background-color: @keno-powderblue;
  border-color: @keno-powderblue;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  &.bg-powderblue {
    background-color: @keno-powderblue;
    border-color: @keno-powderblue;
  }

  &.bg-lightskyblue {
    background-color: @keno-lightskyblue;
    border-color: @keno-lightskyblue;
  }

  &.bg-cadetblue {
    background-color: @keno-cadetblue;
    border-color: @keno-cadetblue;
  }

  &.bg-royalblue {
    background-color: @keno-royalblue;
    border-color: @keno-royalblue;
  }

  &.bg-dodgerblue {
    background-color: @keno-dodgerblue;
    border-color: @keno-dodgerblue;
  }

  &.bg-white {
    background-color: @keno-white;
    border-color: @keno-white;
  }

  &.bg-superlightblue {
    background-color: @keno-superlightblue;
    border-color: @keno-superlightblue;
  } // `keno-content-wrapper` controls layout of the
  // content for each section. It also serves as the
  // container for all components and elements.
  >.keno-content-wrapper {
    //max-width: 86.7vw;
    margin: auto; // centered text is a recurring theme, so we set it as a
    // default.
    text-align: center; //AAAAAAAAAAAAAAAAAAAAAAAAA
    .a {
      cursor: pointer;
      color: #fff;
      text-decoration: none;

      &.hd {
        text-decoration: none;
      }
    } // PPPPPPPPPPPPPPPPPP
    .p {
      width: 100%;
      max-width: @keno-device-width;
      margin: auto;
      color: #ffffff;
      font-size: 4vw;
      line-height: normal;

      @media (min-width: @keno-desktop-width) {
        font-size: 1.5rem;
        width: @keno-desktop-width;
      }

      &.dark {
        color: @keno-darkgrey;

        .a {
          color: inherit;
          border-bottom: .1rem solid @keno-darkgrey;
        }
      }
    } // HRHRHRHRHRHRHRHRHR
    .hr {
      width: @keno-device-width;
      border-width: .1rem;
      border-style: solid;
      border-top: 0;
      margin: 6vw auto;

      &.mediumblue {
        border-color: @keno-mediumblue;
      }

      @media (min-width: @keno-desktop-width) {
        margin: 2.5rem auto;
        max-width: 96rem;
      }
    }
  } // ´keno-content-wrapper´ END
}

// ´keno-section´ END
// apply the `manchete` class to any `keno-section`
// for the desired manchete effect.
// since the manchete eats some of the previous node
// in the DOM tree, we have applied special position
// properties to the `keno-content-section`
.manchete {
  position: relative; //background-clip: content-box;
  overflow-x: hidden;
  
  &.bg-powderblue {
    background-color: @keno-powderblue;
    border-color: @keno-powderblue;
  }

  &.bg-lightskyblue {
    background-color: @keno-lightskyblue;
    border-color: @keno-lightskyblue;
  }

  &.bg-cadetblue {
    background-color: @keno-cadetblue;
    border-color: @keno-cadetblue;
  }

  &.bg-royalblue {
    background-color: @keno-royalblue;
    border-color: @keno-royalblue;
  }

  &.bg-dodgerblue {
    background-color: @keno-dodgerblue;
    border-color: @keno-dodgerblue;
  }

  &.bg-white {
    background-color: @keno-white;
    border-color: @keno-white;
  }

  &.bg-superlightblue {
    background-color: @keno-superlightblue;
    border-color: @keno-superlightblue;
  }

  &.top {
    position: relative; //background-clip: content-box;
    //.keno-section {
    &:before {
      position: absolute;
      content: ' ';
      display: block;
      top: -10em;
      left: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      border-left-width: 70em;
      border-right-width: 70em;
      border-top-width: 10em;
      border-color: inherit;
      border-top-color: transparent;
      margin-left: -70em;
      z-index: 1;
      @media (min-width: 1264px) {
        border-left-width: 96em;
        border-right-width: 96em;
        margin-left: -96em;
        top: -12em;
        border-top-width: 12em;
      }
      @media (min-width: 1400px) {
        border-left-width: 160em;
        border-right-width: 160em;
        margin-left: -160em;
        top: -15em;
        border-top-width: 15em;
      }
    } // }
  }

  &.bot {
    position: relative;
    background-clip: content-box; //.keno-section {
    &:after {
      position: absolute;
      content: ' ';
      display: block;
      bottom: -10.3rem;
      left: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      border-left-width: 70rem;
      border-right-width: 70rem;
      border-top-width: 10rem;
      border-color: transparent;
      border-top-color: inherit;
      margin-left: -70rem;
      z-index: 1;
      @media (min-width: 1264px) {
        border-left-width: 96rem;
        border-right-width: 96rem;
        margin-left: -96rem;
      }
      @media (min-width: 1400px) {
        border-left-width: 160rem;
        border-right-width: 160rem;
        margin-left: -160rem;
      }
    } //}
    // any element following a manchete on the
    // bottom, should have a minimum padding
    // amount so the content is visible.
    &+.keno-section .keno-content-wrapper {
      padding-top: 35vw;

      @media (min-width: @keno-desktop-width) {
        padding-top: 15rem;
      }
    }
  }
  /*&:before,
    &:after {
	    content: "";
	    width: 200%;
	    height: 200px;
	    position: absolute;
	    top: 200px;
	    left: 0;
	    margin-left: -50%;
	    background-color: inherit;
	    .rotate(8deg);
	    z-index: 0;
    }
    &:before {
	    .rotate(8deg);
    }
    &:after {
	    .rotate(-8deg);
    }*/
  >div {
    position: relative;
  }
}

.mobile-only {
  display: inherit;

  @media (min-width: @keno-desktop-width) {
    display: none;
  }
}

.mobile-hide {
  display: none;

  @media (min-width: @keno-desktop-width) {
    display: inherit;
  }
}

.box-wide {
  width: 100%;

  @media (min-width: @keno-desktop-width) {
    width: 96rem;
    margin: auto;
  }
}

.box-medium {
  width: 100%;

  @media (min-width: @keno-desktop-width) {
    width: 58rem;
    margin: auto;
  }
}

.box-half {
  width: 100%;

  @media (min-width: @keno-desktop-width) {
    width: 50%;
    margin: auto;
    float: left;
  }
}

// side-by-side wrapper is used when two or more
// ´keno-section´'s need to be side by side at
// particular screen/device sizes
.side-by-side {
  @media (min-width: @keno-desktop-width) {
    .keno-section {
      width: 50%;
      display: inline-block;

      &:first-child {
        text-align: right;
      }

      >.keno-content-wrapper {
        width: 100%;
        max-width: 48rem; // max width is 960
        display: inline-block;
      }
    }
  }
}
