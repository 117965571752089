.win-message-container {
  background-color: #00a5eb;
  padding: 4vw 14.13vw 4vw;
}
@media (min-width: 950px) {
  .win-message-container {
    padding: 3rem;
  }
}
.win-message-container .receive-winner-message-icon {
  width: 10vw;
  height: 10vw;
  margin: 0 auto 2.5vw;
}
@media (min-width: 950px) {
  .win-message-container .receive-winner-message-icon {
    width: 6.4rem;
    height: 6.4rem;
    margin: 0 auto 2rem;
  }
}
.win-message-container .notice-step {
  color: #FFF;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
}
.win-message-container .notice-step .headline {
  width: 100%;
  max-width: 86.5vw;
  font-size: 5.5vw;
  text-transform: uppercase;
  line-height: normal;
  margin: 0 auto;
}
@media (min-width: 950px) {
  .win-message-container .notice-step .headline {
    font-size: 3rem;
  }
}
.win-message-container .notice-step .subheadline {
  width: 100%;
  max-width: 60vw;
  font-size: 4.27vw;
  text-transform: uppercase;
  padding: 1vw 0;
  margin: 0 auto;
}
@media (min-width: 950px) {
  .win-message-container .notice-step .subheadline {
    max-width: 86.5vw;
    font-size: 2.2rem;
    line-height: normal;
    padding: 1rem 0 2.5rem;
  }
}
@media (min-width: 950px) {
  .win-message-container .notice-step .checkbox-container {
    display: flex;
  }
}
.win-message-container .notice-step .checkbox-container .keno-checkbox {
  display: flex;
  margin: 3.34vw 0;
}
@media (min-width: 950px) {
  .win-message-container .notice-step .checkbox-container .keno-checkbox {
    margin: 0rem 2rem;
  }
}
.win-message-container .notice-step .checkbox-container .keno-checkbox label {
  display: flex;
  align-items: center;
  position: relative;
}
.win-message-container .notice-step .checkbox-container .keno-checkbox label:not(.ios-skin) {
  position: relative;
  padding: 0 0 0 3.33vw;
}
@media (min-width: 950px) {
  .win-message-container .notice-step .checkbox-container .keno-checkbox label:not(.ios-skin) {
    padding: 0 0 0 2rem;
  }
}
.win-message-container .notice-step .checkbox-container .keno-checkbox label .media-text {
  width: auto;
  font-size: 4.27vw;
  font-weight: bold;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
}
@media (min-width: 950px) {
  .win-message-container .notice-step .checkbox-container .keno-checkbox label .media-text {
    font-size: 1.8rem;
  }
}
