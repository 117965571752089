.keno-system-game .keno-system-game-section .keno-content-wrapper .number-picker-footer {
  padding: 3.334vw 0 3.234vw;
}
@media (min-width: 950px) {
  .keno-system-game .keno-system-game-section .keno-content-wrapper .number-picker-footer {
    padding: 2rem 0 1.1rem;
  }
}
.keno-system-game .keno-system-game-section .keno-content-wrapper .rows-container {
  width: 75.235%;
  margin: auto;
  position: relative;
  padding-top: 12vw;
  margin-bottom: 5.3vw;
}
@media (min-width: 950px) {
  .keno-system-game .keno-system-game-section .keno-content-wrapper .rows-container {
    width: 58rem;
    padding-top: 4rem;
    margin-bottom: 4.5rem;
  }
}
.keno-system-game .keno-system-game-section .keno-content-wrapper .rows-container .row-container {
  border-color: #99dbf7;
  border-style: solid;
  border-width: .1rem 0 .1rem 0;
  padding-bottom: 2.4vw;
}
@media (min-width: 950px) {
  .keno-system-game .keno-system-game-section .keno-content-wrapper .rows-container .row-container {
    padding-bottom: .6rem;
  }
}
.keno-system-game .keno-system-game-section .keno-content-wrapper .rows-container .row-container .header-rules-text {
  font-size: 3.733vw;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  top: 4.46vw;
  left: 0;
  right: 0;
}
@media (min-width: 950px) {
  .keno-system-game .keno-system-game-section .keno-content-wrapper .rows-container .row-container .header-rules-text {
    font-size: 2rem;
    top: 0;
  }
}
.keno-system-game .keno-system-game-section .keno-content-wrapper .rows-container .row-container .counter-container {
  position: absolute;
}
.keno-system-game .keno-system-game-section .keno-content-wrapper .rows-container .row-container .counter-container .counter-box {
  font-size: 3.733vw;
  padding: .1vw 0 1vw;
  display: block;
  clear: both;
}
@media (min-width: 950px) {
  .keno-system-game .keno-system-game-section .keno-content-wrapper .rows-container .row-container .counter-container .counter-box {
    font-size: 1.4rem;
    padding: .1rem 0 .5rem;
  }
}
@media (min-width: 950px) {
  .keno-system-game .keno-system-game-section .keno-content-wrapper .rows-container .row-container .counter-container .counter-box .mobile-hide {
    display: inline;
  }
}
.keno-system-game .keno-system-game-section .keno-content-wrapper .rows-container .row-container .counter-container .counter-box .mobile-only {
  display: inline;
}
@media (min-width: 950px) {
  .keno-system-game .keno-system-game-section .keno-content-wrapper .rows-container .row-container .counter-container .counter-box .mobile-only {
    display: none;
  }
}
@-webkit-keyframes keno-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-moz-keyframes keno-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-ms-keyframes keno-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@keyframes keno-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-webkit-keyframes keno-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes keno-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes keno-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes keno-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.keno-system-game .keno-system-game-section .keno-content-wrapper .rows-container .row-container.shake-state .selected {
  -webkit-animation: keno-number-cell-shake-animation 0.8s forwards;
  -moz-animation: keno-number-cell-shake-animation 0.8s forwards;
  -ms-animation: keno-number-cell-shake-animation 0.8s forwards;
  animation: keno-number-cell-shake-animation 0.8s forwards;
}
.keno-system-game .keno-system-game-section .keno-content-wrapper .rows-container .row-container.shake-state .header-rules-text {
  -webkit-animation: keno-rules-pulse-animation 0.8s forwards;
  -moz-animation: keno-rules-pulse-animation 0.8s forwards;
  -ms-animation: keno-rules-pulse-animation 0.8s forwards;
  animation: keno-rules-pulse-animation 0.8s forwards;
}
