.icon-circle-container {
  width: 13.5vw;
  height: 13.5vw;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 auto 6.5vw;
}
@media (min-width: 950px) {
  .icon-circle-container {
    width: 5rem;
    height: 5rem;
    margin: 0 auto 3rem;
  }
}
.icon-circle-container .icon {
  position: relative;
}
.icon-circle-container .icon svg {
  position: absolute;
  fill: #0891d8;
}
.icon-circle-container .icon svg.svg-book {
  width: 6.667vw;
  height: 6.667vw;
  top: 5.5vw;
  left: -3.3vw;
}
@media (min-width: 950px) {
  .icon-circle-container .icon svg.svg-book {
    width: 2.5rem;
    height: 2.5rem;
    top: 2.3rem;
    left: -1.2rem;
  }
}
.icon-circle-container .icon svg.svg-moneybag {
  top: 1.4rem;
  left: -1.3rem;
  width: 2.7rem;
  height: 4.4rem;
}
@media (min-width: 950px) {
  .icon-circle-container .icon svg.svg-moneybag {
    top: 1.4rem;
    left: -1.3rem;
    width: 2.7rem;
    height: 4.4rem;
  }
}
.arrow-icon {
  width: 3.467vw;
  height: 2.135vw;
  position: absolute;
  right: 4.5vw;
  -webkit-transition: opacity 100ms linear;
  -moz-transition: opacity 100ms linear;
  -ms-transition: opacity 100ms linear;
  -o-transition: opacity 100ms linear;
  transition: opacity 100ms linear;
}
.arrow-icon.hide {
  opacity: 0;
}
.arrow-icon svg {
  width: 3.467vw;
  height: 2.135vw;
}
@media (min-width: 950px) {
  .arrow-icon {
    width: 1.2rem;
    height: .7rem;
    top: 0;
    right: 2rem;
  }
  .arrow-icon svg {
    width: 1.2rem;
    height: .7rem;
  }
}
.arrow-icon.down svg {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.icon {
  display: inline-block;
  vertical-align: top;
}
.icon.right {
  margin-left: 0.8rem;
}
.icon.left {
  margin-right: 0.8rem;
}
.icon.float-right {
  float: right;
}
.icon.float-left {
  float: left;
}
.icon .update-rows__icon {
  width: 4vw;
  height: 4.7vw;
  fill: #4f5254 ;
  position: relative;
  top: 0.2rem;
}
@media (min-width: 950px) {
  .icon .update-rows__icon {
    width: 1.6rem;
    height: 1.8rem;
  }
}
.icon .downarrow__icon {
  width: 4vw;
  height: 4.7vw;
  fill: #4f5254 ;
  position: relative;
  top: 0.2rem;
}
@media (min-width: 950px) {
  .icon .downarrow__icon {
    width: 1.6rem;
    height: 1.8rem;
  }
}
.icon .downarrow__icon.up {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.icon .svg-icon-bell {
  width: 22.6vw;
  height: 20.5vw;
  fill: #1E55A0 ;
  position: relative;
  top: -0.9vw;
  left: -2.8vw;
}
@media (min-width: 950px) {
  .icon .svg-icon-bell {
    width: 12.6rem;
    height: 7.8rem;
    top: -0.3rem;
    left: -3.05rem;
  }
}
.icon .svg-icon-checkmark {
  width: 17.6vw;
  height: 15.5vw;
  fill: #0891d8;
  position: relative;
  top: 1.4vw;
  left: 0.2vw;
}
@media (min-width: 950px) {
  .icon .svg-icon-checkmark {
    width: 10.6rem;
    height: 5.8rem;
    top: 0.6rem;
    left: -2rem;
  }
}
.svg-icon-arrow-up {
  width: 3.467vw;
  height: 2.135vw;
  position: relative;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -ms-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.svg-icon-arrow-up.hide {
  opacity: 0;
}
.svg-icon-arrow-up svg {
  width: 3.467vw;
  height: 2.135vw;
}
@media (min-width: 950px) {
  .svg-icon-arrow-up {
    width: 1.2rem;
    height: .7rem;
    top: -0.1rem;
    right: 0;
    margin-left: 1rem;
  }
  .svg-icon-arrow-up svg {
    width: 1.2rem;
    height: .7rem;
  }
}
svg.checkmark-in-circle {
  width: 13.5vw;
  height: 13.5vw;
  margin: 0 auto;
}
@media (min-width: 950px) {
  svg.checkmark-in-circle {
    width: 5rem;
    height: 5rem;
  }
}
svg.keno-logo {
  width: 33vw;
  height: 7vw;
  margin: 0 auto;
}
@media (min-width: 950px) {
  svg.keno-logo {
    width: 13rem;
    height: 3.2rem;
    margin: 0 auto 1rem;
  }
}
svg.i-in-circle {
  width: 13.5vw;
  height: 13.5vw;
  margin: 0 auto;
}
@media (min-width: 950px) {
  svg.i-in-circle {
    width: 5rem;
    height: 5rem;
  }
}
svg.news-icon-circle,
svg.news-icon-circle-blue {
  width: 13.5vw;
  height: 13.5vw;
  margin: 0 auto;
}
@media (min-width: 950px) {
  svg.news-icon-circle,
  svg.news-icon-circle-blue {
    width: 5rem;
    height: 5rem;
  }
}
svg.keno-classic-logo {
  width: 40vw;
  height: 5vw;
}
@media (min-width: 950px) {
  svg.keno-classic-logo {
    width: 17.5rem;
    height: 2rem;
  }
}
svg.system-keno {
  width: 40vw;
  height: 5vw;
}
@media (min-width: 950px) {
  svg.system-keno {
    width: 17.5rem;
    height: 2rem;
  }
}
svg.vaelgselv-keno {
  width: 40vw;
  height: 5vw;
}
@media (min-width: 950px) {
  svg.vaelgselv-keno {
    width: 17.5rem;
    height: 2rem;
  }
}
