﻿@import url("../Mixins/_mixins.less");

.region-keno {

  .news-view .section {

    .content-wrapper {
      padding: 8vw 0;

      @media (min-width: @keno-desktop-width) {
        padding: 5rem 0;
        min-height: 30rem;
        box-sizing: border-box;
      }

      .content {
        max-width: 86.7vw;
        margin: auto;

        @media (min-width: @keno-desktop-width) {
          max-width: 58rem;
        }

        h1 {
          margin-bottom: 4vw;
          font-size: 5.4vw;
          line-height: 6.7vw;

          @media (min-width: @keno-desktop-width) {
            margin-bottom: 1.5rem;
            font-size: 2.5rem;
            line-height: 4rem;
          }
        }

        hr {
          border: none;
          background-color: @keno-mediumblue;
          color: @keno-mediumblue;
          height: 0.1rem;
        }

        .btn {
          min-width: 16rem;
        }
      }
    }

    .date {
      color: @keno-light-grey;
      font-size: 4vw;
      line-height: normal;
      margin-bottom: 2vw;
      display: block;

      @media (min-width: @keno-desktop-width) {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 1rem;
      }
    }

    p {
      font-size: 4vw;
      line-height: 5.34vw;
      margin: auto;

      @media (min-width: @keno-desktop-width) {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    .large {
      @media (min-width: @keno-desktop-width) {
        font-size: 2.5rem;
      }
    }

    .svg-social-share {
      width: 4rem;
      height: 4rem;
      margin: 2.0rem 1.2rem;
    }
  }
}
