/* ===========================================
   utility.loading-spinner
   ========================================= */
.loading-spinner {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark {
  position: relative;
  width: 4rem;
  height: 4rem;
  background: transparent;
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  animation: loading-spinner-animation 750ms infinite linear;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9IndoaXRlIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJ3aGl0ZSIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
}
.loading-spinner--dark:after {
  content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDAiIGhlaWdodD0iNDAiIHZpZXdCb3g9IjAgMCA0MCA0MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSIyMCIgY3k9IjQuMzc1IiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48Y2lyY2xlIGN4PSIyNy44MTQ5IiBjeT0iNi40ODM4OSIgcj0iMy4xMjUiIGZpbGw9ImJsYWNrIi8+PGNpcmNsZSBjeD0iMzMuNTI4NiIgY3k9IjEyLjIxODIiIHI9IjMuMTI1IiBmaWxsPSJibGFjayIvPjxjaXJjbGUgY3g9IjM1LjYyNSIgY3k9IjIwIiByPSIzLjEyNSIgZmlsbD0iYmxhY2siLz48L3N2Zz4=);
}
@keyframes loading-spinner-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.date-picker-wrapper {
  position: relative;
  user-select: none;
  margin: 0 auto;
  width: 100%;
  color: #000000;
  z-index: 5;
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px) {
  .date-picker-wrapper {
    width: 42.6rem;
  }
}
.date-picker-wrapper .date-picker-placeholder-wrapper {
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  padding: 3vw 13vw 3vw 4vw;
  display: block;
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px) {
  .date-picker-wrapper .date-picker-placeholder-wrapper {
    padding: 1rem 4rem 1rem 1.5rem;
    cursor: pointer;
  }
}
.date-picker-wrapper .date-picker-placeholder-wrapper .date-title {
  font-size: 4.3vw;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  text-align: left;
  color: #4f5254;
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px) {
  .date-picker-wrapper .date-picker-placeholder-wrapper .date-title {
    font-size: 1.6rem;
  }
}
.date-picker-wrapper .date-picker-placeholder-wrapper .icon-arrow {
  width: 5.333vw;
  height: 3.2vw;
  fill: #4f5254;
  transition: transform .15s linear;
  position: absolute;
  top: 4.5vw;
  right: 4vw;
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px) {
  .date-picker-wrapper .date-picker-placeholder-wrapper .icon-arrow {
    width: 1.6rem;
    height: 1.2rem;
    top: 1.5rem;
    right: 1.5rem;
  }
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper {
  width: 100%;
  max-height: 0;
  border-radius: 8px;
  margin-top: 0;
  padding: 0 6vw 4vw;
  position: absolute;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.15s linear, margin-top 0.15s linear, opacity 0.1s linear;
  pointer-events: none;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px) {
  .date-picker-wrapper .date-picker-calendar-outer-wrapper {
    padding: .5rem 2.2rem 3rem;
    box-sizing: border-box;
  }
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-header {
  position: relative;
  overflow: hidden;
  font-size: 1.3rem;
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-header .navigate-arrow {
  padding: 3vw;
  position: absolute;
  top: 0;
  transition: opacity .15s linear, transform .15s linear;
  cursor: pointer;
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px) {
  .date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-header .navigate-arrow {
    padding: 1rem;
    top: .5rem;
  }
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-header .navigate-arrow .icon {
  width: 5.333vw;
  height: 3.2vw;
  fill: #4f5254;
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px) {
  .date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-header .navigate-arrow .icon {
    width: 1.6rem;
    height: 1.2rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px) {
  .date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-header .navigate-arrow:not(.disabled):hover {
    transform: scale(1.15);
  }
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-header .navigate-arrow.next {
  right: 0;
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-header .navigate-arrow.next .icon {
  transform: rotate(-90deg);
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-header .navigate-arrow.prev {
  left: 0;
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-header .navigate-arrow.prev .icon {
  transform: rotate(90deg);
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-header .navigate-arrow.disabled {
  opacity: .2;
  cursor: default;
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-header .date-title {
  font-size: 4.3vw;
  font-weight: bold;
  text-align: center;
  padding: 3vw 3vw;
  display: block;
  text-transform: uppercase;
  color: #4f5254;
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px) {
  .date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-header .date-title {
    font-size: 1.4rem;
    padding: 1.3rem 3rem;
  }
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper {
  border-width: 1px 0 1px 0;
  border-style: solid;
  padding: 3vw 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  user-select: none;
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px) {
  .date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper {
    padding: 1.5rem 1rem;
  }
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper {
  width: 9.4vw;
  height: 6.4vw;
  font-size: 3.5vw;
  font-weight: bold;
  text-align: center;
  margin: .1vw .1vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  cursor: pointer;
  transition: color .1s linear;
  border-radius: 3px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px) {
  .date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper {
    width: 2.7rem;
    height: 2.1rem;
    font-size: 1.2rem;
    margin: .3rem .8rem;
  }
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper:after {
  content: '';
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: scale(0.6);
  transition: opacity .1s linear, transform .15s linear;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
@media screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px) {
  .date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper:hover:after {
    opacity: 1;
    transform: scale(1);
  }
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.header {
  text-transform: uppercase;
  cursor: default;
  color: #000000;
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.header:after {
  content: none;
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.selected {
  cursor: default;
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.selected:hover > .date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.selected:after,
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.selected:after {
  opacity: 1;
  border-radius: 3px;
  transform: scale(1);
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.inactive {
  cursor: default;
  border: none;
  color: #000000;
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.inactive .day-wrapper {
  opacity: .8;
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.inactive:after {
  content: none;
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.current-month {
  z-index: 1;
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.previous-month {
  color: #4f5254;
  font-weight: 600;
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.previous-month.first-day-of-the-month:before {
  background: linear-gradient(to right, rgba(38, 32, 70, 0) 0%, rgba(0, 0, 0, 0.07) 20%, rgba(0, 0, 0, 0.07) 100%);
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.previous-month.last-day-of-the-month:before {
  border-radius: 0 5px 5px 0;
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.next-month {
  color: #4f5254;
  font-weight: 600;
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.next-month.first-day-of-the-month:before {
  border-radius: 5px 0 0 5px;
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.next-month.last-day-of-the-month:before {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.07) 0%, rgba(0, 0, 0, 0.07) 80%, rgba(38, 32, 70, 0) 100%);
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .weeknr.header {
  color: #4f5254;
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .weeknr .day-wrapper {
  color: #4f5254;
  font-weight: 600;
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .weeknr:before {
  content: '';
  position: absolute;
  background-color: rgba(0, 0, 0, 0.05);
  top: -1vw;
  right: -0.6vw;
  bottom: -1vw;
  left: -0.6vw;
  z-index: -2;
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px) {
  .date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .weeknr:before {
    top: -0.3rem;
    right: -0.9rem;
    bottom: -0.3rem;
    left: -0.8rem;
  }
}
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .weeknr,
.date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.header:first-child {
  display: none;
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px) {
  .date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .weeknr,
  .date-picker-wrapper .date-picker-calendar-outer-wrapper .date-picker-calendar-wrapper .calendar-cell-wrapper.header:first-child {
    display: flex;
  }
}
.date-picker-wrapper.opened .date-picker-calendar-outer-wrapper {
  max-height: 100vh;
  margin-top: 3vw;
  pointer-events: auto;
  opacity: 1;
  transition: max-height 0.15s linear, margin-top 0.15s 0.05s linear, opacity 0.1s 0.05s linear;
}
@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape), screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait), screen and (min-width: 1024px) and (max-width: 1408px), screen and (min-width: 1408px) {
  .date-picker-wrapper.opened .date-picker-calendar-outer-wrapper {
    max-height: 30rem;
    margin-top: 1rem;
    box-sizing: border-box;
  }
}
