﻿@import url("../../Mixins/_mixins.less");

// Warning:
// This style is shared for Vikinglotto, Lotto, Keno And All or nothing.
// Individual styling is in the same folder.

.date-picker-wrapper {
  position: relative;
  user-select: none;
  margin: 0 auto;
  width: 100%;
  color: @numbergames-black;
  z-index: 5;

  @media @media-querie-for-tablet-landscape, @media-querie-for-tablet-portrait, @media-querie-for-desktop, @media-querie-for-large-desktop {
    width: 42.6rem;
  }


  .date-picker-placeholder-wrapper {
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    padding: 3vw 13vw 3vw 4vw;
    display: block;
    position: relative;

    @media @media-querie-for-tablet-landscape, @media-querie-for-tablet-portrait, @media-querie-for-desktop, @media-querie-for-large-desktop {
      padding: 1rem 4rem 1rem 1.5rem;
      cursor: pointer;
    }

    .date-title {
      font-size: 4.3vw;
      font-weight: bold;
      text-transform: uppercase;
      display: block;
      text-align: left;
      color: @numbergames-mediumgray;

      @media @media-querie-for-tablet-landscape, @media-querie-for-tablet-portrait, @media-querie-for-desktop, @media-querie-for-large-desktop {
        font-size: 1.6rem;
      }
    }

    .icon-arrow {
      width: 5.333vw;
      height: 3.2vw;
      fill: @numbergames-mediumgray;
      transition: transform .15s linear;
      position: absolute;
      top: 4.5vw;
      right: 4vw;

      @media @media-querie-for-tablet-landscape, @media-querie-for-tablet-portrait, @media-querie-for-desktop, @media-querie-for-large-desktop {
        width: 1.6rem;
        height: 1.2rem;
        top: 1.5rem;
        right: 1.5rem;
      }
    }
  }

  .date-picker-calendar-outer-wrapper {
    width: 100%;
    max-height: 0;
    border-radius: 8px;
    margin-top: 0;
    padding: 0 6vw 4vw;
    position: absolute;
    overflow: hidden;
    opacity: 0;
    transition: max-height .15s linear, margin-top .15s linear, opacity .1s linear;
    pointer-events: none;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;

    @media @media-querie-for-tablet-landscape, @media-querie-for-tablet-portrait, @media-querie-for-desktop, @media-querie-for-large-desktop {
      padding: .5rem 2.2rem 3rem;
      box-sizing: border-box;
    }

    .date-picker-header {
      position: relative;
      overflow: hidden;
      font-size: 1.3rem;

      .navigate-arrow {
        padding: 3vw;
        position: absolute;
        top: 0;
        transition: opacity .15s linear, transform .15s linear;
        cursor: pointer;

        @media @media-querie-for-tablet-landscape, @media-querie-for-tablet-portrait, @media-querie-for-desktop, @media-querie-for-large-desktop {
          padding: 1rem;
          top: .5rem;
        }

        .icon {
          width: 5.333vw;
          height: 3.2vw;
          fill: @numbergames-mediumgray;

          @media @media-querie-for-tablet-landscape, @media-querie-for-tablet-portrait, @media-querie-for-desktop, @media-querie-for-large-desktop {
            width: 1.6rem;
            height: 1.2rem;
          }
        }

        &:not(.disabled):hover {
          @media @media-querie-for-desktop, @media-querie-for-large-desktop {
            transform: scale(1.15);
          }
        }

        &.next {
          right: 0;

          .icon {
            transform: rotate(-90deg);
          }
        }

        &.prev {
          left: 0;

          .icon {
            transform: rotate(90deg);
          }
        }

        &.disabled {
          opacity: .2;
          cursor: default;
        }
      }

      .date-title {
        font-size: 4.3vw;
        font-weight: bold;
        text-align: center;
        padding: 3vw 3vw;
        display: block;
        text-transform: uppercase;
        color: @numbergames-mediumgray;

        @media @media-querie-for-tablet-landscape, @media-querie-for-tablet-portrait, @media-querie-for-desktop, @media-querie-for-large-desktop {
          font-size: 1.4rem;
          padding: 1.3rem 3rem;
        }
      }
    }

    .date-picker-calendar-wrapper {
      border-width: 1px 0 1px 0;
      border-style: solid;
      padding: 3vw 0;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      user-select: none;

      @media @media-querie-for-tablet-landscape, @media-querie-for-tablet-portrait, @media-querie-for-desktop, @media-querie-for-large-desktop {
        padding: 1.5rem 1rem;
      }

      .calendar-cell-wrapper {
        width: 9.4vw;
        height: 6.4vw;
        font-size: 3.5vw;
        font-weight: bold;
        text-align: center;
        margin: .1vw .1vw;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;
        cursor: pointer;
        transition: color .1s linear;
        border-radius: 3px;

        @media @media-querie-for-tablet-landscape, @media-querie-for-tablet-portrait, @media-querie-for-desktop, @media-querie-for-large-desktop {
          width: 2.7rem;
          height: 2.1rem;
          font-size: 1.2rem;
          margin: .3rem .8rem;
        }

        &:after {
          content: '';
          width: 100%;
          height: 100%;
          opacity: 0;
          transform: scale(.6);
          transition: opacity .1s linear, transform .15s linear;
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
        }

        &:hover {
          &:after {
            @media @media-querie-for-desktop, @media-querie-for-large-desktop {
              opacity: 1;
              transform: scale(1);
            }
          }
        }

        &.header {
          text-transform: uppercase;
          cursor: default;
          color: @numbergames-black;

          &:after {
            content: none;
          }
        }

        &.selected {
          cursor: default;

          &:hover > &:after, &:after {
            opacity: 1;
            border-radius: 3px;
            transform: scale(1);
          }
        }

        &.inactive {
          cursor: default;
          border: none;
          color: @numbergames-black;

          .day-wrapper {
            opacity: .8;
          }

          &:after {
            content: none;
          }
        }

        &.current-month {
          z-index: 1;
        }


        &.previous-month {
          color: @numbergames-mediumgray;
          font-weight: 600;

          &.first-day-of-the-month {
            &:before {
              background: linear-gradient(to right, rgba(38,32,70,0) 0%,rgba(0,0,0,0.07) 20%,rgba(0,0,0,0.07) 100%);
            }
          }

          &.last-day-of-the-month {
            &:before {
              border-radius: 0 5px 5px 0;
            }
          }
        }

        &.next-month {
          color: @numbergames-mediumgray;
          font-weight: 600;

          &.first-day-of-the-month {
            &:before {
              border-radius: 5px 0 0 5px;
            }
          }

          &.last-day-of-the-month {
            &:before {
              background: linear-gradient(to right,rgba(0,0,0,0.07) 0%,rgba(0,0,0,0.07) 80%,rgba(38,32,70,0) 100%);
            }
          }
        }
      }

      .weeknr {
        &.header {
          color: @numbergames-mediumgray;
        }

        .day-wrapper {
          color: @numbergames-mediumgray;
          font-weight: 600;
        }

        &:before {
          content: '';
          position: absolute;
          background-color: rgba(0, 0, 0, 0.05);
          top: -1vw;
          right: -0.6vw;
          bottom: -1vw;
          left: -0.6vw;
          z-index: -2;

          @media @media-querie-for-tablet-landscape, @media-querie-for-tablet-portrait, @media-querie-for-desktop, @media-querie-for-large-desktop {
            top: -.3rem;
            right: -.9rem;
            bottom: -.3rem;
            left: -.8rem;
          }
        }
      }

      .weeknr, .calendar-cell-wrapper.header:first-child {
        display: none;

        @media @media-querie-for-tablet-landscape, @media-querie-for-tablet-portrait, @media-querie-for-desktop, @media-querie-for-large-desktop {
          display: flex;
        }
      }
    }
  }

  &.opened {

    .date-picker-calendar-outer-wrapper {
      max-height: 100vh;
      margin-top: 3vw;
      pointer-events: auto;
      opacity: 1;
      transition: max-height .15s linear, margin-top .15s .05s linear, opacity .1s .05s linear;

      @media @media-querie-for-tablet-landscape, @media-querie-for-tablet-portrait, @media-querie-for-desktop, @media-querie-for-large-desktop {
        max-height: 30rem;
        margin-top: 1rem;
        box-sizing: border-box;
      }
    }
  }
}
