// bring in mixins  
@import (reference) "Mixins/_mixins.less"; // ┬──┬﻿ ¯\_(ツ)

.keno-system-game {
  .keno-system-game-section {
    .keno-content-wrapper {
      .number-picker-footer {
        padding:3.334vw 0 3.234vw;
        @media (min-width: @keno-desktop-width) {
          padding: 2rem 0 1.1rem;
        }
      }
      .rows-container {
        width: 75.235%;
        margin:auto;
        position:relative;
        padding-top:12vw;
        margin-bottom:5.3vw;
        @media (min-width: @keno-desktop-width) {
          width: 58rem;
          padding-top:4rem;
          margin-bottom:4.5rem;
        }

        .row-container {
          border-color:@keno-mediumblue;
          border-style:solid;
          border-width:.1rem 0 .1rem 0;
          padding-bottom: 2.4vw;
          @media (min-width: @keno-desktop-width) {
            padding-bottom:.6rem;
          }

          .header-rules-text {
            font-size:3.733vw;
            font-weight:bold;
            color:#fff;
            text-transform:uppercase;
            position:absolute;
            top:4.46vw;
            left:0;
            right:0;
            @media (min-width: @keno-desktop-width) {
              font-size:2rem;
              top:0;
            }
          }

          .counter-container {
            position:absolute;

            .counter-box {
              font-size:3.733vw;
              padding:.1vw 0 1vw;
              display:block;
              clear:both;
              @media (min-width: @keno-desktop-width) {
                font-size:1.4rem;
                padding:.1rem 0 .5rem;
              }
              .mobile-hide {
                @media (min-width: @keno-desktop-width) {
                  display:inline;
                }
              }
              .mobile-only {
                display:inline;
                @media (min-width: @keno-desktop-width) {
                  display:none;
                }
              }

            }
          }

          .keyframes(keno-number-cell-shake-animation; {
            0%   { .transform(scale(1) rotate(0)); }
            10%  { .transform(scale(1.13) rotate(5deg)); }
            20%  { .transform(scale(1.13) rotate(-5deg)); }
            30%  { .transform(scale(1.13) rotate(5deg)); }
            40%  { .transform(scale(1.13) rotate(-5deg)); }
            50%  { .transform(scale(1.13) rotate(5deg)); }
            60%  { .transform(scale(1.13) rotate(-5deg)); }
            70%  { .transform(scale(1.13) rotate(5deg)); }
            80%  { .transform(scale(1.13) rotate(-5deg)); }
            90%  { .transform(scale(1.13) rotate(5deg)); }
            100% { .transform(scale(1) rotate(0)); }
          });

          .keyframes(keno-rules-pulse-animation; {
            0%   { .transform(scale(1)); }
            25%  { .transform(scale(1.13)); }
            50%  { .transform(scale(1)); }
            75%  { .transform(scale(1.13)); }
            100% { .transform(scale(1)); }
          });

          &.shake-state {
            .selected {
              .animation(keno-number-cell-shake-animation .8s forwards);
            }

            .header-rules-text {
              .animation(keno-rules-pulse-animation .8s forwards);
            }
          }
        }
      }
    }
  }
}
