@import url("../Mixins/_mixins.less");

.keno-promotion {

  .keno-section {
    background-color: @keno-white;
    border-color: @keno-white;
    padding: 12vw 0;

    @media @md {
      padding: 5rem 0;
    }

    .money-bags {
      width: 40vw;
      height: 29vw;
      margin: 0 auto 8vw;
      position: relative;

      @media (min-width: @keno-desktop-width) {
        width: 15.6rem;
        height: 12rem;
        margin: 0 auto 1rem;
      }

      svg {
        position: absolute;
        width: 13vw;
        height: 20vw;

        @media (min-width: @keno-desktop-width) {
          width: 5.1rem;
          height: 8.3rem;
        }

        &.moneybag {
          fill: @keno-dodgerblue;

          &:nth-child(1) {
            left: 0;
            bottom: 0;
          }

          &:nth-child(2) {
            left: 13.5vw;
            top: 0;

            @media (min-width: @keno-desktop-width) {
              left: 5.3rem;
            }
          }

          &:nth-child(3) {
            right: 0;
            bottom: 0;
          }
        }

        &.shadow {
          fill: #dce0e3;

          &:nth-child(4) {
            left: 0;
            bottom: 0;
          }

          &:nth-child(5) {
            left: 13.5vw;
            bottom: 3vw;

            @media (min-width: @keno-desktop-width) {
              left: 5.3rem;
              bottom: 1rem;
            }
          }

          &:nth-child(6) {
            right: 0;
            bottom: 0;
          }
        }
      }
    }

    .promotion-headline {
      max-width: 86.5vw;
      font-size: 10.7vw;
      font-style: italic;
      font-weight: bold;
      line-height: 11.2vw;
      text-transform: uppercase;
      color: @keno-royalblue;
      margin: 0 auto 1vw;

      @media (min-width: @keno-desktop-width) {
        font-size: 4rem;
        line-height: 4rem;
        margin-bottom: 1rem;
      }
    }

    .promotion-subheadline {
      max-width: 86.5vw;
      font-size: 5.4vw;
      font-style: italic;
      font-weight: bold;
      line-height: 5.9vw;
      text-transform: uppercase;
      color: @keno-powderblue;
      margin: 0 auto;

      @media (min-width: @keno-desktop-width) {
        font-size: 2rem;
        line-height: 2.4rem;
      }
    }

    .btn.cta {
      margin: 4vw 0 0;

      @media (min-width: @keno-desktop-width) {
        margin: 2rem 0 0;
      }
    }
  }
}
