.keno-collapsible-section {
  position: relative;
  max-height: 28rem;
  overflow: hidden;
  transition: max-height 0.25s;
}
@media (min-width: 950px) {
  .keno-collapsible-section {
    max-height: 28rem;
  }
}
.keno-collapsible-section .inner-collapsible-section {
  padding-bottom: 16vw;
}
@media (min-width: 950px) {
  .keno-collapsible-section .inner-collapsible-section {
    padding-bottom: 9.8rem;
  }
}
.keno-collapsible-section .inner-collapsible-section .editor-content h2 {
  font-size: 4vw;
  margin: 0;
  font-weight: bold;
  color: #1e55a0;
}
@media (min-width: 950px) {
  .keno-collapsible-section .inner-collapsible-section .editor-content h2 {
    font-size: 2rem;
  }
}
.keno-collapsible-section .inner-collapsible-section .editor-content p {
  color: #4f5254;
}
@media (min-width: 950px) {
  .keno-collapsible-section .inner-collapsible-section .editor-content p {
    font-size: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
.keno-collapsible-section .mobile-message {
  font-size: 3vw;
  color: #4f5254;
  padding: 1vw 4vw 6vw;
  margin: 0;
}
.keno-collapsible-section .expand-button-container {
  width: 100%;
  height: 70%;
  position: absolute;
  bottom: 0;
  transition: transform .5s;
  background: linear-gradient(to bottom, rgba(235, 248, 253, 0) 0%, rgba(235, 248, 253, 0.9) 60%, #ebf8fd 100%);
}
.keno-collapsible-section .expand-button-container .button {
  font-size: 1.4rem;
  line-height: .8;
  font-weight: bold;
  padding: 1.4rem 1.3rem 1.5rem;
  border-radius: 6px;
  background-color: #fff;
  color: #4f5254;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: top .5s, transform .5s;
}
.keno-collapsible-section .expand-button-container .button .collapse-text {
  display: none;
}
.keno-collapsible-section .expand-button-container .button .icon {
  width: 1.4rem;
  height: .8rem;
  fill: #4f5254;
  margin-left: .7rem;
  position: relative;
  top: .2rem;
}
.keno-collapsible-section .svg-icon-arrow-up {
  margin-left: 2vw;
  transform: rotate(180deg);
}
@media (min-width: 950px) {
  .keno-collapsible-section .svg-icon-arrow-up {
    margin-left: 1rem;
  }
}
.keno-collapsible-section.active .expand-button-container {
  transform: translateY(100%);
}
.keno-collapsible-section.active .expand-button-container .button {
  top: 0;
  transform: translate(-50%, -125%);
}
@media (min-width: 950px) {
  .keno-collapsible-section.active .expand-button-container .button {
    transform: translate(-50%, -180%);
  }
}
.keno-collapsible-section.active .expand-button-container .button .icon {
  transform: rotate(180deg);
}
.keno-collapsible-section.active .expand-button-container .collapse-text {
  display: inline-block;
}
.keno-collapsible-section.active .expand-button-container .expand-text {
  display: none;
}
.keno-collapsible-section.active .svg-icon-arrow-up {
  transform: rotate(0deg);
}
