.keno-faq.keno-section .keno-section {
  padding-top: 8vw;
}
@media (min-width: 950px) {
  .keno-faq.keno-section .keno-section {
    padding-top: 6rem;
  }
  .keno-faq.keno-section .keno-section p {
    max-width: 58rem;
    margin: auto;
  }
}
.keno-faq.keno-section .keno-content-wrapper .richtext {
  border-bottom: 0.1rem solid #99dbf7;
}
.keno-faq.keno-section .keno-content-wrapper:last-child .richtext {
  border-bottom: none;
}
.keno-faq.keno-section .prizetables {
  margin-top: -0.1rem;
}
.keno-faq.keno-section .select-dropdown {
  max-width: 86.667vw;
  margin: auto;
  display: block;
}
@media (min-width: 950px) {
  .keno-faq.keno-section .select-dropdown {
    max-width: 58rem;
  }
}
.keno-faq.keno-section .panel {
  display: none;
}
.keno-faq.keno-section .panel.is-displayed {
  display: block;
}
body.mode-edit .keno-faq.keno-section .panel {
  display: block;
}
.keno-faq.keno-section .box-medium {
  width: 86.667vw;
  margin: auto;
  text-align: center;
}
@media (min-width: 950px) {
  .keno-faq.keno-section .box-medium {
    width: 58rem;
  }
}
.keno-faq.keno-section .richtext {
  color: #4f5254;
  padding: 7.333vw 0;
}
@media (min-width: 950px) {
  .keno-faq.keno-section .richtext {
    padding: 3rem 0;
  }
}
.keno-faq.keno-section .prizetables {
  text-align: center;
}
.keno-faq.keno-section .prizetables .stake-selector {
  padding-top: 6rem;
}
.keno-faq.keno-section p {
  font-size: 4vw;
  line-height: 1.3;
  padding-bottom: 5.333vw;
  margin: 0 auto;
  max-width: 86.667vw;
}
.keno-faq.keno-section p:last-of-type {
  padding-bottom: 0;
}
@media (min-width: 950px) {
  .keno-faq.keno-section p {
    font-size: 1.5rem;
    padding-bottom: 2rem;
  }
}
.keno-faq.keno-section h2.hd {
  font-size: 5.333vw;
  color: #1e55a0;
  padding-bottom: 2.666vw;
}
@media (min-width: 950px) {
  .keno-faq.keno-section h2.hd {
    font-size: 2.5rem;
    padding-bottom: 2rem;
  }
}
.keno-faq.keno-section .btn {
  margin-top: 4.5vw;
}
@media (min-width: 950px) {
  .keno-faq.keno-section .btn {
    margin-top: 2rem;
  }
}
