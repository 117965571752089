// bring in mixins
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

.keno-winnings-section {
  width:100%;
  padding:7.5vw 0;
  text-align:center;
  overflow:hidden;
  position:relative;
  @media (min-width: @keno-desktop-width) {
    padding:4.25rem 0;
  }

  .winnings-headline {
    font-size:5.333vw;
    font-weight:bold;
    font-style:italic;
    color: @keno-powderblue;
    text-transform: uppercase;
    margin:0;
    @media (min-width: @keno-desktop-width) {
      font-size:3rem;
    }
  }

  .winnings-subheadline {
    font-size:4vw;
    font-weight:bold;
    color:@keno-royalblue;
    text-transform:uppercase;
    margin:3.2vw 0;
    @media (min-width: @keno-desktop-width) {
      font-size:2rem;
      margin:2rem 0;
    }

    .notice {
      color: @keno-cta-yellow;
    }

    .a, a:visited {
      color: inherit;
    }
  }

  .winnings-notice {
    font-size:2.8vw;

    @media (min-width: @keno-desktop-width) {
      font-size:1.4rem;
      margin:2rem 0;
    }
  }

  .rows-numbers-wrapper {
    direction: rtl;
    position:relative;
    -webkit-touch-callout:none;
    .user-select(none);
    @media (min-width: @keno-desktop-width) {
      width: 74rem;
      margin:auto;
      overflow:hidden;
    }

    .rows-numbers-inner-container {
      .transitionWithTransform(transform .25s linear);
      position:relative;
    }

    .row-number-box {
      width: 12vw;
      height: 12vw;
      display:inline-block;
      position:relative;
      @media (min-width: @keno-desktop-width) {
        width:6rem;
        height:6rem;
        cursor:pointer;
      }

      &:before {
        content:'';
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
      }

      .row-number-text {
        font-size:5vw;
        font-weight:bold;
        font-style:italic;
        line-height:2.5;
        color:inherit;
        @media (min-width: @keno-desktop-width) {
          font-size:2.5rem;
        }
      }
    }

    .rows-numbers-blue, .rows-numbers-white {
      width:12vw;
      white-space:nowrap;
      position:absolute;
      top:0;
      right:50%;
      .transform(translateX(50%));
      @media (min-width: @keno-desktop-width) {
        width:6rem;
      }
    }

    .rows-numbers-blue {
      color: @keno-powderblue;
    }

    .rows-numbers-white {
      color:#fff;
      overflow:hidden;
    }

    .rows-numbers-background {
      width: 12vw;
      height: 12vw;
      background-color: @keno-powderblue;
      .border-radius(6px);
      position:relative;
      right:50%;
      .transform(translateX(50%));
      @media (min-width: @keno-desktop-width) {
        width:6rem;
        height:6rem;
      }
    }

    .next-number-button, .prev-number-button {
      width:14.5vw;
      position:absolute;
      top:0;
      bottom:0;
      .transitionWithTransform(transform .25s);
      @media (min-width: @keno-desktop-width) {
        width:8rem;
        overflow:hidden;
        cursor:pointer;
      }

      &:after {
        content:'';
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
      }

      &__icon {
        width:4.267vw;
        height:4.267vw;
        fill: @keno-powderblue;
        position:absolute;
        top:50%;
        transition:transform .25s, opacity .25s;
        @media (min-width: @keno-desktop-width) {
          width:1.8rem;
          height:1.8rem;
        }
      }

      &.active {
        transform:translate3d(0,0,0);
        .next-number-button__icon, .prev-number-button__icon {
          transform:translate3d(0,-50%,0);
          opacity:1;
        }
      }
    }
    .next-number-button {
      right:0;
      transform:translate3d(20%,0,0);
      background: -moz-linear-gradient(left,  rgba(235,248,253,0) 0%, rgba(235,248,253,0.4) 10%, rgba(235,248,253,0.65) 20%, rgba(235,248,253,0.8) 50%, rgba(235,248,253,1) 99%, rgba(235,248,253,1) 100%);
      background: -webkit-linear-gradient(left,  rgba(235,248,253,0) 0%,rgba(235,248,253,0.4) 10%,rgba(235,248,253,0.65) 20%,rgba(235,248,253,0.8) 50%,rgba(235,248,253,1) 99%,rgba(235,248,253,1) 100%);
      background: linear-gradient(to right,  rgba(235,248,253,0) 0%,rgba(235,248,253,0.4) 10%,rgba(235,248,253,0.65) 20%,rgba(235,248,253,0.8) 50%,rgba(235,248,253,1) 99%,rgba(235,248,253,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ebf8fd', endColorstr='#ebf8fd',GradientType=1 );

      &__icon {
        right:5.8vw;
        transform:translate3d(-20%,-50%,0);
        opacity:0;
        @media (min-width: @keno-desktop-width) {
          right:3rem;
        }
      }
    }
    .prev-number-button {
      left:0;
      transform:translate3d(-20%,0,0);
      background: -moz-linear-gradient(left,  rgba(235,248,253,1) 0%, rgba(235,248,253,1) 1%, rgba(235,248,253,0.8) 50%, rgba(235,248,253,0.65) 80%, rgba(235,248,253,0.4) 90%, rgba(235,248,253,0) 100%);
      background: -webkit-linear-gradient(left,  rgba(235,248,253,1) 0%,rgba(235,248,253,1) 1%,rgba(235,248,253,0.8) 50%,rgba(235,248,253,0.65) 80%,rgba(235,248,253,0.4) 90%,rgba(235,248,253,0) 100%);
      background: linear-gradient(to right,  rgba(235,248,253,1) 0%,rgba(235,248,253,1) 1%,rgba(235,248,253,0.8) 50%,rgba(235,248,253,0.65) 80%,rgba(235,248,253,0.4) 90%,rgba(235,248,253,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebf8fd', endColorstr='#00ebf8fd',GradientType=1 );

      &__icon {
        left:5.8vw;
        transform:translate3d(20%,-50%,0);
        opacity:0;
        @media (min-width: @keno-desktop-width) {
          left:3rem;
        }
      }
    }
  }

  .winnings-wrapper {
    width:100%;
    height:16vw;
    margin-top: -3vw;
    position:relative;
    overflow:hidden;
    -webkit-touch-callout:none;
    .user-select(none);
    @media (min-width: @keno-desktop-width) {
      height:9.9rem;
      margin-top: -2.5rem;
    }

    .winnings-inner-wrapper {
      width:100%;
      margin-bottom:-2.716vw;
      position:absolute;
      bottom:50%;
      .transitionWithTransform(transform .25s linear);
      @media (min-width: @keno-desktop-width) {
        margin-bottom:-1.65rem;
      }

      .winning-amount {
        font-size:5.333vw;
        line-height:5.433vw;
        font-weight:bold;
        font-style:italic;
        color:@keno-powderblue;
        .transitionWithTransform(transform .25s linear);
        @media (min-width: @keno-desktop-width) {
          font-size:2.5rem;
          line-height:3.3rem;
          cursor:default;
        }

        &:last-child {
          .transform(scale(1.25));
        }
      }
    }

    .winnings-top-gradient, .winnings-bottom-gradient {
      width:100%;
      height:5.733vw;
      position:absolute;
      left:0;
      @media (min-width: @keno-desktop-width) {
        height:3.3rem;
      }
    }

    .winnings-top-gradient {
      top:-0.3vw;
      background: -moz-linear-gradient(top,  rgba(235,248,253,1) 0%, rgba(235,248,253,0.99) 1%, rgba(235,248,253,0.6) 60%, rgba(235,248,253,0) 100%);
      background: -webkit-linear-gradient(top,  rgba(235,248,253,1) 0%,rgba(235,248,253,0.99) 1%,rgba(235,248,253,0.6) 60%,rgba(235,248,253,0) 100%);
      background: linear-gradient(to bottom,  rgba(235,248,253,1) 0%,rgba(235,248,253,0.99) 1%,rgba(235,248,253,0.6) 60%,rgba(235,248,253,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebf8fd', endColorstr='#00ebf8fd',GradientType=0 );
      @media (min-width: @keno-desktop-width) {
        top:-.3rem;
      }
    }

    .winnings-bottom-gradient {
      bottom:-0.3vw;
      background: -moz-linear-gradient(top,  rgba(235,248,253,0) 0%, rgba(235,248,253,0.6) 40%, rgba(235,248,253,0.99) 99%, rgba(235,248,253,1) 100%);
      background: -webkit-linear-gradient(top,  rgba(235,248,253,0) 0%,rgba(235,248,253,0.6) 40%,rgba(235,248,253,0.99) 99%,rgba(235,248,253,1) 100%);
      background: linear-gradient(to bottom,  rgba(235,248,253,0) 0%,rgba(235,248,253,0.6) 40%,rgba(235,248,253,0.99) 99%,rgba(235,248,253,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ebf8fd', endColorstr='#ebf8fd',GradientType=0 );
      @media (min-width: @keno-desktop-width) {
        bottom:-.3rem;
      }
    }
  }

  .winnings-level-overview {
    width:75.235%;
    font-size:0;
    margin:auto;
    padding-bottom:9.2vw;
    position:relative;
    .clearfix();

    @media (min-width: @keno-desktop-width) {
      width:58rem;
      padding-bottom:4.5rem;
    }

    .number {
      width:7.52vw;
      height:10.666vw;
      font-size:3.2vw;
      font-weight:bold;
      line-height:10.866vw;
      text-align:center;
      color:@keno-mediumblue;
      display:inline-block;
      position:relative;
      -webkit-touch-callout:none;
      .user-select(none);
      cursor:default;
      @media (min-width: @keno-desktop-width) {
        width:5.8rem;
        height:5.6rem;
        font-size:1.8rem;
        line-height:5.6rem;
      }

      &.selected {
        color:#fff;

        &:before {
          content:'';
          border-left: 1.2vw solid transparent;
          border-right: 1.2vw solid transparent;
          border-bottom: 1.333vw solid #ebf8fd;
          position:absolute;
          bottom:-.2vw;
          left:50%;
          .transform(translateX(-50%));

          @media (min-width: @keno-desktop-width) {
            border-left: .6rem solid transparent;
            border-right: .6rem solid transparent;
            border-bottom: .7rem solid #ebf8fd;
            bottom:0;
          }
        }
      }
    }

    .out-of-range-numbers {
      text-align:left;
      background-color:@keno-powderblue;
      position:relative;
      float:left;
      .border-radiuses(0, 0, 6px, 6px);

      &.full-out-of-range {
        border-radius:6px;
      }

    }
    .in-range-numbers {
      text-align:right;
      background-color:@keno-dodgerblue;
      position:relative;
      margin-left:-.2vw;
      float:left;
      .border-radiuses(6px, 6px, 0, 0);
      @media (min-width: @keno-desktop-width) {
        margin-left:0;
      }

      .info-text {
        font-size:3.2vw;
        font-weight:bold;
        line-height:.7;
        color:@keno-dodgerblue;
        text-transform:uppercase;
        position:absolute;
        left:50%;
        bottom:-5.7vw;
        transform:translateX(-50%);

        @media (min-width: @keno-desktop-width) {
          font-size:1.8rem;
          bottom:-3.2rem;
        }
      }
    }
  }
}
