﻿// bring in mixins
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

@cell-width--tablet: calc(~'16.666% - 16px');
@cell-height--tablet: 8vw;

.keno-classic-game,
.keno-system-game,
.keno-lucky-game {

  .statistics-component-for-game-client-wrapper {
    border-color: @keno-royalblue;
    background-color: @keno-royalblue;
    position: relative;
    z-index: 4;

    .statistics-component-outer-wrapper {

      .statistics-component-toggle-button-wrapper {
        .toggle-button {
          .numbers-count-wrapper {
            background-color: @keno-powderblue;
          }
        }
      }

      .statistics-component-inner-wrapper {
        box-sizing: content-box;

        .numbers-from-statistics-wrapper {
          .selected-number-box {
            background-color: @keno-white;

            .number-cell {
              width: 8vw;
              height: 8vw;
              font-size: 3vw;
              line-height: 1;
              margin: 1vw;
              padding-top: 2.7vw;
              color: @keno-white;
              background-color: #0591da;
              border-radius: 6px;
              font-weight: bold;
              box-sizing: border-box;

              @media (min-width: @keno-desktop-width) {
                width: 4rem;
                height: 4rem;
                font-size: 1.6rem;
                line-height: 1.5;
                margin: .5rem;
                padding-top: .9rem;
              }
            }

            &.selected {
              .number-cell {
                background-color: #21375f;
              }
            }
          }
        }
      }
    }
  }
}
