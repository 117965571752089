.keno-classic-game .statistics-component-for-game-client-wrapper,
.keno-system-game .statistics-component-for-game-client-wrapper,
.keno-lucky-game .statistics-component-for-game-client-wrapper {
  border-color: #1e55a0;
  background-color: #1e55a0;
  position: relative;
  z-index: 4;
}
.keno-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .numbers-count-wrapper,
.keno-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .numbers-count-wrapper,
.keno-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-toggle-button-wrapper .toggle-button .numbers-count-wrapper {
  background-color: #00a5eb;
}
.keno-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper,
.keno-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper,
.keno-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper {
  box-sizing: content-box;
}
.keno-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box,
.keno-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box,
.keno-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box {
  background-color: #fff;
}
.keno-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell,
.keno-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell,
.keno-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell {
  width: 8vw;
  height: 8vw;
  font-size: 3vw;
  line-height: 1;
  margin: 1vw;
  padding-top: 2.7vw;
  color: #fff;
  background-color: #0591da;
  border-radius: 6px;
  font-weight: bold;
  box-sizing: border-box;
}
@media (min-width: 950px) {
  .keno-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell,
  .keno-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell,
  .keno-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box .number-cell {
    width: 4rem;
    height: 4rem;
    font-size: 1.6rem;
    line-height: 1.5;
    margin: .5rem;
    padding-top: .9rem;
  }
}
.keno-classic-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.selected .number-cell,
.keno-system-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.selected .number-cell,
.keno-lucky-game .statistics-component-for-game-client-wrapper .statistics-component-outer-wrapper .statistics-component-inner-wrapper .numbers-from-statistics-wrapper .selected-number-box.selected .number-cell {
  background-color: #21375f;
}
