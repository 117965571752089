@media screen and (max-width: 768px) and (orientation: landscape) {
  .top-navigation {
    display: none;
  }
}
.orientation-screen {
  display: none;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: #00a5eb;
  z-index: 99;
  opacity: 0;
  pointer-events: none;
  filter: blur(1rem);
  transition: opacity 0.25s linear, filter .25s linear;
  transform: none !important;
}
.has-touch .orientation-screen {
  display: block;
}
@media screen and (max-width: 768px) and (orientation: landscape) and (min-aspect-ratio: 1/1) {
  .orientation-screen {
    opacity: 1;
    pointer-events: all;
    filter: blur(0rem);
    visibility: visible;
  }
  .overlay-enabled .orientation-screen {
    display: none;
  }
}
.orientation-screen-inner {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80%;
  width: auto;
  padding: 0 8vh;
}
.orientation-screen-message {
  color: #FFF;
  display: block;
  font-weight: bold;
  font-style: italic;
  font-size: 8vh;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
}
.orientation-screen-logo {
  width: 44vh;
  height: 12vh;
  margin: 6vh auto 6vh;
  background-image: url("/Components/DanskeSpil/Domain/Keno/Styles/Graphics/keno-white2.svg");
  background-size: 100% 100%;
}
.orientation-screen-icon {
  width: 45vh;
  height: 35vh;
  display: block;
  margin: 2vh auto 4vh auto;
  overflow: visible !important;
}
.orientation-screen-icon .phone {
  animation: orientation-phone-rotate 4s infinite;
  transform-origin: center;
  fill: #FFF;
}
.orientation-screen-icon .lines .line1,
.orientation-screen-icon .lines .line2 {
  transform-origin: center;
}
.orientation-screen-icon .lines .line1 {
  transform: rotate(45deg);
  animation: orientation-phone-line1 4s infinite;
}
.orientation-screen-icon .lines .line2 {
  transform: rotate(-45deg);
  animation: orientation-phone-line2 4s infinite;
}
.orientation-screen-icon .shadow {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #0891D8;
  animation: orientation-shadow 4s infinite;
  transform-origin: center bottom;
}
@keyframes orientation-phone-line1 {
  0% {
    transform: translate(0, 0%) rotate(45deg) scaleX(1);
  }
  10% {
    transform: translate(0, 0%) rotate(45deg) scaleX(1);
  }
  30% {
    transform: translate(5%, -55%) rotate(45deg) scaleX(0.7);
  }
  60% {
    transform: translate(5%, -55%) rotate(45deg) scaleX(0.7);
  }
  90% {
    transform: translate(0%, 0%) rotate(45deg) scaleX(1);
  }
  100% {
    transform: translate(0%, 0%) rotate(45deg) scaleX(1);
  }
}
@keyframes orientation-phone-line2 {
  0% {
    transform: rotate(-45deg) scaleX(1) translate(0%, 0%);
  }
  10% {
    transform: rotate(-45deg) scaleX(1) translate(0%, 0%);
  }
  30% {
    transform: rotate(-45deg) scaleX(0.5) translate(0%, 120%);
  }
  60% {
    transform: rotate(-45deg) scaleX(0.5) translate(0%, 120%);
  }
  90% {
    transform: rotate(-45deg) scaleX(1) translate(0%, 0%);
  }
  100% {
    transform: rotate(-45deg) scaleX(1) translate(0%, 0%);
  }
}
@keyframes orientation-phone-rotate {
  0% {
    transform: rotate(0deg) translateX(0%) scale(1);
  }
  10% {
    transform: rotate(0deg) translateX(0%) scale(1);
  }
  40% {
    transform: rotate(90deg) translateX(-5%) scale(0.8);
  }
  60% {
    transform: rotate(90deg) translateX(-5%) scale(0.8);
  }
  90% {
    transform: rotate(0deg) translateX(0%) scale(1);
  }
  100% {
    transform: rotate(0deg) translateX(0%) scale(1);
  }
}
@keyframes orientation-shadow {
  0% {
    transform: scale(1) translateY(0);
  }
  10% {
    transform: scale(1) translateY(0);
  }
  40% {
    transform: scale(0.5) translateY(100%);
  }
  60% {
    transform: scale(0.5) translateY(100%);
  }
  90% {
    transform: scale(1) translateY(0);
  }
  100% {
    transform: scale(1) translateY(0);
  }
}
