﻿// bring in mixins
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

.keno-collapsible-section {
  position: relative;
  max-height: 28rem;
  overflow: hidden;
  transition: max-height .25s;

  @media (min-width: @keno-desktop-width) {
    max-height: 28rem;
  }

  .inner-collapsible-section {
    padding-bottom: 16vw;

    @media (min-width: @keno-desktop-width) {
      padding-bottom: 9.8rem;
    }

    .editor-content {
      h2 {
        font-size: 4vw;
        margin: 0;
        font-weight: bold;
        color: @keno-royalblue;

        @media (min-width: @keno-desktop-width) {
          font-size: 2rem;
        }
      }

      p {
        color: @keno-darkgrey;

        @media (min-width: @keno-desktop-width) {
          font-size: 1.5rem;
          padding-bottom: 1.5rem;
        }
      }
    }
  }

  .mobile-message {
    font-size: 3vw;
    color: @keno-darkgrey;
    padding: 1vw 4vw 6vw;
    margin: 0;
  }

  .expand-button-container {
    width: 100%;
    height: 70%;
    position: absolute;
    bottom: 0;
    transition: transform .5s;
    background: linear-gradient(to bottom, rgba(235,248,253,0) 0%,rgba(235,248,253,0.9) 60%,rgba(235,248,253,1) 100%);

    .button {
      font-size: 1.4rem;
      line-height: .8;
      font-weight: bold;
      padding: 1.4rem 1.3rem 1.5rem;
      border-radius: 6px;
      background-color: #fff;
      color: @keno-darkgrey;
      text-transform: uppercase;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      transition: top .5s, transform .5s;

      .collapse-text {
        display: none;
      }

      .icon {
        width: 1.4rem;
        height: .8rem;
        fill: @keno-darkgrey;
        margin-left: .7rem;
        position: relative;
        top: .2rem;
      }
    }
  }

  .svg-icon-arrow-up {
    margin-left: 2vw;
    transform: rotate(180deg);

    @media (min-width: @keno-desktop-width) {
      margin-left: 1rem;
    }
  }

  &.active {
    .expand-button-container {
      transform: translateY(100%);

      .button {
        top: 0;
        transform: translate(-50%, -125%);

        @media (min-width: @keno-desktop-width) {
          transform: translate(-50%, -180%);
        }

        .icon {
          transform: rotate(180deg);
        }
      }

      .collapse-text {
        display: inline-block;
      }

      .expand-text {
        display: none;
      }
    }

    .svg-icon-arrow-up {
      transform: rotate(0deg);
    }
  }
}
