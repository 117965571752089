.region-keno {
  font-size: 1.4rem;
  background-color: #00a5eb;
  width: 100%;
}
.region-keno .container {
  width: 100vw;
}
@media (min-width: 950px) {
  .region-keno .container {
    width: 100%;
  }
}
.region-keno .page-box .container {
  width: 100%;
}
.keno-section {
  position: relative;
  font-style: normal;
  background-color: #00a5eb;
  border-color: #00a5eb;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.keno-section.bg-powderblue {
  background-color: #00a5eb;
  border-color: #00a5eb;
}
.keno-section.bg-lightskyblue {
  background-color: #e0f4fd;
  border-color: #e0f4fd;
}
.keno-section.bg-cadetblue {
  background-color: #b8e6f9;
  border-color: #b8e6f9;
}
.keno-section.bg-royalblue {
  background-color: #1e55a0;
  border-color: #1e55a0;
}
.keno-section.bg-dodgerblue {
  background-color: #0891d8;
  border-color: #0891d8;
}
.keno-section.bg-white {
  background-color: #fff;
  border-color: #fff;
}
.keno-section.bg-superlightblue {
  background-color: #ebf8fd;
  border-color: #ebf8fd;
}
.keno-section > .keno-content-wrapper {
  margin: auto;
  text-align: center;
}
.keno-section > .keno-content-wrapper .a {
  cursor: pointer;
  color: #fff;
  text-decoration: none;
}
.keno-section > .keno-content-wrapper .a.hd {
  text-decoration: none;
}
.keno-section > .keno-content-wrapper .p {
  width: 100%;
  max-width: 86.5vw;
  margin: auto;
  color: #ffffff;
  font-size: 4vw;
  line-height: normal;
}
@media (min-width: 950px) {
  .keno-section > .keno-content-wrapper .p {
    font-size: 1.5rem;
    width: 950px;
  }
}
.keno-section > .keno-content-wrapper .p.dark {
  color: #4f5254;
}
.keno-section > .keno-content-wrapper .p.dark .a {
  color: inherit;
  border-bottom: 0.1rem solid #4f5254;
}
.keno-section > .keno-content-wrapper .hr {
  width: 86.5vw;
  border-width: .1rem;
  border-style: solid;
  border-top: 0;
  margin: 6vw auto;
}
.keno-section > .keno-content-wrapper .hr.mediumblue {
  border-color: #99dbf7;
}
@media (min-width: 950px) {
  .keno-section > .keno-content-wrapper .hr {
    margin: 2.5rem auto;
    max-width: 96rem;
  }
}
.manchete {
  position: relative;
  overflow-x: hidden;
  /*&:before,
    &:after {
	    content: "";
	    width: 200%;
	    height: 200px;
	    position: absolute;
	    top: 200px;
	    left: 0;
	    margin-left: -50%;
	    background-color: inherit;
	    .rotate(8deg);
	    z-index: 0;
    }
    &:before {
	    .rotate(8deg);
    }
    &:after {
	    .rotate(-8deg);
    }*/
}
.manchete.bg-powderblue {
  background-color: #00a5eb;
  border-color: #00a5eb;
}
.manchete.bg-lightskyblue {
  background-color: #e0f4fd;
  border-color: #e0f4fd;
}
.manchete.bg-cadetblue {
  background-color: #b8e6f9;
  border-color: #b8e6f9;
}
.manchete.bg-royalblue {
  background-color: #1e55a0;
  border-color: #1e55a0;
}
.manchete.bg-dodgerblue {
  background-color: #0891d8;
  border-color: #0891d8;
}
.manchete.bg-white {
  background-color: #fff;
  border-color: #fff;
}
.manchete.bg-superlightblue {
  background-color: #ebf8fd;
  border-color: #ebf8fd;
}
.manchete.top {
  position: relative;
}
.manchete.top:before {
  position: absolute;
  content: ' ';
  display: block;
  top: -10em;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-left-width: 70em;
  border-right-width: 70em;
  border-top-width: 10em;
  border-color: inherit;
  border-top-color: transparent;
  margin-left: -70em;
  z-index: 1;
}
@media (min-width: 1264px) {
  .manchete.top:before {
    border-left-width: 96em;
    border-right-width: 96em;
    margin-left: -96em;
    top: -12em;
    border-top-width: 12em;
  }
}
@media (min-width: 1400px) {
  .manchete.top:before {
    border-left-width: 160em;
    border-right-width: 160em;
    margin-left: -160em;
    top: -15em;
    border-top-width: 15em;
  }
}
.manchete.bot {
  position: relative;
  background-clip: content-box;
}
.manchete.bot:after {
  position: absolute;
  content: ' ';
  display: block;
  bottom: -10.3rem;
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-left-width: 70rem;
  border-right-width: 70rem;
  border-top-width: 10rem;
  border-color: transparent;
  border-top-color: inherit;
  margin-left: -70rem;
  z-index: 1;
}
@media (min-width: 1264px) {
  .manchete.bot:after {
    border-left-width: 96rem;
    border-right-width: 96rem;
    margin-left: -96rem;
  }
}
@media (min-width: 1400px) {
  .manchete.bot:after {
    border-left-width: 160rem;
    border-right-width: 160rem;
    margin-left: -160rem;
  }
}
.manchete.bot + .keno-section .keno-content-wrapper {
  padding-top: 35vw;
}
@media (min-width: 950px) {
  .manchete.bot + .keno-section .keno-content-wrapper {
    padding-top: 15rem;
  }
}
.manchete > div {
  position: relative;
}
.mobile-only {
  display: inherit;
}
@media (min-width: 950px) {
  .mobile-only {
    display: none;
  }
}
.mobile-hide {
  display: none;
}
@media (min-width: 950px) {
  .mobile-hide {
    display: inherit;
  }
}
.box-wide {
  width: 100%;
}
@media (min-width: 950px) {
  .box-wide {
    width: 96rem;
    margin: auto;
  }
}
.box-medium {
  width: 100%;
}
@media (min-width: 950px) {
  .box-medium {
    width: 58rem;
    margin: auto;
  }
}
.box-half {
  width: 100%;
}
@media (min-width: 950px) {
  .box-half {
    width: 50%;
    margin: auto;
    float: left;
  }
}
@media (min-width: 950px) {
  .side-by-side .keno-section {
    width: 50%;
    display: inline-block;
  }
  .side-by-side .keno-section:first-child {
    text-align: right;
  }
  .side-by-side .keno-section > .keno-content-wrapper {
    width: 100%;
    max-width: 48rem;
    display: inline-block;
  }
}
