.keno-slider-selector .slider-container {
  width: 86.667%;
  margin: auto;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (min-width: 950px) {
  .keno-slider-selector .slider-container {
    width: 100%;
  }
}
.keno-slider-selector .slider-container .slider-bar {
  padding: 2.935vw 0vw;
  position: relative;
}
@media (min-width: 950px) {
  .keno-slider-selector .slider-container .slider-bar {
    padding: 1.6rem 0rem;
  }
}
.keno-slider-selector .slider-container .slider-bar:before {
  content: '';
  width: 100%;
  height: 1.333vw;
  background-color: #00a5eb;
  -webkit-border-radius: 0.6665vw;
  -moz-border-radius: 0.6665vw;
  border-radius: 0.6665vw;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  display: block;
  position: relative;
  left: 0vw;
  cursor: pointer;
}
.blue-select-bar .keno-slider-selector .slider-container .slider-bar:before {
  background-color: #00a5eb;
}
@media (min-width: 950px) {
  .keno-slider-selector .slider-container .slider-bar:before {
    width: 100%;
    height: .6rem;
    background-color: #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    left: 0rem;
  }
}
.keno-slider-selector .slider-container .slider-bar .selector {
  width: 7.2vw;
  height: 7.2vw;
  background-color: #ebb914;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  margin-top: -3.6vw;
  margin-left: 0vw;
  transform: translateX(-1.6vw);
  transition: transform 0.25s cubic-bezier(0.34, 1.61, 0.7, 1);
}
@media (min-width: 950px) {
  .keno-slider-selector .slider-container .slider-bar .selector {
    width: 3.8rem;
    height: 3.8rem;
    cursor: pointer;
    margin-top: -1.9rem;
    margin-left: 0;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
.keno-slider-selector .slider-container .slider-bar .selector:before {
  content: '';
  border-left: 1.2vw solid transparent;
  border-right: 1.2vw solid transparent;
  border-top: 1.333vw solid #2b2b2b;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  -o-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
@media (min-width: 950px) {
  .keno-slider-selector .slider-container .slider-bar .selector:before {
    border-left: .6rem solid transparent;
    border-right: .6rem solid transparent;
    border-top: .6rem solid #2b2b2b;
  }
}
.keno-slider-selector .slider-container .slider-bar .selector:after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: inherit;
  position: absolute;
  z-index: -1;
  left: 50%;
  top: 50%;
  transform: scale(1) translate3d(-50%, -50%, 0);
  transition: transform .1s linear;
}
.keno-slider-selector .slider-container .slider-bar .selector.pressed:after {
  transform: scale(2) translate3d(-25%, -28%, 0);
}
@media (min-width: 950px) {
  .keno-slider-selector .slider-container .slider-bar .selector.pressed:after {
    transform: scale(1.2) translate3d(-42%, -42%, 0);
  }
}
.keno-slider-selector .slider-container .numbers-container {
  width: 100%;
  position: relative;
  left: 0%;
  display: table;
  table-layout: fixed;
  margin-top: 2.266vw;
}
@media (min-width: 950px) {
  .keno-slider-selector .slider-container .numbers-container {
    width: 100%;
    margin-top: .9rem;
    left: 0;
  }
}
.keno-slider-selector .slider-container .numbers-container .number {
  font-size: 4vw;
  font-weight: bold;
  color: #99dbf7;
  line-height: .8;
  display: table-cell;
  -webkit-transition: color 0.25s;
  -moz-transition: color 0.25s;
  -ms-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
  position: relative;
}
@media (min-width: 950px) {
  .keno-slider-selector .slider-container .numbers-container .number {
    font-size: 1.8rem;
    color: #4dc0f1;
    cursor: pointer;
  }
}
.keno-slider-selector .slider-container .numbers-container .number.selected {
  color: #1e55a0;
}
.blue-select-bar .keno-slider-selector .slider-container .numbers-container .number.selected {
  color: #1e55a0;
}
@media (min-width: 950px) {
  .keno-slider-selector .slider-container .numbers-container .number.selected {
    color: #fff;
    cursor: default;
  }
}
