// bring in mixins
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

.blue-table {
  width:86.667vw;
  table-layout:fixed;
  border:.1rem solid @keno-powderblue;
  border-radius:6px;
  border-collapse:inherit;
  margin:0 6.666vw;

  @media (min-width: @keno-desktop-width) {
    width:100%;
    margin:0;

    &.mobile-hide {
      display:table;
    }
  }

  th {
    font-weight:bold;
    color:#fff;
    text-transform:uppercase;
  }

  td, th {
    &:first-child {
      border-left:none;
    }
  }

  thead {
    th {
      border-left:.1rem solid #0c85cd;
      background-color:@keno-powderblue;
      font-size:4vw;
      padding:4.4vw 0 4vw;

      @media (min-width: @keno-desktop-width) {
        font-size:1.8rem;
        padding:1.7rem 0 1.4rem;
      }
    }
    &.smaller-font-size {
      th {
        @media (min-width: @keno-desktop-width) {
          font-size:1.3rem;
        }
      }
    }
  }

  tbody {

    th {
      font-size:2.666vw;
      padding:2.666vw 0 2.666vw;
      background-color:@keno-dodgerblue;
      border-left:.1rem solid #0c85cd;
      &.empty {
        background-color:transparent;
      }
      @media (min-width: @keno-desktop-width) {
        font-size:1.3rem;
        padding:2rem 0 1.8rem;
      }
    }
    td {
      font-size:3.466vw;
      padding:3.866vw 0 3.666vw;
      color:@keno-darkgrey;
      background-color:transparent;
      border-left:.1rem solid @keno-powderblue;
      transition:color .25s, background-color .25s;
      @media (min-width: @keno-desktop-width) {
        font-size:1.5rem;
        padding:1.9rem 0 1.7rem;
      }
    }

    tr.selected {
      td {
        font-weight:bold;
        color:@keno-royalblue;
        background-color:#fff;
      }
    }

    tr.bottom-border {
      td {
        border-bottom:.1rem solid @keno-powderblue;
      }
    }
  }
}
