.keno-barometer-section {
  position: relative;
}
@media (min-width: 950px) {
  .keno-barometer-section {
    height: 1rem;
    margin-bottom: 20.8rem;
    -webkit-transition: height 0.25s;
    -moz-transition: height 0.25s;
    -ms-transition: height 0.25s;
    -o-transition: height 0.25s;
    transition: height 0.25s;
  }
}
.keno-barometer-section .keno-barometer-logo-box {
  padding: 6vw 0;
  text-align: center;
  line-height: 0;
  position: relative;
  z-index: 4;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 0.25s, opacity 0.25s;
  -moz-transition: -moz-transform 0.25s, opacity 0.25s;
  -ms-transition: -ms-transform 0.25s, opacity 0.25s;
  -o-transition: -o-transform 0.25s, opacity 0.25s;
  transition: transform 0.25s, opacity 0.25s;
}
@media (min-width: 950px) {
  .keno-barometer-section .keno-barometer-logo-box {
    padding: 3rem 0;
    top: 0;
  }
}
.keno-barometer-section .keno-barometer-logo-box .logo-image {
  height: 4vw;
  max-width: 90%;
}
@media (min-width: 950px) {
  .keno-barometer-section .keno-barometer-logo-box .logo-image {
    height: auto;
  }
}
.keno-barometer-section .game-heading {
  display: block;
  width: 100%;
  text-align: center;
  margin: auto;
  padding-top: 7vw;
  padding-bottom: 7vw;
  font-size: 5vw;
  font-weight: bold;
  color: #fff;
  line-height: .8;
  position: relative;
  z-index: 4;
  font-style: italic;
}
@media (min-width: 950px) {
  .keno-barometer-section .game-heading {
    padding-top: 3rem;
    padding-bottom: 2.5rem;
    width: 58rem;
    font-size: 2rem;
    color: #fff;
  }
}
.keno-barometer-section .keno-barometer {
  width: 100%;
  background-color: #fff;
  padding: 4.8vw 0 14.4vw;
  position: relative;
  z-index: 3;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 0.25s linear;
  -moz-transition: -moz-transform 0.25s linear;
  -ms-transition: -ms-transform 0.25s linear;
  -o-transition: -o-transform 0.25s linear;
  transition: transform 0.25s linear;
}
@media (min-width: 950px) {
  .keno-barometer-section .keno-barometer {
    width: 96rem;
    margin: auto;
    padding: 5.3rem 0 4.1rem;
    background-color: transparent;
    position: absolute;
    top: 2.5rem;
    left: 0;
    right: 0;
  }
  .keno-barometer-section .keno-barometer:before,
  .keno-barometer-section .keno-barometer:after {
    content: '';
    height: 20rem;
    width: 100vw;
    position: absolute;
    top: -1.7rem;
    z-index: -1;
    background-color: #0891d8;
    -webkit-transition: -webkit-transform 0.25s, top 0.25s;
    -moz-transition: -moz-transform 0.25s, top 0.25s;
    -ms-transition: -ms-transform 0.25s, top 0.25s;
    -o-transition: -o-transform 0.25s, top 0.25s;
    transition: transform 0.25s, top 0.25s;
  }
  .keno-barometer-section .keno-barometer:before {
    left: 50%;
    -webkit-transform: translateX(-100%) rotate(8deg);
    -moz-transform: translateX(-100%) rotate(8deg);
    -ms-transform: translateX(-100%) rotate(8deg);
    -o-transform: translateX(-100%) rotate(8deg);
    transform: translateX(-100%) rotate(8deg);
    -webkit-transform-origin: bottom right;
    -moz-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    -o-transform-origin: bottom right;
    transform-origin: bottom right;
  }
  .keno-barometer-section .keno-barometer:after {
    right: 50%;
    -webkit-transform: translateX(100%) rotate(-8deg);
    -moz-transform: translateX(100%) rotate(-8deg);
    -ms-transform: translateX(100%) rotate(-8deg);
    -o-transform: translateX(100%) rotate(-8deg);
    transform: translateX(100%) rotate(-8deg);
    -webkit-transform-origin: bottom left;
    -moz-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    -o-transform-origin: bottom left;
    transform-origin: bottom left;
  }
}
.keno-barometer-section .keno-barometer .inner {
  width: 86.667%;
  font-size: 2.4vw;
  font-weight: bold;
  color: #0891d8;
  text-transform: uppercase;
  margin: auto;
  position: relative;
}
@media (min-width: 950px) {
  .keno-barometer-section .keno-barometer .inner {
    width: 58rem;
    font-size: 1.5rem;
    color: #99dbf7;
    top: 0;
    -webkit-transition: top 0.25s;
    -moz-transition: top 0.25s;
    -ms-transition: top 0.25s;
    -o-transition: top 0.25s;
    transition: top 0.25s;
  }
}
.keno-barometer-section .keno-barometer .inner .bar-container {
  width: 100%;
  position: relative;
}
.keno-barometer-section .keno-barometer .inner .bar-container.white,
.keno-barometer-section .keno-barometer .inner .bar-container.yellow {
  height: 1rem;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
@media (min-width: 950px) {
  .keno-barometer-section .keno-barometer .inner .bar-container.white,
  .keno-barometer-section .keno-barometer .inner .bar-container.yellow {
    height: .6rem;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }
}
.keno-barometer-section .keno-barometer .inner .bar-container.white {
  width: 100%;
  background-color: #0891d8;
}
@media (min-width: 950px) {
  .keno-barometer-section .keno-barometer .inner .bar-container.white {
    background-color: #fff;
  }
}
.keno-barometer-section .keno-barometer .inner .bar-container.yellow {
  width: 20%;
  background-color: #ebb914;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: width 0.25s 0s;
  -moz-transition: width 0.25s 0s;
  -ms-transition: width 0.25s 0s;
  -o-transition: width 0.25s 0s;
  transition: width 0.25s 0s;
}
@media (min-width: 950px) {
  .keno-barometer-section .keno-barometer .inner .bar-container.yellow {
    -webkit-transition: width 0.25s;
    -moz-transition: width 0.25s;
    -ms-transition: width 0.25s;
    -o-transition: width 0.25s;
    transition: width 0.25s;
    transition-delay: 1.05s;
  }
}
.keno-barometer-section .keno-barometer .inner .left-text,
.keno-barometer-section .keno-barometer .inner .center-text,
.keno-barometer-section .keno-barometer .inner .right-text {
  position: absolute;
  top: 4.7vw;
}
@media (min-width: 950px) {
  .keno-barometer-section .keno-barometer .inner .left-text,
  .keno-barometer-section .keno-barometer .inner .center-text,
  .keno-barometer-section .keno-barometer .inner .right-text {
    top: 3.2rem;
  }
}
.keno-barometer-section .keno-barometer .inner .left-text {
  left: 0;
}
.keno-barometer-section .keno-barometer .inner .center-text {
  text-align: center;
  line-height: 1.2;
  left: 50%;
  -webkit-transform: translateY(0) translateX(-50%);
  -moz-transform: translateY(0) translateX(-50%);
  -ms-transform: translateY(0) translateX(-50%);
  -o-transform: translateY(0) translateX(-50%);
  transform: translateY(0) translateX(-50%);
}
.keno-barometer-section .keno-barometer .inner .center-text .total {
  font-size: 3.734vw;
  color: #1e55a0;
  position: relative;
  top: .8vw;
}
.keno-barometer-section .keno-barometer .inner .center-text .notice {
  color: #f5d900;
}
@media (min-width: 950px) {
  .keno-barometer-section .keno-barometer .inner .center-text {
    -webkit-transform: translateY(-19%) translateX(-50%);
    -moz-transform: translateY(-19%) translateX(-50%);
    -ms-transform: translateY(-19%) translateX(-50%);
    -o-transform: translateY(-19%) translateX(-50%);
    transform: translateY(-19%) translateX(-50%);
  }
  .keno-barometer-section .keno-barometer .inner .center-text .total {
    font-size: 2rem;
    color: #fff;
    top: auto;
  }
}
.keno-barometer-section .keno-barometer .inner .right-text {
  right: 0;
}
.keno-barometer-section .keno-barometer .left-bags-container,
.keno-barometer-section .keno-barometer .right-bags-container,
.keno-barometer-section .keno-barometer .money-coin {
  display: none;
}
@media (min-width: 950px) {
  .keno-barometer-section .keno-barometer .left-bags-container,
  .keno-barometer-section .keno-barometer .right-bags-container,
  .keno-barometer-section .keno-barometer .money-coin {
    display: block;
  }
  .keno-barometer-section .keno-barometer .left-bags-container,
  .keno-barometer-section .keno-barometer .right-bags-container {
    width: 12rem;
    -webkit-transition: -webkit-transform 0.25s;
    -moz-transition: -moz-transform 0.25s;
    -ms-transition: -ms-transform 0.25s;
    -o-transition: -o-transform 0.25s;
    transition: transform 0.25s;
  }
  .keno-barometer-section .keno-barometer .left-bags-container {
    position: absolute;
    -webkit-transform: scale(1) translateX(0);
    -moz-transform: scale(1) translateX(0);
    -ms-transform: scale(1) translateX(0);
    -o-transform: scale(1) translateX(0);
    transform: scale(1) translateX(0);
  }
  .keno-barometer-section .keno-barometer .left-bags-container .small-bag {
    width: 3.5rem;
    height: 5.8rem;
    position: absolute;
    overflow: visible;
  }
  .keno-barometer-section .keno-barometer .left-bags-container .small-bag .bag {
    fill: #fff;
  }
  .keno-barometer-section .keno-barometer .left-bags-container .small-bag .shadow {
    fill: #1e55a0;
  }
  .keno-barometer-section .keno-barometer .left-bags-container .small-bag.bag-one {
    top: -1.7rem;
    left: 6rem;
  }
  .keno-barometer-section .keno-barometer .left-bags-container .small-bag.bag-one .shadow {
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: translateY(4rem) scale(1);
    -moz-transform: translateY(4rem) scale(1);
    -ms-transform: translateY(4rem) scale(1);
    -o-transform: translateY(4rem) scale(1);
    transform: translateY(4rem) scale(1);
  }
  .keno-barometer-section .keno-barometer .left-bags-container .small-bag.bag-two {
    top: -4.1rem;
    left: 9.9rem;
  }
  .keno-barometer-section .keno-barometer .left-bags-container .small-bag.bag-two .shadow {
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: translateY(13rem) scaleX(0.9);
    -moz-transform: translateY(13rem) scaleX(0.9);
    -ms-transform: translateY(13rem) scaleX(0.9);
    -o-transform: translateY(13rem) scaleX(0.9);
    transform: translateY(13rem) scaleX(0.9);
  }
  .keno-barometer-section .keno-barometer .left-bags-container .small-bag.bag-three {
    top: -1.7rem;
    left: 13.4rem;
  }
  .keno-barometer-section .keno-barometer .left-bags-container .small-bag.bag-three .shadow {
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-transform: translateY(4rem) translateX(0) scale(1);
    -moz-transform: translateY(4rem) translateX(0) scale(1);
    -ms-transform: translateY(4rem) translateX(0) scale(1);
    -o-transform: translateY(4rem) translateX(0) scale(1);
    transform: translateY(4rem) translateX(0) scale(1);
  }
  .keno-barometer-section .keno-barometer .right-bags-container {
    position: absolute;
    right: 0;
    -webkit-transform: scale(1) translateX(0);
    -moz-transform: scale(1) translateX(0);
    -ms-transform: scale(1) translateX(0);
    -o-transform: scale(1) translateX(0);
    transform: scale(1) translateX(0);
  }
  .keno-barometer-section .keno-barometer .right-bags-container .big-bag {
    width: 7.5rem;
    height: 12rem;
    position: absolute;
    top: -6rem;
    right: 8.5rem;
    overflow: visible;
  }
  .keno-barometer-section .keno-barometer .right-bags-container .big-bag .bag {
    fill: #fff;
  }
  .keno-barometer-section .keno-barometer .right-bags-container .big-bag .shadow {
    fill: #1e55a0;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
    -webkit-transform: translateY(-2rem) translateX(0) scale(1);
    -moz-transform: translateY(-2rem) translateX(0) scale(1);
    -ms-transform: translateY(-2rem) translateX(0) scale(1);
    -o-transform: translateY(-2rem) translateX(0) scale(1);
    transform: translateY(-2rem) translateX(0) scale(1);
  }
  .keno-barometer-section .keno-barometer .money-coin {
    width: 2rem;
    height: 2rem;
    fill: #fff;
    position: absolute;
    top: 3rem;
    left: 11rem;
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
  }
}
@-webkit-keyframes increase-winnings-coin-one-animation {
  20% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  35% {
    -webkit-transform: translateX(5rem) translateY(-4rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-4rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-4rem) scale(1);
    -o-transform: translateX(5rem) translateY(-4rem) scale(1);
    transform: translateX(5rem) translateY(-4rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(10rem) translateY(2rem) scale(0);
    -moz-transform: translateX(10rem) translateY(2rem) scale(0);
    -ms-transform: translateX(10rem) translateY(2rem) scale(0);
    -o-transform: translateX(10rem) translateY(2rem) scale(0);
    transform: translateX(10rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  68% {
    -webkit-transform: translateX(60rem) translateY(2rem) scale(0);
    -moz-transform: translateX(60rem) translateY(2rem) scale(0);
    -ms-transform: translateX(60rem) translateY(2rem) scale(0);
    -o-transform: translateX(60rem) translateY(2rem) scale(0);
    transform: translateX(60rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  78% {
    -webkit-transform: translateX(70rem) translateY(-9rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-9rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-9rem) scale(1);
    -o-transform: translateX(70rem) translateY(-9rem) scale(1);
    transform: translateX(70rem) translateY(-9rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(72rem) translateY(-9rem) scale(1);
    -moz-transform: translateX(72rem) translateY(-9rem) scale(1);
    -ms-transform: translateX(72rem) translateY(-9rem) scale(1);
    -o-transform: translateX(72rem) translateY(-9rem) scale(1);
    transform: translateX(72rem) translateY(-9rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateX(72rem) translateY(-2rem) scale(0);
    -moz-transform: translateX(72rem) translateY(-2rem) scale(0);
    -ms-transform: translateX(72rem) translateY(-2rem) scale(0);
    -o-transform: translateX(72rem) translateY(-2rem) scale(0);
    transform: translateX(72rem) translateY(-2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-moz-keyframes increase-winnings-coin-one-animation {
  20% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  35% {
    -webkit-transform: translateX(5rem) translateY(-4rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-4rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-4rem) scale(1);
    -o-transform: translateX(5rem) translateY(-4rem) scale(1);
    transform: translateX(5rem) translateY(-4rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(10rem) translateY(2rem) scale(0);
    -moz-transform: translateX(10rem) translateY(2rem) scale(0);
    -ms-transform: translateX(10rem) translateY(2rem) scale(0);
    -o-transform: translateX(10rem) translateY(2rem) scale(0);
    transform: translateX(10rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  68% {
    -webkit-transform: translateX(60rem) translateY(2rem) scale(0);
    -moz-transform: translateX(60rem) translateY(2rem) scale(0);
    -ms-transform: translateX(60rem) translateY(2rem) scale(0);
    -o-transform: translateX(60rem) translateY(2rem) scale(0);
    transform: translateX(60rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  78% {
    -webkit-transform: translateX(70rem) translateY(-9rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-9rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-9rem) scale(1);
    -o-transform: translateX(70rem) translateY(-9rem) scale(1);
    transform: translateX(70rem) translateY(-9rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(72rem) translateY(-9rem) scale(1);
    -moz-transform: translateX(72rem) translateY(-9rem) scale(1);
    -ms-transform: translateX(72rem) translateY(-9rem) scale(1);
    -o-transform: translateX(72rem) translateY(-9rem) scale(1);
    transform: translateX(72rem) translateY(-9rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateX(72rem) translateY(-2rem) scale(0);
    -moz-transform: translateX(72rem) translateY(-2rem) scale(0);
    -ms-transform: translateX(72rem) translateY(-2rem) scale(0);
    -o-transform: translateX(72rem) translateY(-2rem) scale(0);
    transform: translateX(72rem) translateY(-2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-ms-keyframes increase-winnings-coin-one-animation {
  20% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  35% {
    -webkit-transform: translateX(5rem) translateY(-4rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-4rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-4rem) scale(1);
    -o-transform: translateX(5rem) translateY(-4rem) scale(1);
    transform: translateX(5rem) translateY(-4rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(10rem) translateY(2rem) scale(0);
    -moz-transform: translateX(10rem) translateY(2rem) scale(0);
    -ms-transform: translateX(10rem) translateY(2rem) scale(0);
    -o-transform: translateX(10rem) translateY(2rem) scale(0);
    transform: translateX(10rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  68% {
    -webkit-transform: translateX(60rem) translateY(2rem) scale(0);
    -moz-transform: translateX(60rem) translateY(2rem) scale(0);
    -ms-transform: translateX(60rem) translateY(2rem) scale(0);
    -o-transform: translateX(60rem) translateY(2rem) scale(0);
    transform: translateX(60rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  78% {
    -webkit-transform: translateX(70rem) translateY(-9rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-9rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-9rem) scale(1);
    -o-transform: translateX(70rem) translateY(-9rem) scale(1);
    transform: translateX(70rem) translateY(-9rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(72rem) translateY(-9rem) scale(1);
    -moz-transform: translateX(72rem) translateY(-9rem) scale(1);
    -ms-transform: translateX(72rem) translateY(-9rem) scale(1);
    -o-transform: translateX(72rem) translateY(-9rem) scale(1);
    transform: translateX(72rem) translateY(-9rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateX(72rem) translateY(-2rem) scale(0);
    -moz-transform: translateX(72rem) translateY(-2rem) scale(0);
    -ms-transform: translateX(72rem) translateY(-2rem) scale(0);
    -o-transform: translateX(72rem) translateY(-2rem) scale(0);
    transform: translateX(72rem) translateY(-2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@keyframes increase-winnings-coin-one-animation {
  20% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  35% {
    -webkit-transform: translateX(5rem) translateY(-4rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-4rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-4rem) scale(1);
    -o-transform: translateX(5rem) translateY(-4rem) scale(1);
    transform: translateX(5rem) translateY(-4rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(10rem) translateY(2rem) scale(0);
    -moz-transform: translateX(10rem) translateY(2rem) scale(0);
    -ms-transform: translateX(10rem) translateY(2rem) scale(0);
    -o-transform: translateX(10rem) translateY(2rem) scale(0);
    transform: translateX(10rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  68% {
    -webkit-transform: translateX(60rem) translateY(2rem) scale(0);
    -moz-transform: translateX(60rem) translateY(2rem) scale(0);
    -ms-transform: translateX(60rem) translateY(2rem) scale(0);
    -o-transform: translateX(60rem) translateY(2rem) scale(0);
    transform: translateX(60rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  78% {
    -webkit-transform: translateX(70rem) translateY(-9rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-9rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-9rem) scale(1);
    -o-transform: translateX(70rem) translateY(-9rem) scale(1);
    transform: translateX(70rem) translateY(-9rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(72rem) translateY(-9rem) scale(1);
    -moz-transform: translateX(72rem) translateY(-9rem) scale(1);
    -ms-transform: translateX(72rem) translateY(-9rem) scale(1);
    -o-transform: translateX(72rem) translateY(-9rem) scale(1);
    transform: translateX(72rem) translateY(-9rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateX(72rem) translateY(-2rem) scale(0);
    -moz-transform: translateX(72rem) translateY(-2rem) scale(0);
    -ms-transform: translateX(72rem) translateY(-2rem) scale(0);
    -o-transform: translateX(72rem) translateY(-2rem) scale(0);
    transform: translateX(72rem) translateY(-2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-webkit-keyframes increase-winnings-coin-two-animation {
  30% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  45% {
    -webkit-transform: translateX(5rem) translateY(-4rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-4rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-4rem) scale(1);
    -o-transform: translateX(5rem) translateY(-4rem) scale(1);
    transform: translateX(5rem) translateY(-4rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  60% {
    -webkit-transform: translateX(10rem) translateY(2rem) scale(0);
    -moz-transform: translateX(10rem) translateY(2rem) scale(0);
    -ms-transform: translateX(10rem) translateY(2rem) scale(0);
    -o-transform: translateX(10rem) translateY(2rem) scale(0);
    transform: translateX(10rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  73% {
    -webkit-transform: translateX(60rem) translateY(2rem) scale(0);
    -moz-transform: translateX(60rem) translateY(2rem) scale(0);
    -ms-transform: translateX(60rem) translateY(2rem) scale(0);
    -o-transform: translateX(60rem) translateY(2rem) scale(0);
    transform: translateX(60rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  83% {
    -webkit-transform: translateX(70rem) translateY(-7rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-7rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-7rem) scale(1);
    -o-transform: translateX(70rem) translateY(-7rem) scale(1);
    transform: translateX(70rem) translateY(-7rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  85% {
    -webkit-transform: translateX(72rem) translateY(-7rem) scale(1);
    -moz-transform: translateX(72rem) translateY(-7rem) scale(1);
    -ms-transform: translateX(72rem) translateY(-7rem) scale(1);
    -o-transform: translateX(72rem) translateY(-7rem) scale(1);
    transform: translateX(72rem) translateY(-7rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  95% {
    -webkit-transform: translateX(72rem) translateY(0rem) scale(0);
    -moz-transform: translateX(72rem) translateY(0rem) scale(0);
    -ms-transform: translateX(72rem) translateY(0rem) scale(0);
    -o-transform: translateX(72rem) translateY(0rem) scale(0);
    transform: translateX(72rem) translateY(0rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-moz-keyframes increase-winnings-coin-two-animation {
  30% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  45% {
    -webkit-transform: translateX(5rem) translateY(-4rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-4rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-4rem) scale(1);
    -o-transform: translateX(5rem) translateY(-4rem) scale(1);
    transform: translateX(5rem) translateY(-4rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  60% {
    -webkit-transform: translateX(10rem) translateY(2rem) scale(0);
    -moz-transform: translateX(10rem) translateY(2rem) scale(0);
    -ms-transform: translateX(10rem) translateY(2rem) scale(0);
    -o-transform: translateX(10rem) translateY(2rem) scale(0);
    transform: translateX(10rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  73% {
    -webkit-transform: translateX(60rem) translateY(2rem) scale(0);
    -moz-transform: translateX(60rem) translateY(2rem) scale(0);
    -ms-transform: translateX(60rem) translateY(2rem) scale(0);
    -o-transform: translateX(60rem) translateY(2rem) scale(0);
    transform: translateX(60rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  83% {
    -webkit-transform: translateX(70rem) translateY(-7rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-7rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-7rem) scale(1);
    -o-transform: translateX(70rem) translateY(-7rem) scale(1);
    transform: translateX(70rem) translateY(-7rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  85% {
    -webkit-transform: translateX(72rem) translateY(-7rem) scale(1);
    -moz-transform: translateX(72rem) translateY(-7rem) scale(1);
    -ms-transform: translateX(72rem) translateY(-7rem) scale(1);
    -o-transform: translateX(72rem) translateY(-7rem) scale(1);
    transform: translateX(72rem) translateY(-7rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  95% {
    -webkit-transform: translateX(72rem) translateY(0rem) scale(0);
    -moz-transform: translateX(72rem) translateY(0rem) scale(0);
    -ms-transform: translateX(72rem) translateY(0rem) scale(0);
    -o-transform: translateX(72rem) translateY(0rem) scale(0);
    transform: translateX(72rem) translateY(0rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-ms-keyframes increase-winnings-coin-two-animation {
  30% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  45% {
    -webkit-transform: translateX(5rem) translateY(-4rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-4rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-4rem) scale(1);
    -o-transform: translateX(5rem) translateY(-4rem) scale(1);
    transform: translateX(5rem) translateY(-4rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  60% {
    -webkit-transform: translateX(10rem) translateY(2rem) scale(0);
    -moz-transform: translateX(10rem) translateY(2rem) scale(0);
    -ms-transform: translateX(10rem) translateY(2rem) scale(0);
    -o-transform: translateX(10rem) translateY(2rem) scale(0);
    transform: translateX(10rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  73% {
    -webkit-transform: translateX(60rem) translateY(2rem) scale(0);
    -moz-transform: translateX(60rem) translateY(2rem) scale(0);
    -ms-transform: translateX(60rem) translateY(2rem) scale(0);
    -o-transform: translateX(60rem) translateY(2rem) scale(0);
    transform: translateX(60rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  83% {
    -webkit-transform: translateX(70rem) translateY(-7rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-7rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-7rem) scale(1);
    -o-transform: translateX(70rem) translateY(-7rem) scale(1);
    transform: translateX(70rem) translateY(-7rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  85% {
    -webkit-transform: translateX(72rem) translateY(-7rem) scale(1);
    -moz-transform: translateX(72rem) translateY(-7rem) scale(1);
    -ms-transform: translateX(72rem) translateY(-7rem) scale(1);
    -o-transform: translateX(72rem) translateY(-7rem) scale(1);
    transform: translateX(72rem) translateY(-7rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  95% {
    -webkit-transform: translateX(72rem) translateY(0rem) scale(0);
    -moz-transform: translateX(72rem) translateY(0rem) scale(0);
    -ms-transform: translateX(72rem) translateY(0rem) scale(0);
    -o-transform: translateX(72rem) translateY(0rem) scale(0);
    transform: translateX(72rem) translateY(0rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@keyframes increase-winnings-coin-two-animation {
  30% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  45% {
    -webkit-transform: translateX(5rem) translateY(-4rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-4rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-4rem) scale(1);
    -o-transform: translateX(5rem) translateY(-4rem) scale(1);
    transform: translateX(5rem) translateY(-4rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  60% {
    -webkit-transform: translateX(10rem) translateY(2rem) scale(0);
    -moz-transform: translateX(10rem) translateY(2rem) scale(0);
    -ms-transform: translateX(10rem) translateY(2rem) scale(0);
    -o-transform: translateX(10rem) translateY(2rem) scale(0);
    transform: translateX(10rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  73% {
    -webkit-transform: translateX(60rem) translateY(2rem) scale(0);
    -moz-transform: translateX(60rem) translateY(2rem) scale(0);
    -ms-transform: translateX(60rem) translateY(2rem) scale(0);
    -o-transform: translateX(60rem) translateY(2rem) scale(0);
    transform: translateX(60rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  83% {
    -webkit-transform: translateX(70rem) translateY(-7rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-7rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-7rem) scale(1);
    -o-transform: translateX(70rem) translateY(-7rem) scale(1);
    transform: translateX(70rem) translateY(-7rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  85% {
    -webkit-transform: translateX(72rem) translateY(-7rem) scale(1);
    -moz-transform: translateX(72rem) translateY(-7rem) scale(1);
    -ms-transform: translateX(72rem) translateY(-7rem) scale(1);
    -o-transform: translateX(72rem) translateY(-7rem) scale(1);
    transform: translateX(72rem) translateY(-7rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  95% {
    -webkit-transform: translateX(72rem) translateY(0rem) scale(0);
    -moz-transform: translateX(72rem) translateY(0rem) scale(0);
    -ms-transform: translateX(72rem) translateY(0rem) scale(0);
    -o-transform: translateX(72rem) translateY(0rem) scale(0);
    transform: translateX(72rem) translateY(0rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-webkit-keyframes increase-winnings-coin-three-animation {
  40% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  55% {
    -webkit-transform: translateX(5rem) translateY(-4rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-4rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-4rem) scale(1);
    -o-transform: translateX(5rem) translateY(-4rem) scale(1);
    transform: translateX(5rem) translateY(-4rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  70% {
    -webkit-transform: translateX(10rem) translateY(2rem) scale(0);
    -moz-transform: translateX(10rem) translateY(2rem) scale(0);
    -ms-transform: translateX(10rem) translateY(2rem) scale(0);
    -o-transform: translateX(10rem) translateY(2rem) scale(0);
    transform: translateX(10rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  78% {
    -webkit-transform: translateX(60rem) translateY(2rem) scale(0);
    -moz-transform: translateX(60rem) translateY(2rem) scale(0);
    -ms-transform: translateX(60rem) translateY(2rem) scale(0);
    -o-transform: translateX(60rem) translateY(2rem) scale(0);
    transform: translateX(60rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  88% {
    -webkit-transform: translateX(70rem) translateY(-7rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-7rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-7rem) scale(1);
    -o-transform: translateX(70rem) translateY(-7rem) scale(1);
    transform: translateX(70rem) translateY(-7rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateX(72rem) translateY(-7rem) scale(1);
    -moz-transform: translateX(72rem) translateY(-7rem) scale(1);
    -ms-transform: translateX(72rem) translateY(-7rem) scale(1);
    -o-transform: translateX(72rem) translateY(-7rem) scale(1);
    transform: translateX(72rem) translateY(-7rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(72rem) translateY(0rem) scale(0);
    -moz-transform: translateX(72rem) translateY(0rem) scale(0);
    -ms-transform: translateX(72rem) translateY(0rem) scale(0);
    -o-transform: translateX(72rem) translateY(0rem) scale(0);
    transform: translateX(72rem) translateY(0rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-moz-keyframes increase-winnings-coin-three-animation {
  40% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  55% {
    -webkit-transform: translateX(5rem) translateY(-4rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-4rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-4rem) scale(1);
    -o-transform: translateX(5rem) translateY(-4rem) scale(1);
    transform: translateX(5rem) translateY(-4rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  70% {
    -webkit-transform: translateX(10rem) translateY(2rem) scale(0);
    -moz-transform: translateX(10rem) translateY(2rem) scale(0);
    -ms-transform: translateX(10rem) translateY(2rem) scale(0);
    -o-transform: translateX(10rem) translateY(2rem) scale(0);
    transform: translateX(10rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  78% {
    -webkit-transform: translateX(60rem) translateY(2rem) scale(0);
    -moz-transform: translateX(60rem) translateY(2rem) scale(0);
    -ms-transform: translateX(60rem) translateY(2rem) scale(0);
    -o-transform: translateX(60rem) translateY(2rem) scale(0);
    transform: translateX(60rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  88% {
    -webkit-transform: translateX(70rem) translateY(-7rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-7rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-7rem) scale(1);
    -o-transform: translateX(70rem) translateY(-7rem) scale(1);
    transform: translateX(70rem) translateY(-7rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateX(72rem) translateY(-7rem) scale(1);
    -moz-transform: translateX(72rem) translateY(-7rem) scale(1);
    -ms-transform: translateX(72rem) translateY(-7rem) scale(1);
    -o-transform: translateX(72rem) translateY(-7rem) scale(1);
    transform: translateX(72rem) translateY(-7rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(72rem) translateY(0rem) scale(0);
    -moz-transform: translateX(72rem) translateY(0rem) scale(0);
    -ms-transform: translateX(72rem) translateY(0rem) scale(0);
    -o-transform: translateX(72rem) translateY(0rem) scale(0);
    transform: translateX(72rem) translateY(0rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-ms-keyframes increase-winnings-coin-three-animation {
  40% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  55% {
    -webkit-transform: translateX(5rem) translateY(-4rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-4rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-4rem) scale(1);
    -o-transform: translateX(5rem) translateY(-4rem) scale(1);
    transform: translateX(5rem) translateY(-4rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  70% {
    -webkit-transform: translateX(10rem) translateY(2rem) scale(0);
    -moz-transform: translateX(10rem) translateY(2rem) scale(0);
    -ms-transform: translateX(10rem) translateY(2rem) scale(0);
    -o-transform: translateX(10rem) translateY(2rem) scale(0);
    transform: translateX(10rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  78% {
    -webkit-transform: translateX(60rem) translateY(2rem) scale(0);
    -moz-transform: translateX(60rem) translateY(2rem) scale(0);
    -ms-transform: translateX(60rem) translateY(2rem) scale(0);
    -o-transform: translateX(60rem) translateY(2rem) scale(0);
    transform: translateX(60rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  88% {
    -webkit-transform: translateX(70rem) translateY(-7rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-7rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-7rem) scale(1);
    -o-transform: translateX(70rem) translateY(-7rem) scale(1);
    transform: translateX(70rem) translateY(-7rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateX(72rem) translateY(-7rem) scale(1);
    -moz-transform: translateX(72rem) translateY(-7rem) scale(1);
    -ms-transform: translateX(72rem) translateY(-7rem) scale(1);
    -o-transform: translateX(72rem) translateY(-7rem) scale(1);
    transform: translateX(72rem) translateY(-7rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(72rem) translateY(0rem) scale(0);
    -moz-transform: translateX(72rem) translateY(0rem) scale(0);
    -ms-transform: translateX(72rem) translateY(0rem) scale(0);
    -o-transform: translateX(72rem) translateY(0rem) scale(0);
    transform: translateX(72rem) translateY(0rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@keyframes increase-winnings-coin-three-animation {
  40% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  55% {
    -webkit-transform: translateX(5rem) translateY(-4rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-4rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-4rem) scale(1);
    -o-transform: translateX(5rem) translateY(-4rem) scale(1);
    transform: translateX(5rem) translateY(-4rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  70% {
    -webkit-transform: translateX(10rem) translateY(2rem) scale(0);
    -moz-transform: translateX(10rem) translateY(2rem) scale(0);
    -ms-transform: translateX(10rem) translateY(2rem) scale(0);
    -o-transform: translateX(10rem) translateY(2rem) scale(0);
    transform: translateX(10rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  78% {
    -webkit-transform: translateX(60rem) translateY(2rem) scale(0);
    -moz-transform: translateX(60rem) translateY(2rem) scale(0);
    -ms-transform: translateX(60rem) translateY(2rem) scale(0);
    -o-transform: translateX(60rem) translateY(2rem) scale(0);
    transform: translateX(60rem) translateY(2rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  88% {
    -webkit-transform: translateX(70rem) translateY(-7rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-7rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-7rem) scale(1);
    -o-transform: translateX(70rem) translateY(-7rem) scale(1);
    transform: translateX(70rem) translateY(-7rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateX(72rem) translateY(-7rem) scale(1);
    -moz-transform: translateX(72rem) translateY(-7rem) scale(1);
    -ms-transform: translateX(72rem) translateY(-7rem) scale(1);
    -o-transform: translateX(72rem) translateY(-7rem) scale(1);
    transform: translateX(72rem) translateY(-7rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(72rem) translateY(0rem) scale(0);
    -moz-transform: translateX(72rem) translateY(0rem) scale(0);
    -ms-transform: translateX(72rem) translateY(0rem) scale(0);
    -o-transform: translateX(72rem) translateY(0rem) scale(0);
    transform: translateX(72rem) translateY(0rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-webkit-keyframes sticky-increase-winnings-coin-one-animation {
  20% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  35% {
    -webkit-transform: translateX(5rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-2rem) scale(1);
    -o-transform: translateX(5rem) translateY(-2rem) scale(1);
    transform: translateX(5rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  68% {
    -webkit-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  78% {
    -webkit-transform: translateX(67rem) translateY(-3rem) scale(1);
    -moz-transform: translateX(67rem) translateY(-3rem) scale(1);
    -ms-transform: translateX(67rem) translateY(-3rem) scale(1);
    -o-transform: translateX(67rem) translateY(-3rem) scale(1);
    transform: translateX(67rem) translateY(-3rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(70rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-2rem) scale(1);
    -o-transform: translateX(70rem) translateY(-2rem) scale(1);
    transform: translateX(70rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-moz-keyframes sticky-increase-winnings-coin-one-animation {
  20% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  35% {
    -webkit-transform: translateX(5rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-2rem) scale(1);
    -o-transform: translateX(5rem) translateY(-2rem) scale(1);
    transform: translateX(5rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  68% {
    -webkit-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  78% {
    -webkit-transform: translateX(67rem) translateY(-3rem) scale(1);
    -moz-transform: translateX(67rem) translateY(-3rem) scale(1);
    -ms-transform: translateX(67rem) translateY(-3rem) scale(1);
    -o-transform: translateX(67rem) translateY(-3rem) scale(1);
    transform: translateX(67rem) translateY(-3rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(70rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-2rem) scale(1);
    -o-transform: translateX(70rem) translateY(-2rem) scale(1);
    transform: translateX(70rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-ms-keyframes sticky-increase-winnings-coin-one-animation {
  20% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  35% {
    -webkit-transform: translateX(5rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-2rem) scale(1);
    -o-transform: translateX(5rem) translateY(-2rem) scale(1);
    transform: translateX(5rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  68% {
    -webkit-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  78% {
    -webkit-transform: translateX(67rem) translateY(-3rem) scale(1);
    -moz-transform: translateX(67rem) translateY(-3rem) scale(1);
    -ms-transform: translateX(67rem) translateY(-3rem) scale(1);
    -o-transform: translateX(67rem) translateY(-3rem) scale(1);
    transform: translateX(67rem) translateY(-3rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(70rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-2rem) scale(1);
    -o-transform: translateX(70rem) translateY(-2rem) scale(1);
    transform: translateX(70rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@keyframes sticky-increase-winnings-coin-one-animation {
  20% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  35% {
    -webkit-transform: translateX(5rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-2rem) scale(1);
    -o-transform: translateX(5rem) translateY(-2rem) scale(1);
    transform: translateX(5rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  68% {
    -webkit-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  78% {
    -webkit-transform: translateX(67rem) translateY(-3rem) scale(1);
    -moz-transform: translateX(67rem) translateY(-3rem) scale(1);
    -ms-transform: translateX(67rem) translateY(-3rem) scale(1);
    -o-transform: translateX(67rem) translateY(-3rem) scale(1);
    transform: translateX(67rem) translateY(-3rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(70rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-2rem) scale(1);
    -o-transform: translateX(70rem) translateY(-2rem) scale(1);
    transform: translateX(70rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-webkit-keyframes sticky-increase-winnings-coin-two-animation {
  30% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  45% {
    -webkit-transform: translateX(5rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-2rem) scale(1);
    -o-transform: translateX(5rem) translateY(-2rem) scale(1);
    transform: translateX(5rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  60% {
    -webkit-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  73% {
    -webkit-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  83% {
    -webkit-transform: translateX(67rem) translateY(-3rem) scale(1);
    -moz-transform: translateX(67rem) translateY(-3rem) scale(1);
    -ms-transform: translateX(67rem) translateY(-3rem) scale(1);
    -o-transform: translateX(67rem) translateY(-3rem) scale(1);
    transform: translateX(67rem) translateY(-3rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  85% {
    -webkit-transform: translateX(70rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-2rem) scale(1);
    -o-transform: translateX(70rem) translateY(-2rem) scale(1);
    transform: translateX(70rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  95% {
    -webkit-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-moz-keyframes sticky-increase-winnings-coin-two-animation {
  30% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  45% {
    -webkit-transform: translateX(5rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-2rem) scale(1);
    -o-transform: translateX(5rem) translateY(-2rem) scale(1);
    transform: translateX(5rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  60% {
    -webkit-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  73% {
    -webkit-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  83% {
    -webkit-transform: translateX(67rem) translateY(-3rem) scale(1);
    -moz-transform: translateX(67rem) translateY(-3rem) scale(1);
    -ms-transform: translateX(67rem) translateY(-3rem) scale(1);
    -o-transform: translateX(67rem) translateY(-3rem) scale(1);
    transform: translateX(67rem) translateY(-3rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  85% {
    -webkit-transform: translateX(70rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-2rem) scale(1);
    -o-transform: translateX(70rem) translateY(-2rem) scale(1);
    transform: translateX(70rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  95% {
    -webkit-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-ms-keyframes sticky-increase-winnings-coin-two-animation {
  30% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  45% {
    -webkit-transform: translateX(5rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-2rem) scale(1);
    -o-transform: translateX(5rem) translateY(-2rem) scale(1);
    transform: translateX(5rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  60% {
    -webkit-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  73% {
    -webkit-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  83% {
    -webkit-transform: translateX(67rem) translateY(-3rem) scale(1);
    -moz-transform: translateX(67rem) translateY(-3rem) scale(1);
    -ms-transform: translateX(67rem) translateY(-3rem) scale(1);
    -o-transform: translateX(67rem) translateY(-3rem) scale(1);
    transform: translateX(67rem) translateY(-3rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  85% {
    -webkit-transform: translateX(70rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-2rem) scale(1);
    -o-transform: translateX(70rem) translateY(-2rem) scale(1);
    transform: translateX(70rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  95% {
    -webkit-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@keyframes sticky-increase-winnings-coin-two-animation {
  30% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  45% {
    -webkit-transform: translateX(5rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-2rem) scale(1);
    -o-transform: translateX(5rem) translateY(-2rem) scale(1);
    transform: translateX(5rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  60% {
    -webkit-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  73% {
    -webkit-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  83% {
    -webkit-transform: translateX(67rem) translateY(-3rem) scale(1);
    -moz-transform: translateX(67rem) translateY(-3rem) scale(1);
    -ms-transform: translateX(67rem) translateY(-3rem) scale(1);
    -o-transform: translateX(67rem) translateY(-3rem) scale(1);
    transform: translateX(67rem) translateY(-3rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  85% {
    -webkit-transform: translateX(70rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-2rem) scale(1);
    -o-transform: translateX(70rem) translateY(-2rem) scale(1);
    transform: translateX(70rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  95% {
    -webkit-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-webkit-keyframes sticky-increase-winnings-coin-three-animation {
  40% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  55% {
    -webkit-transform: translateX(5rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-2rem) scale(1);
    -o-transform: translateX(5rem) translateY(-2rem) scale(1);
    transform: translateX(5rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  70% {
    -webkit-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  78% {
    -webkit-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  88% {
    -webkit-transform: translateX(67rem) translateY(-3rem) scale(1);
    -moz-transform: translateX(67rem) translateY(-3rem) scale(1);
    -ms-transform: translateX(67rem) translateY(-3rem) scale(1);
    -o-transform: translateX(67rem) translateY(-3rem) scale(1);
    transform: translateX(67rem) translateY(-3rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateX(70rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-2rem) scale(1);
    -o-transform: translateX(70rem) translateY(-2rem) scale(1);
    transform: translateX(70rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-moz-keyframes sticky-increase-winnings-coin-three-animation {
  40% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  55% {
    -webkit-transform: translateX(5rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-2rem) scale(1);
    -o-transform: translateX(5rem) translateY(-2rem) scale(1);
    transform: translateX(5rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  70% {
    -webkit-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  78% {
    -webkit-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  88% {
    -webkit-transform: translateX(67rem) translateY(-3rem) scale(1);
    -moz-transform: translateX(67rem) translateY(-3rem) scale(1);
    -ms-transform: translateX(67rem) translateY(-3rem) scale(1);
    -o-transform: translateX(67rem) translateY(-3rem) scale(1);
    transform: translateX(67rem) translateY(-3rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateX(70rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-2rem) scale(1);
    -o-transform: translateX(70rem) translateY(-2rem) scale(1);
    transform: translateX(70rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-ms-keyframes sticky-increase-winnings-coin-three-animation {
  40% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  55% {
    -webkit-transform: translateX(5rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-2rem) scale(1);
    -o-transform: translateX(5rem) translateY(-2rem) scale(1);
    transform: translateX(5rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  70% {
    -webkit-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  78% {
    -webkit-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  88% {
    -webkit-transform: translateX(67rem) translateY(-3rem) scale(1);
    -moz-transform: translateX(67rem) translateY(-3rem) scale(1);
    -ms-transform: translateX(67rem) translateY(-3rem) scale(1);
    -o-transform: translateX(67rem) translateY(-3rem) scale(1);
    transform: translateX(67rem) translateY(-3rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateX(70rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-2rem) scale(1);
    -o-transform: translateX(70rem) translateY(-2rem) scale(1);
    transform: translateX(70rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@keyframes sticky-increase-winnings-coin-three-animation {
  40% {
    -webkit-transform: translateX(0) translateY(0) scale(0);
    -moz-transform: translateX(0) translateY(0) scale(0);
    -ms-transform: translateX(0) translateY(0) scale(0);
    -o-transform: translateX(0) translateY(0) scale(0);
    transform: translateX(0) translateY(0) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  55% {
    -webkit-transform: translateX(5rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(5rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(5rem) translateY(-2rem) scale(1);
    -o-transform: translateX(5rem) translateY(-2rem) scale(1);
    transform: translateX(5rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  70% {
    -webkit-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(9rem) translateY(-1.5rem) scale(0);
    transform: translateX(9rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  78% {
    -webkit-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(60rem) translateY(-1.5rem) scale(0);
    transform: translateX(60rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  88% {
    -webkit-transform: translateX(67rem) translateY(-3rem) scale(1);
    -moz-transform: translateX(67rem) translateY(-3rem) scale(1);
    -ms-transform: translateX(67rem) translateY(-3rem) scale(1);
    -o-transform: translateX(67rem) translateY(-3rem) scale(1);
    transform: translateX(67rem) translateY(-3rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  90% {
    -webkit-transform: translateX(70rem) translateY(-2rem) scale(1);
    -moz-transform: translateX(70rem) translateY(-2rem) scale(1);
    -ms-transform: translateX(70rem) translateY(-2rem) scale(1);
    -o-transform: translateX(70rem) translateY(-2rem) scale(1);
    transform: translateX(70rem) translateY(-2rem) scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -moz-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -ms-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -o-transform: translateX(70rem) translateY(-1.5rem) scale(0);
    transform: translateX(70rem) translateY(-1.5rem) scale(0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-webkit-keyframes increase-winnings-small-bag-one-bag-animation {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes increase-winnings-small-bag-one-bag-animation {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes increase-winnings-small-bag-one-bag-animation {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes increase-winnings-small-bag-one-bag-animation {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes increase-winnings-small-bag-one-shadow-animation {
  0% {
    -webkit-transform: translateY(4rem) scale(1);
    -moz-transform: translateY(4rem) scale(1);
    -ms-transform: translateY(4rem) scale(1);
    -o-transform: translateY(4rem) scale(1);
    transform: translateY(4rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
  20% {
    -webkit-transform: translateY(4rem) scale(0.5);
    -moz-transform: translateY(4rem) scale(0.5);
    -ms-transform: translateY(4rem) scale(0.5);
    -o-transform: translateY(4rem) scale(0.5);
    transform: translateY(4rem) scale(0.5);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  50% {
    -webkit-transform: translateY(4rem) scale(1);
    -moz-transform: translateY(4rem) scale(1);
    -ms-transform: translateY(4rem) scale(1);
    -o-transform: translateY(4rem) scale(1);
    transform: translateY(4rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
}
@-moz-keyframes increase-winnings-small-bag-one-shadow-animation {
  0% {
    -webkit-transform: translateY(4rem) scale(1);
    -moz-transform: translateY(4rem) scale(1);
    -ms-transform: translateY(4rem) scale(1);
    -o-transform: translateY(4rem) scale(1);
    transform: translateY(4rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
  20% {
    -webkit-transform: translateY(4rem) scale(0.5);
    -moz-transform: translateY(4rem) scale(0.5);
    -ms-transform: translateY(4rem) scale(0.5);
    -o-transform: translateY(4rem) scale(0.5);
    transform: translateY(4rem) scale(0.5);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  50% {
    -webkit-transform: translateY(4rem) scale(1);
    -moz-transform: translateY(4rem) scale(1);
    -ms-transform: translateY(4rem) scale(1);
    -o-transform: translateY(4rem) scale(1);
    transform: translateY(4rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
}
@-ms-keyframes increase-winnings-small-bag-one-shadow-animation {
  0% {
    -webkit-transform: translateY(4rem) scale(1);
    -moz-transform: translateY(4rem) scale(1);
    -ms-transform: translateY(4rem) scale(1);
    -o-transform: translateY(4rem) scale(1);
    transform: translateY(4rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
  20% {
    -webkit-transform: translateY(4rem) scale(0.5);
    -moz-transform: translateY(4rem) scale(0.5);
    -ms-transform: translateY(4rem) scale(0.5);
    -o-transform: translateY(4rem) scale(0.5);
    transform: translateY(4rem) scale(0.5);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  50% {
    -webkit-transform: translateY(4rem) scale(1);
    -moz-transform: translateY(4rem) scale(1);
    -ms-transform: translateY(4rem) scale(1);
    -o-transform: translateY(4rem) scale(1);
    transform: translateY(4rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
}
@keyframes increase-winnings-small-bag-one-shadow-animation {
  0% {
    -webkit-transform: translateY(4rem) scale(1);
    -moz-transform: translateY(4rem) scale(1);
    -ms-transform: translateY(4rem) scale(1);
    -o-transform: translateY(4rem) scale(1);
    transform: translateY(4rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
  20% {
    -webkit-transform: translateY(4rem) scale(0.5);
    -moz-transform: translateY(4rem) scale(0.5);
    -ms-transform: translateY(4rem) scale(0.5);
    -o-transform: translateY(4rem) scale(0.5);
    transform: translateY(4rem) scale(0.5);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  50% {
    -webkit-transform: translateY(4rem) scale(1);
    -moz-transform: translateY(4rem) scale(1);
    -ms-transform: translateY(4rem) scale(1);
    -o-transform: translateY(4rem) scale(1);
    transform: translateY(4rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
}
.keno-barometer-section.increase-winnings .keno-barometer .left-bags-container .bag-one .bag {
  -webkit-animation: increase-winnings-small-bag-one-bag-animation 1.4s forwards;
  -moz-animation: increase-winnings-small-bag-one-bag-animation 1.4s forwards;
  -ms-animation: increase-winnings-small-bag-one-bag-animation 1.4s forwards;
  animation: increase-winnings-small-bag-one-bag-animation 1.4s forwards;
  animation-delay: 0.4s;
}
.keno-barometer-section.increase-winnings .keno-barometer .left-bags-container .bag-one .shadow {
  -webkit-animation: increase-winnings-small-bag-one-shadow-animation 1.4s forwards;
  -moz-animation: increase-winnings-small-bag-one-shadow-animation 1.4s forwards;
  -ms-animation: increase-winnings-small-bag-one-shadow-animation 1.4s forwards;
  animation: increase-winnings-small-bag-one-shadow-animation 1.4s forwards;
  animation-delay: 0.4s;
}
@-webkit-keyframes increase-winnings-small-bag-two-bag-animation {
  10% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  30% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  60% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes increase-winnings-small-bag-two-bag-animation {
  10% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  30% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  60% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes increase-winnings-small-bag-two-bag-animation {
  10% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  30% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  60% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes increase-winnings-small-bag-two-bag-animation {
  10% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  30% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  60% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes increase-winnings-small-bag-two-shadow-animation {
  10% {
    -webkit-transform: translateY(13rem) scale(0.9);
    -moz-transform: translateY(13rem) scale(0.9);
    -ms-transform: translateY(13rem) scale(0.9);
    -o-transform: translateY(13rem) scale(0.9);
    transform: translateY(13rem) scale(0.9);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  30% {
    -webkit-transform: translateY(13rem) scale(0.4);
    -moz-transform: translateY(13rem) scale(0.4);
    -ms-transform: translateY(13rem) scale(0.4);
    -o-transform: translateY(13rem) scale(0.4);
    transform: translateY(13rem) scale(0.4);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.1;
    -moz-opacity: 0.1;
    opacity: 0.1;
  }
  60% {
    -webkit-transform: translateY(13rem) scale(0.9);
    -moz-transform: translateY(13rem) scale(0.9);
    -ms-transform: translateY(13rem) scale(0.9);
    -o-transform: translateY(13rem) scale(0.9);
    transform: translateY(13rem) scale(0.9);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
}
@-moz-keyframes increase-winnings-small-bag-two-shadow-animation {
  10% {
    -webkit-transform: translateY(13rem) scale(0.9);
    -moz-transform: translateY(13rem) scale(0.9);
    -ms-transform: translateY(13rem) scale(0.9);
    -o-transform: translateY(13rem) scale(0.9);
    transform: translateY(13rem) scale(0.9);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  30% {
    -webkit-transform: translateY(13rem) scale(0.4);
    -moz-transform: translateY(13rem) scale(0.4);
    -ms-transform: translateY(13rem) scale(0.4);
    -o-transform: translateY(13rem) scale(0.4);
    transform: translateY(13rem) scale(0.4);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.1;
    -moz-opacity: 0.1;
    opacity: 0.1;
  }
  60% {
    -webkit-transform: translateY(13rem) scale(0.9);
    -moz-transform: translateY(13rem) scale(0.9);
    -ms-transform: translateY(13rem) scale(0.9);
    -o-transform: translateY(13rem) scale(0.9);
    transform: translateY(13rem) scale(0.9);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
}
@-ms-keyframes increase-winnings-small-bag-two-shadow-animation {
  10% {
    -webkit-transform: translateY(13rem) scale(0.9);
    -moz-transform: translateY(13rem) scale(0.9);
    -ms-transform: translateY(13rem) scale(0.9);
    -o-transform: translateY(13rem) scale(0.9);
    transform: translateY(13rem) scale(0.9);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  30% {
    -webkit-transform: translateY(13rem) scale(0.4);
    -moz-transform: translateY(13rem) scale(0.4);
    -ms-transform: translateY(13rem) scale(0.4);
    -o-transform: translateY(13rem) scale(0.4);
    transform: translateY(13rem) scale(0.4);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.1;
    -moz-opacity: 0.1;
    opacity: 0.1;
  }
  60% {
    -webkit-transform: translateY(13rem) scale(0.9);
    -moz-transform: translateY(13rem) scale(0.9);
    -ms-transform: translateY(13rem) scale(0.9);
    -o-transform: translateY(13rem) scale(0.9);
    transform: translateY(13rem) scale(0.9);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
}
@keyframes increase-winnings-small-bag-two-shadow-animation {
  10% {
    -webkit-transform: translateY(13rem) scale(0.9);
    -moz-transform: translateY(13rem) scale(0.9);
    -ms-transform: translateY(13rem) scale(0.9);
    -o-transform: translateY(13rem) scale(0.9);
    transform: translateY(13rem) scale(0.9);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  30% {
    -webkit-transform: translateY(13rem) scale(0.4);
    -moz-transform: translateY(13rem) scale(0.4);
    -ms-transform: translateY(13rem) scale(0.4);
    -o-transform: translateY(13rem) scale(0.4);
    transform: translateY(13rem) scale(0.4);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.1;
    -moz-opacity: 0.1;
    opacity: 0.1;
  }
  60% {
    -webkit-transform: translateY(13rem) scale(0.9);
    -moz-transform: translateY(13rem) scale(0.9);
    -ms-transform: translateY(13rem) scale(0.9);
    -o-transform: translateY(13rem) scale(0.9);
    transform: translateY(13rem) scale(0.9);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
}
.keno-barometer-section.increase-winnings .keno-barometer .left-bags-container .bag-two .bag {
  -webkit-animation: increase-winnings-small-bag-two-bag-animation 1.4s forwards;
  -moz-animation: increase-winnings-small-bag-two-bag-animation 1.4s forwards;
  -ms-animation: increase-winnings-small-bag-two-bag-animation 1.4s forwards;
  animation: increase-winnings-small-bag-two-bag-animation 1.4s forwards;
  animation-delay: 0.4s;
}
.keno-barometer-section.increase-winnings .keno-barometer .left-bags-container .bag-two .shadow {
  -webkit-animation: increase-winnings-small-bag-two-shadow-animation 1.4s forwards;
  -moz-animation: increase-winnings-small-bag-two-shadow-animation 1.4s forwards;
  -ms-animation: increase-winnings-small-bag-two-shadow-animation 1.4s forwards;
  animation: increase-winnings-small-bag-two-shadow-animation 1.4s forwards;
  animation-delay: 0.4s;
}
@-webkit-keyframes increase-winnings-small-bag-three-bag-animation {
  20% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  70% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes increase-winnings-small-bag-three-bag-animation {
  20% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  70% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes increase-winnings-small-bag-three-bag-animation {
  20% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  70% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes increase-winnings-small-bag-three-bag-animation {
  20% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  70% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes increase-winnings-small-bag-three-shadow-animation {
  20% {
    -webkit-transform: translateY(4rem) scale(1);
    -moz-transform: translateY(4rem) scale(1);
    -ms-transform: translateY(4rem) scale(1);
    -o-transform: translateY(4rem) scale(1);
    transform: translateY(4rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
  40% {
    -webkit-transform: translateY(4rem) scale(0.5);
    -moz-transform: translateY(4rem) scale(0.5);
    -ms-transform: translateY(4rem) scale(0.5);
    -o-transform: translateY(4rem) scale(0.5);
    transform: translateY(4rem) scale(0.5);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  70% {
    -webkit-transform: translateY(4rem) scale(1);
    -moz-transform: translateY(4rem) scale(1);
    -ms-transform: translateY(4rem) scale(1);
    -o-transform: translateY(4rem) scale(1);
    transform: translateY(4rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
}
@-moz-keyframes increase-winnings-small-bag-three-shadow-animation {
  20% {
    -webkit-transform: translateY(4rem) scale(1);
    -moz-transform: translateY(4rem) scale(1);
    -ms-transform: translateY(4rem) scale(1);
    -o-transform: translateY(4rem) scale(1);
    transform: translateY(4rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
  40% {
    -webkit-transform: translateY(4rem) scale(0.5);
    -moz-transform: translateY(4rem) scale(0.5);
    -ms-transform: translateY(4rem) scale(0.5);
    -o-transform: translateY(4rem) scale(0.5);
    transform: translateY(4rem) scale(0.5);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  70% {
    -webkit-transform: translateY(4rem) scale(1);
    -moz-transform: translateY(4rem) scale(1);
    -ms-transform: translateY(4rem) scale(1);
    -o-transform: translateY(4rem) scale(1);
    transform: translateY(4rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
}
@-ms-keyframes increase-winnings-small-bag-three-shadow-animation {
  20% {
    -webkit-transform: translateY(4rem) scale(1);
    -moz-transform: translateY(4rem) scale(1);
    -ms-transform: translateY(4rem) scale(1);
    -o-transform: translateY(4rem) scale(1);
    transform: translateY(4rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
  40% {
    -webkit-transform: translateY(4rem) scale(0.5);
    -moz-transform: translateY(4rem) scale(0.5);
    -ms-transform: translateY(4rem) scale(0.5);
    -o-transform: translateY(4rem) scale(0.5);
    transform: translateY(4rem) scale(0.5);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  70% {
    -webkit-transform: translateY(4rem) scale(1);
    -moz-transform: translateY(4rem) scale(1);
    -ms-transform: translateY(4rem) scale(1);
    -o-transform: translateY(4rem) scale(1);
    transform: translateY(4rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
}
@keyframes increase-winnings-small-bag-three-shadow-animation {
  20% {
    -webkit-transform: translateY(4rem) scale(1);
    -moz-transform: translateY(4rem) scale(1);
    -ms-transform: translateY(4rem) scale(1);
    -o-transform: translateY(4rem) scale(1);
    transform: translateY(4rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
  40% {
    -webkit-transform: translateY(4rem) scale(0.5);
    -moz-transform: translateY(4rem) scale(0.5);
    -ms-transform: translateY(4rem) scale(0.5);
    -o-transform: translateY(4rem) scale(0.5);
    transform: translateY(4rem) scale(0.5);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  70% {
    -webkit-transform: translateY(4rem) scale(1);
    -moz-transform: translateY(4rem) scale(1);
    -ms-transform: translateY(4rem) scale(1);
    -o-transform: translateY(4rem) scale(1);
    transform: translateY(4rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
}
.keno-barometer-section.increase-winnings .keno-barometer .left-bags-container .bag-three .bag {
  -webkit-animation: increase-winnings-small-bag-three-bag-animation 1.4s forwards;
  -moz-animation: increase-winnings-small-bag-three-bag-animation 1.4s forwards;
  -ms-animation: increase-winnings-small-bag-three-bag-animation 1.4s forwards;
  animation: increase-winnings-small-bag-three-bag-animation 1.4s forwards;
  animation-delay: 0.4s;
}
.keno-barometer-section.increase-winnings .keno-barometer .left-bags-container .bag-three .shadow {
  -webkit-animation: increase-winnings-small-bag-three-shadow-animation 1.4s forwards;
  -moz-animation: increase-winnings-small-bag-three-shadow-animation 1.4s forwards;
  -ms-animation: increase-winnings-small-bag-three-shadow-animation 1.4s forwards;
  animation: increase-winnings-small-bag-three-shadow-animation 1.4s forwards;
  animation-delay: 0.4s;
}
.keno-barometer-section.increase-winnings .keno-barometer .inner .bar-container.yellow {
  width: 30%;
}
.keno-barometer-section.increase-winnings .keno-barometer .inner .total {
  -webkit-animation: increase-winnings-total-animation 0.25s forwards;
  -moz-animation: increase-winnings-total-animation 0.25s forwards;
  -ms-animation: increase-winnings-total-animation 0.25s forwards;
  animation: increase-winnings-total-animation 0.25s forwards;
}
@-webkit-keyframes increase-winnings-total-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes increase-winnings-total-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes increase-winnings-total-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes increase-winnings-total-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@media (min-width: 950px) {
  .keno-barometer-section.increase-winnings .keno-barometer .inner .total {
    -webkit-animation: increase-winnings-total-animation 1.4s forwards;
    -moz-animation: increase-winnings-total-animation 1.4s forwards;
    -ms-animation: increase-winnings-total-animation 1.4s forwards;
    animation: increase-winnings-total-animation 1.4s forwards;
    animation-delay: 0.4s;
  }
  @-webkit-keyframes increase-winnings-total-animation {
    60% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
    80% {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
    }
    90% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @-moz-keyframes increase-winnings-total-animation {
    60% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
    80% {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
    }
    90% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @-ms-keyframes increase-winnings-total-animation {
    60% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
    80% {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
    }
    90% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes increase-winnings-total-animation {
    60% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
    80% {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2);
    }
    90% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
}
@-webkit-keyframes increase-winnings-big-bag-animation {
  70% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  90% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes increase-winnings-big-bag-animation {
  70% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  90% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes increase-winnings-big-bag-animation {
  70% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  90% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes increase-winnings-big-bag-animation {
  70% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  80% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  90% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.keno-barometer-section.increase-winnings .keno-barometer .right-bags-container .big-bag {
  -webkit-animation: increase-winnings-big-bag-animation 1.4s forwards;
  -moz-animation: increase-winnings-big-bag-animation 1.4s forwards;
  -ms-animation: increase-winnings-big-bag-animation 1.4s forwards;
  animation: increase-winnings-big-bag-animation 1.4s forwards;
  animation-delay: 0.4s;
}
.keno-barometer-section.increase-winnings .keno-barometer .money-coin.coin-one {
  -webkit-animation: increase-winnings-coin-one-animation 1.4s forwards linear;
  -moz-animation: increase-winnings-coin-one-animation 1.4s forwards linear;
  -ms-animation: increase-winnings-coin-one-animation 1.4s forwards linear;
  animation: increase-winnings-coin-one-animation 1.4s forwards linear;
  animation-delay: 0.4s;
}
.keno-barometer-section.increase-winnings .keno-barometer .money-coin.coin-two {
  -webkit-animation: increase-winnings-coin-two-animation 1.4s forwards linear;
  -moz-animation: increase-winnings-coin-two-animation 1.4s forwards linear;
  -ms-animation: increase-winnings-coin-two-animation 1.4s forwards linear;
  animation: increase-winnings-coin-two-animation 1.4s forwards linear;
  animation-delay: 0.4s;
}
.keno-barometer-section.increase-winnings .keno-barometer .money-coin.coin-three {
  -webkit-animation: increase-winnings-coin-three-animation 1.4s forwards linear;
  -moz-animation: increase-winnings-coin-three-animation 1.4s forwards linear;
  -ms-animation: increase-winnings-coin-three-animation 1.4s forwards linear;
  animation: increase-winnings-coin-three-animation 1.4s forwards linear;
  animation-delay: 0.4s;
}
.keno-barometer-section.decrease-winnings .keno-barometer .left-bags-container {
  -webkit-animation: decrease-winnings-small-bags-animation 1.4s forwards;
  -moz-animation: decrease-winnings-small-bags-animation 1.4s forwards;
  -ms-animation: decrease-winnings-small-bags-animation 1.4s forwards;
  animation: decrease-winnings-small-bags-animation 1.4s forwards;
  animation-delay: 0.4s;
}
@-webkit-keyframes decrease-winnings-small-bags-animation {
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  60% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  70% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes decrease-winnings-small-bags-animation {
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  60% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  70% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes decrease-winnings-small-bags-animation {
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  60% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  70% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes decrease-winnings-small-bags-animation {
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  60% {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  70% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.keno-barometer-section.decrease-winnings .keno-barometer .inner .bar-container.yellow {
  width: 10%;
}
@media (min-width: 950px) {
  .keno-barometer-section.decrease-winnings .keno-barometer .inner .bar-container.yellow {
    -webkit-transition: width 0.25s;
    -moz-transition: width 0.25s;
    -ms-transition: width 0.25s;
    -o-transition: width 0.25s;
    transition: width 0.25s;
    transition-delay: 0.86666667s;
  }
}
.keno-barometer-section.decrease-winnings .keno-barometer .inner .total {
  -webkit-animation: decrease-winnings-total-animation 0.25s forwards;
  -moz-animation: decrease-winnings-total-animation 0.25s forwards;
  -ms-animation: decrease-winnings-total-animation 0.25s forwards;
  animation: decrease-winnings-total-animation 0.25s forwards;
}
@-webkit-keyframes decrease-winnings-total-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes decrease-winnings-total-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes decrease-winnings-total-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes decrease-winnings-total-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@media (min-width: 950px) {
  .keno-barometer-section.decrease-winnings .keno-barometer .inner .total {
    -webkit-animation: decrease-winnings-total-animation 1.4s forwards;
    -moz-animation: decrease-winnings-total-animation 1.4s forwards;
    -ms-animation: decrease-winnings-total-animation 1.4s forwards;
    animation: decrease-winnings-total-animation 1.4s forwards;
    animation-delay: 0.4s;
  }
  @-webkit-keyframes decrease-winnings-total-animation {
    40% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      -o-transform: scale(0.8);
      transform: scale(0.8);
    }
    60% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @-moz-keyframes decrease-winnings-total-animation {
    40% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      -o-transform: scale(0.8);
      transform: scale(0.8);
    }
    60% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @-ms-keyframes decrease-winnings-total-animation {
    40% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      -o-transform: scale(0.8);
      transform: scale(0.8);
    }
    60% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes decrease-winnings-total-animation {
    40% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.8);
      -moz-transform: scale(0.8);
      -ms-transform: scale(0.8);
      -o-transform: scale(0.8);
      transform: scale(0.8);
    }
    60% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
}
@-webkit-keyframes decrease-winnings-big-bag-bag-animation {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  30% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-moz-keyframes decrease-winnings-big-bag-bag-animation {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  30% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-ms-keyframes decrease-winnings-big-bag-bag-animation {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  30% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes decrease-winnings-big-bag-bag-animation {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  20% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  30% {
    -webkit-transform: translateY(-10rem);
    -moz-transform: translateY(-10rem);
    -ms-transform: translateY(-10rem);
    -o-transform: translateY(-10rem);
    transform: translateY(-10rem);
  }
  50% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
@-webkit-keyframes decrease-winnings-big-bag-shadow-animation {
  0% {
    -webkit-transform: translateY(-2rem) scale(1);
    -moz-transform: translateY(-2rem) scale(1);
    -ms-transform: translateY(-2rem) scale(1);
    -o-transform: translateY(-2rem) scale(1);
    transform: translateY(-2rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
  20% {
    -webkit-transform: translateY(-2rem) scale(0.5);
    -moz-transform: translateY(-2rem) scale(0.5);
    -ms-transform: translateY(-2rem) scale(0.5);
    -o-transform: translateY(-2rem) scale(0.5);
    transform: translateY(-2rem) scale(0.5);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  30% {
    -webkit-transform: translateY(-2rem) scale(0.5);
    -moz-transform: translateY(-2rem) scale(0.5);
    -ms-transform: translateY(-2rem) scale(0.5);
    -o-transform: translateY(-2rem) scale(0.5);
    transform: translateY(-2rem) scale(0.5);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  50% {
    -webkit-transform: translateY(-2rem) scale(1);
    -moz-transform: translateY(-2rem) scale(1);
    -ms-transform: translateY(-2rem) scale(1);
    -o-transform: translateY(-2rem) scale(1);
    transform: translateY(-2rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
}
@-moz-keyframes decrease-winnings-big-bag-shadow-animation {
  0% {
    -webkit-transform: translateY(-2rem) scale(1);
    -moz-transform: translateY(-2rem) scale(1);
    -ms-transform: translateY(-2rem) scale(1);
    -o-transform: translateY(-2rem) scale(1);
    transform: translateY(-2rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
  20% {
    -webkit-transform: translateY(-2rem) scale(0.5);
    -moz-transform: translateY(-2rem) scale(0.5);
    -ms-transform: translateY(-2rem) scale(0.5);
    -o-transform: translateY(-2rem) scale(0.5);
    transform: translateY(-2rem) scale(0.5);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  30% {
    -webkit-transform: translateY(-2rem) scale(0.5);
    -moz-transform: translateY(-2rem) scale(0.5);
    -ms-transform: translateY(-2rem) scale(0.5);
    -o-transform: translateY(-2rem) scale(0.5);
    transform: translateY(-2rem) scale(0.5);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  50% {
    -webkit-transform: translateY(-2rem) scale(1);
    -moz-transform: translateY(-2rem) scale(1);
    -ms-transform: translateY(-2rem) scale(1);
    -o-transform: translateY(-2rem) scale(1);
    transform: translateY(-2rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
}
@-ms-keyframes decrease-winnings-big-bag-shadow-animation {
  0% {
    -webkit-transform: translateY(-2rem) scale(1);
    -moz-transform: translateY(-2rem) scale(1);
    -ms-transform: translateY(-2rem) scale(1);
    -o-transform: translateY(-2rem) scale(1);
    transform: translateY(-2rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
  20% {
    -webkit-transform: translateY(-2rem) scale(0.5);
    -moz-transform: translateY(-2rem) scale(0.5);
    -ms-transform: translateY(-2rem) scale(0.5);
    -o-transform: translateY(-2rem) scale(0.5);
    transform: translateY(-2rem) scale(0.5);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  30% {
    -webkit-transform: translateY(-2rem) scale(0.5);
    -moz-transform: translateY(-2rem) scale(0.5);
    -ms-transform: translateY(-2rem) scale(0.5);
    -o-transform: translateY(-2rem) scale(0.5);
    transform: translateY(-2rem) scale(0.5);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  50% {
    -webkit-transform: translateY(-2rem) scale(1);
    -moz-transform: translateY(-2rem) scale(1);
    -ms-transform: translateY(-2rem) scale(1);
    -o-transform: translateY(-2rem) scale(1);
    transform: translateY(-2rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
}
@keyframes decrease-winnings-big-bag-shadow-animation {
  0% {
    -webkit-transform: translateY(-2rem) scale(1);
    -moz-transform: translateY(-2rem) scale(1);
    -ms-transform: translateY(-2rem) scale(1);
    -o-transform: translateY(-2rem) scale(1);
    transform: translateY(-2rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
  20% {
    -webkit-transform: translateY(-2rem) scale(0.5);
    -moz-transform: translateY(-2rem) scale(0.5);
    -ms-transform: translateY(-2rem) scale(0.5);
    -o-transform: translateY(-2rem) scale(0.5);
    transform: translateY(-2rem) scale(0.5);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  30% {
    -webkit-transform: translateY(-2rem) scale(0.5);
    -moz-transform: translateY(-2rem) scale(0.5);
    -ms-transform: translateY(-2rem) scale(0.5);
    -o-transform: translateY(-2rem) scale(0.5);
    transform: translateY(-2rem) scale(0.5);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.2;
    -moz-opacity: 0.2;
    opacity: 0.2;
  }
  50% {
    -webkit-transform: translateY(-2rem) scale(1);
    -moz-transform: translateY(-2rem) scale(1);
    -ms-transform: translateY(-2rem) scale(1);
    -o-transform: translateY(-2rem) scale(1);
    transform: translateY(-2rem) scale(1);
    -webkit-transform-origin: center bottom;
    -moz-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    -o-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-opacity: 0.3;
    -moz-opacity: 0.3;
    opacity: 0.3;
  }
}
.keno-barometer-section.decrease-winnings .keno-barometer .right-bags-container .big-bag .bag {
  -webkit-animation: decrease-winnings-big-bag-bag-animation 1.4s forwards;
  -moz-animation: decrease-winnings-big-bag-bag-animation 1.4s forwards;
  -ms-animation: decrease-winnings-big-bag-bag-animation 1.4s forwards;
  animation: decrease-winnings-big-bag-bag-animation 1.4s forwards;
  animation-delay: 0.4s;
}
.keno-barometer-section.decrease-winnings .keno-barometer .right-bags-container .big-bag .shadow {
  -webkit-animation: decrease-winnings-big-bag-shadow-animation 1.4s forwards;
  -moz-animation: decrease-winnings-big-bag-shadow-animation 1.4s forwards;
  -ms-animation: decrease-winnings-big-bag-shadow-animation 1.4s forwards;
  animation: decrease-winnings-big-bag-shadow-animation 1.4s forwards;
  animation-delay: 0.4s;
}
.keno-barometer-section.decrease-winnings .keno-barometer .money-coin.coin-one {
  -webkit-animation: increase-winnings-coin-one-animation 1.4s reverse linear;
  -moz-animation: increase-winnings-coin-one-animation 1.4s reverse linear;
  -ms-animation: increase-winnings-coin-one-animation 1.4s reverse linear;
  animation: increase-winnings-coin-one-animation 1.4s reverse linear;
  animation-delay: 0.4s;
}
.keno-barometer-section.decrease-winnings .keno-barometer .money-coin.coin-two {
  -webkit-animation: increase-winnings-coin-two-animation 1.4s reverse linear;
  -moz-animation: increase-winnings-coin-two-animation 1.4s reverse linear;
  -ms-animation: increase-winnings-coin-two-animation 1.4s reverse linear;
  animation: increase-winnings-coin-two-animation 1.4s reverse linear;
  animation-delay: 0.4s;
}
.keno-barometer-section.decrease-winnings .keno-barometer .money-coin.coin-three {
  -webkit-animation: increase-winnings-coin-three-animation 1.4s reverse linear;
  -moz-animation: increase-winnings-coin-three-animation 1.4s reverse linear;
  -ms-animation: increase-winnings-coin-three-animation 1.4s reverse linear;
  animation: increase-winnings-coin-three-animation 1.4s reverse linear;
  animation-delay: 0.4s;
}
@media (min-width: 950px) {
  .keno-barometer-section.sticky {
    margin-bottom: 11rem;
  }
  .keno-barometer-section.sticky .keno-barometer-logo-box {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
.keno-barometer-section.sticky .keno-barometer {
  position: fixed;
  top: 0;
}
.keno-barometer-section.sticky .keno-barometer:before,
.keno-barometer-section.sticky .keno-barometer:after {
  top: -10rem;
  -webkit-opacity: 0.9;
  -moz-opacity: 0.9;
  opacity: 0.9;
}
.keno-barometer-section.sticky .keno-barometer:before {
  -webkit-transform: translateX(-100%) rotate(0deg);
  -moz-transform: translateX(-100%) rotate(0deg);
  -ms-transform: translateX(-100%) rotate(0deg);
  -o-transform: translateX(-100%) rotate(0deg);
  transform: translateX(-100%) rotate(0deg);
}
.keno-barometer-section.sticky .keno-barometer:after {
  -webkit-transform: translateX(100%) rotate(0deg);
  -moz-transform: translateX(100%) rotate(0deg);
  -ms-transform: translateX(100%) rotate(0deg);
  -o-transform: translateX(100%) rotate(0deg);
  transform: translateX(100%) rotate(0deg);
}
@media (min-width: 950px) {
  .keno-barometer-section.sticky .keno-barometer .inner {
    top: -3rem;
  }
}
.keno-barometer-section.sticky .keno-barometer .left-bags-container {
  -webkit-transform: scale(0.6) translateX(60%);
  -moz-transform: scale(0.6) translateX(60%);
  -ms-transform: scale(0.6) translateX(60%);
  -o-transform: scale(0.6) translateX(60%);
  transform: scale(0.6) translateX(60%);
}
.keno-barometer-section.sticky .keno-barometer .right-bags-container {
  -webkit-transform: scale(0.6) translateX(-60%);
  -moz-transform: scale(0.6) translateX(-60%);
  -ms-transform: scale(0.6) translateX(-60%);
  -o-transform: scale(0.6) translateX(-60%);
  transform: scale(0.6) translateX(-60%);
}
.keno-barometer-section.sticky.increase-winnings .money-coin.coin-one {
  -webkit-animation: sticky-increase-winnings-coin-one-animation 1.4s forwards linear;
  -moz-animation: sticky-increase-winnings-coin-one-animation 1.4s forwards linear;
  -ms-animation: sticky-increase-winnings-coin-one-animation 1.4s forwards linear;
  animation: sticky-increase-winnings-coin-one-animation 1.4s forwards linear;
  animation-delay: 0.4s;
}
.keno-barometer-section.sticky.increase-winnings .money-coin.coin-two {
  -webkit-animation: sticky-increase-winnings-coin-two-animation 1.4s forwards linear;
  -moz-animation: sticky-increase-winnings-coin-two-animation 1.4s forwards linear;
  -ms-animation: sticky-increase-winnings-coin-two-animation 1.4s forwards linear;
  animation: sticky-increase-winnings-coin-two-animation 1.4s forwards linear;
  animation-delay: 0.4s;
}
.keno-barometer-section.sticky.increase-winnings .money-coin.coin-three {
  -webkit-animation: sticky-increase-winnings-coin-three-animation 1.4s forwards linear;
  -moz-animation: sticky-increase-winnings-coin-three-animation 1.4s forwards linear;
  -ms-animation: sticky-increase-winnings-coin-three-animation 1.4s forwards linear;
  animation: sticky-increase-winnings-coin-three-animation 1.4s forwards linear;
  animation-delay: 0.4s;
}
.keno-barometer-section.sticky.decrease-winnings .keno-barometer .left-bags-container {
  -webkit-animation: sticky-decrease-winnings-small-bags-animation 1.4s forwards;
  -moz-animation: sticky-decrease-winnings-small-bags-animation 1.4s forwards;
  -ms-animation: sticky-decrease-winnings-small-bags-animation 1.4s forwards;
  animation: sticky-decrease-winnings-small-bags-animation 1.4s forwards;
  animation-delay: 0.4s;
}
@-webkit-keyframes sticky-decrease-winnings-small-bags-animation {
  50% {
    -webkit-transform: scale(0.6) translateX(60%);
    -moz-transform: scale(0.6) translateX(60%);
    -ms-transform: scale(0.6) translateX(60%);
    -o-transform: scale(0.6) translateX(60%);
    transform: scale(0.6) translateX(60%);
  }
  60% {
    -webkit-transform: scale(0.8) translateX(40%);
    -moz-transform: scale(0.8) translateX(40%);
    -ms-transform: scale(0.8) translateX(40%);
    -o-transform: scale(0.8) translateX(40%);
    transform: scale(0.8) translateX(40%);
  }
  70% {
    -webkit-transform: scale(0.6) translateX(60%);
    -moz-transform: scale(0.6) translateX(60%);
    -ms-transform: scale(0.6) translateX(60%);
    -o-transform: scale(0.6) translateX(60%);
    transform: scale(0.6) translateX(60%);
  }
}
@-moz-keyframes sticky-decrease-winnings-small-bags-animation {
  50% {
    -webkit-transform: scale(0.6) translateX(60%);
    -moz-transform: scale(0.6) translateX(60%);
    -ms-transform: scale(0.6) translateX(60%);
    -o-transform: scale(0.6) translateX(60%);
    transform: scale(0.6) translateX(60%);
  }
  60% {
    -webkit-transform: scale(0.8) translateX(40%);
    -moz-transform: scale(0.8) translateX(40%);
    -ms-transform: scale(0.8) translateX(40%);
    -o-transform: scale(0.8) translateX(40%);
    transform: scale(0.8) translateX(40%);
  }
  70% {
    -webkit-transform: scale(0.6) translateX(60%);
    -moz-transform: scale(0.6) translateX(60%);
    -ms-transform: scale(0.6) translateX(60%);
    -o-transform: scale(0.6) translateX(60%);
    transform: scale(0.6) translateX(60%);
  }
}
@-ms-keyframes sticky-decrease-winnings-small-bags-animation {
  50% {
    -webkit-transform: scale(0.6) translateX(60%);
    -moz-transform: scale(0.6) translateX(60%);
    -ms-transform: scale(0.6) translateX(60%);
    -o-transform: scale(0.6) translateX(60%);
    transform: scale(0.6) translateX(60%);
  }
  60% {
    -webkit-transform: scale(0.8) translateX(40%);
    -moz-transform: scale(0.8) translateX(40%);
    -ms-transform: scale(0.8) translateX(40%);
    -o-transform: scale(0.8) translateX(40%);
    transform: scale(0.8) translateX(40%);
  }
  70% {
    -webkit-transform: scale(0.6) translateX(60%);
    -moz-transform: scale(0.6) translateX(60%);
    -ms-transform: scale(0.6) translateX(60%);
    -o-transform: scale(0.6) translateX(60%);
    transform: scale(0.6) translateX(60%);
  }
}
@keyframes sticky-decrease-winnings-small-bags-animation {
  50% {
    -webkit-transform: scale(0.6) translateX(60%);
    -moz-transform: scale(0.6) translateX(60%);
    -ms-transform: scale(0.6) translateX(60%);
    -o-transform: scale(0.6) translateX(60%);
    transform: scale(0.6) translateX(60%);
  }
  60% {
    -webkit-transform: scale(0.8) translateX(40%);
    -moz-transform: scale(0.8) translateX(40%);
    -ms-transform: scale(0.8) translateX(40%);
    -o-transform: scale(0.8) translateX(40%);
    transform: scale(0.8) translateX(40%);
  }
  70% {
    -webkit-transform: scale(0.6) translateX(60%);
    -moz-transform: scale(0.6) translateX(60%);
    -ms-transform: scale(0.6) translateX(60%);
    -o-transform: scale(0.6) translateX(60%);
    transform: scale(0.6) translateX(60%);
  }
}
.keno-barometer-section.sticky.decrease-winnings .money-coin.coin-one {
  -webkit-animation: sticky-increase-winnings-coin-one-animation 1.4s reverse linear;
  -moz-animation: sticky-increase-winnings-coin-one-animation 1.4s reverse linear;
  -ms-animation: sticky-increase-winnings-coin-one-animation 1.4s reverse linear;
  animation: sticky-increase-winnings-coin-one-animation 1.4s reverse linear;
  animation-delay: 0.4s;
}
.keno-barometer-section.sticky.decrease-winnings .money-coin.coin-two {
  -webkit-animation: sticky-increase-winnings-coin-two-animation 1.4s reverse linear;
  -moz-animation: sticky-increase-winnings-coin-two-animation 1.4s reverse linear;
  -ms-animation: sticky-increase-winnings-coin-two-animation 1.4s reverse linear;
  animation: sticky-increase-winnings-coin-two-animation 1.4s reverse linear;
  animation-delay: 0.4s;
}
.keno-barometer-section.sticky.decrease-winnings .money-coin.coin-three {
  -webkit-animation: sticky-increase-winnings-coin-three-animation 1.4s reverse linear;
  -moz-animation: sticky-increase-winnings-coin-three-animation 1.4s reverse linear;
  -ms-animation: sticky-increase-winnings-coin-three-animation 1.4s reverse linear;
  animation: sticky-increase-winnings-coin-three-animation 1.4s reverse linear;
  animation-delay: 0.4s;
}
