.countdown {
  width: 100%;
  max-width: 88vw;
  text-align: center;
  margin: auto;
}
@media (min-width: 950px) {
  .countdown {
    max-width: 96rem;
  }
}
.countdown.hide {
  display: none;
}
.countdown.no-opendraw .countdown-headline {
  color: #fff;
}
.countdown.no-opendraw .number-container {
  color: #fff;
}
.countdown .number-container {
  color: #e0f4fd;
}
.countdown .countdown-headline {
  font-size: 3.75vw;
  font-weight: bold;
  text-transform: uppercase;
  color: #00a5eb;
}
@media (min-width: 950px) {
  .countdown .countdown-headline {
    font-size: 2rem;
  }
}
.countdown .number-container {
  display: inline-block;
  margin: 0 3vw;
  color: #1e55a0;
}
@media (min-width: 950px) {
  .countdown .number-container {
    margin: 0 1.5rem;
  }
}
.countdown .number-container > span {
  margin: 0 .2rem;
  display: initial;
}
.countdown .number-container .number {
  font-size: 7.5vw;
}
@media (min-width: 950px) {
  .countdown .number-container .number {
    font-size: 3.8rem;
    line-height: normal;
  }
}
div[data-component="keno-game-closed"] .keno-no-open-draw .keno-section .header-icon {
  margin-bottom: 1rem;
}
div[data-component="keno-game-closed"] .keno-no-open-draw .keno-section .header-icon svg {
  width: 10vw;
  height: 10vw;
}
@media (min-width: 950px) {
  div[data-component="keno-game-closed"] .keno-no-open-draw .keno-section .header-icon svg {
    width: 6.4rem;
    height: 6.4rem;
  }
}
div[data-component="keno-game-closed"] .keno-no-open-draw .keno-section.manchete.top {
  padding: 5rem 0 7rem;
}
div[data-component="keno-game-closed"] .keno-no-open-draw .keno-section .countdown .countdown-headline {
  color: #00a5eb;
}
div[data-component="keno-game-closed"] .keno-no-open-draw .keno-section .countdown .number-container {
  color: #1e55a0;
}
