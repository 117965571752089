@media (min-width: 950px) {
  .keno-game-promotion-spots-container {
    display: flex;
  }
}
@media (min-width: 950px) {
  .keno-game-promotion-spots-container .keno-fast-play {
    flex: 1;
  }
}
.keno-game-promotion-spots-container .keno-fast-play .keno-section {
  height: 100%;
}
.keno-game-promotion-spots-container .keno-fast-play .keno-section .fast-play-game-spot {
  background: transparent;
  padding: 12vw;
}
@media (min-width: 950px) {
  .keno-game-promotion-spots-container .keno-fast-play .keno-section .fast-play-game-spot {
    float: left;
    padding: 5rem 11rem 5rem;
  }
}
.keno-game-promotion-spots-container .keno-fast-play .keno-section .fast-play-game-spot .fastplay-headline {
  max-width: 86.5vw;
  font-size: 10.7vw;
  font-style: italic;
  font-weight: bold;
  line-height: 11.2vw;
  text-transform: uppercase;
  color: #1e55a0;
  margin: 0 auto 1vw;
}
@media (min-width: 950px) {
  .keno-game-promotion-spots-container .keno-fast-play .keno-section .fast-play-game-spot .fastplay-headline {
    font-size: 4rem;
    line-height: 4rem;
    margin-bottom: 1rem;
  }
}
.keno-game-promotion-spots-container .keno-fast-play .keno-section .fast-play-game-spot .fastplay-subheadline {
  max-width: 86.5vw;
  font-size: 5.4vw;
  font-style: italic;
  font-weight: bold;
  line-height: 5.9vw;
  text-transform: uppercase;
  color: #00a5eb;
  margin: 0 auto;
}
@media (min-width: 950px) {
  .keno-game-promotion-spots-container .keno-fast-play .keno-section .fast-play-game-spot .fastplay-subheadline {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}
.keno-game-promotion-spots-container .keno-fast-play .keno-section .fast-play-game-spot .btn.cta {
  margin: 4vw 0 0;
}
@media (min-width: 950px) {
  .keno-game-promotion-spots-container .keno-fast-play .keno-section .fast-play-game-spot .btn.cta {
    margin: 2rem 0 0;
  }
}
@media (min-width: 950px) {
  .keno-game-promotion-spots-container .keno-fast-play:first-of-type .keno-section .fast-play-game-spot {
    float: right;
  }
}
