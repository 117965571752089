.keno-section .purchase-bar-wrapper {
  width: 100%;
  height: 16.3vw;
  position: relative;
  z-index: 11;
  will-change: scroll-position, transform;
  transform: translate3d(0, 0, 0);
}
@media (min-width: 950px) {
  .keno-section .purchase-bar-wrapper {
    height: 7rem;
  }
}
@media screen and (max-width: 768px) and (orientation: landscape) {
  .keno-section .purchase-bar-wrapper {
    width: 100% !important;
  }
}
.keno-section .purchase-bar-wrapper .purchase-bar {
  width: 100%;
  height: 16.5vw;
  position: relative;
  font-size: 3.2vw;
  font-weight: bold;
}
@media (min-width: 950px) {
  .keno-section .purchase-bar-wrapper .purchase-bar {
    font-size: 1.4rem;
    height: 7rem;
  }
}
.keno-section .purchase-bar-wrapper .purchase-bar:before,
.keno-section .purchase-bar-wrapper .purchase-bar:after {
  content: "";
  width: calc((100% - 96rem) / 2);
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.95);
}
.keno-section .purchase-bar-wrapper .purchase-bar:before {
  left: 0;
}
.keno-section .purchase-bar-wrapper .purchase-bar:after {
  right: 0;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content {
  position: relative;
  text-align: left;
  background-color: inherit;
  width: 100%;
  max-width: 96rem;
  height: inherit;
  margin: auto;
  display: table;
  table-layout: fixed;
}
@media (min-width: 950px) {
  .keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content {
    height: 100%;
  }
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content a {
  line-height: inherit;
  display: block;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content .dropdown-wrapper {
  position: relative;
  padding: 0 5vw;
  line-height: 16.3vw;
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}
@media (min-width: 950px) {
  .keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content .dropdown-wrapper {
    padding: 0 3rem;
    line-height: 7rem;
  }
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content .dropdown-wrapper:hover {
  background-color: #ffffff;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content .dropdown-wrapper .arrow-up-icon {
  width: 3.467vw;
  height: 2.135vw;
  position: absolute;
  right: 5vw;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -ms-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content .dropdown-wrapper .arrow-up-icon.hide {
  opacity: 0;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content .dropdown-wrapper .arrow-up-icon svg {
  width: 3.467vw;
  height: 2.135vw;
}
@media (min-width: 950px) {
  .keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content .dropdown-wrapper .arrow-up-icon {
    width: 1.2rem;
    height: .7rem;
    top: 0;
    right: 2rem;
  }
  .keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content .dropdown-wrapper .arrow-up-icon svg {
    width: 1.2rem;
    height: .7rem;
    margin-left: 0;
  }
}
@media (min-width: 950px) {
  .keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content .dropdown-wrapper {
    line-height: 7rem;
  }
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div {
  width: 29%;
  height: inherit;
  display: table-cell;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.95);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (min-width: 950px) {
  .keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div {
    width: auto;
  }
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.number-of-draws {
  border-left: 0.1rem solid #cdd3d7;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div span {
  display: none;
}
@media (min-width: 950px) {
  .keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div span {
    display: inline;
  }
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div .select-stake,
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div .select-draws {
  position: absolute;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-back {
  text-align: center;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-cta {
  width: 42%;
  text-align: center;
  border-left: none;
  background-color: #feb700;
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}
@media (min-width: 950px) {
  .keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-cta {
    width: auto;
  }
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-cta:hover {
  background-color: #f5d900;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-cta .total-price {
  transition: transform .25s, opacity .25s;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-cta.is-inactive {
  cursor: default;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-cta.is-inactive:hover {
  background-color: #feb700;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-cta.is-inactive .total-price {
  -webkit-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-cta .purchase .purchase-button-loader-box {
  width: 100%;
  position: absolute;
  transform: translateY(-200%) scale(0.8);
  opacity: 0;
  transition: transform .25s, opacity .15s;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-cta .purchase .purchase-button-loader-box .loader-text {
  display: inline-block;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-cta .purchase .purchase-button-loader-box .loader-animation-box {
  margin-left: .5rem;
  display: inline-block;
}
@keyframes loader-dot-animation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100%);
  }
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-cta .purchase .purchase-button-loader-box .loader-animation-box .loader-dot {
  width: 1.2vw;
  height: 1.2vw;
  margin-left: .3rem;
  border-radius: 50%;
  background-color: #000;
  display: inline-block;
}
@media (min-width: 950px) {
  .keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-cta .purchase .purchase-button-loader-box .loader-animation-box .loader-dot {
    width: .8rem;
    height: .8rem;
  }
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-cta.processing .purchase .purchase-button-loader-box {
  transform: translateY(0) scale(1);
  opacity: 1;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-cta.processing .purchase .purchase-button-loader-box .loader-animation-box .loader-dot:nth-child(1n) {
  animation: loader-dot-animation 0.5s ease-in-out -0.66s infinite;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-cta.processing .purchase .purchase-button-loader-box .loader-animation-box .loader-dot:nth-child(2n) {
  animation: loader-dot-animation 0.5s ease-in-out -0.33s infinite;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-cta.processing .purchase .purchase-button-loader-box .loader-animation-box .loader-dot:nth-child(3n) {
  animation: loader-dot-animation 0.5s ease-in-out -1s infinite;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.purchase-bar-cta.processing .purchase .total-price {
  transform: translateY(100%) scale(0.8);
  opacity: .6;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div.auto {
  width: auto;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div .dropdown-list-wrapper {
  width: 100%;
  position: absolute;
  bottom: 16.3vw;
  left: 0;
  max-height: 0;
  transition: max-height 0.3s cubic-bezier(0.34, 1.61, 0.7, 1);
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 -0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem 0.5rem 0 0;
}
@media (min-width: 950px) {
  .keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div .dropdown-list-wrapper {
    bottom: 7rem;
  }
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div .dropdown-list-wrapper ul.dropdown {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
  overflow: hidden;
  position: relative;
}
@media (min-width: 950px) {
}
@media (min-width: 950px) {
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div .dropdown-list-wrapper ul.dropdown > li {
  line-height: 4.1vw;
  padding: 4vw 5vw;
  border-bottom: 0.1rem solid #cdd3d7;
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media (min-width: 950px) {
  .keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div .dropdown-list-wrapper ul.dropdown > li {
    line-height: normal;
    padding: 2.5rem 3rem;
  }
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div .dropdown-list-wrapper ul.dropdown > li:hover {
  background-color: #ffffff;
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div .dropdown-list-wrapper ul.dropdown > li span {
  display: none;
}
@media (min-width: 950px) {
  .keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div .dropdown-list-wrapper ul.dropdown > li span {
    display: inline;
  }
}
.keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div .dropdown-list-wrapper ul.dropdown > li:first-child {
  padding: 4.1vw 5vw;
  cursor: default;
  background-color: #fff;
  color: #a3a6ab;
}
@media (min-width: 950px) {
  .keno-section .purchase-bar-wrapper .purchase-bar .purchase-bar-content > div .dropdown-list-wrapper ul.dropdown > li:first-child {
    padding: 2.5rem 3rem;
  }
}
.keno-section .purchase-bar-wrapper.fixed .purchase-bar {
  position: fixed;
  bottom: 0;
}
@media (min-width: 950px) {
  .keno-section .purchase-bar-wrapper.fixed {
    bottom: 3rem;
  }
}
