@import url("../Mixins/_mixins.less");

.keno-winning-numbers .keno-section {

  padding:12vw 0;
  @media (min-width: @keno-desktop-width) {
    padding:5rem 0;
  }

  .news-heading {
    @media (min-width: @keno-desktop-width) {
      padding: 14rem 0 6rem;
    }
  }

  .keno-content-wrapper {
    &.bring-to-front {
      z-index: 3;
    }
    .select-date-section {
      width:86.667vw;
      margin:auto;
      position:relative;
      @media (min-width: @keno-desktop-width) {
        width:58rem;
      }

      h2.hd {
        font-size:5.333vw;
        margin-bottom: 2rem;
        @media (min-width: @keno-desktop-width) {
          font-size:2rem;
        }
      }
      .select-dropdown {
        max-width: 100%;
      }
    }

    .ticket-config {
      .clearfix();
      @media (min-width: @keno-desktop-width) {
        margin-bottom:3rem;
      }
      .box-half {
        @media (min-width: @keno-desktop-width) {
          width:46.875%;
          &:first-of-type {
            float:left;
          }
          &:last-of-type {
            float:right;
          }
        }
      }
    }
    h3.hd {
      font-size:5.333vw;
      padding:4vw 0 2.4vw;
      @media (min-width: @keno-desktop-width) {
        font-size:2rem;
        padding:3rem 0;
      }
    }

    .number-picker-container {
      padding:0 15.6vw;
      @media (min-width: @keno-desktop-width) {
        padding:0;
      }
      .cell {
        margin:2.6vw;
        @media (min-width: @keno-desktop-width) {
          margin: 0.43rem;
        }
      }
    }
  }
}
