// bring in mixins  
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

.keno-level-slider-section {
  @media (min-width: @keno-desktop-width) {
    background-color:transparent;
    margin-bottom:9rem;
    position:relative;
    &:before, &:after {
      content: '';
      height: 30rem;
      width: 100vw;
      position: absolute;
      bottom: -6rem;
      z-index: -1;
      background-color: #0891d8;
    }
    &:before {
      left: 50%;
      .transform(translateX(-100%) rotate(8deg));
      .transform-origin(bottom, right);
    }
    &:after {
      right: 50%;
      .transform(translateX(100%) rotate(-8deg));
      .transform-origin(bottom, left);
    }
  }

  .game-heading {
    display: block;
    width: 100%;
    text-align: center;
    margin: auto;
    padding-top: 7vw;
    padding-bottom: 7vw;
    border-bottom: .1rem solid @keno-powderblue;
    font-size: 5vw;
    font-weight: bold;
    font-style: italic;
    color: #fff;
    line-height: .8;
    @media (min-width: @keno-desktop-width) {
      padding-top: 3rem;
      padding-bottom: 2.5rem;
      width: 58rem;
      font-size: 2rem;
      color: #fff;
    }
  }

  .logo-container {
    width: 75.235%;
    text-align:center;
    line-height:0;
    margin:auto;
    padding:6.266vw 0 5.733vw;
    position:relative;
    @media (min-width: @keno-desktop-width) {
      width: 58rem;
      padding:3.3rem 0 2.7rem;
      border-bottom:.1rem solid @keno-powderblue;
    }
  }

  .headline {
    position: relative;
    margin:auto;
    font-size:4vw;
    font-weight:bold;
    color:@keno-royalblue;
    text-align:center;
    text-transform:uppercase;
    line-height:.8;
    padding:3.866vw 0 0;
    background-color:#fff;
    @media (min-width: @keno-desktop-width) {
      width: 58rem;
      font-size:2rem;
      color:#fff;
      padding:3.9rem 0 0;
      background-color:transparent;
      padding-top: 3rem;
    }
  }

  .keno-slider-selector {
    width: 100%;
    text-align:center;
    margin:-.1vw auto 0;
    padding:1.733vw 0 5.333vw;
    background-color:#fff;
    position:relative;
    @media (min-width: @keno-desktop-width) {
      width: 58rem;
      margin:auto;
      padding:1rem 0 0;
      background-color:transparent;
    }
  }
}