.keno-no-open-draw .keno-section {
  padding: 5rem 0 7rem;
}
.keno-no-open-draw .keno-section.manchete.top {
  padding: 0;
}
.keno-no-open-draw .keno-section .no-opendraw-headline {
  max-width: 86.5vw;
  font-size: 5.4vw;
  font-weight: bold;
  font-style: italic;
  line-height: 5.6vw;
  color: #fff;
  text-transform: uppercase;
  margin: 0 auto;
}
@media (min-width: 950px) {
  .keno-no-open-draw .keno-section .no-opendraw-headline {
    font-size: 3.5rem;
    line-height: 4.2rem;
  }
}
.keno-no-open-draw .keno-section .no-opendraw-subheadline {
  max-width: 86.5vw;
  font-size: 4.4vw;
  font-weight: bold;
  font-style: italic;
  line-height: 6vw;
  color: #fff;
  text-transform: uppercase;
  padding-top: 1vw;
  margin: 0 auto;
}
@media (min-width: 950px) {
  .keno-no-open-draw .keno-section .no-opendraw-subheadline {
    font-size: 2.5rem;
    line-height: 3rem;
    padding-top: 1rem;
  }
}
.keno-no-open-draw .keno-section .no-opendraw-subheadline.content {
  color: #00a5eb;
}
@media (min-width: 950px) {
  .keno-no-open-draw .keno-section .no-opendraw-subheadline.content {
    font-size: 2.5rem;
  }
}
