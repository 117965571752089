@media (min-width: 950px) {
  .keno-level-slider-section {
    background-color: transparent;
    margin-bottom: 9rem;
    position: relative;
  }
  .keno-level-slider-section:before,
  .keno-level-slider-section:after {
    content: '';
    height: 30rem;
    width: 100vw;
    position: absolute;
    bottom: -6rem;
    z-index: -1;
    background-color: #0891d8;
  }
  .keno-level-slider-section:before {
    left: 50%;
    -webkit-transform: translateX(-100%) rotate(8deg);
    -moz-transform: translateX(-100%) rotate(8deg);
    -ms-transform: translateX(-100%) rotate(8deg);
    -o-transform: translateX(-100%) rotate(8deg);
    transform: translateX(-100%) rotate(8deg);
    -webkit-transform-origin: bottom right;
    -moz-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    -o-transform-origin: bottom right;
    transform-origin: bottom right;
  }
  .keno-level-slider-section:after {
    right: 50%;
    -webkit-transform: translateX(100%) rotate(-8deg);
    -moz-transform: translateX(100%) rotate(-8deg);
    -ms-transform: translateX(100%) rotate(-8deg);
    -o-transform: translateX(100%) rotate(-8deg);
    transform: translateX(100%) rotate(-8deg);
    -webkit-transform-origin: bottom left;
    -moz-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    -o-transform-origin: bottom left;
    transform-origin: bottom left;
  }
}
.keno-level-slider-section .game-heading {
  display: block;
  width: 100%;
  text-align: center;
  margin: auto;
  padding-top: 7vw;
  padding-bottom: 7vw;
  border-bottom: 0.1rem solid #00a5eb;
  font-size: 5vw;
  font-weight: bold;
  font-style: italic;
  color: #fff;
  line-height: .8;
}
@media (min-width: 950px) {
  .keno-level-slider-section .game-heading {
    padding-top: 3rem;
    padding-bottom: 2.5rem;
    width: 58rem;
    font-size: 2rem;
    color: #fff;
  }
}
.keno-level-slider-section .logo-container {
  width: 75.235%;
  text-align: center;
  line-height: 0;
  margin: auto;
  padding: 6.266vw 0 5.733vw;
  position: relative;
}
@media (min-width: 950px) {
  .keno-level-slider-section .logo-container {
    width: 58rem;
    padding: 3.3rem 0 2.7rem;
    border-bottom: 0.1rem solid #00a5eb;
  }
}
.keno-level-slider-section .headline {
  position: relative;
  margin: auto;
  font-size: 4vw;
  font-weight: bold;
  color: #1e55a0;
  text-align: center;
  text-transform: uppercase;
  line-height: .8;
  padding: 3.866vw 0 0;
  background-color: #fff;
}
@media (min-width: 950px) {
  .keno-level-slider-section .headline {
    width: 58rem;
    font-size: 2rem;
    color: #fff;
    padding: 3.9rem 0 0;
    background-color: transparent;
    padding-top: 3rem;
  }
}
.keno-level-slider-section .keno-slider-selector {
  width: 100%;
  text-align: center;
  margin: -0.1vw auto 0;
  padding: 1.733vw 0 5.333vw;
  background-color: #fff;
  position: relative;
}
@media (min-width: 950px) {
  .keno-level-slider-section .keno-slider-selector {
    width: 58rem;
    margin: auto;
    padding: 1rem 0 0;
    background-color: transparent;
  }
}
