﻿@import url("../Mixins/_mixins.less");

.keno-confirm {
  .keno-section {
    background-color: @keno-white;
    border-color: @keno-white;

    .btn {
      margin: 6rem 1rem 2rem;
    }

    .cta-deposit {
      margin: 0 0 2rem;
    }

    .keno-row-display {
      padding: 2vw 0 0;

      .alt-text {
        font-size: 3.35vw;
        color: @keno-darkgrey;
        padding-bottom: 3vw;
        margin-top: 0;

        @media (min-width: @keno-desktop-width) {
          font-size: 2rem;
          padding-bottom: 3rem;
        }
      }

      .row-title {
        max-width: unset;
        font-size: 4.5vw;
        font-weight: bold;
        text-transform: uppercase;
        color: @keno-dodgerblue;
        line-height: 8vw;
        padding: 3vw 0;
        margin: 0 auto;

        @media (min-width: @keno-mobile-width) and (max-width: @keno-desktop-width) {
          font-size: 4vw;
          line-height: 5vw;
          padding: 1.6vw 0;
        }

        @media (min-width: @keno-desktop-width) {
          max-width: 58rem;
          font-size: 2rem;
          line-height: 2.4rem;
          border-bottom: 1px solid @keno-mediumblue;
          padding: 1.2rem 0;
        }

        .multiple-draws {
          display: block;
        }

        &:nth-child(even) {
          background-color: @keno-lightskyblue;

          @media (min-width: @keno-desktop-width) {
            background-color: @keno-white;
          }
        }

        &:last-of-type {
          border-bottom: none;

          @media (min-width: @keno-desktop-width) {
            padding-bottom: 1.2rem;
          }
        }

        .row-content {
          font-weight: bold;
          font-style: italic;
          color: @keno-royalblue;
          text-transform: uppercase;

          &.numbers {
            display: block;
          }
        }
      }

      .rows-wrapper {
        max-height: 44vw;
        overflow-y: hidden;
        transition: max-height .25s linear;
        position: relative;
        margin-bottom: 4vw;

        @media (min-width: @keno-mobile-width) and (max-width: @keno-desktop-width) {
          max-height: 26vw;
        }

        @media (min-width: @keno-desktop-width) {
          max-height: 14rem;
          margin-bottom: 4rem;
        }

        .row-title:first-child {
          @media (min-width: @keno-desktop-width) {
            border-top: 1px solid @keno-mediumblue;
          }
        }

        &.expanded {
          .gradient-wrapper {
            display: none;
          }
        }

        .gradient-wrapper {
          width: 100%;
          height: 22vw;
          background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.65) 35%,rgba(255,255,255,0.8) 70%,rgba(255,255,255,0.95) 100%);
          position: absolute;
          bottom: 0;
          left: 0;

          @media (min-width: @keno-mobile-width) and (max-width: @keno-desktop-width) {
            height: 13vw;
          }

          @media (min-width: @keno-desktop-width) {
            height: 7rem;
          }

          & .show-more-button {
            min-width: 32vw;
            font-size: 3.8vw;
            font-weight: bold;
            line-height: 10.7vw;
            text-transform: uppercase;
            color: @keno-darkgrey;
            background-color: @keno-cta-yellow;
            border-radius: 5px;
            display: inline-block;
            padding: 0 4vw;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            user-select: none;
            margin: 0;
            transition: background-color .15s linear;

            @media (min-width: @keno-mobile-width) and (max-width: @keno-desktop-width) {
              font-size: 3vw;
              line-height: 8vw;
            }

            @media (min-width: @keno-desktop-width) {
              min-width: 12rem;
              font-size: 1.4rem;
              line-height: 4rem;
              padding: 0 1.2rem;
            }

            &:hover {
              @media (min-width: @keno-desktop-width) {
                background-color: @keno-cta-yellow-hover;
              }
            }
          }
        }
      }

      .terms-container {
        max-width: 86.5vw;
        font-size: 3vw;
        color: @keno-darkgrey;
        margin: 0 auto;
        margin-bottom: 7vw;

        @media (min-width: @keno-desktop-width) {
          font-size: 1.5rem;
          margin-bottom: 4rem;
        }

        .terms-link {
          color: @keno-darkgrey;
          display: block;

          @media (min-width: @keno-desktop-width) {
            display: inline-block;
          }
        }
      }
    }
  }
}
