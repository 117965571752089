﻿@import (reference) "Mixins/_mixins.less";

.btn {
  box-sizing: border-box;
  outline: none;
  border: none;
  border-radius: 1vw;
  cursor: pointer;
  color: #4f5254;
  font-weight: bold;
  font-size: 3.8vw;
  line-height: 10.7vw;
  padding: 0 4vw;
  display: inline-block;
  position: relative;
  vertical-align: top;
  text-transform: uppercase;
  text-decoration: none;
  background-color: @keno-btn-color;

  &:hover {
    background-color: @keno-btn-color-hover;
  }

  &.dogderblue {
    background-color: @keno-dodgerblue;
    color: #fff;
  }

  &.powderblue {
    background-color: @keno-powderblue;
    color: #fff;
  }

  &.cta {
    background-color: @keno-cta-yellow;
    color: #322b00;
  }

  &.grey {
    color: #fff;
    background-color: #a3a6ab;
  }

  &.fixed-width-120 {
    width: 33.4vw;
  }

  &.fixed-width-140 {
    width: 37.35vw;
  }

  &.fixed-width-160 {
    width: 44vw;
    padding: 0;
  }

  &.close {
    position: absolute;
    right: 0;
    width: 4.7vw;
    height: 4.7vw;
    padding: 0;
    background-color: transparent;

    &:before,
    &:after {
      content: ' ';
      position: absolute;
      left: 45%;
      top: -15%;
      display: block;
      height: 6vw;
      width: 0.7vw;
      background-color: #4f5254;
    }

    &:before {
      transform: rotate(45deg) translateZ(0);
    }

    &:after {
      transform: rotate(-45deg) translateZ(0);
    }
  }
  // Desktop
  @media @md {
    font-size: 1.4rem;
    line-height: 4rem;
    padding: 0 2rem;
    border-radius: .5rem;

    &.fixed-width-120 {
      width: 12rem;
    }

    &.fixed-width-140 {
      width: 14rem;
    }

    &.fixed-width-160 {
      width: 16rem;
    }

    &.close {
      width: 2rem;
      height: 2rem;

      &:before,
      &:after {
        height: 2.6rem;
        width: .2rem;
      }
    }
  }
}


// Checkbox:
// currently only an iOS wannabe skin exists
@keno-radio-switch-radius: 7.3vw;
@keno-radio-switch-width: 14vw;
@keno-radio-switch-border-width: 0.6vw;

@keno-desktop-radio-switch-radius: 2.8rem;
@keno-desktop-radio-switch-width: 6rem;
@keno-desktop-radio-switch-border-width: 0.2rem;

.keno-checkbox {
  text-align: left; // this should not be neccessary, we should remove text align from parent
  position: relative;
  color: #999;
  display: inline-block;

  > label {
    cursor: pointer;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    .user-select(none);
  }
  // iOS-like switch, I summon thee!
  .ios-skin {
    float: left;
    position: relative;
    display: inline-block;
    height: @keno-radio-switch-radius;
    cursor: pointer;

    > input[type="checkbox"] {
      box-sizing: border-box;
      padding: 0;
    }

    > input {
      line-height: normal;
      position: absolute;
      z-index: -1;
      visibility: hidden;
      opacity: 0;
      font-family: inherit;
      font-size: 100%;
      margin: 0;
    }
  }
  // Body of the checkbox wherein the switch
  // lives and moves
  .ios-skin .ios-skin_body {
    position: relative;
    display: inline-block;
    width: @keno-radio-switch-width;
    height: @keno-radio-switch-radius;
    border: @keno-radio-switch-border-width solid #fff;
    border-radius: @keno-radio-switch-radius;
    box-sizing: border-box;
    background-color: #fff;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: background-color 250ms cubic-bezier(.34, 1.61, .7, 1);
    transition: background-color 250ms cubic-bezier(.34, 1.61, .7, 1);
  }
  // The switch is either found on the left or
  // the right side, depending on the state
  @keno-radio-switch-pos: @keno-radio-switch-width - @keno-radio-switch-radius;

  .ios-skin > :checked ~ .ios-skin_body .ios-skin_switch {
    -webkit-transform: translateX(@keno-radio-switch-pos);
    transform: translateX(@keno-radio-switch-pos);
  }
  // A checkmark or a dash is visible
  // in the empty space of the body of
  // the checkbox
  @keno-radio-checkmark-length: 2.9vw;
  @keno-radio-checkmark-width: 0.8vw;
  @keno-desktop-radio-checkmark-length: 1.5rem;
  @keno-desktop-radio-checkmark-width: 0.4rem;

  .ios-skin .checkmark {
    position: absolute;
    right: 5.2vw;
    bottom: 3.9vw;
    z-index: 1;

    @media @md {
      width: @keno-desktop-radio-checkmark-width;
      height: @keno-desktop-radio-checkmark-length;
      right: 0;
      bottom: 0.1rem;
      display: inline-block;
      margin: -1.3rem 2.2rem 0 0;
    }
    // Unchecked checkboxes show a ´dash´
    &:after {
      content: "";
      position: absolute;
      background-color: @keno-dodgerblue;
      height: @keno-radio-checkmark-width;
      width: @keno-radio-checkmark-length;
    }
  }

  .ios-skin > :checked ~ .checkmark {
    .rotate(225deg);
    right: 10.6vw;
    bottom: 2.2vw;
    margin: -1vw 0 0 2.5vw;

    @media @md {
      right: 4rem;
      bottom: 0.6rem;
      margin: -0.8rem 0 0 1.8rem;
    }
    // Checked checkboxes show a ´checkmark´
    &:before {
      content: "";
      position: absolute;
      width: @keno-radio-checkmark-width;
      height: @keno-radio-checkmark-length;
      background-color: @keno-dodgerblue;
    }

    &:after {
      width: @keno-radio-checkmark-length / 3 * 2;
    }
  }
  // The circular switch that lives inside
  // the body and moves from side to side
  .ios-skin .ios-skin_switch {
    position: absolute;
    top: -@keno-radio-switch-border-width;
    left: -@keno-radio-switch-border-width;
    display: inline-block;
    width: @keno-radio-switch-radius;
    height: @keno-radio-switch-radius;
    border: @keno-radio-switch-border-width solid #fff;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: @keno-dodgerblue;
    box-shadow: 0 .2rem .2rem rgba(0,0,0,0.13);
    // Bounces back and fourth cuz of the lolz
    -webkit-transition: 250ms cubic-bezier(.34, 1.61, .7, 1);
    transition: 250ms cubic-bezier(.34, 1.61, .7, 1);
  }
  // Backgorund color changes when changing
  // the state of the checkbox
  .ios-skin > :not(:checked) ~ .ios-skin_body {
    border-color: @keno-mediumblue;
    background-color: @keno-mediumblue;
  }

  .ios-skin > :not(:checked) ~ .ios-skin_body .ios-skin_switch {
    border-color: @keno-mediumblue;
  }
  // Optional: label following the checkbox
  label:not(.ios-skin) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: @keno-radio-switch-radius;
    line-height: @keno-radio-switch-radius;
    padding: 0 0 0 @keno-radio-switch-radius/2 + @keno-radio-switch-width;
    text-align: left;
    .user-select(none);
  }
  // Desktop
  @media @md {
    @keno-radio-switch-radius: 2.8rem;
    @keno-radio-switch-width: 6rem;
    @keno-radio-switch-border-width: .2rem;

    .ios-skin {
      height: @keno-radio-switch-radius;
    }

    .ios-skin .ios-skin_body {
      width: @keno-radio-switch-width;
      height: @keno-radio-switch-radius;
      border: @keno-radio-switch-border-width solid #fff;
      border-radius: @keno-radio-switch-radius;
    }

    @keno-radio-switch-pos: @keno-radio-switch-width - @keno-radio-switch-radius;

    .ios-skin > :checked ~ .ios-skin_body .ios-skin_switch {
      -webkit-transform: translateX(@keno-radio-switch-pos);
      transform: translateX(@keno-radio-switch-pos);
    }

    @keno-radio-checkmark-length: 1.5rem;
    @keno-radio-checkmark-width: .4rem;

    .ios-skin .checkmark {
      width: @keno-radio-checkmark-width;
      height: @keno-radio-checkmark-length;
      margin: -1.3rem 2.2rem 0 0;

      &:after {
        background-color: @keno-dodgerblue;
        height: @keno-radio-checkmark-width;
        width: @keno-radio-checkmark-length;
      }
    }

    .ios-skin > :checked ~ .checkmark {
      margin: -.8rem 0 0 2.2rem;

      &:before {
        width: @keno-radio-checkmark-width;
        height: @keno-radio-checkmark-length;
        background-color: @keno-dodgerblue;
      }

      &:after {
        width: @keno-radio-checkmark-length / 3 * 2;
      }
    }

    .ios-skin .ios-skin_switch {
      top: -@keno-radio-switch-border-width;
      left: -@keno-radio-switch-border-width;
      width: @keno-radio-switch-radius;
      height: @keno-radio-switch-radius;
      border: @keno-radio-switch-border-width solid #fff;
      box-shadow: 0 .2rem .2rem rgba(0,0,0,0.13);
    }

    label:not(.ios-skin) {
      height: @keno-radio-switch-radius;
      line-height: @keno-radio-switch-radius;
      padding: 0 0 0 @keno-radio-switch-radius/2 + @keno-radio-switch-width;
    }
  }
}


.select-dropdown {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  z-index: 10;
  width: 100%;
  max-width: 50rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.5em;
  font-weight: 700;
  color: @keno-royalblue;

  &:after {
    content: '';
    height: 1rem;
    background-color: @keno-cadetblue;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50%;
    z-index: -1;
    transition: bottom .05s .2s, background-color .05s;
  }

  select {
    display: none;
  }

  .cs-placeholder {
    background-color: @keno-cadetblue;
    border-radius: .6rem;
    transition: background-color .15s;

    .select-dropdown-arrow {
      width: 4vw;
      height: 2.5vw;
      fill: @keno-royalblue;
      position: absolute;
      top: 6.8vw;
      right: 4vw;
      transition: transform .15s linear;

      @media @md {
        width: 1.3rem;
        height: .8rem;
        top: 2.5rem;
        right: 2.4rem;
      }
    }
  }

  span {
    display: block;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: .6rem 2.5rem .2rem;
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 5rem;
    /*
        border-top: .1rem solid @keno-cadetblue;
        border-bottom: .1rem solid @keno-cadetblue;
				*/
  }

  .select-options {
    max-height: 0;
    background: @keno-cadetblue;
    pointer-events: none;
    position: absolute;
    overflow: hidden;
    width: 100%;
    border-radius: 0 0 .6rem .6rem;
    transition: max-height .25s;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        border-top: .1rem solid @keno-lightskyblue;

        &:hover {
          background-color: @keno-mediumblue;
        }
      }
    }
  }

  &.reversed {
    &:after {
      top: 50%;
      transition: top .05s .2s;
    }

    .select-options {
      top: auto;
      bottom: 6rem;
      border-radius: .6rem .6rem 0 0;


      ul {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
  // when active!
  &.is-active {
    z-index: 11;

    &:after {
      bottom: -.4rem;
      background-color: #92d3ef;
      transition: bottom .0s .0s, background-color 0s 0s;
    }

    .cs-placeholder {
      background-color: #92d3ef;

      .select-dropdown-arrow {
        transform: translateY(100%);
      }
    }

    .select-options {
      top: 6rem;
      pointer-events: auto;

      &.overflowed {
        overflow-y: auto;
      }
    }

    &.reversed {
      &:after {
        bottom: inherit;
        top: -.4rem;
        transition: top .0s .0s;
      }

      .cs-placeholder {
        .select-dropdown-arrow {
          transform: translateY(-100%) rotate(180deg);
        }
      }

      .select-options {
        top: auto;
      }
    }

  }
}


//sitecore related classes
.keno-cta-button {
  box-sizing: border-box;
  outline: none;
  border: none;
  border-radius: 1vw;
  cursor: pointer;
  font-weight: bold;
  font-size: 3.8vw;
  line-height: 10.7vw;
  padding: 0 4vw;
  display: inline-block;
  position: relative;
  vertical-align: top;
  text-transform: uppercase;
  text-decoration: none;
  background-color: @keno-cta-yellow;
  color: #322b00;

  @media @md {
    font-size: 1.4rem;
    line-height: 4rem;
    padding: 0 1.2rem;
    border-radius: .5rem;
  }
}
