@import (reference) "Mixins/_mixins.less";

.icon-circle-container {
  width: 13.5vw;
  height: 13.5vw;
  background-color: #fff;
  border-radius: 50%;
  margin: 0 auto 6.5vw;
  @media (min-width: @keno-desktop-width) {
    width: 5rem;
    height: 5rem;
    margin: 0 auto 3rem;
  }
  .icon {
    position: relative;
    svg {
      position: absolute;
      fill: @keno-dodgerblue;
           
      // book svg icon
      &.svg-book {
        width: 6.667vw;
        height: 6.667vw;
        top: 5.5vw;
        left: -3.3vw;
        @media (min-width: @keno-desktop-width) {
          width: 2.5rem;
          height: 2.5rem;
          top: 2.3rem;
          left: -1.2rem;
        }
      }

      // moneybag svg icon
      &.svg-moneybag {
        top: 1.4rem;
        left: -1.3rem;
        width: 2.7rem;
        height: 4.4rem;
        @media (min-width: @keno-desktop-width) {
          top: 1.4rem;
          left: -1.3rem;
          width: 2.7rem;
          height: 4.4rem;
        }
      }
    } 
  }
}

.arrow-icon {
  width: 3.467vw;
  height: 2.135vw;
  position: absolute;
  right: 4.5vw;
  .transition(opacity 100ms linear);
  &.hide {
    opacity: 0;
  }
  svg {
    width: 3.467vw;
    height: 2.135vw;
  }
  @media (min-width: @keno-desktop-width) {
    width: 1.2rem;
    height: .7rem;
    top: 0;
    right: 2rem;
    svg {
      width: 1.2rem;
      height: .7rem;
    }
  }
  &.up {
    // default
  }
  &.down {
    svg {
      .rotate(180deg);
    }
  }
}
 

.icon {
  display: inline-block;
  vertical-align: top;
  // margin to separate icon from text
  &.right {
    margin-left: 0.8rem;
  }
  &.left {
    margin-right: 0.8rem;
  }
  // float icons to either side
  &.float-right {
    float: right;
  }
  &.float-left {
    float: left;
  }

  // update arrow
  .update-rows__icon {
    width:4vw;
    height:4.7vw;
    fill:#4f5254 ;
    position: relative;
    top: 0.2rem;
    @media (min-width: @keno-desktop-width) {
      width:1.6rem;
      height:1.8rem;
    }
  }

  // downarrow
  .downarrow__icon {
    width:4vw;
    height:4.7vw;
    fill:#4f5254 ;
    position: relative;
    top: 0.2rem;
    @media (min-width: @keno-desktop-width) {
      width:1.6rem;
      height:1.8rem;
    }
    &.up {
      .rotate(180deg);
    }
  }

  .svg-icon-bell {
    width:22.6vw;
    height:20.5vw;
    fill:#1E55A0 ;
    position: relative;
    top: -0.9vw;
    left: -2.8vw;
    @media (min-width: @keno-desktop-width) {
      width: 12.6rem;
      height: 7.8rem;
      top: -0.3rem;
      left: -3.05rem;
    }
  }

  .svg-icon-checkmark {
    width: 17.6vw;
    height: 15.5vw;
    fill: @keno-dodgerblue;
    position: relative;
    top: 1.4vw;
    left: 0.2vw;
    @media (min-width: @keno-desktop-width) {
      width: 10.6rem;
      height: 5.8rem;
      top: 0.6rem;
      left: -2.0rem;
    }
  }

}

.svg-icon-arrow-up {
  width: 3.467vw;
  height: 2.135vw;
  position: relative;
  .transition(opacity .15s linear);
  &.hide {
    opacity: 0;
  }
  svg {
    width: 3.467vw;
    height: 2.135vw;
  }
  @media (min-width: @keno-desktop-width) {
    width: 1.2rem;
    height: .7rem;
    top: -.1rem;
    right: 0;
    margin-left: 1rem;
    svg {
      width: 1.2rem;
      height: .7rem;
    }
  }
}

//////////////////////////////////////////////////////
//               sitecore header icons              //
//////////////////////////////////////////////////////
svg.checkmark-in-circle {
  width: 13.5vw;
  height: 13.5vw;
  margin: 0 auto;
  @media (min-width: @keno-desktop-width) {
    width: 5rem;
    height: 5rem;
  }
}
svg.keno-logo {
  width: 33vw;
  height: 7vw;
  margin: 0 auto;
  @media (min-width: @keno-desktop-width) {
    width: 13rem;
    height: 3.2rem;
    margin: 0 auto 1rem;
  }
}
svg.i-in-circle {
  width: 13.5vw;
  height: 13.5vw;
  margin: 0 auto;
  @media (min-width: @keno-desktop-width) {
    width: 5rem;
    height: 5rem;
  }
}
svg.news-icon-circle,
svg.news-icon-circle-blue {
  width: 13.5vw;
  height: 13.5vw;
  margin: 0 auto;
  @media (min-width: @keno-desktop-width) {
    width: 5rem;
    height: 5rem;
  }
}
svg.keno-classic-logo {
  width: 40vw;
  height: 5vw;
  @media (min-width: @keno-desktop-width) {
    width: 17.5rem;
    height: 2rem;
  }
}
svg.system-keno {
  width: 40vw;
  height: 5vw;
  @media (min-width: @keno-desktop-width) {
    width: 17.5rem;
    height: 2rem;
  }
}
svg.vaelgselv-keno {
  width: 40vw;
  height: 5vw;
  @media (min-width: @keno-desktop-width) {
    width: 17.5rem;
    height: 2rem;
  }
}