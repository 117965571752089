﻿@import url("../variables.less");
 
.top-navigation-theme-keno {
  .menu-toggle-right {
    background-color: #00a5eb;
  }

  .brand-nav {
    background-color: #00a5eb;
  }

  .corporate-nav {
    background-color: #0091cf;

    .accountmenu {
      .choose-account-btn {
        display: none;
      }
    }
  }

  .mobile-navigation {
    &-header {
      background-color: #00a5eb;
    }

    &-footer {
      &__icon {
        background-color: #00a5eb;
      }
    }

    &-item {
      &-link {
        &:before {
          background-color: #00a5eb;
        }
      }
    }
  }

  .user-info {
    background-color: #0084BC;
  }



  .mega-menu {
    &__campaign {
      &__cta-button {
        background-color: #00a5eb;
        color: white;

        &:hover {
          opacity: .9;
        }
      }
    }
  }
}
