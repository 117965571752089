@import url("../Mixins/_mixins.less");

.plus-sign-up {
  background: @ds-white;
  text-align: center;
}

.plus-sign-up__content {
  position: relative;
  background-color: @keno-powderblue;
  padding: 5.33vw 1rem;
  overflow: hidden;

  @media @md {
    padding: 2.5rem 1rem 3rem;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    opacity: 0.1;
    transform: rotate(-20deg);
    bottom: -6rem;
    height: 20rem;
    right: auto;
    width: 20rem;
    left: auto;
    margin-left: 20rem;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url(/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoCommonIcons.svg#plus-icon_use);
  }

  &::before {
    bottom: auto;
    top: -4rem;
    margin: 0 0 0 -55rem;
  }
}

.plus-sign-up__pre-text {
  font-size: 6.13vw;
  font-weight: bold;
  color: @keno-royalblue;
  padding: 2.66vw;

  @media @md {
    padding: 1rem;
    font-size: 2.5rem;
  }
}

.plus-sign-up__title {
  font-size: 6.66vw;
  line-height: 1.1;
  font-weight: bold;
  color: @ds-white;
  padding: 0 0 5.33vw;

  @media @md {
    padding: 0 0 1.5rem;
    font-size: 3rem;
  }
}

.plus-sign-up__subtitle {
  font-size: 6.13vw;
  line-height: 1.1;
  font-weight: bold;
  color: @ds-white;
  padding: 0 5.33vw 5.33vw;

  @media @md {
    padding: 0 0 3rem;
    font-size: 2.3rem;
  }
}

.plus-sign-up__cta {
  min-width: 44vw;

  @media @md {
    min-width: 16rem;
  }
}

.plus-sign-up__icon {
  background-image: url(/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoCommonIcons.svg#plus-icon_use);
  width: 18vw;
  height: 18vw;
  background-position: center;
  margin: 0 auto 6vw;
  background-repeat: no-repeat;
  background-size: 100%;

  @media @md {
    width: 6.5rem;
    height: 6.5rem;
    margin-bottom: 2rem;
  }
}
