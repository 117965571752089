.blue-table {
  width: 86.667vw;
  table-layout: fixed;
  border: 0.1rem solid #00a5eb;
  border-radius: 6px;
  border-collapse: inherit;
  margin: 0 6.666vw;
}
@media (min-width: 950px) {
  .blue-table {
    width: 100%;
    margin: 0;
  }
  .blue-table.mobile-hide {
    display: table;
  }
}
.blue-table th {
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}
.blue-table td:first-child,
.blue-table th:first-child {
  border-left: none;
}
.blue-table thead th {
  border-left: .1rem solid #0c85cd;
  background-color: #00a5eb;
  font-size: 4vw;
  padding: 4.4vw 0 4vw;
}
@media (min-width: 950px) {
  .blue-table thead th {
    font-size: 1.8rem;
    padding: 1.7rem 0 1.4rem;
  }
}
@media (min-width: 950px) {
  .blue-table thead.smaller-font-size th {
    font-size: 1.3rem;
  }
}
.blue-table tbody th {
  font-size: 2.666vw;
  padding: 2.666vw 0 2.666vw;
  background-color: #0891d8;
  border-left: .1rem solid #0c85cd;
}
.blue-table tbody th.empty {
  background-color: transparent;
}
@media (min-width: 950px) {
  .blue-table tbody th {
    font-size: 1.3rem;
    padding: 2rem 0 1.8rem;
  }
}
.blue-table tbody td {
  font-size: 3.466vw;
  padding: 3.866vw 0 3.666vw;
  color: #4f5254;
  background-color: transparent;
  border-left: 0.1rem solid #00a5eb;
  transition: color 0.25s, background-color 0.25s;
}
@media (min-width: 950px) {
  .blue-table tbody td {
    font-size: 1.5rem;
    padding: 1.9rem 0 1.7rem;
  }
}
.blue-table tbody tr.selected td {
  font-weight: bold;
  color: #1e55a0;
  background-color: #fff;
}
.blue-table tbody tr.bottom-border td {
  border-bottom: 0.1rem solid #00a5eb;
}
