﻿@import url("../../Mixins/_mixins.less");

.date-picker-wrapper {
  .date-picker-placeholder-wrapper {
    background-color: @keno-cadetblue;
    border-color: transparent;

    .date-title {
      color: @keno-royalblue;
    }

    .icon-arrow {
      fill: @keno-royalblue;
    }
  }

  .date-picker-calendar-outer-wrapper {
    border-color: @keno-powderblue;
    background-color: @numbergames-white;

    .date-picker-calendar-wrapper {
      border-color: @keno-powderblue;


      .calendar-cell-wrapper {

        &:not(.weeknr):not(.inactive) {

          &.selected .day-wrapper {
            color: @numbergames-white;
          }

          .day-wrapper {
            color: @keno-powderblue;
          }
        }

        &:after {
          background-color: @numbergames-white;
        }

        &.selected {
          &:hover > &:after, &:after {
            background-color: @keno-powderblue;
          }
        }
      }
    }
  }
}

