.region-keno .news-view .section .content-wrapper {
  padding: 8vw 0;
}
@media (min-width: 950px) {
  .region-keno .news-view .section .content-wrapper {
    padding: 5rem 0;
    min-height: 30rem;
    box-sizing: border-box;
  }
}
.region-keno .news-view .section .content-wrapper .content {
  max-width: 86.7vw;
  margin: auto;
}
@media (min-width: 950px) {
  .region-keno .news-view .section .content-wrapper .content {
    max-width: 58rem;
  }
}
.region-keno .news-view .section .content-wrapper .content h1 {
  margin-bottom: 4vw;
  font-size: 5.4vw;
  line-height: 6.7vw;
}
@media (min-width: 950px) {
  .region-keno .news-view .section .content-wrapper .content h1 {
    margin-bottom: 1.5rem;
    font-size: 2.5rem;
    line-height: 4rem;
  }
}
.region-keno .news-view .section .content-wrapper .content hr {
  border: none;
  background-color: #99dbf7;
  color: #99dbf7;
  height: 0.1rem;
}
.region-keno .news-view .section .content-wrapper .content .btn {
  min-width: 16rem;
}
.region-keno .news-view .section .date {
  color: #a3a6ab;
  font-size: 4vw;
  line-height: normal;
  margin-bottom: 2vw;
  display: block;
}
@media (min-width: 950px) {
  .region-keno .news-view .section .date {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }
}
.region-keno .news-view .section p {
  font-size: 4vw;
  line-height: 5.34vw;
  margin: auto;
}
@media (min-width: 950px) {
  .region-keno .news-view .section p {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
@media (min-width: 950px) {
  .region-keno .news-view .section .large {
    font-size: 2.5rem;
  }
}
.region-keno .news-view .section .svg-social-share {
  width: 4rem;
  height: 4rem;
  margin: 2.0rem 1.2rem;
}
