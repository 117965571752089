.top-navigation-theme-keno .menu-toggle-right {
  background-color: #00a5eb;
}
.top-navigation-theme-keno .brand-nav {
  background-color: #00a5eb;
}
.top-navigation-theme-keno .corporate-nav {
  background-color: #0091cf;
}
.top-navigation-theme-keno .corporate-nav .accountmenu .choose-account-btn {
  display: none;
}
.top-navigation-theme-keno .mobile-navigation-header {
  background-color: #00a5eb;
}
.top-navigation-theme-keno .mobile-navigation-footer__icon {
  background-color: #00a5eb;
}
.top-navigation-theme-keno .mobile-navigation-item-link:before {
  background-color: #00a5eb;
}
.top-navigation-theme-keno .user-info {
  background-color: #0084BC;
}
.top-navigation-theme-keno .mega-menu__campaign__cta-button {
  background-color: #00a5eb;
  color: white;
}
.top-navigation-theme-keno .mega-menu__campaign__cta-button:hover {
  opacity: .9;
}
