// bring in mixins
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

.keno-slider-selector {
  .slider-container {
    width:86.667%;
    margin:auto;
    -webkit-touch-callout:none;
    .user-select(none);
    @media (min-width: @keno-desktop-width) {
      width:100%;
    }

    .slider-bar {
      padding:2.935vw 0vw;
      position:relative;
      @media (min-width: @keno-desktop-width) {
        padding:1.6rem 0rem;
      }

      &:before {
        content:'';
        width: 100%;
        height:1.333vw;
        background-color:@keno-powderblue;
        .border-radius(.6665vw);
        display:block;
        position:relative;
        left: 0vw;
        cursor:pointer;

        .blue-select-bar & {
          background-color:@keno-powderblue;
        }
        @media (min-width: @keno-desktop-width) {
          width: 100%;
          height:.6rem;
          background-color:#fff;
          .border-radius(3px);
          left: 0rem;
        }
      }

      .selector {
        width:7.2vw;
        height:7.2vw;
        background-color:#ebb914;
        border-radius:50%;
        position:absolute;
        top:50%;
        margin-top:-3.6vw;
        margin-left: 0vw;
        transform:translateX(-1.6vw);
        transition:transform .25s cubic-bezier(0.34, 1.61, 0.7, 1);
        @media (min-width: @keno-desktop-width) {
          width:3.8rem;
          height:3.8rem;
          cursor:pointer;
          margin-top:-1.9rem;
          margin-left: 0;
          .transform(translateX(0));

          .box-half & {
            //margin-left: .8%;
          }
        }

        &:before {
          content:'';
          border-left:1.2vw solid transparent;
          border-right:1.2vw solid transparent;
          border-top:1.333vw solid #2b2b2b;
          position:absolute;
          top:50%;
          left:50%;
          .transform(translate3d(-50%,-50%,0));
          @media (min-width: @keno-desktop-width) {
            border-left:.6rem solid transparent;
            border-right:.6rem solid transparent;
            border-top:.6rem solid #2b2b2b;
          }
        }

        &:after {
          content:'';
          width:100%;
          height:100%;
          border-radius:50%;
          background-color:inherit;
          position:absolute;
          z-index:-1;
          left:50%;
          top:50%;
          transform:scale(1) translate3d(-50%,-50%,0);
          transition:transform .1s linear;
        }

        &.pressed {
          &:after {
            transform:scale(2) translate3d(-25%, -28%, 0);
            @media (min-width: @keno-desktop-width) {
              transform:scale(1.2) translate3d(-42%,-42%,0);
            }
          }
        }
      }
    }

    .numbers-container {
      width: 100%;
      position:relative;
      left: 0%;
      display:table;
      table-layout:fixed;
      margin-top:2.266vw;
      @media (min-width: @keno-desktop-width) {
        width: 100%;
        margin-top:.9rem;
        left: 0;
      }

      .number {
        font-size:4vw;
        font-weight:bold;
        color:#99dbf7;
        line-height:.8;
        display:table-cell;
        .transition(color .25s);
        position: relative;
        @media (min-width: @keno-desktop-width) {
          font-size:1.8rem;
          color:#4dc0f1;
          cursor:pointer;
        }

        &.selected {
          color:@keno-royalblue;

          .blue-select-bar & {
            color:@keno-royalblue;
          }
          @media (min-width: @keno-desktop-width) {
            color:#fff;
            cursor:default;
          }
        }
      }
    }
  }
}
