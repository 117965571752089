.keno-million {
  background-color: #00a5eb;
  border-color: #00a5eb;
  position: relative;
}
.keno-million .game-not-available-wrapper {
  padding: 15vw 0;
  text-align: center;
}
@media (min-width: 950px) {
  .keno-million .game-not-available-wrapper {
    padding: 5rem 0;
  }
}
.keno-million .game-not-available-wrapper svg {
  width: 17.33vw;
  height: 17.33vw;
  margin-bottom: 2vw;
  margin-top: -8vw;
}
@media (min-width: 950px) {
  .keno-million .game-not-available-wrapper svg {
    width: 6.4rem;
    height: 6.4rem;
    margin-bottom: 1rem;
    margin-top: 0;
  }
}
.keno-million .game-not-available-wrapper .subheadline {
  font-size: 3.733vw;
  text-transform: uppercase;
  color: #fff;
  line-height: 1.3;
  padding-top: 1.6vw;
  margin: 0;
}
@media (min-width: 950px) {
  .keno-million .game-not-available-wrapper .subheadline {
    font-size: 2rem;
    padding-top: 0.5rem;
  }
}
.keno-million .keno-content-wrapper {
  padding: 12vw 0 0 0;
}
@media screen and (min-width: 950px) {
  .keno-million .keno-content-wrapper {
    padding: 5rem 0 0 0;
  }
}
.keno-million .keno-million-headline {
  max-width: 86.5vw;
  font-size: 10.7vw;
  font-style: italic;
  color: #fff;
  line-height: 11.2vw;
  text-transform: uppercase;
  margin: 0 auto 1.4vw;
}
@media (min-width: 950px) {
  .keno-million .keno-million-headline {
    font-size: 4rem;
    line-height: 4rem;
    margin: 0 auto 1rem;
  }
}
.keno-million .subheading {
  max-width: 96rem;
  margin: auto;
}
@media (min-width: 950px) {
  .keno-million .subheading {
    width: 100%;
    max-width: 86.5vw;
  }
}
.keno-million .subheading .subheading-text {
  max-width: 86.5vw;
  font-size: 5.4vw;
  font-weight: bold;
  line-height: 5.9vw;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  padding: 0 .3rem;
}
@media (min-width: 950px) {
  .keno-million .subheading .subheading-text {
    font-size: 2rem;
    line-height: 2.4rem;
    margin: 0 auto .5rem;
  }
}
.keno-million .subheading .subheading-text.yellow {
  color: #feb700;
}
.keno-million .draw-info {
  margin: 6vw 0;
}
@media (min-width: 950px) {
  .keno-million .draw-info {
    margin: 2rem 0;
  }
}
.keno-million .draw-info .draw-info-text {
  max-width: 86.5vw;
  font-size: 4vw;
  line-height: 6vw;
  color: #fff;
  display: inline-block;
}
@media (min-width: 950px) {
  .keno-million .draw-info .draw-info-text {
    font-size: 1.5rem;
    padding: 0 1rem;
    line-height: 1;
  }
}
.keno-million hr.divider {
  width: 86.5vw;
  border: 0.1rem solid #99dbf7;
  border-top: 0;
  margin: 6vw auto;
}
@media (min-width: 950px) {
  .keno-million hr.divider {
    margin: 2.5rem auto;
    max-width: 96rem;
  }
}
.keno-million .rows-headline {
  max-width: 86.5vw;
  font-size: 5.4vw;
  line-height: 5.9vw;
  color: #fff;
  text-transform: uppercase;
  margin: 0 auto .5rem;
}
@media (min-width: 950px) {
  .keno-million .rows-headline {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}
.keno-million .row-block {
  width: 100%;
  max-width: 86.5vw;
  max-height: 26vw;
  margin: 1.5rem auto 0;
  position: relative;
  overflow: hidden;
  -webkit-transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  -ms-transition: max-height 0.5s;
  -o-transition: max-height 0.5s;
  transition: max-height 0.5s;
  -webkit-transition-delay: 0s;
  /* Safari */
  transition-delay: 0s;
}
@media (min-width: 950px) {
  .keno-million .row-block {
    max-width: 46rem;
    max-height: 27.5rem;
    width: 100%;
    margin: 1.5rem auto 0;
  }
}
.keno-million .row-block .number-draw-row {
  background-color: #0891d8;
  color: #fff;
  display: block;
  font-size: 4vw;
  line-height: 10.7vw;
  font-weight: bold;
  font-style: italic;
  border-radius: 5px;
  margin: 0 0 4vw;
}
@media (min-width: 950px) {
  .keno-million .row-block .number-draw-row {
    font-size: 2rem;
    line-height: 4rem;
    margin: 0 0 1.5rem;
  }
}
.keno-million .row-block .bottom-gradient {
  width: 100%;
  height: 10.7vw;
  position: absolute;
  bottom: 0;
  display: block;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 165, 235, 0.9) 50%, #00a5eb 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 165, 235, 0.9) 50%, #00a5eb 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 165, 235, 0.9) 50%, #00a5eb 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#007db9e8', endColorstr='#00a5eb', GradientType=0);
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
@media (min-width: 950px) {
  .keno-million .row-block .bottom-gradient {
    display: none;
  }
}
.keno-million .compact {
  display: none;
}
.keno-million .expand {
  display: inline-block;
}
@media (min-width: 950px) {
  .keno-million .expand {
    display: none;
  }
}
.keno-million .btn {
  margin: .5rem .5rem 3.5rem;
}
.keno-million .btn.expand,
.keno-million .btn.compact {
  padding-right: 10vw;
}
@media screen and (min-width: 950px) {
  .keno-million .btn.expand,
  .keno-million .btn.compact {
    padding-right: 4vw;
  }
}
@media screen and (min-width: 950px) {
  .keno-million .btn .arrow-icon {
    display: none;
  }
}
.keno-million .btn.new-row-generator .icon {
  display: none;
}
@media screen and (min-width: 950px) {
  .keno-million .btn.new-row-generator .icon {
    display: inline-block;
  }
}
.keno-million.is-expanded .compact {
  display: inline-block;
}
@media (min-width: 950px) {
  .keno-million.is-expanded .compact {
    display: none;
  }
}
.keno-million.is-expanded .expand {
  display: none;
}
.keno-million.is-expanded .row-block {
  max-height: 74vw;
}
.keno-million.is-expanded .row-block .bottom-gradient {
  opacity: 0;
}
@media (min-width: 950px) {
  .keno-million .help-link {
    top: -10.5rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
}
@media (max-width: 959px) {
  .manchete.bot .keno-section:after {
    display: none;
  }
}
