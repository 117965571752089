@import url("../Mixins/_mixins.less");

.keno-top-spot {

  .keno-section {
    padding: 12vw 0;

    @media @md {
      padding: 5rem 0;
    }

    .keno-video-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      overflow: hidden;
      display: none;

      video::-webkit-media-controls {
        display: none !important;
      }

      video::-webkit-media-controls-start-playback-button {
        display: none !important;
      }

      video {
        object-fit: inherit;
      }

      @media (min-width: @keno-desktop-width) {
        display:block;
      }
    }

    .keno-video {
      min-width: 100%;
      min-height: 100%;
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      transition: opacity 0.5s ease-in-out;

      &.is-loading {
        opacity: 0;
      }

      &.can-playthrough {
        opacity: 1;
      }
    }

    .keno-content-wrapper {
      // Position all other elements above background and video
      > * {
        position: relative;
        z-index: 2;
      }
    }

    h1.hd {
      margin: 4vw auto;
      font-size: 6.7vw;
      line-height: normal;

      @media (min-width: @keno-desktop-width) {
        margin: 2rem auto 1rem;
        word-spacing: normal;
        font-size: 4rem;
        line-height: 4rem;
      }

      &.big-header {
        word-spacing: 25vw;
        font-size: 16vw;
        line-height: 14.7vw;

        @media (min-width: @keno-desktop-width) {
          word-spacing: normal;
          font-size: 6rem;
          line-height: normal;
        }
      }
    }

    .btn {
      margin-bottom: 2vw;
      clear: both;

      @media (min-width: @keno-desktop-width) {
        margin-bottom: 2rem;
      }
    }

    .help-link {
      //position: relative;
      // changes to the underline of the hyperlink
      text-decoration: none;
      border-bottom: .1rem solid #80a8b7;
      display: inline-block;
      line-height: 4.2vw;
      margin-top: 2rem;
      clear: both;

      @media (min-width: @keno-desktop-width) {
        line-height: 1.5rem;
        margin-top: 2rem;
        margin-bottom: 1.5rem;
      }

      &:hover {
        border-color: transparent;
      }
    }
  }
}
