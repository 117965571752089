// bring in mixins  
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)
@keno-purchase-bar-border-color: #cdd3d7;

.keno-section .purchase-bar-wrapper {
  width: 100%;
  height: 16.3vw;
  position: relative;
  z-index: 11;
  will-change:scroll-position, transform;
  transform:translate3d(0,0,0);
  @media (min-width: @keno-desktop-width) {
    height: 7rem;
  }
  @media screen and (max-width: @keno-mobile-width) and (orientation: landscape) {
    width:100% !important;
    //transition:width .1s;
  }

  .purchase-bar {
    width: 100%;
    height: 16.5vw;
    position: relative;
    font-size: 3.2vw;
    font-weight: bold;
    @media (min-width: @keno-desktop-width) {
      font-size: 1.4rem;
      height: 7rem;
    }

    &:before,
    &:after {
      content: "";
      width: calc(~'(100% - 96rem) / 2');
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: rgba(255,255,255,0.95);
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }


    .purchase-bar-content {
      position: relative;
      text-align: left;
      background-color: inherit;
      width: 100%;
      max-width: 96rem;
      height: inherit;
      margin: auto;
      display: table;
      table-layout: fixed;
      // line-height: 70px;
      @media (min-width: @keno-desktop-width) {
        height: 100%;
      }

      a {
        line-height: inherit;
        display: block;
      }

      .dropdown-wrapper {
        position: relative;
        padding: 0 5vw;
        line-height: 16.3vw;
        .transition(background-color 300ms linear);
        @media (min-width: @keno-desktop-width) {
          padding: 0 3rem;
          line-height: 7rem;
        }
        &:hover {
          background-color: rgba(255,255,255,1);
        }
        .arrow-up-icon {
          width: 3.467vw;
          height: 2.135vw;
          position: absolute;
          right: 5vw;
          .transition(opacity .15s linear);
          &.hide {
            opacity: 0;
          }
          svg {
            width: 3.467vw;
            height: 2.135vw;
          }
          @media (min-width: @keno-desktop-width) {
            width: 1.2rem;
            height: .7rem;
            top: 0;
            right: 2rem;
            svg {
              width: 1.2rem;
              height: .7rem;
              margin-left: 0;
            }
          }
        }
        @media (min-width: @keno-desktop-width) {
          line-height: 7rem;
        }
      }

      > div {
        width: 29%;
        height: inherit;
        display: table-cell;
        cursor: pointer;
        vertical-align: middle;
        position: relative;
        box-sizing: border-box;
        background-color: rgba(255,255,255,0.95);
        @media (min-width: @keno-desktop-width) {
          width: auto;
        }

        &.number-of-draws {
          border-left: .1rem solid @keno-purchase-bar-border-color; // fix color mixin?
        }

        .user-select(none);
        span {
          display: none;
          @media (min-width: @keno-desktop-width) {
            display: inline;
          }
        }
        .select-stake,
        .select-draws {
          position: absolute;
        }

        &.purchase-bar-back {
          text-align: center;
        }

        &.purchase-bar-cta {
          width: 42%;
          text-align: center;
          border-left: none;
          background-color: @keno-cta-yellow;
          .transition(background-color 300ms linear);
          @media (min-width: @keno-desktop-width) {
            width: auto;
          }
          &:hover {
            background-color: @keno-cta-yellow-hover;
          }
          .total-price {
            transition:transform .25s, opacity .25s;
            .opacity(1);
          }
          &.is-inactive {
            &:hover {
              background-color: @keno-cta-yellow;
            }
            cursor:default;
            .total-price {
              .opacity(.3);
            }
          }
          .purchase {
            .purchase-button-loader-box {
              width:100%;
              position:absolute;
              transform:translateY(-200%) scale(.8);
              opacity:0;
              transition:transform .25s, opacity .15s;
                            
              .loader-text {
                display:inline-block;
              }

              .loader-animation-box {
                margin-left:.5rem;
                display:inline-block;
                @keyframes loader-dot-animation {
                  0%, 100% { transform:translateY(0); }
                  50% { transform:translateY(-100%); }
                }

                .loader-dot {
                  width:1.2vw;
                  height:1.2vw;
                  margin-left:.3rem;
                  border-radius:50%;
                  background-color:#000;//@lotto-darkgray;
                  display:inline-block;
                  @media (min-width: @keno-desktop-width) {
                    width:.8rem;
                    height:.8rem;
                  }
                }
              }
            }
          }

          &.processing {
            .purchase {
              .purchase-button-loader-box {
                transform:translateY(0) scale(1);
                opacity:1;
                .loader-animation-box {
                  .loader-dot {
                    &:nth-child(1n) {
                      animation:loader-dot-animation .5s ease-in-out -.66s infinite;
                    }
                    &:nth-child(2n) {
                      animation:loader-dot-animation .5s ease-in-out -.33s infinite;
                    }
                    &:nth-child(3n) {
                      animation:loader-dot-animation .5s ease-in-out -1s infinite;
                    }
                  }
                }
              }
              .total-price {
                transform:translateY(100%) scale(.8);
                opacity:.6;
              }
            }
          }
        }
        &.auto {
          width: auto;
        }
        .dropdown-list-wrapper {
          width:100%;
          position:absolute;
          bottom: 16.3vw;
          left: 0;
          max-height: 0;
          transition:max-height .3s cubic-bezier(0.34, 1.61, 0.7, 1);
          overflow-y: auto;
          overflow-x: hidden;
          box-shadow: 0 -0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
          border-radius: 0.5rem 0.5rem 0 0;
                  
          @media (min-width: @keno-desktop-width) {
            bottom: 7rem;
          }

          ul.dropdown {
            margin: 0;
            padding: 0;
            list-style-type: none;
            width: 100%;
            background-color: rgba(255,255,255,0.95);
            border-top-left-radius: .5rem;
            border-top-right-radius: .5rem;
            overflow:hidden;
            position:relative;
            &.active {
              &.select-days {
                //max-height: 91vh;
                //max-height: 75vh;
                @media (min-width: @keno-desktop-width) {
                  //max-height: 630px;
                }
              }
              &.select-stake {
                //max-height: 74vh;
                //max-height: 55vh;
                @media (min-width: @keno-desktop-width) {
                  //max-height: 490px;
                }
              }
            }
            > li {
              line-height: 4.1vw;
              padding: 4vw 5vw;
              border-bottom: .1rem solid @keno-purchase-bar-border-color;
                        
              @media (min-width: @keno-desktop-width) {
                line-height: normal;
                padding: 2.5rem 3rem;
              }
              .transition(background-color 300ms linear);
              &:hover {
                background-color: rgba(255,255,255,1);
              }
              .user-select(none);
              span {
                display: none;
                @media (min-width: @keno-desktop-width) {
                  display: inline;
                }
              }
              &:first-child {
                padding: 4.1vw 5vw;
                cursor: default;
                background-color:#fff;
                color:@keno-light-grey;
                @media (min-width: @keno-desktop-width) {
                  padding: 2.5rem 3rem;
                }
              }
            }
          }
        }
      }
    }
  }

  &.fixed {
    .purchase-bar {
      position: fixed;
      bottom: 0;
    }
    @media (min-width: @keno-desktop-width) {
      bottom: 3rem;
    }
  }

}
