@import url("../Mixins/_mixins.less");

.keno-no-open-draw {
  .keno-section {
    padding: 5rem 0 7rem;

    &.manchete.top {
      padding: 0;
    }

    .no-opendraw-headline {
      max-width: 86.5vw;
      font-size: 5.4vw;
      font-weight: bold;
      font-style: italic;
      line-height: 5.6vw;
      color: @keno-white;
      text-transform: uppercase;
      margin: 0 auto;

      @media (min-width: @keno-desktop-width) {
        font-size: 3.5rem;
        line-height: 4.2rem;
      }
    }

    .no-opendraw-subheadline {
      max-width: 86.5vw;
      font-size: 4.4vw;
      font-weight: bold;
      font-style: italic;
      line-height: 6vw;
      color: @keno-white;
      text-transform: uppercase;
      padding-top: 1vw;
      margin: 0 auto;

      @media (min-width: @keno-desktop-width) {
        font-size: 2.5rem;
        line-height: 3rem;
        padding-top: 1rem;
      }

      &.content {
        color: @keno-powderblue;

        @media (min-width: @keno-desktop-width) {
          font-size: 2.5rem;
        }
      }
    }
  }
}
