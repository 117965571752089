﻿@import url("../Mixins/_mixins.less");

.keno-faq.keno-section {

  .keno-section {
    padding-top: 8vw;
    @media (min-width: @keno-desktop-width) {
      padding-top: 6rem;
      p {
        max-width: 58rem;
        margin: auto;
      }
    }
  }

  .keno-content-wrapper {
    .richtext {
      border-bottom: .1rem solid @keno-mediumblue;  
    }
    &:last-child {
      .richtext {
        border-bottom: none;  
      }
    }
  }

  .prizetables {
    margin-top: -.1rem; // hide the blue line from content wrapper
  }


  .select-dropdown {
    max-width: 86.667vw;
    margin: auto;
    display: block;

    @media (min-width: @keno-desktop-width) {
      max-width: 58rem;
    }
  }

  .panel {
    display: none;

    &.is-displayed {
      display: block;
    }
    // Show all panels in page edit mode
    body.mode-edit & {
      display: block;
    }
  }

  .box-medium {
    width: 86.667vw;
    margin: auto;
    text-align: center;

    @media (min-width: @keno-desktop-width) {
      width: 58rem;
    }
  }

  .richtext {
    color: @keno-darkgrey;
    padding: 7.333vw 0;
    //border-bottom:.1rem solid @keno-mediumblue;
    @media (min-width: @keno-desktop-width) {
      padding: 3rem 0;
    }
  }

  .prizetables {
    text-align: center;

    .stake-selector {
      padding-top: 6rem;
    }
  }

  p {
    font-size: 4vw;
    line-height: 1.3;
    padding-bottom: 5.333vw;
    margin: 0 auto;
    max-width: 86.667vw;

    &:last-of-type {
      padding-bottom: 0;
    }

    @media (min-width: @keno-desktop-width) {
      font-size: 1.5rem;
      padding-bottom: 2rem;
    }
  }

  h2.hd {
    font-size: 5.333vw;
    color: @keno-royalblue;
    padding-bottom: 2.666vw;

    @media (min-width: @keno-desktop-width) {
      font-size: 2.5rem;
      padding-bottom: 2rem;
    }
  }

  .btn {
    margin-top: 4.5vw;

    @media (min-width: @keno-desktop-width) {
      margin-top: 2rem;
    }
  }
}
