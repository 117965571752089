// bring in mixins
@import url("../Mixins/_mixins.less"); // ┬──┬﻿ ¯\_(ツ)

.keno-barometer-section {
  //height:20.634vw;
  position:relative;
  @barometer-winnings-animation-duration:1.4s;
  @barometer-winnings-animation-delay:.4s;

  @media (min-width: @keno-desktop-width) {
    height:1rem;
    margin-bottom:20.8rem;
    .transition(height .25s);
  }

  .keno-barometer-logo-box {
    padding:6vw 0;
    text-align:center;
    line-height:0;
    position:relative;
    z-index:4;
    .opacity(1);
    .transform(translateY(0));
    .transitionWithTransform(transform .25s, opacity .25s;);

    @media (min-width: @keno-desktop-width) {
      padding:3rem 0;
      top:0;
    }

    .logo-image {
      height:4vw;
      max-width:90%;
      @media (min-width: @keno-desktop-width) {
        height:auto;
      }
    }
  }

  .game-heading {
    display: block;
    width: 100%;
    text-align: center;
    margin: auto;
    padding-top: 7vw;
    padding-bottom: 7vw;
    font-size: 5vw;
    font-weight: bold;
    color: #fff;
    line-height: .8;
    position: relative;
    z-index: 4;
    font-style: italic;
    @media (min-width: @keno-desktop-width) {
      padding-top: 3rem;
      padding-bottom: 2.5rem;
      width: 58rem;
      font-size: 2rem;
      color: #fff;
    }
  }

  .keno-barometer {
    width:100%;
    background-color:#fff;
    padding:4.8vw 0 14.4vw;
    position:relative;
    z-index:3;
    .transform(translateY(0));
    .transitionWithTransform(transform .25s linear);

    @media (min-width: @keno-desktop-width) {
      width:96rem;
      margin:auto;
      padding:5.3rem 0 4.1rem;
      background-color:transparent;
      position:absolute;
      top:2.5rem;
      left:0;
      right:0;


      &:before, &:after {
        content:'';
        height:20rem;
        width:100vw;
        position:absolute;
        top:-1.7rem;
        z-index:-1;
        background-color:@keno-dodgerblue;
        .transitionWithTransform(transform .25s, top .25s;);
      }
      &:before {
        left:50%;
        .transform(translateX(-100%) rotate(8deg));
        .transform-origin(bottom, right);
      }
      &:after {
        right:50%;
        .transform(translateX(100%) rotate(-8deg));
        .transform-origin(bottom, left);
      }
    }

    .inner {
      width:86.667%;
      font-size:2.4vw;
      font-weight:bold;
      color:@keno-dodgerblue;
      text-transform:uppercase;
      margin:auto;
      position:relative;

      @media (min-width: @keno-desktop-width) {
        width:58rem;
        font-size:1.5rem;
        color:@keno-mediumblue;
        top:0;
        .transition(top .25s);
      }

      .bar-container {
        width:100%;
        position:relative;

        &.white, &.yellow {
          height:1rem;
          .border-radius(5px);
          @media (min-width: @keno-desktop-width) {
            height:.6rem;
            .border-radius(3px);
          }
        }
        &.white {
          width:100%;
          background-color:@keno-dodgerblue;
          @media (min-width: @keno-desktop-width) {
            background-color:#fff;
          }
        }
        &.yellow {
          width:20%;
          background-color:#ebb914;
          position:absolute;
          left:0;
          top:0;
          .transition(width .25s 0s);

          @media (min-width: @keno-desktop-width) {
            .transition(width .25s);
            transition-delay: @barometer-winnings-animation-duration/2 - 0.05s + @barometer-winnings-animation-delay;
          }
        }
      }

      .left-text, .center-text, .right-text {
        position:absolute;
        top:4.7vw;
        @media (min-width: @keno-desktop-width) {
          top:3.2rem;
        }
      }
      .left-text {
        left:0;
      }
      .center-text {
        text-align:center;
        line-height:1.2;
        left:50%;
        .transform(translateY(0) translateX(-50%));

        .total {
          font-size:3.734vw;
          color:@keno-royalblue;
          position:relative;
          top:.8vw;
        }

        .notice {
          color: @keno-cta-yellow-hover;
        }

        @media (min-width: @keno-desktop-width) {
          .transform(translateY(-19%) translateX(-50%));

          .total {
            font-size:2rem;
            color:#fff;
            top:auto;
          }
        }
      }
      .right-text {
        right:0;
      }

    }

    .left-bags-container, .right-bags-container, .money-coin {
      display:none;
    }
    @media (min-width: @keno-desktop-width) {
      .left-bags-container, .right-bags-container, .money-coin {
        display:block;
      }
      .left-bags-container, .right-bags-container {
        width:12rem;
        .transitionWithTransform(transform .25s);
      }
      .left-bags-container {
        position:absolute;
        .transform(scale(1) translateX(0));

        .small-bag {
          width:3.5rem;
          height:5.8rem;
          position:absolute;
          overflow:visible;

          .bag {
            fill:#fff;
          }
          .shadow {
            fill:#1e55a0;
          }

          &.bag-one {
            top:-1.7rem;
            left:6rem;

            .shadow {
              .opacity(.3);
              .transform-origin(center, bottom);
              .transform(translateY(4rem) scale(1));
            }
          }

          &.bag-two {
            top:-4.1rem;
            left:9.9rem;
            .shadow {
              .opacity(.2);
              .transform-origin(center, bottom);
              .transform(translateY(13rem) scaleX(.9));
            }
          }

          &.bag-three {
            top:-1.7rem;
            left:13.4rem;
            .shadow {
              .opacity(.3);
              .transform-origin(center, bottom);
              .transform(translateY(4rem) translateX(0) scale(1));
            }
          }
        }
      }
      .right-bags-container {
        position:absolute;
        right:0;
        .transform(scale(1) translateX(0));

        .big-bag {
          width:7.5rem;
          height:12rem;
          position:absolute;
          top:-6rem;
          right:8.5rem;
          overflow:visible;

          .bag {
            fill:#fff;
          }
          .shadow {
            fill:#1e55a0;
            .opacity(.3);
            .transform(translateY(-2rem) translateX(0) scale(1));
          }
        }
      }

      .money-coin {
        width:2rem;
        height:2rem;
        fill:#fff;
        position:absolute;
        top:3rem;
        left:11rem;
        //.opacity(0);
        .transform(translateX(0) translateY(0) scale(0));
      }
    }
  }

  .keyframes(increase-winnings-coin-one-animation; {
    20% { .transform(translateX(0) translateY(0) scale(0)); .opacity(0); }
    35% { .transform(translateX(5rem) translateY(-4rem) scale(1)); .opacity(1); }
    50% { .transform(translateX(10rem) translateY(2rem) scale(0)); .opacity(0); }
    68% { .transform(translateX(60rem) translateY(2rem) scale(0)); .opacity(0); }
    78% { .transform(translateX(70rem) translateY(-9rem) scale(1)); .opacity(1); }
    80% { .transform(translateX(72rem) translateY(-9rem) scale(1)); .opacity(1); }
    90% { .transform(translateX(72rem) translateY(-2rem) scale(0)); .opacity(0); }
  });

  .keyframes(increase-winnings-coin-two-animation; {
    30% { .transform(translateX(0) translateY(0) scale(0)); .opacity(0); }
    45% { .transform(translateX(5rem) translateY(-4rem) scale(1)); .opacity(1); }
    60% { .transform(translateX(10rem) translateY(2rem) scale(0)); .opacity(0); }
    73% { .transform(translateX(60rem) translateY(2rem) scale(0)); .opacity(0); }
    83% { .transform(translateX(70rem) translateY(-7rem) scale(1)); .opacity(1); }
    85% { .transform(translateX(72rem) translateY(-7rem) scale(1)); .opacity(1); }
    95% { .transform(translateX(72rem) translateY(0rem) scale(0)); .opacity(0); }
  });

  .keyframes(increase-winnings-coin-three-animation; {
    40% { .transform(translateX(0) translateY(0) scale(0)); .opacity(0); }
    55% { .transform(translateX(5rem) translateY(-4rem) scale(1)); .opacity(1); }
    70% { .transform(translateX(10rem) translateY(2rem) scale(0)); .opacity(0); }
    78% { .transform(translateX(60rem) translateY(2rem) scale(0)); .opacity(0); }
    88% { .transform(translateX(70rem) translateY(-7rem) scale(1)); .opacity(1); }
    90% { .transform(translateX(72rem) translateY(-7rem) scale(1)); .opacity(1); }
    100% { .transform(translateX(72rem) translateY(0rem) scale(0)); .opacity(0); }
  });

  // for sticky navigation
  .keyframes(sticky-increase-winnings-coin-one-animation; {
    20% { .transform(translateX(0) translateY(0) scale(0)); .opacity(0); }
    35% { .transform(translateX(5rem) translateY(-2rem) scale(1)); .opacity(1); }
    50% { .transform(translateX(9rem) translateY(-1.5rem) scale(0)); .opacity(0); }
    68% { .transform(translateX(60rem) translateY(-1.5rem) scale(0)); .opacity(0); }
    78% { .transform(translateX(67rem) translateY(-3rem) scale(1)); .opacity(1); }
    80% { .transform(translateX(70rem) translateY(-2rem) scale(1)); .opacity(1); }
    90% { .transform(translateX(70rem) translateY(-1.5rem) scale(0)); .opacity(0); }
  });

  .keyframes(sticky-increase-winnings-coin-two-animation; {
    30% { .transform(translateX(0) translateY(0) scale(0)); .opacity(0); }
    45% { .transform(translateX(5rem) translateY(-2rem) scale(1)); .opacity(1); }
    60% { .transform(translateX(9rem) translateY(-1.5rem) scale(0)); .opacity(0); }
    73% { .transform(translateX(60rem) translateY(-1.5rem) scale(0)); .opacity(0); }
    83% { .transform(translateX(67rem) translateY(-3rem) scale(1)); .opacity(1); }
    85% { .transform(translateX(70rem) translateY(-2rem) scale(1)); .opacity(1); }
    95% { .transform(translateX(70rem) translateY(-1.5rem) scale(0)); .opacity(0); }
  });

  .keyframes(sticky-increase-winnings-coin-three-animation; {
    40% { .transform(translateX(0) translateY(0) scale(0)); .opacity(0); }
    55% { .transform(translateX(5rem) translateY(-2rem) scale(1)); .opacity(1); }
    70% { .transform(translateX(9rem) translateY(-1.5rem) scale(0)); .opacity(0); }
    78% { .transform(translateX(60rem) translateY(-1.5rem) scale(0)); .opacity(0); }
    88% { .transform(translateX(67rem) translateY(-3rem) scale(1)); .opacity(1); }
    90% { .transform(translateX(70rem) translateY(-2rem) scale(1)); .opacity(1); }
    100% { .transform(translateX(70rem) translateY(-1.5rem) scale(0)); .opacity(0); }
  });

  &.increase-winnings {

    .keno-barometer {

      .left-bags-container {
        .keyframes(increase-winnings-small-bag-one-bag-animation; {
          0% { .transform(translateY(0)); }
          20% { .transform(translateY(-10rem)); }
          50% { .transform(translateY(0)); }
        });

        .keyframes(increase-winnings-small-bag-one-shadow-animation; {
          0% { .transform(translateY(4rem) scale(1)); .transform-origin(center, bottom); .opacity(.3); }
          20% { .transform(translateY(4rem) scale(.5)); .transform-origin(center, bottom); .opacity(.2); }
          50% { .transform(translateY(4rem) scale(1)); .transform-origin(center, bottom); .opacity(.3); }
        });

        .bag-one {
          .bag {
            .animation(increase-winnings-small-bag-one-bag-animation @barometer-winnings-animation-duration forwards);
            animation-delay:@barometer-winnings-animation-delay;
          }
          .shadow {
            .animation(increase-winnings-small-bag-one-shadow-animation @barometer-winnings-animation-duration forwards);
            animation-delay:@barometer-winnings-animation-delay;
          }
        }

        .keyframes(increase-winnings-small-bag-two-bag-animation; {
          10% { .transform(translateY(0)); }
          30% { .transform(translateY(-10rem)); }
          60% { .transform(translateY(0)); }
        });

        .keyframes(increase-winnings-small-bag-two-shadow-animation; {
          10% { .transform(translateY(13rem) scale(.9)); .transform-origin(center, bottom); .opacity(.2); }
          30% { .transform(translateY(13rem) scale(.4)); .transform-origin(center, bottom); .opacity(.1); }
          60% { .transform(translateY(13rem) scale(.9)); .transform-origin(center, bottom); .opacity(.2); }
        });

        .bag-two {
          .bag {
            .animation(increase-winnings-small-bag-two-bag-animation @barometer-winnings-animation-duration forwards);
            animation-delay:@barometer-winnings-animation-delay;
          }
          .shadow {
            .animation(increase-winnings-small-bag-two-shadow-animation @barometer-winnings-animation-duration forwards);
            animation-delay:@barometer-winnings-animation-delay;
          }
        }

        .keyframes(increase-winnings-small-bag-three-bag-animation; {
          20% { .transform(translateY(0)); }
          40% { .transform(translateY(-10rem)); }
          70% { .transform(translateY(0)); }
        });

        .keyframes(increase-winnings-small-bag-three-shadow-animation; {
          20% { .transform(translateY(4rem) scale(1)); .transform-origin(center, bottom); .opacity(.3); }
          40% { .transform(translateY(4rem) scale(.5)); .transform-origin(center, bottom); .opacity(.2); }
          70% { .transform(translateY(4rem) scale(1)); .transform-origin(center, bottom); .opacity(.3); }
        });

        .bag-three {
          .bag {
            .animation(increase-winnings-small-bag-three-bag-animation @barometer-winnings-animation-duration forwards);
            animation-delay:@barometer-winnings-animation-delay;
          }
          .shadow {
            .animation(increase-winnings-small-bag-three-shadow-animation @barometer-winnings-animation-duration forwards);
            animation-delay:@barometer-winnings-animation-delay;
          }
        }
      }

      .inner {
        .bar-container.yellow {
          width:30%;
        }

        .total {
          .keyframes(increase-winnings-total-animation; {
            0% { .transform(scale(1)); }
            50% { .transform(scale(1.2)); }
            100% { .transform(scale(1)); }
          });

          .animation(increase-winnings-total-animation .25s forwards);

          @media (min-width: @keno-desktop-width) {
            .keyframes(increase-winnings-total-animation; {
              60% { .transform(scale(1)); }
              80% { .transform(scale(1.2)); }
              90% { .transform(scale(1)); }
            });

            .animation(increase-winnings-total-animation @barometer-winnings-animation-duration forwards);
            animation-delay:@barometer-winnings-animation-delay;
          }
        }
      }

      .right-bags-container {
        .keyframes(increase-winnings-big-bag-animation; {
          70% { .transform(scale(1)); }
          80% { .transform(scale(1.2)); }
          90% { .transform(scale(1.2)); }
          100% { .transform(scale(1)); }
        });

        .big-bag {
          .animation(increase-winnings-big-bag-animation @barometer-winnings-animation-duration forwards);
          animation-delay:@barometer-winnings-animation-delay;
        }
      }

      .money-coin {
        &.coin-one {
          .animation(increase-winnings-coin-one-animation @barometer-winnings-animation-duration forwards linear);
          animation-delay:@barometer-winnings-animation-delay;
        }

        &.coin-two {
          .animation(increase-winnings-coin-two-animation @barometer-winnings-animation-duration forwards linear);
          animation-delay:@barometer-winnings-animation-delay;
        }

        &.coin-three {
          .animation(increase-winnings-coin-three-animation @barometer-winnings-animation-duration forwards linear);
          animation-delay:@barometer-winnings-animation-delay;
        }
      }
    }
  }

  &.decrease-winnings {
    .keno-barometer {

      .left-bags-container {
        .keyframes(decrease-winnings-small-bags-animation; {
          50% { .transform(scale(1)); }
          60% { .transform(scale(1.2)); }
          70% { .transform(scale(1)); }
        });

        .animation(decrease-winnings-small-bags-animation @barometer-winnings-animation-duration forwards);
        animation-delay:@barometer-winnings-animation-delay;
      }

      .inner {
        .bar-container.yellow {
          @media (min-width: @keno-desktop-width) {
            .transition(width .25s);
            transition-delay:@barometer-winnings-animation-duration / 3 + @barometer-winnings-animation-delay;
          }
          width:10%;
        }
        .total {
          .keyframes(decrease-winnings-total-animation; {
            0% { .transform(scale(1)); }
            50% { .transform(scale(.8)); }
            100% { .transform(scale(1)); }
          });

          .animation(decrease-winnings-total-animation .25s forwards);

          @media (min-width: @keno-desktop-width) {
            .keyframes(decrease-winnings-total-animation; {
              40% { .transform(scale(1)); }
              50% { .transform(scale(.8)); }
              60% { .transform(scale(1)); }
            });

            .animation(decrease-winnings-total-animation @barometer-winnings-animation-duration forwards);
            animation-delay:@barometer-winnings-animation-delay;
          }
        }
      }

      .right-bags-container {
        .keyframes(decrease-winnings-big-bag-bag-animation; {
          0% { .transform(translateY(0)); }
          20% { .transform(translateY(-10rem)); }
          30% { .transform(translateY(-10rem)); }
          50% { .transform(translateY(0)); }
        });

        .keyframes(decrease-winnings-big-bag-shadow-animation; {
          0% { .transform(translateY(-2rem) scale(1)); .transform-origin(center, bottom); .opacity(.3); }
          20% { .transform(translateY(-2rem) scale(.5)); .transform-origin(center, bottom); .opacity(.2); }
          30% { .transform(translateY(-2rem) scale(.5)); .transform-origin(center, bottom); .opacity(.2); }
          50% { .transform(translateY(-2rem) scale(1)); .transform-origin(center, bottom); .opacity(.3); }
        });

        .big-bag {
          .bag {
            .animation(decrease-winnings-big-bag-bag-animation @barometer-winnings-animation-duration forwards);
            animation-delay:@barometer-winnings-animation-delay;
          }
          .shadow {
            .animation(decrease-winnings-big-bag-shadow-animation @barometer-winnings-animation-duration forwards);
            animation-delay:@barometer-winnings-animation-delay;
          }
        }
      }

      .money-coin {
        &.coin-one {
          .animation(increase-winnings-coin-one-animation @barometer-winnings-animation-duration reverse linear);
          animation-delay:@barometer-winnings-animation-delay;
        }

        &.coin-two {
          .animation(increase-winnings-coin-two-animation @barometer-winnings-animation-duration reverse linear);
          animation-delay:@barometer-winnings-animation-delay;
        }

        &.coin-three {
          .animation(increase-winnings-coin-three-animation @barometer-winnings-animation-duration reverse linear);
          animation-delay:@barometer-winnings-animation-delay;
        }
      }
    }
  }


  &.sticky {

    @media (min-width: @keno-desktop-width) {
      margin-bottom: 11rem;

      .keno-barometer-logo-box {
        .opacity(0);
        .transform(translateY(-100%));
      }
    }

    .keno-barometer {
      position:fixed;
      top:0;
      &:before, &:after {
        top:-10rem;
        .opacity(.9);
      }
      &:before {
        .transform(translateX(-100%) rotate(0deg));
      }
      &:after {
        .transform(translateX(100%) rotate(0deg));
      }
      .inner {
        @media (min-width: @keno-desktop-width) {
          top:-3rem;
        }
      }

      .left-bags-container {
        .transform(scale(.6) translateX(60%));
      }
      .right-bags-container {
        .transform(scale(.6) translateX(-60%));
      }

    }
    &.increase-winnings {
      .money-coin {
        &.coin-one {
          .animation(sticky-increase-winnings-coin-one-animation @barometer-winnings-animation-duration forwards linear);
          animation-delay:@barometer-winnings-animation-delay;
        }

        &.coin-two {
          .animation(sticky-increase-winnings-coin-two-animation @barometer-winnings-animation-duration forwards linear);
          animation-delay:@barometer-winnings-animation-delay;
        }

        &.coin-three {
          .animation(sticky-increase-winnings-coin-three-animation @barometer-winnings-animation-duration forwards linear);
          animation-delay:@barometer-winnings-animation-delay;
        }
      }
    }

    &.decrease-winnings {
      .keno-barometer {
        .left-bags-container {
          .keyframes(sticky-decrease-winnings-small-bags-animation; {
            50% { .transform(scale(.6) translateX(60%)); }
            60% { .transform(scale(.8) translateX(40%)); }
            70% { .transform(scale(.6) translateX(60%)); }
          });

          .animation(sticky-decrease-winnings-small-bags-animation @barometer-winnings-animation-duration forwards);
          animation-delay:@barometer-winnings-animation-delay;
        }
      }
      .money-coin {
        &.coin-one {
          .animation(sticky-increase-winnings-coin-one-animation @barometer-winnings-animation-duration reverse linear);
          animation-delay:@barometer-winnings-animation-delay;
        }

        &.coin-two {
          .animation(sticky-increase-winnings-coin-two-animation @barometer-winnings-animation-duration reverse linear);
          animation-delay:@barometer-winnings-animation-delay;
        }

        &.coin-three {
          .animation(sticky-increase-winnings-coin-three-animation @barometer-winnings-animation-duration reverse linear);
          animation-delay:@barometer-winnings-animation-delay;
        }
      }
    }
  }
}
