@import url("../Mixins/_mixins.less");

.keno-footer-navigation {
  background-color: @keno-powderblue;

  .keno-section {
    width: 100%;
    padding: 0;
    margin: auto;
    padding-bottom: 0; // temp hack for presentational purposes
    @media (min-width: @keno-desktop-width) {
      max-width: 96rem;
    }

    .keno-content-wrapper {
      text-align: left;

      .navigation-link-text {
        max-width: 86.5vw;
        font-size: 5.4vw;
        font-weight: bold;
        font-style: italic;
        line-height: 19.9vw;
        text-decoration: none;
        color: @keno-white;
        text-transform: uppercase;
        border-bottom: .1rem solid @keno-mediumblue;
        display: block;
        transition: all 0.3s ease;
        margin: 0 auto;

        @media (min-width: @keno-desktop-width) {
          font-size: 2rem;
          line-height: 10rem;
          display: inline-block;
          width: calc(~'50% - 2.5rem');

          &:nth-child(odd) {
            margin-right: 2.5rem;
          }

          &:nth-child(even) {
            margin-left: 2.5rem;
          }

          &:nth-last-child(1), &:nth-last-child(2) {
            border-bottom: none;
          }
        }

        &:hover {
          color: #000;
          opacity: 0.5;
        }

        &:last-child {
          border-bottom: none;
        }
        // the big block links found in the footer have a css
        // generated arrow stuck at the end.
        &.arrow-right {
          position: relative;

          &:before,
          &:after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -2vw; // border-width = -height/2
            width: 0;
            height: 0;
            border-top: 2vw solid transparent;
            border-bottom: 2vw solid transparent;
            border-left: 2vw solid #fff;

            @media (min-width: @keno-desktop-width) {
              margin-top: -1rem; // border-width = -height/2
              border-top: 1rem solid transparent;
              border-bottom: 1rem solid transparent;
              border-left: 1rem solid #fff;
            }
          }

          &:before {
            right: 0;
            border-left: 2vw solid @keno-mediumblue;

            @media (min-width: @keno-desktop-width) {
              border-left: 1rem solid @keno-mediumblue;
            }
          }

          &:after {
            right: .2rem;
            border-left: 2vw solid @keno-powderblue;

            @media (min-width: @keno-desktop-width) {
              border-left: 1rem solid @keno-powderblue;
            }
          }
        }
      }
    }
  }
}
