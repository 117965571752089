.btn {
  box-sizing: border-box;
  outline: none;
  border: none;
  border-radius: 1vw;
  cursor: pointer;
  color: #4f5254;
  font-weight: bold;
  font-size: 3.8vw;
  line-height: 10.7vw;
  padding: 0 4vw;
  display: inline-block;
  position: relative;
  vertical-align: top;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #fff;
}
.btn:hover {
  background-color: #e0f4fd;
}
.btn.dogderblue {
  background-color: #0891d8;
  color: #fff;
}
.btn.powderblue {
  background-color: #00a5eb;
  color: #fff;
}
.btn.cta {
  background-color: #feb700;
  color: #322b00;
}
.btn.grey {
  color: #fff;
  background-color: #a3a6ab;
}
.btn.fixed-width-120 {
  width: 33.4vw;
}
.btn.fixed-width-140 {
  width: 37.35vw;
}
.btn.fixed-width-160 {
  width: 44vw;
  padding: 0;
}
.btn.close {
  position: absolute;
  right: 0;
  width: 4.7vw;
  height: 4.7vw;
  padding: 0;
  background-color: transparent;
}
.btn.close:before,
.btn.close:after {
  content: ' ';
  position: absolute;
  left: 45%;
  top: -15%;
  display: block;
  height: 6vw;
  width: 0.7vw;
  background-color: #4f5254;
}
.btn.close:before {
  transform: rotate(45deg) translateZ(0);
}
.btn.close:after {
  transform: rotate(-45deg) translateZ(0);
}
@media screen and (min-width: 950px) {
  .btn {
    font-size: 1.4rem;
    line-height: 4rem;
    padding: 0 2rem;
    border-radius: .5rem;
  }
  .btn.fixed-width-120 {
    width: 12rem;
  }
  .btn.fixed-width-140 {
    width: 14rem;
  }
  .btn.fixed-width-160 {
    width: 16rem;
  }
  .btn.close {
    width: 2rem;
    height: 2rem;
  }
  .btn.close:before,
  .btn.close:after {
    height: 2.6rem;
    width: .2rem;
  }
}
.keno-checkbox {
  text-align: left;
  position: relative;
  color: #999;
  display: inline-block;
}
.keno-checkbox > label {
  cursor: pointer;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.keno-checkbox .ios-skin {
  float: left;
  position: relative;
  display: inline-block;
  height: 7.3vw;
  cursor: pointer;
}
.keno-checkbox .ios-skin > input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
.keno-checkbox .ios-skin > input {
  line-height: normal;
  position: absolute;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}
.keno-checkbox .ios-skin .ios-skin_body {
  position: relative;
  display: inline-block;
  width: 14vw;
  height: 7.3vw;
  border: 0.6vw solid #fff;
  border-radius: 7.3vw;
  box-sizing: border-box;
  background-color: #fff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: background-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: background-color 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.keno-checkbox .ios-skin > :checked ~ .ios-skin_body .ios-skin_switch {
  -webkit-transform: translateX(6.7vw);
  transform: translateX(6.7vw);
}
.keno-checkbox .ios-skin .checkmark {
  position: absolute;
  right: 5.2vw;
  bottom: 3.9vw;
  z-index: 1;
}
@media screen and (min-width: 950px) {
  .keno-checkbox .ios-skin .checkmark {
    width: 0.4rem;
    height: 1.5rem;
    right: 0;
    bottom: 0.1rem;
    display: inline-block;
    margin: -1.3rem 2.2rem 0 0;
  }
}
.keno-checkbox .ios-skin .checkmark:after {
  content: "";
  position: absolute;
  background-color: #0891d8;
  height: 0.8vw;
  width: 2.9vw;
}
.keno-checkbox .ios-skin > :checked ~ .checkmark {
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transform: rotate(225deg);
  right: 10.6vw;
  bottom: 2.2vw;
  margin: -1vw 0 0 2.5vw;
}
@media screen and (min-width: 950px) {
  .keno-checkbox .ios-skin > :checked ~ .checkmark {
    right: 4rem;
    bottom: 0.6rem;
    margin: -0.8rem 0 0 1.8rem;
  }
}
.keno-checkbox .ios-skin > :checked ~ .checkmark:before {
  content: "";
  position: absolute;
  width: 0.8vw;
  height: 2.9vw;
  background-color: #0891d8;
}
.keno-checkbox .ios-skin > :checked ~ .checkmark:after {
  width: 1.93333333vw;
}
.keno-checkbox .ios-skin .ios-skin_switch {
  position: absolute;
  top: -0.6vw;
  left: -0.6vw;
  display: inline-block;
  width: 7.3vw;
  height: 7.3vw;
  border: 0.6vw solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: #0891d8;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.13);
  -webkit-transition: 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
  transition: 250ms cubic-bezier(0.34, 1.61, 0.7, 1);
}
.keno-checkbox .ios-skin > :not(:checked) ~ .ios-skin_body {
  border-color: #99dbf7;
  background-color: #99dbf7;
}
.keno-checkbox .ios-skin > :not(:checked) ~ .ios-skin_body .ios-skin_switch {
  border-color: #99dbf7;
}
.keno-checkbox label:not(.ios-skin) {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 7.3vw;
  line-height: 7.3vw;
  padding: 0 0 0 17.65vw;
  text-align: left;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@media screen and (min-width: 950px) {
  .keno-checkbox .ios-skin {
    height: 2.8rem;
  }
  .keno-checkbox .ios-skin .ios-skin_body {
    width: 6rem;
    height: 2.8rem;
    border: 0.2rem solid #fff;
    border-radius: 2.8rem;
  }
  .keno-checkbox .ios-skin > :checked ~ .ios-skin_body .ios-skin_switch {
    -webkit-transform: translateX(3.2rem);
    transform: translateX(3.2rem);
  }
  .keno-checkbox .ios-skin .checkmark {
    width: 0.4rem;
    height: 1.5rem;
    margin: -1.3rem 2.2rem 0 0;
  }
  .keno-checkbox .ios-skin .checkmark:after {
    background-color: #0891d8;
    height: 0.4rem;
    width: 1.5rem;
  }
  .keno-checkbox .ios-skin > :checked ~ .checkmark {
    margin: -0.8rem 0 0 2.2rem;
  }
  .keno-checkbox .ios-skin > :checked ~ .checkmark:before {
    width: 0.4rem;
    height: 1.5rem;
    background-color: #0891d8;
  }
  .keno-checkbox .ios-skin > :checked ~ .checkmark:after {
    width: 1rem;
  }
  .keno-checkbox .ios-skin .ios-skin_switch {
    top: -0.2rem;
    left: -0.2rem;
    width: 2.8rem;
    height: 2.8rem;
    border: 0.2rem solid #fff;
    box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.13);
  }
  .keno-checkbox label:not(.ios-skin) {
    height: 2.8rem;
    line-height: 2.8rem;
    padding: 0 0 0 7.4rem;
  }
}
.select-dropdown {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  text-align: left;
  z-index: 10;
  width: 100%;
  max-width: 50rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.5em;
  font-weight: 700;
  color: #1e55a0;
}
.select-dropdown:after {
  content: '';
  height: 1rem;
  background-color: #b8e6f9;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50%;
  z-index: -1;
  transition: bottom 0.05s 0.2s, background-color 0.05s;
}
.select-dropdown select {
  display: none;
}
.select-dropdown .cs-placeholder {
  background-color: #b8e6f9;
  border-radius: .6rem;
  transition: background-color 0.15s;
}
.select-dropdown .cs-placeholder .select-dropdown-arrow {
  width: 4vw;
  height: 2.5vw;
  fill: #1e55a0;
  position: absolute;
  top: 6.8vw;
  right: 4vw;
  transition: transform .15s linear;
}
@media screen and (min-width: 950px) {
  .select-dropdown .cs-placeholder .select-dropdown-arrow {
    width: 1.3rem;
    height: .8rem;
    top: 2.5rem;
    right: 2.4rem;
  }
}
.select-dropdown span {
  display: block;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: .6rem 2.5rem .2rem;
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 5rem;
  /*
        border-top: .1rem solid @keno-cadetblue;
        border-bottom: .1rem solid @keno-cadetblue;
				*/
}
.select-dropdown .select-options {
  max-height: 0;
  background: #b8e6f9;
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  width: 100%;
  border-radius: 0 0 .6rem .6rem;
  transition: max-height 0.25s;
}
.select-dropdown .select-options ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.select-dropdown .select-options ul li {
  border-top: 0.1rem solid #e0f4fd;
}
.select-dropdown .select-options ul li:hover {
  background-color: #99dbf7;
}
.select-dropdown.reversed:after {
  top: 50%;
  transition: top .05s .2s;
}
.select-dropdown.reversed .select-options {
  top: auto;
  bottom: 6rem;
  border-radius: .6rem .6rem 0 0;
}
.select-dropdown.reversed .select-options ul {
  display: flex;
  flex-direction: column-reverse;
}
.select-dropdown.is-active {
  z-index: 11;
}
.select-dropdown.is-active:after {
  bottom: -0.4rem;
  background-color: #92d3ef;
  transition: bottom 0s 0s, background-color 0s 0s;
}
.select-dropdown.is-active .cs-placeholder {
  background-color: #92d3ef;
}
.select-dropdown.is-active .cs-placeholder .select-dropdown-arrow {
  transform: translateY(100%);
}
.select-dropdown.is-active .select-options {
  top: 6rem;
  pointer-events: auto;
}
.select-dropdown.is-active .select-options.overflowed {
  overflow-y: auto;
}
.select-dropdown.is-active.reversed:after {
  bottom: inherit;
  top: -0.4rem;
  transition: top .0s .0s;
}
.select-dropdown.is-active.reversed .cs-placeholder .select-dropdown-arrow {
  transform: translateY(-100%) rotate(180deg);
}
.select-dropdown.is-active.reversed .select-options {
  top: auto;
}
.keno-cta-button {
  box-sizing: border-box;
  outline: none;
  border: none;
  border-radius: 1vw;
  cursor: pointer;
  font-weight: bold;
  font-size: 3.8vw;
  line-height: 10.7vw;
  padding: 0 4vw;
  display: inline-block;
  position: relative;
  vertical-align: top;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #feb700;
  color: #322b00;
}
@media screen and (min-width: 950px) {
  .keno-cta-button {
    font-size: 1.4rem;
    line-height: 4rem;
    padding: 0 1.2rem;
    border-radius: .5rem;
  }
}
