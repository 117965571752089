@import (reference) "Mixins/_mixins.less";

.hd {
  width: 100%;
  max-width: @keno-device-width;
  text-transform: uppercase;
  margin: 0 auto;
  text-align: inherit;
  display: block;
}

.hd,
.label,
.span,
.p {

  &.xxsmall {
    font-size: 5.4vw;
    line-height: 5.9vw;
  }
  &.xsmall {
    font-size: 2.5rem;
    line-height: 3rem;
  }
  &.small {
    font-size: 3rem;
    line-height: 3.6rem;
  }
  &.large {
    font-size: 5.4vw;
    line-height: 5.6vw;
  }
  &.xlarge {
    font-size: 10.7vw;
    line-height: 11.2vw;
  }
  &.xxlarge {
    font-size: 16vw;
    line-height: 14.7vw;
    margin: 0.4em 0;
  }

  @media (min-width: @keno-desktop-width) {
    &.xxsmall {
      font-size: 2rem;
      line-height: 2.4rem;
    }
    &.xsmall {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    &.small {
      font-size: 3rem;
      line-height: 3.6rem;
    }
    &.large {
      font-size: 3.5rem;
      line-height: 4.2rem;
    }
    &.xlarge {
      font-size: 4rem;
      line-height: 4rem;
    }
    &.xxlarge {
      font-size: 6rem;
      line-height: 7.2rem;
      margin: 0.4em 0;
    }
    &.xxxlarge {
      font-size: 8rem;
      line-height: normal;
    }
  }

  &.italic {
    font-style: italic;
  }
  &.semibold {
    font-weight: 600;
  }
  &.bold {
    font-weight: bold;
  }

  &.mediumblue {
    color: @keno-mediumblue;
  }
  &.powderblue {
    color: @keno-powderblue;
  }
  &.dodgerblue {
    color: @keno-dodgerblue;
  }
  &.royalblue {
    color: @keno-royalblue;
  }
  &.cta-yellow {
    color: @keno-cta-yellow;
  }
  &.white {
    color: #fff;
  }

  // Desktop

}
