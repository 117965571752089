.keno-classic-game-overlay,
.keno-million-game-overlay,
.keno-coupon-overlay {
  width: 100vw;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  -webkit-animation: overlay-background-animation 0.3s forwards linear;
  -moz-animation: overlay-background-animation 0.3s forwards linear;
  -ms-animation: overlay-background-animation 0.3s forwards linear;
  animation: overlay-background-animation 0.3s forwards linear;
}
@-webkit-keyframes overlay-background-animation {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
@-moz-keyframes overlay-background-animation {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
@-ms-keyframes overlay-background-animation {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
@keyframes overlay-background-animation {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
@-webkit-keyframes overlay-panel-animation {
  0% {
    -webkit-transform: translateY(-50%) scale(0.85);
    -moz-transform: translateY(-50%) scale(0.85);
    -ms-transform: translateY(-50%) scale(0.85);
    -o-transform: translateY(-50%) scale(0.85);
    transform: translateY(-50%) scale(0.85);
    -webkit-filter: blur(0.3rem);
    -moz-filter: blur(0.3rem);
    -ms-filter: blur(0.3rem);
    filter: blur(0.3rem);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50%) scale(1);
    -moz-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    -o-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-moz-keyframes overlay-panel-animation {
  0% {
    -webkit-transform: translateY(-50%) scale(0.85);
    -moz-transform: translateY(-50%) scale(0.85);
    -ms-transform: translateY(-50%) scale(0.85);
    -o-transform: translateY(-50%) scale(0.85);
    transform: translateY(-50%) scale(0.85);
    -webkit-filter: blur(0.3rem);
    -moz-filter: blur(0.3rem);
    -ms-filter: blur(0.3rem);
    filter: blur(0.3rem);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50%) scale(1);
    -moz-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    -o-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-ms-keyframes overlay-panel-animation {
  0% {
    -webkit-transform: translateY(-50%) scale(0.85);
    -moz-transform: translateY(-50%) scale(0.85);
    -ms-transform: translateY(-50%) scale(0.85);
    -o-transform: translateY(-50%) scale(0.85);
    transform: translateY(-50%) scale(0.85);
    -webkit-filter: blur(0.3rem);
    -moz-filter: blur(0.3rem);
    -ms-filter: blur(0.3rem);
    filter: blur(0.3rem);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50%) scale(1);
    -moz-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    -o-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@keyframes overlay-panel-animation {
  0% {
    -webkit-transform: translateY(-50%) scale(0.85);
    -moz-transform: translateY(-50%) scale(0.85);
    -ms-transform: translateY(-50%) scale(0.85);
    -o-transform: translateY(-50%) scale(0.85);
    transform: translateY(-50%) scale(0.85);
    -webkit-filter: blur(0.3rem);
    -moz-filter: blur(0.3rem);
    -ms-filter: blur(0.3rem);
    filter: blur(0.3rem);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-50%) scale(1);
    -moz-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    -o-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-webkit-keyframes overlay-panel-remove-animation {
  0% {
    -webkit-transform: translateY(-50%) scale(1);
    -moz-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    -o-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-50%) scale(0.85);
    -moz-transform: translateY(-50%) scale(0.85);
    -ms-transform: translateY(-50%) scale(0.85);
    -o-transform: translateY(-50%) scale(0.85);
    transform: translateY(-50%) scale(0.85);
    -webkit-filter: blur(0.3rem);
    -moz-filter: blur(0.3rem);
    -ms-filter: blur(0.3rem);
    filter: blur(0.3rem);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-moz-keyframes overlay-panel-remove-animation {
  0% {
    -webkit-transform: translateY(-50%) scale(1);
    -moz-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    -o-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-50%) scale(0.85);
    -moz-transform: translateY(-50%) scale(0.85);
    -ms-transform: translateY(-50%) scale(0.85);
    -o-transform: translateY(-50%) scale(0.85);
    transform: translateY(-50%) scale(0.85);
    -webkit-filter: blur(0.3rem);
    -moz-filter: blur(0.3rem);
    -ms-filter: blur(0.3rem);
    filter: blur(0.3rem);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-ms-keyframes overlay-panel-remove-animation {
  0% {
    -webkit-transform: translateY(-50%) scale(1);
    -moz-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    -o-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-50%) scale(0.85);
    -moz-transform: translateY(-50%) scale(0.85);
    -ms-transform: translateY(-50%) scale(0.85);
    -o-transform: translateY(-50%) scale(0.85);
    transform: translateY(-50%) scale(0.85);
    -webkit-filter: blur(0.3rem);
    -moz-filter: blur(0.3rem);
    -ms-filter: blur(0.3rem);
    filter: blur(0.3rem);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@keyframes overlay-panel-remove-animation {
  0% {
    -webkit-transform: translateY(-50%) scale(1);
    -moz-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    -o-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-50%) scale(0.85);
    -moz-transform: translateY(-50%) scale(0.85);
    -ms-transform: translateY(-50%) scale(0.85);
    -o-transform: translateY(-50%) scale(0.85);
    transform: translateY(-50%) scale(0.85);
    -webkit-filter: blur(0.3rem);
    -moz-filter: blur(0.3rem);
    -ms-filter: blur(0.3rem);
    filter: blur(0.3rem);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
.keno-classic-game-overlay .pannel,
.keno-million-game-overlay .pannel,
.keno-coupon-overlay .pannel {
  width: 80%;
  background-color: #fff;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 5.3333vw;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-animation: overlay-panel-animation 0.3s forwards linear;
  -moz-animation: overlay-panel-animation 0.3s forwards linear;
  -ms-animation: overlay-panel-animation 0.3s forwards linear;
  animation: overlay-panel-animation 0.3s forwards linear;
}
@media (min-width: 950px) {
  .keno-classic-game-overlay .pannel,
  .keno-million-game-overlay .pannel,
  .keno-coupon-overlay .pannel {
    width: 30rem;
    padding: 2rem;
  }
}
.keno-classic-game-overlay .icon-wrapper,
.keno-million-game-overlay .icon-wrapper,
.keno-coupon-overlay .icon-wrapper {
  width: 17.334vw;
  height: 17.334vw;
  background-color: #0891d8;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.keno-classic-game-overlay .icon-wrapper > .icon,
.keno-million-game-overlay .icon-wrapper > .icon,
.keno-coupon-overlay .icon-wrapper > .icon {
  width: 9.4vw;
  height: 9.4vw;
  fill: #fff;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (min-width: 950px) {
  .keno-classic-game-overlay .icon-wrapper > .icon,
  .keno-million-game-overlay .icon-wrapper > .icon,
  .keno-coupon-overlay .icon-wrapper > .icon {
    width: 3.4rem;
    height: 3.4rem;
  }
}
@media (min-width: 950px) {
  .keno-classic-game-overlay .icon-wrapper,
  .keno-million-game-overlay .icon-wrapper,
  .keno-coupon-overlay .icon-wrapper {
    width: 6.5rem;
    height: 6.5rem;
  }
}
.keno-classic-game-overlay .headline,
.keno-million-game-overlay .headline,
.keno-coupon-overlay .headline {
  font-size: 6.666vw;
  font-weight: bold;
  color: #1e55a0;
  margin: 3.5vw 0 0;
}
@media (min-width: 950px) {
  .keno-classic-game-overlay .headline,
  .keno-million-game-overlay .headline,
  .keno-coupon-overlay .headline {
    font-size: 2.5rem;
    margin: 1rem 1.5rem 0.3rem;
  }
}
.keno-classic-game-overlay .text,
.keno-million-game-overlay .text,
.keno-coupon-overlay .text {
  font-size: 3vw;
  color: #4f5254;
  margin: 3vw 0 0;
}
@media (min-width: 950px) {
  .keno-classic-game-overlay .text,
  .keno-million-game-overlay .text,
  .keno-coupon-overlay .text {
    font-size: 1.5rem;
    margin: 0.3rem 1.5rem 1.5rem;
  }
}
.keno-classic-game-overlay .buttons,
.keno-million-game-overlay .buttons,
.keno-coupon-overlay .buttons {
  margin: 4.5vw 0 0;
}
.keno-classic-game-overlay .buttons:after,
.keno-million-game-overlay .buttons:after,
.keno-coupon-overlay .buttons:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.keno-classic-game-overlay .buttons > .btn:first-child,
.keno-million-game-overlay .buttons > .btn:first-child,
.keno-coupon-overlay .buttons > .btn:first-child {
  float: left;
}
.keno-classic-game-overlay .buttons > .btn:last-child,
.keno-million-game-overlay .buttons > .btn:last-child,
.keno-coupon-overlay .buttons > .btn:last-child {
  float: right;
}
@media (min-width: 950px) {
  .keno-classic-game-overlay .buttons,
  .keno-million-game-overlay .buttons,
  .keno-coupon-overlay .buttons {
    margin: 1.5rem 0 0;
  }
  .keno-classic-game-overlay .buttons .btn,
  .keno-million-game-overlay .buttons .btn,
  .keno-coupon-overlay .buttons .btn {
    width: 12.4rem;
  }
  .keno-classic-game-overlay .buttons > .btn:first-child,
  .keno-million-game-overlay .buttons > .btn:first-child,
  .keno-coupon-overlay .buttons > .btn:first-child {
    float: none;
    margin-right: .5rem;
  }
  .keno-classic-game-overlay .buttons > .btn:last-child,
  .keno-million-game-overlay .buttons > .btn:last-child,
  .keno-coupon-overlay .buttons > .btn:last-child {
    float: none;
    margin-left: .5rem;
  }
}
.keno-classic-game-overlay.remove-overlay,
.keno-million-game-overlay.remove-overlay,
.keno-coupon-overlay.remove-overlay {
  -webkit-animation: overlay-background-animation 0.15s reverse linear;
  -moz-animation: overlay-background-animation 0.15s reverse linear;
  -ms-animation: overlay-background-animation 0.15s reverse linear;
  animation: overlay-background-animation 0.15s reverse linear;
}
.keno-classic-game-overlay.remove-overlay .pannel,
.keno-million-game-overlay.remove-overlay .pannel,
.keno-coupon-overlay.remove-overlay .pannel {
  -webkit-animation: overlay-panel-remove-animation 0.15s forwards linear;
  -moz-animation: overlay-panel-remove-animation 0.15s forwards linear;
  -ms-animation: overlay-panel-remove-animation 0.15s forwards linear;
  animation: overlay-panel-remove-animation 0.15s forwards linear;
}
.keno-coupon-overlay .pannel {
  padding: 0;
  overflow: hidden;
}
@media (min-width: 950px) {
  .keno-coupon-overlay .pannel {
    width: 35rem;
  }
}
@keyframes lotto-coupon-iframe-wrapper-height-animation-desktop {
  100% {
    height: 40rem;
    opacity: 1;
  }
}
@keyframes lotto-coupon-iframe-wrapper-height-animation-mobile {
  100% {
    height: 60vh;
    opacity: 1;
  }
}
.ios-device .keno-coupon-overlay .pannel .coupon-iframe-wrapper {
  height: 0;
  opacity: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  animation: lotto-coupon-iframe-wrapper-height-animation-mobile 0.3s 0.3s linear forwards;
}
@media (min-width: 950px) {
  .ios-device .keno-coupon-overlay .pannel .coupon-iframe-wrapper {
    animation: lotto-coupon-iframe-wrapper-height-animation-desktop 0.3s 0.3s linear forwards;
  }
}
.keno-coupon-overlay .pannel .coupon-iframe-wrapper .coupon-iframe {
  background-color: #00a5eb;
  width: 100%;
  height: 0;
  opacity: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  animation: lotto-coupon-iframe-wrapper-height-animation-mobile 0.3s 0.3s linear forwards;
}
@media (min-width: 950px) {
  .keno-coupon-overlay .pannel .coupon-iframe-wrapper .coupon-iframe {
    animation: lotto-coupon-iframe-wrapper-height-animation-desktop 0.3s 0.3s linear forwards;
  }
}
.ios-device .keno-coupon-overlay .pannel .coupon-iframe-wrapper .coupon-iframe {
  height: 100%;
  opacity: 1;
  overflow: visible;
  animation: none;
}
@media (min-width: 950px) {
  .ios-device .keno-coupon-overlay .pannel .coupon-iframe-wrapper .coupon-iframe {
    animation: none;
  }
}
.keno-coupon-overlay .pannel .buttons {
  margin: 3vw 4vw 4vw;
}
@media (min-width: 950px) {
  .keno-coupon-overlay .pannel .buttons {
    margin: 1.5rem 2rem 2rem;
  }
}
.keno-coupon-overlay .pannel .buttons .btn {
  width: 34vw;
  transition: background-color 0.15s linear;
}
@media (min-width: 768px) and (max-width: 950px) {
  .keno-coupon-overlay .pannel .buttons .btn {
    width: 34vw;
  }
}
@media (min-width: 950px) {
  .keno-coupon-overlay .pannel .buttons .btn {
    width: 15rem;
  }
}
@media (min-width: 950px) {
  .keno-coupon-overlay .pannel .buttons .btn:hover {
    background-color: #f5d900;
  }
}
@-webkit-keyframes overlay-active-animation {
  0% {
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
  }
  100% {
    -webkit-filter: blur(0.3rem);
    -moz-filter: blur(0.3rem);
    -ms-filter: blur(0.3rem);
    filter: blur(0.3rem);
  }
}
@-moz-keyframes overlay-active-animation {
  0% {
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
  }
  100% {
    -webkit-filter: blur(0.3rem);
    -moz-filter: blur(0.3rem);
    -ms-filter: blur(0.3rem);
    filter: blur(0.3rem);
  }
}
@-ms-keyframes overlay-active-animation {
  0% {
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
  }
  100% {
    -webkit-filter: blur(0.3rem);
    -moz-filter: blur(0.3rem);
    -ms-filter: blur(0.3rem);
    filter: blur(0.3rem);
  }
}
@keyframes overlay-active-animation {
  0% {
    -webkit-filter: blur(0);
    -moz-filter: blur(0);
    -ms-filter: blur(0);
    filter: blur(0);
  }
  100% {
    -webkit-filter: blur(0.3rem);
    -moz-filter: blur(0.3rem);
    -ms-filter: blur(0.3rem);
    filter: blur(0.3rem);
  }
}
.overlay-active {
  -webkit-animation: overlay-active-animation 0.3s forwards linear;
  -moz-animation: overlay-active-animation 0.3s forwards linear;
  -ms-animation: overlay-active-animation 0.3s forwards linear;
  animation: overlay-active-animation 0.3s forwards linear;
}
.remove-overlay {
  pointer-events: none;
  -webkit-animation: overlay-active-animation 0.15s reverse linear;
  -moz-animation: overlay-active-animation 0.15s reverse linear;
  -ms-animation: overlay-active-animation 0.15s reverse linear;
  animation: overlay-active-animation 0.15s reverse linear;
}
