.keno-classic-game .keno-content-wrapper {
  width: 100vw;
  text-align: left;
  overflow: hidden;
}
@media (min-width: 950px) {
  .keno-classic-game .keno-content-wrapper {
    width: 100%;
    overflow: visible;
  }
}
.keno-classic-game .rows-container {
  height: 142.3vw;
  font-size: 0;
  white-space: nowrap;
  padding-top: 5.333vw;
  padding-bottom: 4.8vw;
  position: relative;
  will-change: transform;
  overflow: hidden;
  /*&.slide-to-left {
			.animation(slide-to-left-animation @rows-sliding-animation-duration forwards linear);
			.next-next-row {
				.animation(slide-to-left-next-next-row-animation @rows-sliding-animation-duration forwards linear);
			}
		}
		&.slide-to-left-half {
			.animation(slide-to-left-half-animation @rows-sliding-animation-duration forwards linear);
			.next-next-row {
				.animation(slide-to-left-half-next-next-row-animation @rows-sliding-animation-duration forwards linear);
			}
		}*/
  /*.new-row:not(.next-next-row) {
			.animation(slide-to-right-next-row-animation @rows-sliding-animation-duration forwards linear);
		}*/
}
@media (min-width: 950px) {
  .keno-classic-game .rows-container {
    height: 56.5rem;
    padding-top: 4rem;
    padding-bottom: 2rem;
  }
}
@-webkit-keyframes keno-slide-to-right-animation {
  /*0% { .transform(translate3D(0, 0, 0)); }
			100% { .transform(translate3D(-37%, 0, 0)); }*/
}
@-moz-keyframes keno-slide-to-right-animation {
  /*0% { .transform(translate3D(0, 0, 0)); }
			100% { .transform(translate3D(-37%, 0, 0)); }*/
}
@-ms-keyframes keno-slide-to-right-animation {
  /*0% { .transform(translate3D(0, 0, 0)); }
			100% { .transform(translate3D(-37%, 0, 0)); }*/
}
@keyframes keno-slide-to-right-animation {
  /*0% { .transform(translate3D(0, 0, 0)); }
			100% { .transform(translate3D(-37%, 0, 0)); }*/
}
@-webkit-keyframes keno-slide-to-right-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-moz-keyframes keno-slide-to-right-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-ms-keyframes keno-slide-to-right-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@keyframes keno-slide-to-right-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-webkit-keyframes keno-slide-to-right-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes keno-slide-to-right-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes keno-slide-to-right-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes keno-slide-to-right-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-webkit-keyframes keno-slide-to-right-prev-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes keno-slide-to-right-prev-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes keno-slide-to-right-prev-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes keno-slide-to-right-prev-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-webkit-keyframes keno-slide-to-right-prev-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes keno-slide-to-right-prev-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes keno-slide-to-right-prev-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes keno-slide-to-right-prev-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-webkit-keyframes keno-slide-to-left-animation {
  /*0% { .transform(translate3D(-74%, 0, 0)); }
			100% { .transform(translate3D(-37%, 0, 0)); }*/
}
@-moz-keyframes keno-slide-to-left-animation {
  /*0% { .transform(translate3D(-74%, 0, 0)); }
			100% { .transform(translate3D(-37%, 0, 0)); }*/
}
@-ms-keyframes keno-slide-to-left-animation {
  /*0% { .transform(translate3D(-74%, 0, 0)); }
			100% { .transform(translate3D(-37%, 0, 0)); }*/
}
@keyframes keno-slide-to-left-animation {
  /*0% { .transform(translate3D(-74%, 0, 0)); }
			100% { .transform(translate3D(-37%, 0, 0)); }*/
}
@-webkit-keyframes keno-slide-to-left-half-animation {
  /*0% { .transform(translate3D(-37%, 0, 0)); }
			100% { .transform(translate3D(0, 0, 0)); }*/
}
@-moz-keyframes keno-slide-to-left-half-animation {
  /*0% { .transform(translate3D(-37%, 0, 0)); }
			100% { .transform(translate3D(0, 0, 0)); }*/
}
@-ms-keyframes keno-slide-to-left-half-animation {
  /*0% { .transform(translate3D(-37%, 0, 0)); }
			100% { .transform(translate3D(0, 0, 0)); }*/
}
@keyframes keno-slide-to-left-half-animation {
  /*0% { .transform(translate3D(-37%, 0, 0)); }
			100% { .transform(translate3D(0, 0, 0)); }*/
}
@-webkit-keyframes keno-slide-to-left-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-moz-keyframes keno-slide-to-left-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-ms-keyframes keno-slide-to-left-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@keyframes keno-slide-to-left-infocus-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
}
@-webkit-keyframes keno-slide-to-left-next-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes keno-slide-to-left-next-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes keno-slide-to-left-next-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes keno-slide-to-left-next-row-animation {
  0% {
    -webkit-transform: scale(1) translate3D(0, 0, 0);
    -moz-transform: scale(1) translate3D(0, 0, 0);
    -ms-transform: scale(1) translate3D(0, 0, 0);
    -o-transform: scale(1) translate3D(0, 0, 0);
    transform: scale(1) translate3D(0, 0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-webkit-keyframes keno-slide-to-right-next-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes keno-slide-to-right-next-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes keno-slide-to-right-next-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes keno-slide-to-right-next-next-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(115%, 1%, 0);
    transform: scale(0.9) translate3D(115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(185%, 1%, 0);
    transform: scale(0.9) translate3D(185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-webkit-keyframes keno-slide-to-left-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-moz-keyframes keno-slide-to-left-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@-ms-keyframes keno-slide-to-left-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@keyframes keno-slide-to-left-prev-row-animation {
  0% {
    -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
    transform: scale(0.9) translate3D(-185%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
    -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
    transform: scale(0.9) translate3D(-115%, 1%, 0);
    -webkit-opacity: 0.5;
    -moz-opacity: 0.5;
    opacity: 0.5;
  }
}
@media (min-width: 950px) {
  @-webkit-keyframes keno-slide-to-right-animation {
    /*0% { .transform(translate3D(0, 0, 0)); }
				100% { .transform(translate3D(-29rem, 0, 0)); }*/
  }
  @-moz-keyframes keno-slide-to-right-animation {
    /*0% { .transform(translate3D(0, 0, 0)); }
				100% { .transform(translate3D(-29rem, 0, 0)); }*/
  }
  @-ms-keyframes keno-slide-to-right-animation {
    /*0% { .transform(translate3D(0, 0, 0)); }
				100% { .transform(translate3D(-29rem, 0, 0)); }*/
  }
  @keyframes keno-slide-to-right-animation {
    /*0% { .transform(translate3D(0, 0, 0)); }
				100% { .transform(translate3D(-29rem, 0, 0)); }*/
  }
  @-webkit-keyframes keno-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-moz-keyframes keno-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-ms-keyframes keno-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @keyframes keno-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-webkit-keyframes keno-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes keno-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes keno-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes keno-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes keno-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes keno-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes keno-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes keno-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes keno-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes keno-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes keno-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes keno-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes keno-slide-to-left-half-animation {
    /*0% { .transform(translate3D(-29rem, 0, 0)); }
				100% { .transform(translateX(0%)); }*/
  }
  @-moz-keyframes keno-slide-to-left-half-animation {
    /*0% { .transform(translate3D(-29rem, 0, 0)); }
				100% { .transform(translateX(0%)); }*/
  }
  @-ms-keyframes keno-slide-to-left-half-animation {
    /*0% { .transform(translate3D(-29rem, 0, 0)); }
				100% { .transform(translateX(0%)); }*/
  }
  @keyframes keno-slide-to-left-half-animation {
    /*0% { .transform(translate3D(-29rem, 0, 0)); }
				100% { .transform(translateX(0%)); }*/
  }
  @-webkit-keyframes keno-slide-to-left-animation {
    /*0% { .transform(translate3D(-58rem, 0, 0)); }
				100% { .transform(translate3D(-29rem, 0, 0)); }*/
  }
  @-moz-keyframes keno-slide-to-left-animation {
    /*0% { .transform(translate3D(-58rem, 0, 0)); }
				100% { .transform(translate3D(-29rem, 0, 0)); }*/
  }
  @-ms-keyframes keno-slide-to-left-animation {
    /*0% { .transform(translate3D(-58rem, 0, 0)); }
				100% { .transform(translate3D(-29rem, 0, 0)); }*/
  }
  @keyframes keno-slide-to-left-animation {
    /*0% { .transform(translate3D(-58rem, 0, 0)); }
				100% { .transform(translate3D(-29rem, 0, 0)); }*/
  }
  @-webkit-keyframes keno-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-moz-keyframes keno-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-ms-keyframes keno-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @keyframes keno-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-webkit-keyframes keno-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes keno-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes keno-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes keno-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes keno-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes keno-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes keno-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes keno-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes keno-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes keno-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes keno-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes keno-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
}
@media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: 950px) {
  @-webkit-keyframes keno-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-moz-keyframes keno-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-ms-keyframes keno-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @keyframes keno-slide-to-right-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-webkit-keyframes keno-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes keno-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes keno-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes keno-slide-to-right-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes keno-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes keno-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes keno-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes keno-slide-to-right-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes keno-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes keno-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes keno-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes keno-slide-to-right-prev-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes keno-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-moz-keyframes keno-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-ms-keyframes keno-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @keyframes keno-slide-to-left-infocus-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
  }
  @-webkit-keyframes keno-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes keno-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes keno-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes keno-slide-to-left-next-row-animation {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes keno-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes keno-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes keno-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes keno-slide-to-left-next-next-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-webkit-keyframes keno-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-moz-keyframes keno-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @-ms-keyframes keno-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
  @keyframes keno-slide-to-left-prev-row-animation {
    0% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
    100% {
      -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
      -webkit-opacity: 0.5;
      -moz-opacity: 0.5;
      opacity: 0.5;
    }
  }
}
.keno-classic-game .rows-container.slide-to-right {
  -webkit-animation: keno-slide-to-right-animation 0.6s forwards ease-in-out;
  -moz-animation: keno-slide-to-right-animation 0.6s forwards ease-in-out;
  -ms-animation: keno-slide-to-right-animation 0.6s forwards ease-in-out;
  animation: keno-slide-to-right-animation 0.6s forwards ease-in-out;
}
.keno-classic-game .rows-container.slide-to-right .in-focus {
  -webkit-animation: keno-slide-to-right-infocus-row-animation 0.6s forwards ease-in-out;
  -moz-animation: keno-slide-to-right-infocus-row-animation 0.6s forwards ease-in-out;
  -ms-animation: keno-slide-to-right-infocus-row-animation 0.6s forwards ease-in-out;
  animation: keno-slide-to-right-infocus-row-animation 0.6s forwards ease-in-out;
}
.keno-classic-game .rows-container.slide-to-right .next-row {
  -webkit-animation: keno-slide-to-right-next-row-animation 0.6s forwards ease-in-out;
  -moz-animation: keno-slide-to-right-next-row-animation 0.6s forwards ease-in-out;
  -ms-animation: keno-slide-to-right-next-row-animation 0.6s forwards ease-in-out;
  animation: keno-slide-to-right-next-row-animation 0.6s forwards ease-in-out;
}
.keno-classic-game .rows-container.slide-to-right .prev-row {
  -webkit-animation: keno-slide-to-right-prev-row-animation 0.6s forwards ease-in-out;
  -moz-animation: keno-slide-to-right-prev-row-animation 0.6s forwards ease-in-out;
  -ms-animation: keno-slide-to-right-prev-row-animation 0.6s forwards ease-in-out;
  animation: keno-slide-to-right-prev-row-animation 0.6s forwards ease-in-out;
}
.keno-classic-game .rows-container.slide-to-right .prev-prev-row {
  -webkit-animation: keno-slide-to-right-prev-prev-row-animation 0.6s forwards ease-in-out;
  -moz-animation: keno-slide-to-right-prev-prev-row-animation 0.6s forwards ease-in-out;
  -ms-animation: keno-slide-to-right-prev-prev-row-animation 0.6s forwards ease-in-out;
  animation: keno-slide-to-right-prev-prev-row-animation 0.6s forwards ease-in-out;
}
.keno-classic-game .rows-container.slide-to-left,
.keno-classic-game .rows-container.slide-to-left-half {
  -webkit-animation: keno-slide-to-left-animation 0.6s forwards linear;
  -moz-animation: keno-slide-to-left-animation 0.6s forwards linear;
  -ms-animation: keno-slide-to-left-animation 0.6s forwards linear;
  animation: keno-slide-to-left-animation 0.6s forwards linear;
}
.keno-classic-game .rows-container.slide-to-left .in-focus,
.keno-classic-game .rows-container.slide-to-left-half .in-focus {
  -webkit-animation: keno-slide-to-left-infocus-row-animation 0.6s forwards linear;
  -moz-animation: keno-slide-to-left-infocus-row-animation 0.6s forwards linear;
  -ms-animation: keno-slide-to-left-infocus-row-animation 0.6s forwards linear;
  animation: keno-slide-to-left-infocus-row-animation 0.6s forwards linear;
}
.keno-classic-game .rows-container.slide-to-left .next-row,
.keno-classic-game .rows-container.slide-to-left-half .next-row {
  -webkit-animation: keno-slide-to-left-next-row-animation 0.6s forwards linear;
  -moz-animation: keno-slide-to-left-next-row-animation 0.6s forwards linear;
  -ms-animation: keno-slide-to-left-next-row-animation 0.6s forwards linear;
  animation: keno-slide-to-left-next-row-animation 0.6s forwards linear;
}
.keno-classic-game .rows-container.slide-to-left .next-next-row,
.keno-classic-game .rows-container.slide-to-left-half .next-next-row {
  -webkit-animation: keno-slide-to-left-next-next-row-animation 0.6s forwards linear;
  -moz-animation: keno-slide-to-left-next-next-row-animation 0.6s forwards linear;
  -ms-animation: keno-slide-to-left-next-next-row-animation 0.6s forwards linear;
  animation: keno-slide-to-left-next-next-row-animation 0.6s forwards linear;
}
.keno-classic-game .rows-container.slide-to-left .prev-row,
.keno-classic-game .rows-container.slide-to-left-half .prev-row {
  -webkit-animation: keno-slide-to-left-prev-row-animation 0.6s forwards linear;
  -moz-animation: keno-slide-to-left-prev-row-animation 0.6s forwards linear;
  -ms-animation: keno-slide-to-left-prev-row-animation 0.6s forwards linear;
  animation: keno-slide-to-left-prev-row-animation 0.6s forwards linear;
}
.keno-classic-game .rows-container.rows-static-position {
  /*@media (min-width: @keno-desktop-width) {

			}
			.in-focus{
				@media (min-width: @keno-desktop-width) {
					.transform(matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,-290,0,0,1));
				}
			}*/
}
.keno-classic-game .rows-container.slide-to-left .in-focus + .next-row:not(.interacted-row):last-of-type,
.keno-classic-game .rows-container.slide-to-left-half .in-focus + .next-row:not(.interacted-row):last-of-type {
  -webkit-animation: keno-slide-to-left-next-row-animation 0.6s forwards linear;
  -moz-animation: keno-slide-to-left-next-row-animation 0.6s forwards linear;
  -ms-animation: keno-slide-to-left-next-row-animation 0.6s forwards linear;
  animation: keno-slide-to-left-next-row-animation 0.6s forwards linear;
}
@-webkit-keyframes keno-delete-row-animation-in-focus-row {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    -moz-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    -ms-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    -o-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-moz-keyframes keno-delete-row-animation-in-focus-row {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    -moz-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    -ms-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    -o-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@-ms-keyframes keno-delete-row-animation-in-focus-row {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    -moz-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    -ms-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    -o-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@keyframes keno-delete-row-animation-in-focus-row {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  100% {
    -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    -moz-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    -ms-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    -o-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 1200, 0, 1);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
  }
}
@media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: 950px) {
  @-webkit-keyframes keno-delete-row-animation-in-focus-row {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      -moz-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      -ms-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      -o-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      -webkit-opacity: 0;
      -moz-opacity: 0;
      opacity: 0;
    }
  }
  @-moz-keyframes keno-delete-row-animation-in-focus-row {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      -moz-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      -ms-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      -o-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      -webkit-opacity: 0;
      -moz-opacity: 0;
      opacity: 0;
    }
  }
  @-ms-keyframes keno-delete-row-animation-in-focus-row {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      -moz-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      -ms-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      -o-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      -webkit-opacity: 0;
      -moz-opacity: 0;
      opacity: 0;
    }
  }
  @keyframes keno-delete-row-animation-in-focus-row {
    0% {
      -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      -webkit-opacity: 1;
      -moz-opacity: 1;
      opacity: 1;
    }
    100% {
      -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      -moz-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      -ms-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      -o-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, -0.0002, 0, 0, 1, 0, 0, 900, 0, 1);
      -webkit-opacity: 0;
      -moz-opacity: 0;
      opacity: 0;
    }
  }
}
.keno-classic-game .rows-container.delete-row > .in-focus {
  -webkit-animation: keno-delete-row-animation-in-focus-row 0.5s 0s forwards linear;
  -moz-animation: keno-delete-row-animation-in-focus-row 0.5s 0s forwards linear;
  -ms-animation: keno-delete-row-animation-in-focus-row 0.5s 0s forwards linear;
  animation: keno-delete-row-animation-in-focus-row 0.5s 0s forwards linear;
}
.keno-classic-game .rows-container.delete-row > .in-focus + .next-row {
  -webkit-animation: keno-slide-to-right-infocus-row-animation 0.5s 0.15s forwards linear;
  -moz-animation: keno-slide-to-right-infocus-row-animation 0.5s 0.15s forwards linear;
  -ms-animation: keno-slide-to-right-infocus-row-animation 0.5s 0.15s forwards linear;
  animation: keno-slide-to-right-infocus-row-animation 0.5s 0.15s forwards linear;
}
.keno-classic-game .rows-container.delete-row > .in-focus + .next-row + div {
  -webkit-animation: keno-delete-row-animation-next-next-row 0.5s 0.15s forwards linear;
  -moz-animation: keno-delete-row-animation-next-next-row 0.5s 0.15s forwards linear;
  -ms-animation: keno-delete-row-animation-next-next-row 0.5s 0.15s forwards linear;
  animation: keno-delete-row-animation-next-next-row 0.5s 0.15s forwards linear;
}
@keyframes keno-row-blur-aniamtion {
  0% {
    filter: blur(0);
  }
  5% {
    filter: blur(1rem);
  }
  95% {
    filter: blur(1rem);
  }
  100% {
    filter: blur(0);
  }
}
@keyframes keno-instructions-box-one {
  0% {
    transform: translate(-50%, -150%) scale(0.6);
    opacity: 0;
  }
  5% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  95% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 50%) scale(0.6);
    opacity: 0;
  }
}
@keyframes keno-instructions-box-two {
  0% {
    transform: translate(90%, -50%) scale(0.6);
    opacity: 0;
  }
  5% {
    transform: translate(90%, -50%) scale(1);
    opacity: 1;
  }
  95% {
    transform: translate(90%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(90%, -50%) scale(0.6);
    opacity: 0;
  }
}
@keyframes keno-instructions-box-three {
  0% {
    transform: translate(180%, -150%) scale(0.6);
    opacity: 0;
  }
  5% {
    transform: translate(180%, -50%) scale(1);
    opacity: 1;
  }
  95% {
    transform: translate(180%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(180%, 50%) scale(0.6);
    opacity: 0;
  }
}
@media (min-width: 950px) {
  .keno-classic-game .rows-container.first-new-row-valid .new-row .cell,
  .keno-classic-game .rows-container.new-row-valid .new-row .cell {
    transform-style: preserve-3d;
    transform: rotateX(0) translateZ(0);
    perspective: 21.14vw;
  }
}
@media (min-width: 950px) {
  .keno-classic-game .rows-container.first-new-row-valid .new-row .cell .box,
  .keno-classic-game .rows-container.new-row-valid .new-row .cell .box {
    transform-style: preserve-3d;
    transform: translate3D(0, 0, -4.5275vw);
    transform: translate3D(0, 0, -2rem);
  }
}
@keyframes keno-flip-face-demo-animation {
  from {
    transform: rotateX(0deg) translate3D(0, 0, 4.5275vw);
  }
  to {
    transform: rotateX(-90deg) translate3D(0, 0, 4.5275vw);
  }
}
@keyframes keno-flip-back-demo-animation {
  from {
    transform: rotateX(90deg) translate3D(0, 0, 4.5275vw);
  }
  to {
    transform: rotateX(0deg) translate3D(0, 0, 4.5275vw);
  }
}
@media (min-width: 950px) {
  @keyframes keno-flip-face-demo-animation {
    0% {
      transform: rotateX(0deg) translate3D(0, 0, 2rem);
    }
    30% {
      transform: rotateX(-90deg) translate3D(0, 0, 2rem);
    }
    70% {
      transform: rotateX(-270deg) translate3D(0, 0, 2rem);
    }
    100% {
      transform: rotateX(-360deg) translate3D(0, 0, 2rem);
    }
  }
  @keyframes keno-flip-back-demo-animation {
    0% {
      transform: rotateX(90deg) translate3D(0, 0, 2rem);
    }
    30% {
      transform: rotateX(10deg) translate3D(0, 0, 2rem);
    }
    70% {
      transform: rotateX(-10deg) translate3D(0, 0, 2rem);
    }
    100% {
      transform: rotateX(-90deg) translate3D(0, 0, 2rem);
    }
  }
}
@media (min-width: 950px) {
  .keno-classic-game .rows-container.first-new-row-valid .new-row .cell .box .front-face,
  .keno-classic-game .rows-container.new-row-valid .new-row .cell .box .front-face,
  .keno-classic-game .rows-container.first-new-row-valid .new-row .cell .box .back-face,
  .keno-classic-game .rows-container.new-row-valid .new-row .cell .box .back-face {
    visibility: visible;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    will-change: transform;
  }
}
@media (min-width: 950px) {
  .keno-classic-game .rows-container.first-new-row-valid .new-row .cell .box .front-face,
  .keno-classic-game .rows-container.new-row-valid .new-row .cell .box .front-face {
    transform: rotateX(0deg) translate3D(0, 0, 2rem);
    animation: keno-flip-face-demo-animation 0.8s forwards linear;
  }
}
@media (min-width: 950px) {
  .keno-classic-game .rows-container.first-new-row-valid .new-row .cell .box .back-face,
  .keno-classic-game .rows-container.new-row-valid .new-row .cell .box .back-face {
    transform: rotateX(-90deg) translate3D(0, 0, 2rem);
    animation: keno-flip-back-demo-animation 0.8s forwards linear;
  }
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+1) .box .front-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+1) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+1) .box .back-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+1) .box .back-face {
  animation-delay: 1.5s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+2) .box .front-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+2) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+2) .box .back-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+2) .box .back-face {
  animation-delay: 1.58s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+3) .box .front-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+3) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+3) .box .back-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+3) .box .back-face {
  animation-delay: 1.66s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+4) .box .front-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+4) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+4) .box .back-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+4) .box .back-face {
  animation-delay: 1.74s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+5) .box .front-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+5) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+5) .box .back-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+5) .box .back-face {
  animation-delay: 1.82s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+6) .box .front-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+6) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+6) .box .back-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+6) .box .back-face {
  animation-delay: 1.9s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+7) .box .front-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+7) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+7) .box .back-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+7) .box .back-face {
  animation-delay: 1.98s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+8) .box .front-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+8) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+8) .box .back-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+8) .box .back-face {
  animation-delay: 2.06s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+9) .box .front-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+9) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+9) .box .back-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+9) .box .back-face {
  animation-delay: 2.14s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+10) .box .front-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+10) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+10) .box .back-face,
.keno-classic-game .rows-container.new-row-valid .new-row .cell:nth-child(10n+10) .box .back-face {
  animation-delay: 2.22s;
}
@media (min-width: 950px) {
  .keno-classic-game .rows-container.first-new-row-valid .in-focus {
    animation: keno-row-blur-aniamtion 4.5s linear forwards;
  }
}
@media (min-width: 950px) {
  .keno-classic-game .rows-container.first-new-row-valid .new-row {
    animation: keno-row-blur-aniamtion 3.5s 2s linear forwards;
  }
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+1) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+1) .box .back-face {
  animation-delay: 5.5s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+2) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+2) .box .back-face {
  animation-delay: 5.58s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+3) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+3) .box .back-face {
  animation-delay: 5.66s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+4) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+4) .box .back-face {
  animation-delay: 5.74s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+5) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+5) .box .back-face {
  animation-delay: 5.82s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+6) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+6) .box .back-face {
  animation-delay: 5.9s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+7) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+7) .box .back-face {
  animation-delay: 5.98s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+8) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+8) .box .back-face {
  animation-delay: 6.06s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+9) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+9) .box .back-face {
  animation-delay: 6.14s;
}
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+10) .box .front-face,
.keno-classic-game .rows-container.first-new-row-valid .new-row .cell:nth-child(10n+10) .box .back-face {
  animation-delay: 6.22s;
}
@media (min-width: 950px) {
  .keno-classic-game .rows-container.first-new-row-valid .instructions-wrapper {
    width: 58rem;
    height: 100%;
    margin: auto;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1;
    pointer-events: none;
  }
}
@media (min-width: 950px) {
  .keno-classic-game .rows-container.first-new-row-valid .instructions-wrapper .instructions-text-box {
    top: 50%;
    color: #fff;
    position: absolute;
    white-space: normal;
    padding: 2rem;
    text-align: center;
    line-height: 1.1;
    font-weight: bold;
  }
}
.keno-classic-game .rows-container.first-new-row-valid .instructions-wrapper .instructions-text-box:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  filter: blur(1rem);
}
@media (min-width: 950px) {
  .keno-classic-game .rows-container.first-new-row-valid .instructions-wrapper .instructions-text-box.box-one {
    width: 40rem;
    font-size: 5rem;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: keno-instructions-box-one 4.5s linear forwards;
  }
}
@media (min-width: 950px) {
  .keno-classic-game .rows-container.first-new-row-valid .instructions-wrapper .instructions-text-box.box-two {
    width: 11rem;
    height: 11rem;
    font-size: 5rem;
    line-height: 2;
    right: 0;
    transform: translate(90%, -50%);
    opacity: 0;
    animation: keno-instructions-box-two 3.5s 0.9s linear forwards;
    border-radius: 50%;
  }
  .keno-classic-game .rows-container.first-new-row-valid .instructions-wrapper .instructions-text-box.box-two:after {
    background-color: rgba(5, 145, 218, 0.9);
    border-radius: 50%;
  }
}
@media (min-width: 950px) {
  .keno-classic-game .rows-container.first-new-row-valid .instructions-wrapper .instructions-text-box.box-three {
    width: 20rem;
    font-size: 5rem;
    right: 7rem;
    transform: translate(180%, -50%);
    opacity: 0;
    animation: keno-instructions-box-three 3.5s 2s linear forwards;
  }
}
.keno-classic-game .left-gradient,
.keno-classic-game .right-gradient {
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
}
@media (min-width: 950px) {
  .keno-classic-game .left-gradient,
  .keno-classic-game .right-gradient {
    width: 19vw;
  }
}
.keno-classic-game .left-gradient {
  left: 0;
  background: -moz-linear-gradient(left, #00a5eb 0%, rgba(0, 165, 235, 0) 100%);
  background: -webkit-linear-gradient(left, #00a5eb 0%, rgba(0, 165, 235, 0) 100%);
  background: linear-gradient(to right, #00a5eb 0%, rgba(0, 165, 235, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00a5eb', endColorstr='#0000a5eb', GradientType=1);
}
.keno-classic-game .right-gradient {
  right: -19vw;
  background: -moz-linear-gradient(left, rgba(0, 165, 235, 0) 0%, #00a5eb 100%);
  background: -webkit-linear-gradient(left, rgba(0, 165, 235, 0) 0%, #00a5eb 100%);
  background: linear-gradient(to right, rgba(0, 165, 235, 0) 0%, #00a5eb 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0000a5eb', endColorstr='#00a5eb', GradientType=1);
  transition: right .25S linear;
}
.keno-classic-game .right-gradient.active {
  right: 0;
}
.keno-classic-game .rows-wrapper {
  filter: url("#rows-side-sliding-blur");
}
.keno-classic-game .row-container {
  width: 75.235%;
  padding-bottom: 4.8vw;
  border-bottom: .2rem solid #4dc0f1;
  display: inline-block;
  position: absolute;
  visibility: hidden;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
}
@media (min-width: 950px) {
  .keno-classic-game .row-container {
    width: 58rem;
    padding-bottom: 1rem;
  }
}
.keno-classic-game .row-container.in-focus,
.keno-classic-game .row-container.next-row,
.keno-classic-game .row-container.prev-row,
.keno-classic-game .row-container.next-next-row,
.keno-classic-game .row-container.prev-prev-row {
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
  visibility: visible;
  will-change: transform, opacity;
}
.keno-classic-game .row-container.in-focus {
  -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -moz-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -ms-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -o-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  z-index: 1;
}
.keno-classic-game .row-container.next-row:after,
.keno-classic-game .row-container.prev-row:after,
.keno-classic-game .row-container.prev-prev-row:after,
.keno-classic-game .row-container.next-next-row:after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.keno-classic-game .row-container.next-row {
  -webkit-transform: scale(0.9) translate3D(115%, 1%, 0);
  -moz-transform: scale(0.9) translate3D(115%, 1%, 0);
  -ms-transform: scale(0.9) translate3D(115%, 1%, 0);
  -o-transform: scale(0.9) translate3D(115%, 1%, 0);
  transform: scale(0.9) translate3D(115%, 1%, 0);
}
@media (min-width: 950px) {
  .keno-classic-game .row-container.next-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 650, 9, 0, 1);
  }
}
@media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: 950px) {
  .keno-classic-game .row-container.next-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 487.5, 9, 0, 1);
  }
}
.keno-classic-game .row-container.next-next-row {
  -webkit-transform: scale(0.9) translate3D(185%, 1%, 0);
  -moz-transform: scale(0.9) translate3D(185%, 1%, 0);
  -ms-transform: scale(0.9) translate3D(185%, 1%, 0);
  -o-transform: scale(0.9) translate3D(185%, 1%, 0);
  transform: scale(0.9) translate3D(185%, 1%, 0);
}
@media (min-width: 950px) {
  .keno-classic-game .row-container.next-next-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 1200, 9, 0, 1);
  }
}
@media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: 950px) {
  .keno-classic-game .row-container.next-next-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, 900, 9, 0, 1);
  }
}
.keno-classic-game .row-container.prev-row {
  -webkit-transform: scale(0.9) translate3D(-115%, 1%, 0);
  -moz-transform: scale(0.9) translate3D(-115%, 1%, 0);
  -ms-transform: scale(0.9) translate3D(-115%, 1%, 0);
  -o-transform: scale(0.9) translate3D(-115%, 1%, 0);
  transform: scale(0.9) translate3D(-115%, 1%, 0);
}
@media (min-width: 950px) {
  .keno-classic-game .row-container.prev-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -650, 9, 0, 1);
  }
}
@media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: 950px) {
  .keno-classic-game .row-container.prev-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -487.5, 9, 0, 1);
  }
}
.keno-classic-game .row-container.prev-prev-row {
  -webkit-transform: scale(0.9) translate3D(-185%, 1%, 0);
  -moz-transform: scale(0.9) translate3D(-185%, 1%, 0);
  -ms-transform: scale(0.9) translate3D(-185%, 1%, 0);
  -o-transform: scale(0.9) translate3D(-185%, 1%, 0);
  transform: scale(0.9) translate3D(-185%, 1%, 0);
}
@media (min-width: 950px) {
  .keno-classic-game .row-container.prev-prev-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -1200, 9, 0, 1);
  }
}
@media screen and (min-resolution: 1.2dppx) and (min-resolution: 115dpi) and (min-device-width: 950px) {
  .keno-classic-game .row-container.prev-prev-row {
    -webkit-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
    -moz-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
    -ms-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
    -o-transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
    transform: matrix3d(0.89, 0, 0, 0, 0, 0.89, 0, 0, 0, 0, 1, 0, -900, 9, 0, 1);
  }
}
@-webkit-keyframes keno-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-moz-keyframes keno-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-ms-keyframes keno-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@keyframes keno-number-cell-shake-animation {
  0% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
  10% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  20% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  30% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  40% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  50% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  60% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  70% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  80% {
    -webkit-transform: scale(1.13) rotate(-5deg);
    -moz-transform: scale(1.13) rotate(-5deg);
    -ms-transform: scale(1.13) rotate(-5deg);
    -o-transform: scale(1.13) rotate(-5deg);
    transform: scale(1.13) rotate(-5deg);
  }
  90% {
    -webkit-transform: scale(1.13) rotate(5deg);
    -moz-transform: scale(1.13) rotate(5deg);
    -ms-transform: scale(1.13) rotate(5deg);
    -o-transform: scale(1.13) rotate(5deg);
    transform: scale(1.13) rotate(5deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
    -moz-transform: scale(1) rotate(0);
    -ms-transform: scale(1) rotate(0);
    -o-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
  }
}
@-webkit-keyframes keno-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes keno-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes keno-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes keno-rules-pulse-animation {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.13);
    -moz-transform: scale(1.13);
    -ms-transform: scale(1.13);
    -o-transform: scale(1.13);
    transform: scale(1.13);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.keno-classic-game .row-container.shake-state .selected {
  -webkit-animation: keno-number-cell-shake-animation 0.8s forwards;
  -moz-animation: keno-number-cell-shake-animation 0.8s forwards;
  -ms-animation: keno-number-cell-shake-animation 0.8s forwards;
  animation: keno-number-cell-shake-animation 0.8s forwards;
}
.keno-classic-game .row-container.shake-state .header-rules-text {
  -webkit-animation: keno-rules-pulse-animation 0.8s forwards;
  -moz-animation: keno-rules-pulse-animation 0.8s forwards;
  -ms-animation: keno-rules-pulse-animation 0.8s forwards;
  animation: keno-rules-pulse-animation 0.8s forwards;
}
.keno-classic-game .row-header {
  font-size: 3.734vw;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  padding-bottom: 3.334vw;
  border-bottom: .2rem solid #4dc0f1;
}
.keno-classic-game .row-header:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
@media (min-width: 950px) {
  .keno-classic-game .row-header {
    font-size: 2rem;
    padding-bottom: 1.3rem;
  }
}
.keno-classic-game .row-header .counter {
  padding-right: 2.666vw;
  float: left;
}
@media (min-width: 950px) {
  .keno-classic-game .row-header .counter {
    padding-right: 2rem;
  }
}
.keno-classic-game .row-header .right-side {
  padding-right: 2.667vw;
  position: relative;
}
@media (min-width: 950px) {
  .keno-classic-game .row-header .right-side {
    padding-right: 1.5rem;
  }
}
.keno-classic-game .row-header .right-side .row-delete-button {
  display: block;
  position: absolute;
  top: .75rem;
  right: 0;
  cursor: pointer;
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -ms-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.keno-classic-game .row-header .right-side .row-delete-button .icon-x-big.icon {
  width: 2.667vw;
  height: 2.667vw;
  fill: #fff;
}
@media (min-width: 950px) {
  .keno-classic-game .row-header .right-side .row-delete-button .icon-x-big.icon {
    width: 1.2rem;
    height: 1.2rem;
  }
}
.keno-classic-game .row-header .right-side .row-delete-button.inactive {
  -webkit-opacity: 0.3;
  -moz-opacity: 0.3;
  opacity: 0.3;
  cursor: default;
}
.keno-classic-game .row-header .header-rules-text {
  color: #1e55a0;
  display: inline-block;
}
.keno-classic-game .prev-row-button {
  width: 10.5vw;
  height: 32vw;
  background-color: rgba(235, 248, 253, 0.8);
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 0 6px 6px 0;
  -webkit-transform: translateY(-50%) translateX(-10.5vw);
  -moz-transform: translateY(-50%) translateX(-10.5vw);
  -ms-transform: translateY(-50%) translateX(-10.5vw);
  -o-transform: translateY(-50%) translateX(-10.5vw);
  transform: translateY(-50%) translateX(-10.5vw);
  -webkit-transition: -webkit-transform 0.15s;
  -moz-transition: -moz-transform 0.15s;
  -ms-transition: -ms-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  z-index: 1;
}
@media (min-width: 950px) {
  .keno-classic-game .prev-row-button {
    width: 4.2rem;
    height: 14rem;
    -webkit-transform: translateY(-50%) translateX(-4.2rem);
    -moz-transform: translateY(-50%) translateX(-4.2rem);
    -ms-transform: translateY(-50%) translateX(-4.2rem);
    -o-transform: translateY(-50%) translateX(-4.2rem);
    transform: translateY(-50%) translateX(-4.2rem);
    cursor: pointer;
  }
}
.keno-classic-game .prev-row-button .icon {
  width: 2.4vw;
  height: 3.468vw;
  font-size: 4.5vw;
  line-height: .55;
  fill: #3f6372;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-1.5vw);
  -moz-transform: translateY(-50%) translateX(-1.5vw);
  -ms-transform: translateY(-50%) translateX(-1.5vw);
  -o-transform: translateY(-50%) translateX(-1.5vw);
  transform: translateY(-50%) translateX(-1.5vw);
}
@media (min-width: 950px) {
  .keno-classic-game .prev-row-button .icon {
    width: 1.2rem;
    height: 1.2rem;
    -webkit-transform: translateY(-50%) translateX(-0.6rem);
    -moz-transform: translateY(-50%) translateX(-0.6rem);
    -ms-transform: translateY(-50%) translateX(-0.6rem);
    -o-transform: translateY(-50%) translateX(-0.6rem);
    transform: translateY(-50%) translateX(-0.6rem);
  }
}
.keno-classic-game .prev-row-button.active {
  -webkit-transform: translateY(-50%) translateX(-1.5vw);
  -moz-transform: translateY(-50%) translateX(-1.5vw);
  -ms-transform: translateY(-50%) translateX(-1.5vw);
  -o-transform: translateY(-50%) translateX(-1.5vw);
  transform: translateY(-50%) translateX(-1.5vw);
}
@media (min-width: 950px) {
  .keno-classic-game .prev-row-button.active {
    -webkit-transform: translateY(-50%) translateX(0);
    -moz-transform: translateY(-50%) translateX(0);
    -ms-transform: translateY(-50%) translateX(0);
    -o-transform: translateY(-50%) translateX(0);
    transform: translateY(-50%) translateX(0);
  }
}
.keno-classic-game .create-new-row-button,
.keno-classic-game .next-row-button {
  width: 10.5vw;
  height: 32vw;
  background-color: rgba(235, 248, 253, 0.8);
  position: absolute;
  right: 0;
  top: 50%;
  border-radius: 6px 0 0 6px;
  -webkit-transform: translateY(-50%) translateX(10.5vw);
  -moz-transform: translateY(-50%) translateX(10.5vw);
  -ms-transform: translateY(-50%) translateX(10.5vw);
  -o-transform: translateY(-50%) translateX(10.5vw);
  transform: translateY(-50%) translateX(10.5vw);
  -webkit-transition: -webkit-transform 0.15s;
  -moz-transition: -moz-transform 0.15s;
  -ms-transition: -ms-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  z-index: 1;
}
@media (min-width: 950px) {
  .keno-classic-game .create-new-row-button,
  .keno-classic-game .next-row-button {
    width: 4.2rem;
    height: 14rem;
    -webkit-transform: translateY(-50%) translateX(4.2rem);
    -moz-transform: translateY(-50%) translateX(4.2rem);
    -ms-transform: translateY(-50%) translateX(4.2rem);
    -o-transform: translateY(-50%) translateX(4.2rem);
    transform: translateY(-50%) translateX(4.2rem);
    cursor: pointer;
  }
}
.keno-classic-game .create-new-row-button .icon,
.keno-classic-game .next-row-button .icon {
  width: 2.4vw;
  height: 3.468vw;
  font-size: 4.5vw;
  line-height: .55;
  fill: #3f6372;
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-1.5vw);
  -moz-transform: translateY(-50%) translateX(-1.5vw);
  -ms-transform: translateY(-50%) translateX(-1.5vw);
  -o-transform: translateY(-50%) translateX(-1.5vw);
  transform: translateY(-50%) translateX(-1.5vw);
}
@media (min-width: 950px) {
  .keno-classic-game .create-new-row-button .icon,
  .keno-classic-game .next-row-button .icon {
    width: 1.2rem;
    height: 1.2rem;
    -webkit-transform: translateY(-50%) translateX(-0.4rem);
    -moz-transform: translateY(-50%) translateX(-0.4rem);
    -ms-transform: translateY(-50%) translateX(-0.4rem);
    -o-transform: translateY(-50%) translateX(-0.4rem);
    transform: translateY(-50%) translateX(-0.4rem);
  }
}
.keno-classic-game .create-new-row-button.active,
.keno-classic-game .next-row-button.active {
  -webkit-transform: translateY(-50%) translateX(1.5vw);
  -moz-transform: translateY(-50%) translateX(1.5vw);
  -ms-transform: translateY(-50%) translateX(1.5vw);
  -o-transform: translateY(-50%) translateX(1.5vw);
  transform: translateY(-50%) translateX(1.5vw);
}
@media (min-width: 950px) {
  .keno-classic-game .create-new-row-button.active,
  .keno-classic-game .next-row-button.active {
    -webkit-transform: translateY(-50%) translateX(0rem);
    -moz-transform: translateY(-50%) translateX(0rem);
    -ms-transform: translateY(-50%) translateX(0rem);
    -o-transform: translateY(-50%) translateX(0rem);
    transform: translateY(-50%) translateX(0rem);
  }
}
.keno-classic-game .rows-list-box .container {
  width: 87.8vw;
  position: absolute;
  overflow: hidden;
  z-index: 2;
  transform: translate3d(-100%, 0, 0);
  -webkit-transition: -webkit-transform 0.15s;
  -moz-transition: -moz-transform 0.15s;
  -ms-transition: -ms-transform 0.15s;
  -o-transition: -o-transform 0.15s;
  transition: transform 0.15s;
  border-radius: 0 6px 6px 0;
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .container {
    width: 50rem;
  }
}
.keno-classic-game .rows-list-box .container.single-row {
  transform: translate3d(-100%, 30vw, 0);
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .container.single-row {
    transform: translate3d(-100%, 8rem, 0);
  }
}
.keno-classic-game .rows-list-box .container .informations-box,
.keno-classic-game .rows-list-box .container > .headline,
.keno-classic-game .rows-list-box .container .items-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: rgba(235, 248, 253, 0.8);
  -webkit-transition: background-color 0.1s;
  -moz-transition: background-color 0.1s;
  -ms-transition: background-color 0.1s;
  -o-transition: background-color 0.1s;
  transition: background-color 0.1s;
}
.keno-classic-game .rows-list-box .container .informations-box {
  font-size: 3.733vw;
  color: #4f5254;
  padding: 3.34vw 6.667vw 0;
  -webkit-border-radius: 0 6px 0 0;
  -moz-border-radius: 0 6px 0 0;
  border-radius: 0 6px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  position: relative;
  z-index: 2;
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .container .informations-box {
    font-size: 1.5rem;
    padding: 2.5rem 3rem 0;
  }
}
.keno-classic-game .rows-list-box .container .informations-box p {
  margin: 0;
}
.keno-classic-game .rows-list-box .container > .headline {
  color: #00a5eb;
  font-size: 3.734vw;
  font-weight: bold;
  padding: 3.34vw 6.667vw;
  position: relative;
  z-index: 2;
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .container > .headline {
    font-size: 2rem;
    padding: 1.1rem 3rem 1rem;
  }
}
.keno-classic-game .rows-list-box .container .items-box {
  position: relative;
  overflow: hidden;
  overflow-y: auto;
  -webkit-border-radius: 0 0 6px 0;
  -moz-border-radius: 0 0 6px 0;
  border-radius: 0 0 6px 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.keno-classic-game .rows-list-box .container .items-box .items-inner-box .item {
  width: calc(100% - 6.667vw);
  font-size: 3.2vw;
  font-weight: bold;
  line-height: 1.25;
  padding: 3.4vw 0;
  border-top: .2rem solid #cdd3d7;
  position: relative;
  left: 3.333vw;
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .container .items-box .items-inner-box .item {
    width: calc(100% - 6rem);
    font-size: 2rem;
    padding: 1.2rem 0 1.3rem;
    left: 3rem;
  }
}
.keno-classic-game .rows-list-box .container .items-box .items-inner-box .item .headline {
  color: #00a5eb;
  cursor: default;
}
.keno-classic-game .rows-list-box .container .items-box .items-inner-box .item .numbers {
  color: #1e55a0;
  font-style: italic;
}
.keno-classic-game .rows-list-box .container .items-box .items-inner-box .item .delete-button {
  width: 10vw;
  height: 15vw;
  position: absolute;
  top: 0;
  right: 0;
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .container .items-box .items-inner-box .item .delete-button {
    top: 1.2rem;
    width: 3rem;
    height: 5rem;
  }
}
.keno-classic-game .rows-list-box .container .items-box .items-inner-box .item .delete-button .icon-x-big {
  width: 2.667vw;
  height: 2.667vw;
  position: absolute;
  fill: #777a7f;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .container .items-box .items-inner-box .item .delete-button .icon-x-big {
    width: 1.2rem;
    height: 1.2rem;
    cursor: pointer;
    fill: #00a5eb;
  }
}
.keno-classic-game .rows-list-box .container .items-box .items-inner-box .item .delete-button .icon-x-big .icon {
  width: 2.667vw;
  height: 2.667vw;
  position: absolute;
  fill: #777a7f;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .container .items-box .items-inner-box .item .delete-button .icon-x-big .icon {
    width: 1.2rem;
    height: 1.2rem;
  }
}
.keno-classic-game .rows-list-box .container .items-box .items-inner-box .item.invalid-row .headline:before {
  content: '!';
  width: 3.734vw;
  height: 3.734vw;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background-color: #d01212;
  margin-right: 0.934vw;
  position: relative;
  top: 0;
  line-height: 1.3;
  text-indent: 1.3vw;
  color: #fff;
  cursor: pointer;
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .container .items-box .items-inner-box .item.invalid-row .headline:before {
    width: 1.8rem;
    height: 1.8rem;
    font-size: 1.5rem;
    margin-right: .7rem;
    line-height: 1.34;
    text-indent: .65rem;
    top: -0.1rem;
  }
}
.keno-classic-game .rows-list-box .container .items-box .scroll-button {
  height: 12vw;
  left: 0;
  right: 0;
  position: fixed;
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.25s, opacity 0.25s;
  -moz-transition: -moz-transform 0.25s, opacity 0.25s;
  -ms-transition: -ms-transform 0.25s, opacity 0.25s;
  -o-transition: -o-transform 0.25s, opacity 0.25s;
  transition: transform 0.25s, opacity 0.25s;
  z-index: 1;
  cursor: pointer;
  will-change: transform;
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .container .items-box .scroll-button {
    height: 7.7rem;
    right: 1.8rem;
  }
}
.keno-classic-game .rows-list-box .container .items-box .scroll-button.scroll-up {
  background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 50%, rgba(255, 255, 255, 0.7) 75%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(top, #ffffff 0%, #ffffff 50%, rgba(255, 255, 255, 0.7) 75%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 50%, rgba(255, 255, 255, 0.7) 75%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0);
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.keno-classic-game .rows-list-box .container .items-box .scroll-button.scroll-down {
  bottom: 0;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 25%, #ffffff 50%, #ffffff 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 25%, #ffffff 50%, #ffffff 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 25%, #ffffff 50%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0);
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
}
.keno-classic-game .rows-list-box .container .items-box .scroll-button .icon {
  width: 4.266vw;
  height: 2.4vw;
  fill: #00a5eb;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .container .items-box .scroll-button .icon {
    height: 1rem;
  }
}
.keno-classic-game .rows-list-box .container .items-box .scroll-button.active {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}
.keno-classic-game .rows-list-box .container .error-message-box {
  background-color: #e9e9e9;
  color: #4f5254;
  margin-top: -7vw;
  margin-left: -1.333vw;
  padding: 4.666vw 4vw;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  position: absolute;
  z-index: 3;
  left: 3.2vw;
  transform: translate3d(0, -100%, 0);
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  visibility: hidden;
  transition: top .25s, transform .25s, opacity .25s, visibility 0s .3s;
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .container .error-message-box {
    left: 3rem;
    margin-top: -3rem;
    margin-left: -1rem;
    padding: 1.5rem 2rem;
  }
}
.keno-classic-game .rows-list-box .container .error-message-box:after {
  content: '';
  border-left: 1.333vw solid transparent;
  border-right: 1.333vw solid transparent;
  border-top: 1.333vw solid #e9e9e9;
  position: absolute;
  bottom: -1.333vw;
  left: 2vw;
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .container .error-message-box:after {
    border-left: .6rem solid transparent;
    border-right: .6rem solid transparent;
    border-top: .6rem solid #e9e9e9;
    bottom: -0.5rem;
    left: 1.3rem;
  }
}
.keno-classic-game .rows-list-box .container .error-message-box .error-headline {
  font-size: 3.2vw;
  font-weight: bold;
  text-transform: uppercase;
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .container .error-message-box .error-headline {
    font-size: 1.6rem;
  }
}
.keno-classic-game .rows-list-box .container .error-message-box .error-subheadline {
  font-size: 3.2vw;
  font-weight: 100;
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .container .error-message-box .error-subheadline {
    font-size: 1.5rem;
  }
}
.keno-classic-game .rows-list-box .container .error-message-box.active {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0, -110%, 0);
  transition: top .25s, transform .25s, opacity .25s, visibility 0s;
}
.keno-classic-game .rows-list-box .button {
  width: 10.5vw;
  height: 10.5vw;
  background-color: rgba(235, 248, 253, 0.8);
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 0 6px 6px 0;
  overflow: hidden;
  -webkit-transform: translateY(-30vw) translateX(-10.5vw);
  -moz-transform: translateY(-30vw) translateX(-10.5vw);
  -ms-transform: translateY(-30vw) translateX(-10.5vw);
  -o-transform: translateY(-30vw) translateX(-10.5vw);
  transform: translateY(-30vw) translateX(-10.5vw);
  -webkit-transition: -webkit-transform 0.15s, background-color 0.1s;
  -moz-transition: -moz-transform 0.15s, background-color 0.1s;
  -ms-transition: -ms-transform 0.15s, background-color 0.1s;
  -o-transition: -o-transform 0.15s, background-color 0.1s;
  transition: transform 0.15s, background-color 0.1s;
  z-index: 1;
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .button {
    width: 4.2rem;
    height: 7rem;
    -webkit-transform: translateY(-16rem) translateX(-4.2vw);
    -moz-transform: translateY(-16rem) translateX(-4.2vw);
    -ms-transform: translateY(-16rem) translateX(-4.2vw);
    -o-transform: translateY(-16rem) translateX(-4.2vw);
    transform: translateY(-16rem) translateX(-4.2vw);
    cursor: pointer;
  }
}
.keno-classic-game .rows-list-box .button .icon-list {
  width: 3.8vw;
  height: 4vw;
  fill: #3f6372;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-1.6vw);
  -moz-transform: translateY(-50%) translateX(-1.6vw);
  -ms-transform: translateY(-50%) translateX(-1.6vw);
  -o-transform: translateY(-50%) translateX(-1.6vw);
  transform: translateY(-50%) translateX(-1.6vw);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -webkit-transition: -webkit-transform 0.25s, opacity 0.25s;
  -moz-transition: -moz-transform 0.25s, opacity 0.25s;
  -ms-transition: -ms-transform 0.25s, opacity 0.25s;
  -o-transition: -o-transform 0.25s, opacity 0.25s;
  transition: transform 0.25s, opacity 0.25s;
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .button .icon-list {
    width: 1.5rem;
    height: 1.2rem;
    -webkit-transform: translateY(-50%) translateX(-0.7rem);
    -moz-transform: translateY(-50%) translateX(-0.7rem);
    -ms-transform: translateY(-50%) translateX(-0.7rem);
    -o-transform: translateY(-50%) translateX(-0.7rem);
    transform: translateY(-50%) translateX(-0.7rem);
  }
}
.keno-classic-game .rows-list-box .button .icon-left-arrow {
  width: 3.8vw;
  height: 3.47vw;
  fill: #4f5254;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(7vw);
  -moz-transform: translateY(-50%) translateX(7vw);
  -ms-transform: translateY(-50%) translateX(7vw);
  -o-transform: translateY(-50%) translateX(7vw);
  transform: translateY(-50%) translateX(7vw);
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -webkit-transition: -webkit-transform 0.25s, opacity 0.25s;
  -moz-transition: -moz-transform 0.25s, opacity 0.25s;
  -ms-transition: -ms-transform 0.25s, opacity 0.25s;
  -o-transition: -o-transform 0.25s, opacity 0.25s;
  transition: transform 0.25s, opacity 0.25s;
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .button .icon-left-arrow {
    width: 1.5rem;
    height: 1.2rem;
    -webkit-transform: translateY(-50%) translateX(-0.7rem);
    -moz-transform: translateY(-50%) translateX(-0.7rem);
    -ms-transform: translateY(-50%) translateX(-0.7rem);
    -o-transform: translateY(-50%) translateX(-0.7rem);
    transform: translateY(-50%) translateX(-0.7rem);
  }
}
.keno-classic-game .rows-list-box .button.active {
  -webkit-transform: translateY(-30vw) translateX(-1.5vw);
  -moz-transform: translateY(-30vw) translateX(-1.5vw);
  -ms-transform: translateY(-30vw) translateX(-1.5vw);
  -o-transform: translateY(-30vw) translateX(-1.5vw);
  transform: translateY(-30vw) translateX(-1.5vw);
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box .button.active {
    -webkit-transform: translateY(-16rem) translateX(0);
    -moz-transform: translateY(-16rem) translateX(0);
    -ms-transform: translateY(-16rem) translateX(0);
    -o-transform: translateY(-16rem) translateX(0);
    transform: translateY(-16rem) translateX(0);
  }
}
.keno-classic-game .rows-list-box.active .container {
  transform: translate3d(0, 0, 0);
}
.keno-classic-game .rows-list-box.active .container .informations-box,
.keno-classic-game .rows-list-box.active .container > .headline,
.keno-classic-game .rows-list-box.active .container .items-box {
  background-color: #ffffff;
}
.keno-classic-game .rows-list-box.active .container.single-row {
  transform: translate3d(0, 30vw, 0);
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box.active .container.single-row {
    transform: translate3d(0, 8rem, 0);
  }
}
.keno-classic-game .rows-list-box.active .button {
  background-color: #ffffff;
  -webkit-transform: translateY(-30vw) translateX(85.6vw);
  -moz-transform: translateY(-30vw) translateX(85.6vw);
  -ms-transform: translateY(-30vw) translateX(85.6vw);
  -o-transform: translateY(-30vw) translateX(85.6vw);
  transform: translateY(-30vw) translateX(85.6vw);
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box.active .button {
    -webkit-transform: translateY(-16rem) translateX(48.8rem);
    -moz-transform: translateY(-16rem) translateX(48.8rem);
    -ms-transform: translateY(-16rem) translateX(48.8rem);
    -o-transform: translateY(-16rem) translateX(48.8rem);
    transform: translateY(-16rem) translateX(48.8rem);
  }
}
.keno-classic-game .rows-list-box.active .button .icon-list {
  -webkit-transform: translateY(-50%) translateX(-11vw);
  -moz-transform: translateY(-50%) translateX(-11vw);
  -ms-transform: translateY(-50%) translateX(-11vw);
  -o-transform: translateY(-50%) translateX(-11vw);
  transform: translateY(-50%) translateX(-11vw);
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
}
.keno-classic-game .rows-list-box.active .button .icon-left-arrow {
  -webkit-transform: translateY(-50%) translateX(-1.6vw);
  -moz-transform: translateY(-50%) translateX(-1.6vw);
  -ms-transform: translateY(-50%) translateX(-1.6vw);
  -o-transform: translateY(-50%) translateX(-1.6vw);
  transform: translateY(-50%) translateX(-1.6vw);
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}
@media (min-width: 950px) {
  .keno-classic-game .rows-list-box.active .button .icon-left-arrow {
    -webkit-transform: translateY(-50%) translateX(-0.3rem);
    -moz-transform: translateY(-50%) translateX(-0.3rem);
    -ms-transform: translateY(-50%) translateX(-0.3rem);
    -o-transform: translateY(-50%) translateX(-0.3rem);
    transform: translateY(-50%) translateX(-0.3rem);
  }
}
